<ng-container *ngIf="charts">
  <app-line-chart
    *ngIf="chartMode === chartModes.LINE; else barChart"
    [charts]="charts"
    [labels]="labels"
    [height]="chartHeight"
    [tooltip]="tooltip"
    [useLegendWithSelect]="useLegendWithSelect"
    [isEmptyState]="isEmptyState"
    [viewType]="viewType"
  >
  </app-line-chart>
  <ng-template #barChart>
    <app-column-chart
      [values]="charts"
      [options]="{height: chartHeight, tooltip: tooltip, title: 'KPI\'s' }"
      [labels]="labels"
      [useLegendWithSelect]="useLegendWithSelect"
      [isEmptyState]="isEmptyState"
      [viewType]="viewType"
    >
    </app-column-chart>
  </ng-template>
</ng-container>
