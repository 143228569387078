<app-chart-wrapper [chartContext]="chartContext" class="chart-wrapper">
  <app-empty-state-chart *ngIf="isEmptyState" [viewType]="viewType"></app-empty-state-chart>
  <app-chart-legend *ngIf="isGroupedBySeries" [labels]="categories" [colors]="colors"></app-chart-legend>
  <apx-chart
    *ngIf="chartOptions"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [legend]="chartOptions.legend"
    [tooltip]="chartOptions.tooltip"
    [colors]="chartOptions.colors"
    [stroke]="chartOptions.stroke"
    [plotOptions]="chartOptions.plotOptions"
    [states]="chartOptions.states"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [grid]="chartOptions.grid"
  >
  </apx-chart>
</app-chart-wrapper>
