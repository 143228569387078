import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDefined',
})
export class IsDefinedPipe implements PipeTransform {
  public transform(value: unknown): boolean {
    return value !== undefined && value !== null;
  }
}
