import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IStepperItem, TStepperIconsMap } from '@app/shared/types/interfaces';

export interface IStepClickEvent {
  targetStep: IStepperItem;
  stepIndex: number;
}

@Component({
  selector: 'wevestr-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input()
  public steps: IStepperItem[] = [];
  @Input()
  public iconsMap: TStepperIconsMap = { passed: 'check', error: 'cross' };
  @Output()
  public onStepClick = new EventEmitter<IStepClickEvent>();

  public onStepperItemClick(stepperItem: IStepperItem, index: number): void {
    if (stepperItem.disabled !== true) {
      this.onStepClick.emit({ targetStep: stepperItem, stepIndex: index });
    }
  }
}
