import { Component, Input } from '@angular/core';

import { PLATFORM_TITLE } from '@app/onboarding/constants/onboarding.constants';

@Component({
  selector: 'wevestr-side-cover',
  templateUrl: './side-cover.component.html',
  styleUrls: ['./side-cover.component.scss'],
})
export class SideCoverComponent {
  @Input() public title = PLATFORM_TITLE;
}
