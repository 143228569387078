import { Directive, Inject, Input } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { VALIDATION_DELAY } from '@app/forms/constants';
import { VALIDITY_STATUS } from '@app/shared/utils/constants/validators.constants';
import { any, equals, not } from 'ramda';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

const defaultMapping = (v: unknown): unknown => v;

const isValid = equals(VALIDITY_STATUS.VALID);

@Directive({
  selector: '[formControlName][wvLinkControls], [formControl][[wvLinkControls]]',
})
export class LinkControlsDirective {
  @Input('wvLinkControls') public linkedControl!: UntypedFormControl;
  @Input() public mappingFormula: CallableFunction = defaultMapping;
  @Input() public filterByOptions: unknown[] = [null, undefined];

  private unsubscribe$ = new Subject<void>();

  constructor(@Inject(NgControl) private hostControl: UntypedFormControl) {}

  public ngAfterViewInit(): void {
    this.setControlReflection(this.hostControl, this.linkedControl, this.mappingFormula);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setControlReflection(
    base: UntypedFormControl,
    attached: UntypedFormControl,
    formula: CallableFunction,
  ): void {
    base.valueChanges
      .pipe(
        distinctUntilChanged(equals),
        debounceTime(VALIDATION_DELAY * 2),
        withLatestFrom(base.statusChanges),
        filter(([value, status]) => isValid(status) && not(any(equals(value), this.filterByOptions))),
        map(([v]) => formula(v)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((value) => {
        attached.patchValue(value, { onlySelf: true, emitEvent: false });
      });
  }
}
