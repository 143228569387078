<div class="wizard flex-column justify-content-space-between align-items-center">
  <wevestr-stepper
    [steps]="headersStatus$ | async"
    (onStepClick)="onHeaderClick($event)"
    class="wizard__stepper"
  ></wevestr-stepper>
  <div class="welcome-card wizard__card card">
    <ng-content></ng-content>
  </div>
</div>
