import { IRoleMapping } from '@app/types/interfaces/profile.interface';
import { User } from '@app/shared/types/classes';
import { IProfile } from '@app/types/interfaces/profile.interface';

export function isTheSameUser(user1: { id: number }, user2: { id: number }): boolean {
  return user1.id === user2.id;
}

export function getCompanyRole({ profile }: User, companyId: number): IRoleMapping {
  return profile.roles && profile.roles.find((role) => role.companyId === companyId);
}

export function getProfileUserName(
  { shareholders }: IProfile,
  currentCompanyId: number,
): { firstName: string; lastName: string } {
  const currentShareholder = shareholders.find(({ companyId }) => companyId === currentCompanyId);
  return { firstName: currentShareholder.firstName, lastName: currentShareholder.lastName };
}
