import { ImportDataService } from '@app/settings/services/import-data.service';
import { FrameworkPhasesService } from '@app/framework/services/framework-phases.service';
import { ContributionTypesService } from '@app/framework/services/contribution-types.service';
import { PhaseMultipliersService } from '@app/framework/services/phase-multipliers.service';
import { PhasesTimelineService } from '@app/settings/services/phases-timeline.service';
import { SettingsTabResolver } from '@app/settings/services/settings-tabs.resolver';
import { RedirectToAvailableCompanySettingsTabGuard } from '@app/settings/services/redirect-to-available-company-settings-tab.guard';

export const services = [
  ImportDataService,
  FrameworkPhasesService,
  ContributionTypesService,
  PhaseMultipliersService,
  PhasesTimelineService,
  SettingsTabResolver,
  RedirectToAvailableCompanySettingsTabGuard,
];

export * from '@app/settings/services/import-data.service';
export * from '@app/settings/services/phases-timeline.service';
export * from '@app/framework/services/framework-phases.service';
export * from '@app/framework/services/phase-multipliers.service';
export * from '@app/framework/services/contribution-types.service';
export * from '@app/settings/services/settings-tabs.resolver';
export * from '@app/settings/services/redirect-to-available-company-settings-tab.guard';
