import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICompanySettings } from '@app/shared/types/interfaces/company-settings.interface';
import { ICurrency } from '@app/shared/types/interfaces';
import { map, tap } from 'rxjs/operators';
import { Frequency } from '@app/shared/types/enums';
import { OnboardingStatus } from '@wevestr/bff-types/models/enums/onboardingStatus';
import { DEFAULT_DECIMALS_NUMBER } from '@app/settings/constants/settings.constants';

const DEFAULT_COMPANY_SETTINGS = {
  id: <number>null,
  currency: <ICurrency>{ id: 1, code: 'EUR', decimals: DEFAULT_DECIMALS_NUMBER },
  dataEditMode: Frequency.QUARTERLY,
  isFrameworkSetup: false,
  isSinglePhasedSlicingPie: true,
  onboardingStatus: OnboardingStatus.COMPLETED,
};

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService {
  protected readonly baseUrl: string;
  private _companyId: number;

  //TODO: make introduce factory method for CompanySettings
  private settings$ = new BehaviorSubject<ICompanySettings>({
    ...DEFAULT_COMPANY_SETTINGS,
    isSinglePhasedSlicingPie: true,
  });

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = this.configService.getFullBaseUrl();
  }

  public set companyId(companyId: number) {
    this._companyId = companyId;
  }

  private get url(): string {
    return `companies/${this._companyId}/settings`;
  }

  /**
   * Syncronous method @settings is allowed because of default value + resolver are taking care of it
   */
  public get settings(): ICompanySettings {
    return this.settings$.value;
  }

  public set settings(settings: ICompanySettings) {
    this.settings$.next(settings);
  }

  public get isSinglePhasedSlicingPie(): boolean {
    return this.settings.isSinglePhasedSlicingPie;
  }

  public getSettings$(): Observable<ICompanySettings> {
    return this.settings$.asObservable();
  }

  public get(): Observable<ICompanySettings> {
    return this.http
      .get<ICompanySettings>(`${this.baseUrl}/${this.url}`)
      .pipe(tap((settings: ICompanySettings) => this.settings$.next(settings)));
  }

  public edit(companySettings: Partial<ICompanySettings>): Observable<ICompanySettings> {
    return this.http
      .patch<ICompanySettings>(`${this.baseUrl}/${this.url}/`, companySettings)
      .pipe(tap((settings: ICompanySettings) => this.settings$.next(settings)));
  }

  public canEditViewTypeData(viewType: Frequency): boolean {
    const settings = this.settings$.value;

    return !settings?.dataEditMode || settings.dataEditMode === viewType;
  }

  public isOnboardingFinished$(): Observable<boolean> {
    return this.settings$.pipe(map((settings) => settings.onboardingStatus === OnboardingStatus.COMPLETED));
  }

  public isOnboardingRequestSubmitted$(): Observable<boolean> {
    return this.settings$.pipe(
      map((settings) => [OnboardingStatus.COMPLETED, OnboardingStatus.IN_PROGRESS].includes(settings.onboardingStatus)),
    );
  }
}
