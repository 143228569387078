import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { Observable, of } from 'rxjs';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ResetPasswordGuard {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const resetPasswordToken: string = route.queryParams.token;

    return this.auth.validateResetPassword(resetPasswordToken).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        this.router.navigate(loginPath);
        return of(false);
      }),
    );
  }
}
