import { ChangeDetectorRef, Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

import { ButtonService } from './button.service';
import { HttpLoadingService } from '@app/shared/services/http-loading.service';

@Component({
  selector: 'wevestr-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @HostBinding('attr.class') classAttr = 'full-width flex';

  @Input() buttonText = 'Continue';
  @Input() loadingText = 'Please Wait';
  @Input() disabled = false;
  @Input() type = 'submit';
  @Input() class = '';
  @Input() buttonDataId?: string;
  @Input() variant: 'primary' | 'secondary' | 'cancel' | 'outline' = 'primary';

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  public isLoading = false;
  public isHTTPLoading = false;

  constructor(
    private buttonService: ButtonService,
    private httpLoadingService: HttpLoadingService,
    private cdr: ChangeDetectorRef,
  ) {
    this.buttonService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
      this.cdr.markForCheck();
    });

    this.httpLoadingService.isLoading$.subscribe((loading) => {
      this.isHTTPLoading = loading;
      this.cdr.markForCheck();
    });
  }

  public handleClick(event: Event): void {
    if (!this.disabled && !this.isLoading && !this.isHTTPLoading) {
      this.onClick.emit(event);
    }
  }

  public getButtonClass(): string {
    const baseClass = 'button wevestr-button app-body';

    let variantClass = '';
    switch (this.variant) {
      case 'primary':
        variantClass = 'primary-button bg-blue-500 text-white';
        break;
      case 'secondary':
        variantClass = 'secondary-button text-black';
        break;
      case 'cancel':
        variantClass = 'cancel-button text-white';
        break;
      case 'outline':
        variantClass = 'outline-button';
        break;
    }
    return `${baseClass} ${variantClass} ${this.class}`;
  }
}
