<app-dialog [title]="TITLE" [closeOnEsc]="true">
  <p class="app-body margin-bottom-l">{{ TEXT }}</p>
  <form class="flex-column justify-content-space-between">
    <div class="form" [formGroup]="form">
      <wevestr-input
        class="input-container"
        [showAllErrors]="false"
        formControlName="name"
        label="Share Type Name"
        placeholder="Fill in the Share Type's Name for Scenario Modeling"
      >
        <ng-container errors>
          <div class="error-message app-body-small-const" wvFormError="required">{{ ERROR_REQUIRED }}</div>
        </ng-container>
      </wevestr-input>
    </div>
    <div class="margin-top-l flex">
      <button
        (click)="handleSubmitForm()"
        [disabled]="form.invalid || isRequestAlive"
        class="button primary-button button__small app-body"
        type="submit"
      >
        Save
      </button>
    </div>
  </form>
</app-dialog>
