import { ApexOptions, XAxisAnnotations } from 'ng-apexcharts';

const FONT_COLOR = '#919394';
const VESTED_COLOR = '#567fee';
const CLIFF_COLOR = '#d24fd1';
const UNCONDITIONAL_VESTED_COLOR = '#12B0CA';
const ANNOTATION_COLOR = '#567fee';
const TRANSPARENT = '#ffffff';
export const ANNOTATION_LABELS = {
  GRANT_DATE: 'Grant Date',
  FIRST_VESTING: 'First Vesting',
  LAST_VESTING: 'Last Vesting',
  SINGLE_VESTING: 'Vesting Date',
};

const CATEGORIES = {
  VESTED: 'Vested',
  CLIFF_PERIOD: 'Cliff Period',
  UNCONDITIONAL_VESTED: 'Unconditional Vested',
};

export const MIN_OF_VESTING_POINTS = 2;

export const NUMBER_OF_DUMMY_POINTS = 1;

export const SECOND_LINE_ANNOTATION_Y_OFFSET = 18;

export const GRANT_DATE_LABEL_PERCENTAGE = 8;

export const GRANT_DATE_ANNOTATION_APPROXIMATION = 32;

export const GRANT_DATE_ANNOTATION_WIDTH = 64;

export const FIRST_VESTING_DATE_ANNOTATION_APPROXIMATION = 35;

export const FIRST_VESTING_DATE_ANNOTATION_WIDTH = 71;

export const LAST_VESTING_DATE_ANNOTATION_APPROXIMATION = 35;

export const LAST_VESTING_DATE_ANNOTATION_WIDTH = 70;

export const DEFAULT_X_AXIS_LABEL_GRID_ARRAY = [0, 50, 0];

export const STATIC_VESTING_STEP_LINE_CHART_OPTIONS: Partial<ApexOptions> = {
  series: [],
  chart: {
    type: 'line',
    height: 260,
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
    foreColor: FONT_COLOR,
    width: '100%',
    offsetX: -5,
  },
  grid: {
    padding: {
      left: 0,
      right: 6,
    },
  },
  stroke: {
    curve: 'stepline',
    width: 2,
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: 'datetime',
    tooltip: {
      enabled: false,
    },
    labels: {
      show: false,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
      },
    },
    axisBorder: {
      show: true,
      offsetX: -1,
      offsetY: -1,
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: false,
    },
    axisBorder: {
      show: true,
      offsetX: -14,
    },
    crosshairs: {
      position: 'back',
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    inverseOrder: true,
    labels: {
      colors: [FONT_COLOR],
    },
    markers: {
      width: 14,
      height: 14,
      offsetY: 2,
    },
  },
  annotations: {
    position: 'front',
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: [0],
  },
};

export const STATIC_VESTED_SERIES_OPTIONS = {
  name: CATEGORIES.VESTED,
  color: VESTED_COLOR,
};

export const STATIC_CLIFF_PERIOD_SERIES_OPTIONS = {
  name: CATEGORIES.CLIFF_PERIOD,
  color: CLIFF_COLOR,
  type: 'line',
  data: [0, 0],
};

export const STATIC_REVERSE_UNCONDITIONAL_VESTED_SERIES_OPTIONS = {
  name: CATEGORIES.UNCONDITIONAL_VESTED,
  color: UNCONDITIONAL_VESTED_COLOR,
};

export const STATIC_X_AXIS_ANNOTATION_OPTIONS: Partial<XAxisAnnotations> = {
  strokeDashArray: 3,
  borderColor: ANNOTATION_COLOR,
  opacity: 0.5,
  label: {
    orientation: 'horizontal',
    position: 'top',
    textAnchor: 'middle',
    borderWidth: 0,
    borderRadius: 2,
    offsetY: -12,
    style: {
      background: ANNOTATION_COLOR,
      color: TRANSPARENT,
    },
  },
};
