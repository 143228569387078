import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IsDefinedPipe } from '@app/shared/pipes';

import { ITableColumn } from '@app/shared/types/interfaces/table-columns.interface';
import { ITableOptions } from '@app/types/interfaces/table-options.interface';
import { ANY } from '@app/shared/types/any';

@Directive()
export abstract class TableBaseRow {
  @Input() columns: ITableColumn[];
  @Input() options: Partial<ITableOptions> = {};
  @Input() rowValue: Record<string, ANY>;
  @Input() index: number;

  @Input() editMode: boolean;
  @Input() editRowIndex: number;
  @Input() canReorder: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;

  @Output() saveRowData: EventEmitter<Record<string, ANY>> = new EventEmitter();
  @Output() cancelSaveData: EventEmitter<void> = new EventEmitter();
  @Output() enterEditMode: EventEmitter<number> = new EventEmitter();
  @Output() deleteRow: EventEmitter<void> = new EventEmitter();
  @Output() rowHover: EventEmitter<{ index: number; row: ANY }> = new EventEmitter();

  @Input() roundedCorners = false;

  isDefinedPipe: IsDefinedPipe;

  constructor() {
    this.isDefinedPipe = new IsDefinedPipe();
  }

  isHovered: boolean;

  handleHover(event: ANY): void {
    this.isHovered = Boolean(event);
    this.rowHover.emit(event);
  }

  get isRowEditable(): boolean {
    return !this.options.isRowEditable || this.options.isRowEditable(this.rowValue);
  }

  get isRowDeletable(): boolean {
    return !this.options.isRowDeletable || this.options.isRowDeletable(this.rowValue);
  }

  get isRowEditing(): boolean {
    return this.editRowIndex === this.index;
  }

  get isSomeElementEditing(): boolean {
    return this.isDefinedPipe.transform(this.editRowIndex);
  }

  get isReorderEnabled(): boolean {
    return this.canReorder && this.editMode && !this.isSomeElementEditing;
  }
}
