export const manageSharesSteps = [
  {
    label: 'Round',
    title: 'Select Funding Round',
    complete: false,
    description: 'Adding new shares to your cap table is simple, all we need is a few details below.',
  },
  {
    label: 'Shares',
    title: 'Add Shares',
    complete: false,
    description: 'Adding new shares to your cap table is simple, all we need is a few details below.',
  },
  {
    label: 'Document(s)',
    title: 'Upload Document(s)',
    complete: false,
    description: 'Adding new shares to your cap table is simple, all we need is a few details below.',
  },
  {
    label: 'Overview',
    title: 'Share Overview',
    complete: false,
    description: 'Adding new shares to your cap table is simple, all we need is a few details below.',
  },
];
