import { Component, Input } from '@angular/core';

import { DEFAULT_VERIFY_EMAIL_CONTENT } from '@app/auth/constants/auth.constants';
import { IVerifyEmailCopy } from '@app/auth/types/interfaces/verify-email-copy.interface';
import { SUPPORT_EMAIL_ADDRESS } from '@app/shared/utils/constants/common.constants';
import { openInNewTab } from '@app/shared/utils/helpers/common.helpers';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent {
  public readonly defaultVerifyEmailCopy = DEFAULT_VERIFY_EMAIL_CONTENT;
  public readonly SUPPORT_EMAIL_ADDRESS = SUPPORT_EMAIL_ADDRESS;
  public verifyCopyright: IVerifyEmailCopy = this.defaultVerifyEmailCopy;

  @Input()
  public set content(content: IVerifyEmailCopy) {
    this.verifyCopyright = {
      ...this.defaultVerifyEmailCopy,
      ...content,
    };
  }

  public openGmail(): void {
    openInNewTab('https://mail.google.com/mail');
  }

  public openOutlook(): void {
    openInNewTab('https://outlook.office.com/mail/inbox');
  }
}
