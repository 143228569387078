import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';

import { ConfigService } from '@app/services/config.service';

export function errorHandlerFactory(configService: ConfigService): ErrorHandler {
  return environment.sentryDSN ? new SentryErrorHandler(configService) : new ErrorHandler();
}

export class SentryErrorHandler implements ErrorHandler {
  constructor(private configService: ConfigService) {
    Sentry.init({
      dsn: this.configService.getSentryDSN(),
      autoSessionTracking: true,
      // NOTE: This is the latest implementation of browser tracing using sentry
      // https://docs.sentry.io/platforms/javascript/guides/angular/
      integrations: [
        // Integration for Browser Tracing
        Sentry.browserTracingIntegration(),
        // [UNCOMMENT TO ENABLE REPLAY] - Requires business account and may incur additional costs
        // Integration for Replay
        // Sentry.replayIntegration(),
      ],
      // [CONFIG] for Sentry Browser Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', this.configService.getFullBaseUrl()],
      // [UNCOMMENT TO ENABLE REPLAY] [CONFIG] for Sentry Replay
      // replaysSessionSampleRate: 0.1, // Capture Replay for 10% of all sessions,
      // replaysOnErrorSampleRate: 1.0, // plus for 100% of sessions with an error

      // NOTE: Deprecated and is disabled sentry-access is given
      // integrations: [
      //   new Integrations.BrowserTracing({
      //     tracingOrigins: [this.configService.getFullBaseUrl()],
      //     routingInstrumentation: Sentry.routingInstrumentation,
      //   }),
      // ],
    });
  }

  public handleError(error: Record<string, unknown>): void {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
