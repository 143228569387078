import { Component, Input, OnInit } from '@angular/core';

import { IAvatarListElement } from '@app/shared/types/interfaces/avatars-list-element.interface';

@Component({
  selector: 'wevestr-stakeholders-avatars-list',
  templateUrl: './stakeholders-avatars-list.component.html',
  styleUrls: ['./stakeholders-avatars-list.component.scss'],
})
export class StakeholdersAvatarsListComponent implements OnInit {
  @Input()
  public displayCount = 3;

  @Input()
  public stakeholders: IAvatarListElement[] = [];

  public moreThanDisplayCountLabel: string | null = null;
  public hiddenNamesTooltip = '';

  public ngOnInit(): void {
    this.updateHiddenNamesTooltip();
  }

  private updateHiddenNamesTooltip(): void {
    const stakeholdersCount = this.stakeholders.length;

    if (stakeholdersCount > this.displayCount) {
      // Calculate the hidden stakeholders
      const hiddenStakeholders = this.stakeholders.slice(this.displayCount);

      // Map the hidden stakeholders' names for the tooltip
      const names = hiddenStakeholders.map(
        (stakeholder) => stakeholder.displayName || `${stakeholder.firstName} ${stakeholder.lastName}`,
      );

      // Create a tooltip with the first two names and add "and more" if there are more than two hidden
      this.hiddenNamesTooltip = names.slice(0, 2).join(', ') + (names.length > 2 ? ', and more.' : '');

      // Set a label like "+3" for the number of hidden stakeholders
      this.moreThanDisplayCountLabel = `${stakeholdersCount - this.displayCount}`;
    } else {
      this.hiddenNamesTooltip = '';
      this.moreThanDisplayCountLabel = null;
    }
  }
}
