import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsRouteNames } from '@app/settings/types/enums';
import { PermissionsService } from '@app/shared/services/permissions.service';
import { IPermissionsMap } from '@app/shared/types/interfaces';
import { SettingsAccessService } from '@app/settings/services/settings-access.service';
import { COMPANY_TABS } from '@app/settings/constants/settings.constants';
import { SettingsNavigationService } from '@app/settings/services/settings-navigation.service';

@Injectable()
export class RedirectToAvailableCompanySettingsTabGuard {
  constructor(
    private permissionsService: PermissionsService,
    private settingsAccessService: SettingsAccessService,
    private settingsNavigationService: SettingsNavigationService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const tabName = this.getRoutePath(route) as SettingsRouteNames;

    return this.permissionsService.getPermissionMap$().pipe(
      map((permissionMap) => {
        if (this.settingsAccessService.canViewCompanySettingsTab(tabName, permissionMap)) {
          return true;
        } else {
          this.navigateToAllowedCompanySettingsTab(permissionMap);
        }
      }),
    );
  }

  private getRoutePath(route: ActivatedRouteSnapshot): string {
    return route.url[0].path;
  }

  private navigateToAllowedCompanySettingsTab(permissionMap: IPermissionsMap): void {
    const firstAvailableCompanyTab = COMPANY_TABS.find((companySettingsTab) =>
      this.settingsAccessService.canViewCompanySettingsTab(companySettingsTab.route, permissionMap),
    );

    if (firstAvailableCompanyTab) {
      this.settingsNavigationService.navigateCompanySettingsTab(firstAvailableCompanyTab.route);
    }
  }
}
