import { isEmpty, isNil, map, or, pipe, pluck, propEq, remove } from 'ramda';

export const removeElementByIndex = <T>(list: T[], index: number): T[] => {
  const numberOfElements = 1;
  return remove(index, numberOfElements, list);
};

export const isNilOrEmpty = (array: unknown[]): boolean => or(isNil(array), isEmpty(array));

export const keepInitialOrder = <T extends { id: unknown }>(
  initialArray: T[],
  arrayToReorder: T[],
  update = (arrayToReorderItem: T): Partial<T> => ({ ...arrayToReorderItem }),
): T[] =>
  map(
    (initialArrayItem: T) => ({
      ...initialArrayItem,
      ...pipe<T[], unknown, T, Partial<T>>(findById, (item) => item && update(item))(
        arrayToReorder,
        initialArrayItem.id,
      ),
    }),
    initialArray,
  );

export const concatArrays = <T>(...arrays: T[][]): T[] => [].concat(...arrays);

export function findById<T extends { id: unknown }>(items: T[], id: unknown): T {
  return items?.find(propEq('id', id));
}

export const getIds = pluck('id');
