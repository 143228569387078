<div class="top-controls__container" *ngIf="user">
  <button class="top-controls__notification" *ngIf="false">
    <mat-icon class="top-controls__icon" svgIcon="notification"></mat-icon>
  </button>
  <div
    class="navbar-item top-controls__item has-dropdown"
    (mouseover)="showDropdown($event.currentTarget)"
    (mouseout)="hideDropdown($event.currentTarget)"
  >
    <div class="navbar-link top-controls__link">
      <app-avatar
        class="top-controls__avatar"
        [size]="52"
        [name]="currentUser?.firstName + ' ' + currentUser?.lastName"
        [image]="user.profile?.avatarUrl"
      >
      </app-avatar>
    </div>
    <div class="navbar-dropdown top-controls__dropdown">
      <ng-container *ngFor="let option of options">
        <a *ngIf="option.enabled$ | async" class="navbar-item top-controls__dropdown-item" [routerLink]="option.link">
          {{ option.label }}
        </a>
      </ng-container>
      <a class="navbar-item top-controls__dropdown-item" (click)="startProductTour.emit()">Product Tour</a>
      <hr class="navbar-divider" />
      <a class="navbar-item top-controls__dropdown-item" (click)="logout.emit()">Logout</a>
    </div>
  </div>
</div>
