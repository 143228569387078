import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';

import { BACK_ARROW_URLS, DEMO_WELCOME_URL } from '@app/shared/utils/constants/paths.constants';
import {
  ROUTES_WITH_INTERIM_SCREEN,
  ROUTES_WITH_RIGHT_SIDE_COVER,
  ROUTES_WITH_SIDEBAR,
  ROUTES_WITH_FULL_SCREEN,
} from '@app/auth/constants/auth.constants';
import { PageName } from '@app/shared/types/enums/page-name.enum';

interface IInterimScreenParams {
  title: string;
  description: string;
  redirectUrl: string[];
}
@Component({
  selector: 'app-auth-base',
  templateUrl: './auth-base.component.html',
  styleUrls: ['./auth-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthBaseComponent implements OnInit, OnDestroy {
  public backArrowRoutes = BACK_ARROW_URLS;
  public isArrowShown = false;
  public isLogoShown = true;
  public isSidebarVisible = false;
  public pageName: PageName;
  private ngUnsubscribe = new Subject();
  public isInterimScreenVisible = false;
  public interimScreenParams: IInterimScreenParams;
  public isRightSideCoverVisible = false;
  public isFullScreenVisible = false;

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {}

  private setArrowVisibility(): void {
    this.isArrowShown = this.backArrowRoutes.some((url: string) => url === this.router.url);
  }

  private setSidebarVisibility(route: string): void {
    const routeWithoutQueryParams = this.getRouteWithoutQueryParams(route);
    this.isSidebarVisible = ROUTES_WITH_SIDEBAR.includes(routeWithoutQueryParams);
  }

  private setInterimScreen(): void {
    this.setInterimScreenVisibility(this.router.url);
    this.setInterimScreenParams(this.isInterimScreenVisible);
  }

  private setInterimScreenVisibility(route: string): void {
    const routeWithoutQueryParams = this.getRouteWithoutQueryParams(route);
    this.isInterimScreenVisible = ROUTES_WITH_INTERIM_SCREEN.includes(routeWithoutQueryParams);
  }

  private setRightSideCoverVisibility(route: string): void {
    const routeWithoutQueryParams = this.getRouteWithoutQueryParams(route);
    this.isRightSideCoverVisible = ROUTES_WITH_RIGHT_SIDE_COVER.includes(routeWithoutQueryParams);
  }

  private setFullScreenVisibility(route: string): void {
    const routeWithoutQueryParams = this.getRouteWithoutQueryParams(route);
    this.isFullScreenVisible = ROUTES_WITH_FULL_SCREEN.includes(routeWithoutQueryParams);
  }

  private getRouteWithoutQueryParams(route: string): string {
    return route.split('?')[0];
  }

  private setInterimScreenParams(isInterimScreenVisible: boolean): void {
    if (isInterimScreenVisible) {
      this.interimScreenParams = <IInterimScreenParams>this.activatedRoute.firstChild.snapshot.data;
    }
  }

  public ngOnInit(): void {
    this.isLogoShown = !this.router.url.includes(DEMO_WELCOME_URL);
    this.pageName = this.activatedRoute.firstChild.snapshot.data.pageName;
    this.setInterimScreen();
    this.setSidebarVisibility(this.router.url);
    this.subscribeRouterEvents();
    this.setArrowVisibility();
    this.setRightSideCoverVisibility(this.router.url);
    this.setFullScreenVisibility(this.router.url);
  }

  private subscribeRouterEvents(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setArrowVisibility();
        this.setInterimScreen();
        this.setSidebarVisibility(event.url);
        this.setRightSideCoverVisibility(this.router.url);
      }
    });
  }

  public handleRedirect(): void {
    this.router.navigate(this.interimScreenParams.redirectUrl);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
