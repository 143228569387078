import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'asFormGroup',
})
export class AsFormGroupPipe implements PipeTransform {
  public transform(control: AbstractControl): UntypedFormGroup {
    return control as UntypedFormGroup;
  }
}
