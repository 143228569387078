import { CompanyDashboardType } from '@wevestr/bff-types/enums/companyDashboardType.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CrudService } from '@app/shared/services/crud.service';
import { ICompanyIndicators } from '@app/shared/types/interfaces';
import { ICompany } from '@app/shareholder-management/types/interfaces';
import { ConfigService } from '@app/services/config.service';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { IDuplicateCompanyCheckResponseDTO } from '@wevestr/bff-types/dtos/duplicateCompanyCheckDTO';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends CrudService<ICompany> {
  private company$: BehaviorSubject<ICompany> = new BehaviorSubject(null);

  constructor(configService: ConfigService, http: HttpClient) {
    super(http, configService);
  }

  public get url(): string {
    return 'companies';
  }

  public getIndicators(): Observable<ICompanyIndicators> {
    return this._http.get<ICompanyIndicators>(`${this.baseUrl}/${this.url}/${COMPANY_ID_TOKEN}/indicators`);
  }

  public edit(company: ICompany): Observable<ICompany> {
    return super.edit(company).pipe(tap((company: ICompany) => this.company$.next(company)));
  }

  public editLogo(company: Partial<ICompany>): Observable<ICompany> {
    const formData = new FormData();
    formData.set('logo', company.logo as unknown as Blob);

    return this._http
      .patch<ICompany>(`${this.baseUrl}/${this.url}/${company.id}/logo`, formData)
      .pipe(tap((company: ICompany) => this.company$.next(company)));
  }

  public deleteLogo(companyId: number): Observable<ICompany> {
    return this._http
      .delete<ICompany>(`${this.baseUrl}/${this.url}/${companyId}/logo`)
      .pipe(tap((company: ICompany) => this.company$.next(company)));
  }

  public get(companyId: number, force?: boolean): Observable<ICompany> {
    if (this.company$.value && !force) {
      return this.company$.asObservable();
    }

    return this._http
      .get<ICompany>(`${this.baseUrl}/${this.url}/${companyId}`)
      .pipe(tap((company: ICompany) => this.company$.next(company)));
  }

  public getCurrentCompany$(): Observable<ICompany> {
    return this.company$.asObservable();
  }

  public getValuation(companyId: number): Observable<{ valuation: number }> {
    return this._http.get<{ valuation: number }>(`${this.baseUrl}/${this.url}/${companyId}/valuation`);
  }

  public resendUnpaidInvoices(companyId: number): Observable<boolean> {
    return this._http.post<boolean>(`${this.baseUrl}/${this.url}/${companyId}/payments/resend-unpaid-invoices`, null);
  }

  public companyDashboardType$(): Observable<CompanyDashboardType> {
    return this.company$.pipe(map((company) => company.dashboardType));
  }

  public isDuplicateCompany(companyName: string, country: number): Observable<IDuplicateCompanyCheckResponseDTO> {
    return this._http.get<IDuplicateCompanyCheckResponseDTO>(`${this.baseUrl}/${this.url}/duplicate-check`, {
      params: { companyName, country },
    });
  }

  public isDuplicateStakeholderEmail(stakeholderEmail: string): Observable<IDuplicateCompanyCheckResponseDTO> {
    return this._http.get<IDuplicateCompanyCheckResponseDTO>(`${this.baseUrl}/${this.url}/email-duplicate-check`, {
      params: { email: stakeholderEmail },
    });
  }
}
