<app-detailed-info-side-card entityType="Shareholder" [shareholder]="stakeholder">
  <div class="info-piece">
    <span class="app-body">Shares (#)</span>
    <span class="app-numbers-medium info-piece__value">{{ transaction.sharesAmount | numberFormat: noDecimalFormat }}</span>
  </div>
  <div class="info-piece">
    <span class="app-body">Shares (%)</span>
    <span class="app-numbers-medium info-piece__value">{{ transaction.sharesPercentage | numberFormat }}%</span>
  </div>
  <div class="info-piece">
    <span class="app-body">Convertibles</span>
    <span class="app-body info-piece__value">{{ transaction.isConvertibleTransaction | booleanLabel }}</span>
  </div>
  <div class="info-piece">
    <span class="app-body">Liquidation Preference</span>
    <div class="flex-row"
         [matTooltip]="transaction.liquidationPreferenceNote | capitalizeFirstLetter"
         [matTooltipClass]="tooltipClassName"
         [matTooltipDisabled]="!transaction.liquidationPreferenceNote"
         matTooltipPosition="above"
    >
      <span class="app-body info-piece__value">{{ transaction.liquidationPreference | booleanLabel }}</span>
      <div *ngIf="transaction.liquidationPreferenceNote" class="info-piece__icon"><mat-icon svgIcon="dialog"></mat-icon></div>
    </div>
  </div>
  <div class="info-piece">
    <span class="app-body">Voting Rights</span>
    <div class="flex-row"
         [matTooltip]="transaction.votingRightNote | capitalizeFirstLetter"
         [matTooltipClass]="tooltipClassName"
         [matTooltipDisabled]="!transaction.votingRightNote"
         matTooltipPosition="above"
    >
      <span class="app-body info-piece__value">{{ transaction.votingRight | booleanLabel }}</span>
      <div *ngIf="transaction.votingRightNote" class="info-piece__icon"><mat-icon svgIcon="dialog"></mat-icon></div>
    </div>
  </div>
  <div class="info-piece">
    <span class="app-body">Preemptive Rights</span>
    <div class="flex-row"
         [matTooltip]="transaction.preemptiveRightNote | capitalizeFirstLetter"
         [matTooltipClass]="tooltipClassName"
         [matTooltipDisabled]="!transaction.preemptiveRightNote"
         matTooltipPosition="above"
    >
      <span class="app-body info-piece__value">{{ transaction.preemptiveRight | booleanLabel }}</span>
      <div *ngIf="transaction.preemptiveRightNote" class="info-piece__icon"><mat-icon svgIcon="dialog"></mat-icon></div>
    </div>
  </div>
  <div class="info-piece">
    <span class="app-body">Tag Along Rights</span>
    <span class="app-numbers-medium info-piece__value" *ngIf="transaction.tagAlong; else noTagAlong"
      >{{ transaction.tagAlong | percentage: 100 }}%</span
    >
    <ng-template #noTagAlong>
      <span class="app-body info-piece__value" *ngIf="!transaction.tagAlong">No</span>
    </ng-template>
  </div>
  <div class="info-piece">
    <span class="app-body">Drag Along Rights</span>
    <span class="app-numbers-medium info-piece__value" *ngIf="transaction.dragAlong; else noDragAlong"
      >{{ transaction.dragAlong | percentage: 100 }}%</span
    >
    <ng-template #noDragAlong>
      <span class="app-body info-piece__value" *ngIf="!transaction.dragAlong">No</span>
    </ng-template>
  </div>
  <div class="info-piece">
    <span class="app-body">Date Issued</span>
    <span class="app-body info-piece__value">{{
      (transaction.dateIssued | dateFormat: DAY_MONTH_YEAR_DATE_FORMAT) || 'Not issued'
    }}</span>
  </div>
</app-detailed-info-side-card>
