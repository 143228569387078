import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { CompanyService } from '@app/shared/services/company.service';
import { ToastService, UserService } from '@app/shared/services';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { INVOICES_RESENT_SUCCESSFULLY } from '@app/shared/utils/constants/toast-messages.constants';

@Component({
  selector: 'app-payment-needed',
  templateUrl: './payment-needed.component.html',
  styleUrls: ['./payment-needed.component.scss'],
})
export class PaymentNeededComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private companySettingsService: CompanySettingsService,
    private companyService: CompanyService,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {}

  handleLogout(): void {
    this.authService
      .logOut()
      .pipe(tap(() => (this.companySettingsService.settings = null)))
      .subscribe(() => this.router.navigate(loginPath));
  }

  handleResendInvoices(): void {
    const companyId = this.userService.currentCompanyId;

    this.companyService.resendUnpaidInvoices(companyId).subscribe(() => {
      this.toastService.success(INVOICES_RESENT_SUCCESSFULLY);
    });
  }
}
