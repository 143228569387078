<div #dropdown class="dropdown full-width flex-column" [ngClass]="{'is-active': opened}">
  <div class="flex-row is-justify-content-space-between">
    <div class="app-body-bold margin-bottom-s" *ngIf="label">{{ label }}</div>
    <div class="app-body select-counter" *ngIf="options">{{ value?.length || 0 }}/{{ options?.length }}</div>
  </div>
  <div class="dropdown-trigger full-width">
    <button
      class="button full-width full-height"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      [disabled]="disabled"
      type="button"
      (blur)="onTouched()"
    >
      <span *ngIf="placeholder" class="full-width has-text-left app-h5 placeholder">
        {{ placeholder }}
      </span>
      <div class="dropdown-icon flex-row">
        <mat-icon svgIcon="dropdown"></mat-icon>
      </div>
    </button>
  </div>
  <div class="dropdown-menu full-width" id="dropdown-menu" role="menu">
    <ul class="dropdown-content app-body">
      <ng-container *ngIf="options?.length !== 0; else emptyOption">
        <li
          class="dropdown-item cursor-pointer app-h5 dropdown-item_regular-option padding-m"
          [ngClass]="{'is-highlighted': option | callback : isHighlighted : value.length}"
          *ngFor="let option of options"
          (click)="onSelectItem(option)"
        >
          {{ option | callback : displayFn}}
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<ng-template #emptyOption>
  <li class="app-body empty-option">{{ emptyOptionText }}</li>
</ng-template>
