import { ICustomVestingFormValues } from '@app/esop/plan-management/types/custom-vesting-form.interface';
import { ILinearVestingFormValues } from '@app/esop/plan-management/types/linear-vesting-form.interface';
import { IVestingEvent } from '@app/esop/plan-management/types/vesting-event.interface';

export enum VestingTypes {
  OneOff = 0,
  Linear = 1,
  Custom = 2,
}

export const VESTING_TYPES = <const>['one_off', 'linear', 'custom'];
export const VESTING_TYPES_FOR_DIRECTION = <const>['linear', 'custom'];
export const VESTING_TYPES_DISPLAY = <const>['One-off', 'Linear', 'Custom'];

export type VestingTypeDisplay = typeof VESTING_TYPES_DISPLAY[number];

export interface IOneOffChange {
  vestingType: typeof VESTING_TYPES[VestingTypes.OneOff];
  vestingSchedules: IVestingEvent[];
}

export interface ILinearChange extends ILinearVestingFormValues {
  vestingType: typeof VESTING_TYPES[VestingTypes.Linear];
}

export interface ICustomChange extends ICustomVestingFormValues {
  vestingType: typeof VESTING_TYPES[VestingTypes.Custom];
}

export interface IVestingTypeOption {
  id: VestingTypes;
  name: VestingTypeDisplay;
}

export const vestingTypeOptions = <IVestingTypeOption[]>[
  { id: VestingTypes.OneOff, name: VESTING_TYPES_DISPLAY[VestingTypes.OneOff] },
  { id: VestingTypes.Linear, name: VESTING_TYPES_DISPLAY[VestingTypes.Linear] },
  { id: VestingTypes.Custom, name: VESTING_TYPES_DISPLAY[VestingTypes.Custom] },
];

export type IVestingScheduleChange = IOneOffChange | ILinearChange | ICustomChange;
