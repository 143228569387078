import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'ramda';

@Pipe({
  name: 'isEmptyArray',
})
export class IsEmptyArrayPipe implements PipeTransform {
  public transform<T>(array: T[] = []): boolean {
    return array && isEmpty(array);
  }
}
