<td
  class="table-column"
  [ngClass]="classes"
  [attr.data-tooltip]="dataTooltip"
  [ngStyle]="style"
  [attr.colspan]="useFullWidth ? '100%' : 1"
>
  <div *ngIf="isReorderIconShown" class="drag-and-drop-icon">
    <mat-icon svgIcon="drag_reorder"></mat-icon>
  </div>

  <div *ngIf="isCollapseIconShown" class="collapse-icon" [ngClass]="{ 'collapse-icon--expanded': isExpanded }">
    <mat-icon svgIcon="arrow" (click)="toggle.emit()"></mat-icon>
  </div>

  <ng-content></ng-content>

  <div *ngIf="isEditActionsShown" class="actions">
    <mat-icon
      *ngIf="canEdit"
      class="cursor-pointer edit-icon margin-right-s"
      svgIcon="edit"
      (click)="edit.emit()"
    ></mat-icon>
    <mat-icon
      *ngIf="canDelete"
      class="cursor-pointer light-red-color"
      svgIcon="trash"
      (click)="delete.emit()"
    ></mat-icon>
  </div>

  <div *ngIf="isEditingActionsShown" class="actions">
    <mat-icon
      class="cursor-pointer margin-right-s check-mark-icon"
      [ngClass]="{ 'icon-disabled': isSavingDisabled }"
      svgIcon="check-mark"
      (click)="save.emit()"
    ></mat-icon>
    <mat-icon class="cursor-pointer light-red-color" svgIcon="cancel" (click)="cancel.emit()"></mat-icon>
  </div>
</td>
