import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { User } from '@app/shared/types/classes';
import { ITopControlOption } from '@app/types/interfaces/top-control-option.interface';
import { IStakeholder } from '@app/types/interfaces/profile.interface';
import { UserService } from '@app/shared/services/user.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-top-controls',
  templateUrl: './top-controls.component.html',
  styleUrls: ['./top-controls.component.scss'],
})
export class TopControlsComponent implements OnInit {
  @Input() public user: User;
  @Input() public companyId: number;
  @Input() public options: ITopControlOption[];
  @Output() public logout = new EventEmitter<void>();
  @Output() public startProductTour = new EventEmitter<void>();
  public currentUser: IStakeholder;
  public currentCompanyId$: Observable<number>;
  private companyIdSubscription: Subscription;
  public currentCompanyId: number;

  constructor(private userService: UserService) {}

  public ngOnInit(): void {
    this.currentCompanyId$ = this.userService.currentCompanyId$;
    this.companyIdSubscription = this.currentCompanyId$.subscribe((companyId) => {
      this.currentCompanyId = companyId;
    });
    this.getCurrentUser();
  }

  ngOnDestroy() {
    if (this.companyIdSubscription) {
      this.companyIdSubscription.unsubscribe();
    }
  }

  public showDropdown(element: HTMLElement): void {
    element.classList.add('is-active');
  }
  public hideDropdown(element: HTMLElement): void {
    element.classList.remove('is-active');
  }

  public getCurrentUser(): void {
    const companyId = this.companyId ? this.companyId : this.currentCompanyId;
    if (this.user && this.user.profile && this.user.profile.shareholders) {
      this.currentUser = this.user.profile.shareholders.find((shareholder) => shareholder.companyId === companyId);
    }
  }
}
