<div *ngIf="hint" class="app-body margin-bottom-m">
  <strong>{{ hint }}</strong>
</div>
<div class="app-body margin-bottom-m">
  Using an app like Google Authenticator, scan the QR code below and enter the key phrase to activate the verification
  token.
</div>
<div class="app-body margin-bottom-m">
  This key phrase is used to recover your account in the event you lose your device or change to a different device.
  Please make sure to keep the key phrase in a safe location.
</div>
<div
  *ngIf="code"
  class="
    tfa-qrcode-container
    flex-row
    is-align-items-center is-justify-content-space-between
    padding-right-m padding-left-m
  "
>
  <img [src]="code.qrcode" [width]="qrCodeImageSize" [height]="qrCodeImageSize" />
  <div class="tfa__code flex-column is-justify-content-center margin-right-m">
    <div class="hint margin-bottom-l has-text-centered">Or enter this code in your verification app</div>
    <div class="code has-text-centered">
      <strong>{{ code.code | format2faSecretCode }}</strong>
    </div>
  </div>
</div>
