import { Size } from '@app/shared/types/enums';
import { ITableAction } from '@app/shared/types/interfaces/table-action.interface';
import { EditModeTableAction } from '@app/shared/types/enums/edit-mode-table-actions.enum';
import { UntypedFormGroup } from '@angular/forms';
import { TemplateRef } from '@angular/core';
import { ITablePaginationOptions } from '@app/shared/types/interfaces/table-pagination-options.interface';
import { ANY } from '@app/shared/types/any';

export enum NestingType {
  NONE = 'NONE',
  NESTED = 'NESTED',
}

export interface ITableOptions {
  cellPaddingSize?: Size;
  rowHeight?: string;
  headerHeight?: string;
  hideHeader?: boolean;
  singleLineHeader?: boolean;
  getRowStyle?: ({ index }: { index: number }) => Record<string, string>;
  actions?: ITableAction[];
  editModeActions?: EditModeTableAction[];
  hideActionsForRow?: (row: ANY, childValue?: ANY) => boolean;
  onRowClick?: (row: Record<string, ANY>) => void;
  hasColorBorder?: boolean;
  getRowColorBorder?: (index: number, row: ANY) => string;
  // TODO: deprecated old nesting behavior, use nestingType instead
  multipleValuesPerRow?: boolean;
  nestingType?: NestingType;
  getHeaderStyle?: () => Record<string, string>;
  hasFooter?: boolean;
  footerHeight?: string;
  getFooterStyle?: () => Record<string, string>;
  showBottomBorders?: boolean;
  isRowEditable?: (row: Record<string, ANY>) => boolean;
  isRowDeletable?: (row: Record<string, ANY>) => boolean;
  lastColumnPaddingLeft?: string;
  tablePaddingLeft?: string;
  tablePaddingRight?: string;
  categoryPaddingLeft?: string;
  categorizedElementPaddingLeft?: string;
  isCategorized?: boolean;
  pagination?: boolean;
  paginationOptions?: ITablePaginationOptions;
  footerText?: string;
  countFirstRowValues?: boolean;
  formFormatter?: { excludedKeys: string[]; formatter: (form: UntypedFormGroup) => Record<string, string | number> };
  allowCreateCategories?: boolean;
  emptyTitle?: string;
  emptyCopy?: string;
  displayEmptyImage?: boolean;
  emptyButtonTemplate?: TemplateRef<ANY>;
  hasBodyBorder?: boolean;
  isScrollAlwaysVisible?: boolean;
}
