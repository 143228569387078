import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class StoreEntityService<T> {
  private _createdEntity$ = new BehaviorSubject<T>(null);

  public get createdEntity$(): Observable<T> {
    return this._createdEntity$.asObservable();
  }

  public setCreatedEntity(entity: T): void {
    this._createdEntity$.next(entity);
  }
}
