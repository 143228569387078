import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@app/services/config.service';
import { CrudService } from '@app/shared/services/crud.service';
import { ICategory } from '@app/performance/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export abstract class CategoryService<T extends ICategory> extends CrudService<T> {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService, false);
  }

  public move(id: number, payload: { afterPositionId: number }): Observable<void> {
    return this._http.post<void>(`${this.baseUrl}/${this.url}/${id}/move`, payload);
  }
}
