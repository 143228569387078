import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/auth/services/auth.service';
import { IVerify2faCode } from '@app/auth/types/interfaces/verify-2fa-code.interface';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss'],
})
export class VerifyCodeComponent {
  public codeControl = new FormControl<string | null>('');
  public hasError = false;

  constructor(private authService: AuthService, private router: Router) {}

  public handleVerifyCode(): void {
    const verifyData: IVerify2faCode = {
      identifierToken: this.authService.token2fa,
      authAppCode: this.codeControl.value,
    };

    this.authService.verify2fa(verifyData).subscribe(
      () => {
        this.navigateOnSuccess();
      },
      () => (this.hasError = true),
    );
  }

  public handleBack(): void {
    this.router.navigate(['/auth/login']);
  }

  private navigateOnSuccess(): void {
    this.authService.token2fa = null;
    this.router.navigate([`/${CompanyRouteNames.COMPANY}/equity/dashboard`]);
  }
}
