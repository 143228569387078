<tr
  cdkDrag
  [cdkDragDisabled]="!isReorderEnabled"
  cdkDragLockAxis="y"
  class="row"
  [ngClass]="classes"
  [ngStyle]="getRowStyle(index)"
  (click)="options.onRowClick && options.onRowClick(row)"
  (mouseenter)="hover.emit({ index: index, row: row })"
  (mouseleave)="hover.emit(null)"
  (cdkDragStarted)="handleDragStarted()"
  (cdkDragEnded)="handleDragReleased()"
>
  <ng-content></ng-content>
</tr>
