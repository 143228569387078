<app-dialog
  title="Import Audit File"
  [closeOnEsc]="!auditFilesSelect?.isDirty || currentImportStep === IMPORT_STEPS.IMPORT_RESULTS"
  [canClose]="!isImportInProgress"
>
  <wevestr-select-audit-files
    *ngIf="currentImportStep === IMPORT_STEPS.ATTACH_FILES"
    [isImportInProgress]="isImportInProgress"
    (select)="runImport($event)"
  >
  </wevestr-select-audit-files>
  <wevestr-import-results
    *ngIf="currentImportStep === IMPORT_STEPS.IMPORT_RESULTS"
    redirectToSectionName="P&L"
    [importDetails]="importDetails"
    (redirect)="handleRedirect()"
    (back)="goToPreviousImportStep()"
  >
  </wevestr-import-results>
</app-dialog>
