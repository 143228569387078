import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ITableOptions } from '@app/types/interfaces/table-options.interface';
import { ANY } from '@app/shared/types/any';

@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent implements OnInit {
  @Input() options: Partial<ITableOptions> = {};
  @Input() row: Record<string, ANY>;
  @Input() index: number;
  @Input() isReorderEnabled: boolean;

  @Input() roundedTopCorners = false;
  @Input() roundedBottomCorners = false;
  @Input() roundedCorners = false;

  @Output() hover: EventEmitter<{ index: number; row: ANY }> = new EventEmitter();

  classes: Record<string, ANY>;

  ngOnInit(): void {
    this.initClasses();
  }

  private initClasses(): void {
    const classes = {
      'row--odd': this.index % 2 === 1,
      'row--even': this.index % 2 === 0,
      'row--categorized': this.options.isCategorized && !this.row.isCategory,
      'row--rounded': this.roundedCorners,
      'row--rounded-top': this.roundedTopCorners,
      'row--rounded-bottom': this.roundedBottomCorners,
    };

    this.classes = classes;
  }

  getRowStyle(index: number): Record<string, ANY> {
    const styleToAdd = (this.options.getRowStyle && this.options.getRowStyle({ index })) || {};
    return Object.assign({ height: this.options.rowHeight ? this.options.rowHeight : '40px' }, styleToAdd);
  }

  handleDragStarted(): void {
    document.body.style.cursor = 'grabbing';

    const placeholderChildren = document.getElementsByClassName('cdk-drag-placeholder')[0].children;
    const previewChildren = document.getElementsByClassName('cdk-drag-preview')[0].children;

    for (let i = 0; i < placeholderChildren.length; i++) {
      (
        previewChildren[i].children[0] as HTMLElement
      ).style.width = `${placeholderChildren[i].children[0].clientWidth}px`;
    }
  }

  handleDragReleased(): void {
    document.body.style.cursor = 'default';
  }
}
