import { Pipe, PipeTransform } from '@angular/core';
import { booleanYesNoFormatter } from '@app/shared/utils/helpers/stakeholder.helpers';

@Pipe({
  name: 'booleanLabel',
})
export class BooleanLabelPipe implements PipeTransform {
  transform(value: boolean): string {
    return booleanYesNoFormatter(value);
  }
}
