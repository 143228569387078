import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import {
  ApexDataLabels,
  ApexLegend,
  ApexPlotOptions,
  ApexChart,
  ApexGrid,
  ApexStates,
  ApexTooltip,
} from 'ng-apexcharts';
import {
  CHART_OPTIONS,
  DATA_LABELS_OPTIONS,
  GRID_OPTIONS,
  LEGEND_OPTIONS,
  STATES_OPTIONS,
  STATIC_PLOT_OPTIONS,
  TOOLTIP_OPTIONS,
} from '@app/shared/components/donut-chart/donut-chart.constants';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: [],
})
export class DonutChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chartGrid')
  public apxChart: ElementRef;

  public plotOptions: Partial<ApexPlotOptions> = STATIC_PLOT_OPTIONS;
  public chartOptions: ApexChart = CHART_OPTIONS;
  public dataLabelsOptions: Partial<ApexDataLabels> = DATA_LABELS_OPTIONS;
  public legendOptions: Partial<ApexLegend> = LEGEND_OPTIONS;
  public gridOptions: ApexGrid = GRID_OPTIONS;
  public statesOptions: ApexStates = STATES_OPTIONS;
  public tooltipOptions: ApexTooltip = TOOLTIP_OPTIONS;

  @Input()
  public series: number[];

  @Input()
  public colors: string[];

  @Input()
  public title: string;

  @Input()
  public total: string;

  constructor() {}

  @HostListener('window:resize')
  public onResize(): void {
    this.getChartWidthPx();
  }

  public ngOnInit(): void {
    this.plotOptions.pie.donut.labels.name.formatter = () => this.title;
    this.plotOptions.pie.donut.labels.value.formatter = () => this.total;
    this.plotOptions.pie.donut.labels.total.formatter = () => this.total;
    this.plotOptions.pie.donut.labels.total.label = this.title;
  }

  public ngAfterViewInit(): void {
    this.getChartWidthPx();
  }

  private getChartWidthPx(): void {
    this.chartOptions = {
      ...this.chartOptions,
      width: this.apxChart.nativeElement.offsetWidth,
    };
  }
}
