import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ImportStatus } from '@app/shared/types/enums/import-status.enum';
import { IImportDetails } from '@app/shared/types/interfaces/import-details.interface';

@Component({
  selector: 'wevestr-import-results',
  templateUrl: './import-results.component.html',
  styleUrls: ['./import-results.component.scss'],
})
export class ImportResultsComponent implements OnInit {
  @Input() importDetails: IImportDetails;
  @Input() redirectToSectionName: string;
  @Output() back = new EventEmitter<void>();
  @Output() redirect = new EventEmitter<void>();

  public isFailed: boolean;
  public resultsHeadlineForStatus = {
    [ImportStatus.COMPLETED]: 'Data imported successfully!',
    [ImportStatus.FAILED]: 'Import failed. Please try again:',
    [ImportStatus.IMPORTED_PARTIALLY]: 'Import done partially. Please check details about files:',
    [ImportStatus.IN_PROGRESS]: 'Import in progress',
  };

  ngOnInit(): void {
    this.isFailed = this.importDetails.status === ImportStatus.FAILED;
  }

  handleRedirect(): void {
    this.redirect.emit();
  }

  handleBack(): void {
    this.back.emit();
  }
}
