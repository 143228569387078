<div class="flex-row">
  <wevestr-multi-select
    class="margin-right-l"
    [formControl]="control"
    [placeholder]="copyright.placeholder"
    [options]="options"
    [displayFn]="formatShareholderName"
    [compareFn]="isTheSameStakeholder"
    [label]="copyright.multiselectLabel"
    [emptyOptionText]="emptyOptionText"
  ></wevestr-multi-select>
  <div class="full-width selected-stakeholders">
    <wevestr-selected-stakeholders
      *ngIf="control.value && control.value.length"
      [stakeholders]="control.value"
      [label]="copyright.selectedLabel"
      [required]="required"
      (removeStakeholder)="removeStakeholder.emit($event)"
    ></wevestr-selected-stakeholders>
    <!-- TODO: extract field-explainer, make 2 components: multiselect and explained multiselect -->
    <wevestr-field-explainer
      *ngIf="copyright.descriptionLabel && copyright.description"
      [title]="copyright.descriptionLabel"
      [description]="copyright.description"
    ></wevestr-field-explainer>
  </div>
</div>
