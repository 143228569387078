<div class="flex-column documents-table margin-top-s" *ngIf="documents | isNotEmptyArray; else emptyState">
  <wevestr-document-preview-card
    class="display-block document-row"
    *ngFor="let document of documents; first as isFirst; last as isLast"
    [document]="document"
    [shouldShowFileSize]="shouldShowFileSize"
    [shouldShowFileExtension]="shouldShowFileExtension"
    (onPreview)="handleDocumentPreview($event)"
    [class.first-row]="isFirst"
    [class.last-row]="isLast"
  ></wevestr-document-preview-card>
</div>

<ng-template #emptyState>
  <div *ngIf="shouldShowEmptyState" class="app-body-medium-weight full-width margin-top-s margin-bottom-m">
    There are no {{ label }} uploaded.
  </div>
</ng-template>
