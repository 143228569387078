import { ITab } from '@app/shared/types/interfaces';

export const enum SettingsRouteNames {
  SETTINGS = 'settings',
  PERSONAL_SETTINGS = 'personal',
  PERSONAL_GENERAL = 'general',
  PERSONAL_SECURITY = 'security',
  PERSONAL_USER = 'user',
  COMPANY_SETTINGS = 'company',
  COMPANY_GENERAL = 'general',
  COMPANY_CAP_TABLE = 'cap-table',
  //COMPANY_IMPORT = 'import-and-integrations',
  COMPANY_EXTERNAL_SOURCES = 'external-sources',
  COMPANY_EXTERNAL_SOURCES_EXACT = 'external-sources/exact',
  COMPANY_EXTERNAL_SOURCES_XERO = 'external-sources/xero',
  COMPANY_EXTERNAL_SOURCES_QUICKBOOKS = 'external-sources/quickbooks',
  COMPANY_STARTUP_COOP = 'framework',
  ESOP = 'esop',
}

export type TSettingsTab = ITab<SettingsRouteNames> & { editDisabled?: boolean };
