import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApiResponse } from '@app/shared/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiResponseUnwrapInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(map((responseEvent) => this.mapDataResponse(responseEvent)));
  }

  private mapDataResponse(responseEvent: HttpEvent<unknown>): HttpEvent<unknown> {
    if (responseEvent instanceof HttpResponse) {
      return this.unwrapResponse(responseEvent);
    }

    return responseEvent;
  }

  private unwrapResponse(responseEvent: HttpResponse<unknown>): HttpEvent<unknown> {
    return responseEvent.clone({ body: (responseEvent.body as IApiResponse<unknown>)?.data });
  }
}
