<form class="register-container flex-column justify-content-space-between">
  <div class="form" [formGroup]="form">
    <wevestr-input
      class="input-container"
      [showAllErrors]="false"
      formControlName="name"
      [label]="nameTitle"
      [placeholder]="namePlaceholder"
    >
      <ng-container errors>
        <div class="error-message app-body-small-const" wvFormError="required">{{ ERRORS_MAP.required }}</div>
      </ng-container>
    </wevestr-input>
    <wevestr-input
      class="margin-top-m-const input-container"
      [inputClassNames]="['comment-input']"
      type="textarea"
      [showAllErrors]="false"
      formControlName="comment"
      [label]="commentTitle"
      [placeholder]="commentPlaceholder"
    >
    </wevestr-input>
  </div>
  <div class="margin-top-l flex justify-content-space-between">
    <div class="is-flex-grow-1"></div>
    <button
      (click)="submitForm()"
      [disabled]="form.invalid || isRequestAlive"
      class="button primary-button button__small app-body"
      type="submit"
    >
      Save
    </button>
  </div>
</form>
