export interface IStepData extends IStep {
  description?: string;
  substeps?: IStep[];
  title?: string;
}

export interface IStep {
  label: string;
  complete?: boolean;
}
