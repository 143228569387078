import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MONTHS_PER_YEAR, QUARTERS_PER_YEAR } from '@app/performance/helpers/dates.helpers';
import { Frequency } from '@app/shared/types/enums';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-empty-state-chart',
  templateUrl: './empty-state-chart.component.html',
  styleUrls: ['./empty-state-chart.component.scss'],
})
export class EmptyStateChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() text = 'No data available yet.';
  @Input() viewType: Frequency;

  private ngUnsubscribe = new Subject();

  amountOfPeriods: number;
  arrayOfPeriodsNumbers: number[];

  setPeriodsAmount(): void {
    switch (this.viewType) {
      case Frequency.MONTHLY:
        this.amountOfPeriods = MONTHS_PER_YEAR;
        break;
      case Frequency.QUARTERLY:
        this.amountOfPeriods = QUARTERS_PER_YEAR;
        break;
      default:
        this.amountOfPeriods = QUARTERS_PER_YEAR;
        break;
    }
  }

  constructor() {}

  counter(i: number): number[] {
    return new Array(i);
  }

  initData(): void {
    if (this.viewType) {
      this.setPeriodsAmount();
      this.arrayOfPeriodsNumbers = this.counter(this.amountOfPeriods);
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
