import { Injectable } from '@angular/core';
import { Driver, DriveStep, driver, AllowedButtons } from 'driver.js';
import { MatDialog } from '@angular/material/dialog';
import { StartProductTourModalComponent } from '@app/components/containers/start-product-tour-modal/start-product-tour-modal.component';
import { SIDEBAR_SECTIONS } from '@app/shared/utils/constants/sections.constants';
import { Section } from '@app/shared/types/enums/section.enum';
import { SECTION_PATH } from '@app/shared/utils/constants/paths.constants';
import { getUrlFromPath } from '@app/shared/utils/helpers/path.helpers';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AnalyticsJourney, AnalyticsSubJourney } from '@app/shared/types/enums/analytics-journey.enum';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { COMPANY_SETTINGS_TABS_PATH } from '@app/settings/constants/paths.constants';
import { SettingsRouteNames } from '@app/settings/types/enums/settings-route-names.enum';
import { Subscription } from 'rxjs';

const CAP_TABLE_URL = getUrlFromPath(SECTION_PATH[Section.capTable]);
const STAKEHOLDERS_URL = getUrlFromPath(SECTION_PATH[Section.stakeholders]);
const DOCUMENTS_URL = getUrlFromPath(SECTION_PATH[Section.documents]);
const FINANCIALS_URL = getUrlFromPath(SECTION_PATH[Section.financials]);

@Injectable({
  providedIn: 'root',
})
export class ProductTourService {
  private navigationEndSubscription: Subscription;
  private driverObj: Driver;
  private driverCancelationObj: Driver;
  private steps: DriveStep[] = [
    {
      element: `#${SIDEBAR_SECTIONS[Section.stakeholders].id}`,
      popover: {
        title:
          '<img class="margin-bottom-s full-width" src="assets/images/product-tour/stakeholder.svg" /><div>Invite Stakeholders</div>',
        description: 'Why not share your progress with your stakeholders and bring them onboard?',
        side: 'right',
        align: 'end',
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'wevestr-stakeholders-add-buttons',
      popover: {
        title: 'Start inviting Stakeholders',
        description: 'Here you can create Stakeholders and Groups. Don’t worry it’s not needed to provide them access!',
        side: 'left',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'app-tabs',
      popover: {
        title: 'Toggle Stakeholder view',
        description: 'Here you can toggle between Stakeholders in a individual and group view.',
        side: 'right',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: async () => {
          await this.navigateTo([CAP_TABLE_URL]);
          this.next();
        },
      },
    },
    {
      element: `#${SIDEBAR_SECTIONS[Section.capTable].id}`,
      popover: {
        title:
          '<img class="margin-bottom-s full-width" src="assets/images/product-tour/cap-table.svg" /><div>View your Cap Table</div>',
        description: 'Start administrating the shares of your Stakeholders on the Cap Table by adding them.',
        side: 'right',
        onPrevClick: async () => {
          await this.navigateTo([STAKEHOLDERS_URL]);
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: '[data-id="Button-AddShares"]',
      popover: {
        title: 'Start adding Shares',
        description:
          'Here you can add Shares to your Cap Table including relevant additional terms and legal documents.',
        side: 'left',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'app-tabs',
      popover: {
        title: 'Toggle Cap Table view',
        description: 'Here you can toggle between the view of your Cap Table per round or per shareholders.',
        side: 'right',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: async () => {
          await this.navigateTo([FINANCIALS_URL]);
          this.next();
        },
      },
    },
    {
      element: `#${SIDEBAR_SECTIONS[Section.financials].id}`,
      popover: {
        title:
          '<img class="margin-bottom-s full-width" src="assets/images/product-tour/financials.svg" /><div>View your Financials</div>',
        description: 'Start budgeting and forecasting your company financials.',
        side: 'right',
        onPrevClick: async () => {
          await this.navigateTo([CAP_TABLE_URL]);
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'wevestr-icon-button[title="Settings"]',
      popover: {
        title: 'Connect Financial API',
        description: 'Here you can connect your accounting software through our Financial API.',
        side: 'left',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: async () => {
          await this.navigateTo(COMPANY_SETTINGS_TABS_PATH[SettingsRouteNames.COMPANY_EXTERNAL_SOURCES]);
          this.next();
        },
      },
    },
    {
      element: 'app-tabs',
      popover: {
        title: 'Connect your Financial API',
        description:
          'Here in Settings you can find all the integrations we offer. Easily connect your accounting software API.',
        side: 'right',
        onPrevClick: async () => {
          await this.navigateTo([FINANCIALS_URL]);
          this.previous();
        },
        onNextClick: async () => {
          await this.navigateTo([DOCUMENTS_URL]);
          this.next();
        },
      },
    },

    {
      element: `#${SIDEBAR_SECTIONS[Section.documents].id}`,
      popover: {
        title:
          '<img class="margin-bottom-s full-width" src="assets/images/product-tour/documents.svg" /><div>Store your Documents</div>',
        description: 'Start uploading your (legal) Documents and view Documents you attached to your Cap Table.',
        side: 'right',
        onPrevClick: async () => {
          await this.navigateTo(COMPANY_SETTINGS_TABS_PATH[SettingsRouteNames.COMPANY_EXTERNAL_SOURCES]);
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'wevestr-new-document-button app-button button',
      popover: {
        title: 'Upload your Documents',
        description: 'Here you can upload (legal) Documents and provide access to your Stakeholders.',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'app-tabs',
      popover: {
        title: 'Toggle Documents view',
        description: 'Here you can toggle between the view of your Documents with company-wide access or personal.',
        side: 'right',
        onPrevClick: () => {
          this.previous();
        },
        onNextClick: () => {
          this.next();
        },
      },
    },
    {
      element: 'app-top-controls',
      popover: {
        title:
          '<img class="margin-bottom-s full-width" src="assets/images/product-tour/congratulations.svg" /><div>Congratulations, finished!</div>',
        description: 'If at a later moment you want to replay the Product Tour, you can find it here in the dropdown.',
      },
    },
  ];
  private config = {
    overlayColor: '#031125',
    overlayOpacity: 0.5,
    showProgress: true,
    showButtons: ['next', 'previous'] as AllowedButtons[],
    nextBtnText: 'Next',
    prevBtnText: 'Previous',
    disableActiveInteraction: true,
    onDestroyStarted: () => {
      if (!this.driverObj.hasNextStep()) {
        this.analyticsService.track(AnalyticsEvent.ProductTourCompleted, {
          journey: AnalyticsJourney.Onboarding,
          subJourney: AnalyticsSubJourney.ProductTour,
        });
        this.driverObj.destroy();
      } else {
        this.analyticsService.track(AnalyticsEvent.ProductTourCancelled, {
          journey: AnalyticsJourney.Onboarding,
          subJourney: AnalyticsSubJourney.ProductTour,
        });
        this.driverObj.destroy();
        this.showWhereIsProductTour();
      }
    },
    steps: this.steps,
  };
  private cancelationConfig = {
    overlayColor: '#031125',
    overlayOpacity: 0.5,
    showProgress: false,
    showButtons: ['next'] as AllowedButtons[],
    nextBtnText: 'Close',
    disableActiveInteraction: true,
    steps: [
      {
        element: 'app-top-controls',
        popover: {
          title:
            '<img class="margin-bottom-s full-width" src="assets/images/product-tour/congratulations.svg" /><div>We got you covered.</div>',
          description: 'If at a later moment you want to play the Product Tour, you can find it here in the dropdown.',
        },
      },
    ],
  };

  constructor(private dialog: MatDialog, private router: Router, private analyticsService: AnalyticsService) {}

  get isProductTourFinished(): boolean {
    return localStorage.getItem('productTourFinished') === 'true';
  }

  public openPreview(): void {
    if (this.isProductTourFinished) {
      return;
    }

    this.dialog.open(StartProductTourModalComponent, { backdropClass: 'product-tour-backdrop' }).afterClosed();
  }

  public async startTour(): Promise<void> {
    await this.navigateTo([STAKEHOLDERS_URL]);
    this.analyticsService.track(AnalyticsEvent.ProductTourStarted, {
      journey: AnalyticsJourney.Onboarding,
      subJourney: AnalyticsSubJourney.ProductTour,
    });
    this.driverObj = driver(this.config);
    this.driverObj.drive();
    this.markAsSeen();
  }

  public markAsSeen(): void {
    localStorage.setItem('productTourFinished', 'true');
  }

  public showWhereIsProductTour(): void {
    this.driverCancelationObj = driver(this.cancelationConfig);
    this.driverCancelationObj.drive();
  }

  public declineTour(): void {
    this.analyticsService.track(AnalyticsEvent.ProductTourNotNow, {
      journey: AnalyticsJourney.Onboarding,
      subJourney: AnalyticsSubJourney.ProductTour,
    });
    this.markAsSeen();
    this.showWhereIsProductTour();
  }

  private next(): void {
    this.analyticsService.track(AnalyticsEvent.ProductTourNextStep, {
      journey: AnalyticsJourney.Onboarding,
      subJourney: AnalyticsSubJourney.ProductTour,
    });
    setTimeout(() => this.driverObj.moveNext(), 200);
  }

  private previous(): void {
    this.analyticsService.track(AnalyticsEvent.ProductTourPreviousStep, {
      journey: AnalyticsJourney.Onboarding,
      subJourney: AnalyticsSubJourney.ProductTour,
    });
    setTimeout(() => this.driverObj.movePrevious(), 200);
  }

  private navigateTo(path: string[]): Promise<void> {
    return new Promise<void>((resolve) => {
      this.navigationEndSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.navigationEndSubscription.unsubscribe();
          resolve();
        }
      });
      this.router.navigate(path);
    });
  }
}
