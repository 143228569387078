import { ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexStates } from 'ng-apexcharts';

const TITLE_LABEL_FONT_SIZE = '16px';
const VALUE_LABEL_FONT_SIZE = '24px';

const BLACK_FONT_COLOR = '#031125';
const MEDIUM_FONT_WEIGHT = 600;
const NUMBER_FONT_FAMILY = 'SF Mono';

export const DATA_LABELS_OPTIONS: Partial<ApexDataLabels> = {
  enabled: false,
};

export const LEGEND_OPTIONS: Partial<ApexLegend> = {
  show: false,
};

export const CHART_OPTIONS: ApexChart = {
  type: 'donut',
  width: 200,
};

export const GRID_OPTIONS: ApexGrid = {
  padding: {
    left: 5,
    right: 5,
    top: 5,
    bottom: 5,
  },
};

export const STATES_OPTIONS: ApexStates = {
  hover: {
    filter: {
      type: 'none',
    },
  },
  active: {
    filter: {
      type: 'none',
    },
  },
};

export const TOOLTIP_OPTIONS = {
  enabled: false,
};

export const STATIC_PLOT_OPTIONS: Partial<ApexPlotOptions> = {
  pie: {
    expandOnClick: false,
    donut: {
      size: '80%',
      labels: {
        show: true,
        name: {
          show: true,
          fontSize: TITLE_LABEL_FONT_SIZE,
          fontWeight: MEDIUM_FONT_WEIGHT,
          color: BLACK_FONT_COLOR,
          offsetY: -20,
        },
        value: {
          show: true,
          fontSize: VALUE_LABEL_FONT_SIZE,
          fontWeight: MEDIUM_FONT_WEIGHT,
          fontFamily: NUMBER_FONT_FAMILY,
          color: BLACK_FONT_COLOR,
          offsetY: 0,
        },
        total: {
          show: true,
          showAlways: true,
          fontSize: TITLE_LABEL_FONT_SIZE,
          fontWeight: MEDIUM_FONT_WEIGHT,
          fontFamily: NUMBER_FONT_FAMILY,
          color: BLACK_FONT_COLOR,
        },
      },
    },
  },
};
