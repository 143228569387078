import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ICompany } from '@app/shareholder-management/types/interfaces';
import { CompanyService } from '@app/shared/services/company.service';
import { UserService } from '@app/shared/services/user.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyResolver {
  constructor(private companyService: CompanyService, private userService: UserService) {}

  resolve(): Observable<ICompany> {
    return this.userService.currentCompanyId$.pipe(
      take(1),
      switchMap((companyId) => this.companyService.get(companyId, true)),
    );
  }
}
