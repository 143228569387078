import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public set(name: string, value: string, days = 400): void {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    const cookieValue = `${encodeURIComponent(name)}=${encodeURIComponent(
      value,
    )};expires=${expires.toUTCString()};path=/`;
    document.cookie = cookieValue;
  }

  public get(name: string): string {
    return this.document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
  }

  public delete(name: string): void {
    this.document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
