import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IStepData } from '@app/shared/types/interfaces';
import { pairwise, takeUntil } from 'rxjs/operators';
import { StepperService } from '@app/services/stepper.service';

@Component({
  selector: 'wevestr-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss'],
})
export class VerticalStepperComponent implements OnInit {
  @Input()
  public steps: IStepData[];
  @Input()
  public currentStepIndex: number;
  @Input()
  public isStepperClickable = true;

  public currentStep$: Observable<number>;
  private unsubscribe$ = new Subject<void>();

  constructor(private stepService: StepperService) {}

  public ngOnInit(): void {
    if (this.isStepperClickable) {
      this.initCurrentStep();
      this.subscribeToStepChange();
    }
  }

  private initCurrentStep(): void {
    this.currentStep$ = this.stepService.currentStep$;
  }

  private subscribeToStepChange(): void {
    this.currentStep$
      .pipe(pairwise(), takeUntil(this.unsubscribe$))
      .subscribe(([previousStep, nextStep]: [number, number]) => {
        const isGoBack = previousStep > nextStep;
        if (isGoBack) {
          this.markPreceedingStepsAsNotComplete(previousStep, nextStep);
        } else {
          this.markStepAsComplete(previousStep);
        }
        this.updateCurrentStepData(nextStep);
      });
  }

  private markPreceedingStepsAsNotComplete(fromStep: number, untilStep: number): void {
    for (let i = fromStep; i >= untilStep; i--) {
      this.markStepAsIncomplete(i);
    }
  }

  private markStepAsComplete(stepIndex: number): void {
    this.steps[stepIndex].complete = true;
  }

  private markStepAsIncomplete(stepIndex: number): void {
    this.steps[stepIndex].complete = false;
  }

  private updateCurrentStepData(stepIndex: number): void {
    this.stepService.setCurrentStepData(this.steps[stepIndex]);
  }

  public handleOpenStep(index: number): void {
    this.stepService.setCurrentStep(index);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
