import { Pipe, PipeTransform } from '@angular/core';

import { IStakeholder } from '@app/types/interfaces/profile.interface';
import { IAvatarListElement } from '@app/shared/types/interfaces/avatars-list-element.interface';

@Pipe({
  name: 'stakeholderAvatarList',
})
export class StakeholderAvatarListPipe implements PipeTransform {
  public transform(stakeholders: IStakeholder[]): IAvatarListElement[] {
    if (!stakeholders) {
      return null;
    }
    return stakeholders.map((stakeholder) => ({
      avatarUrl: stakeholder.profile?.avatarUrl,
      displayName: stakeholder.displayName,
    }));
  }
}
