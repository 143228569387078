export const BARCHART_COLORS = {
  1: '#08306B',
  2: '#16427B',
  3: '#24538A',
  4: '#32659A',
  5: '#4076AA',
  6: '#4E88B9',
  7: '#5C99C9',
  8: '#6AABD9',
  9: '#78BCE8',
  10: '#86CEF8',
  11: `#f3f5f7`,
};

export const OTHERS_BARCHART_COLOR = BARCHART_COLORS[10];
export const CUSTOM_BARCHART_COLOR = BARCHART_COLORS[11];

const COLOR_1 = BARCHART_COLORS[1];
const COLOR_2 = BARCHART_COLORS[2];
const COLOR_3 = BARCHART_COLORS[3];
const COLOR_4 = BARCHART_COLORS[4];
const COLOR_5 = BARCHART_COLORS[5];
const COLOR_6 = BARCHART_COLORS[6];
const COLOR_7 = BARCHART_COLORS[7];
const COLOR_8 = BARCHART_COLORS[8];
const COLOR_9 = BARCHART_COLORS[9];
const COLOR_10 = BARCHART_COLORS[10];
const COLOR_11 = BARCHART_COLORS[11];

export const BARCHART_COLOR_PALETTES = new Map<number, string[]>([
  [1, [COLOR_1]],
  [2, [COLOR_1, COLOR_10]],
  [3, [COLOR_1, COLOR_5, COLOR_10]],
  [4, [COLOR_1, COLOR_4, COLOR_7, COLOR_10]],
  [5, [COLOR_1, COLOR_3, COLOR_5, COLOR_8, COLOR_10]],
  [6, [COLOR_1, COLOR_3, COLOR_4, COLOR_5, COLOR_8, COLOR_10]],
  [7, [COLOR_1, COLOR_2, COLOR_3, COLOR_6, COLOR_8, COLOR_9, COLOR_10]],
  [8, [COLOR_1, COLOR_2, COLOR_3, COLOR_5, COLOR_7, COLOR_8, COLOR_9, COLOR_10]],
  [9, [COLOR_1, COLOR_2, COLOR_3, COLOR_5, COLOR_7, COLOR_8, COLOR_9, COLOR_10, COLOR_11]],
]);

export const CHART_PALETTE = Object.freeze([
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
]);

export const BARCHART_MAX_VALUES_DISPLAYED = 7;
export const BARCHART_WIDGET_VALUES_COUNT = 5;

export const CHART_FONT_FAMILY = 'SF Mono';
export const AXIS_FONT_SIZE = '11px';
export const XAXIS_TEXT_FONT_SIZE = '14px';
export const LABEL_TEXT_FONT_SIZE = '12px';
export const LEGEND_MARKER_SIZE = 7;
export const LABEL_FONT_FAMILY = 'Inter';
export const CHART_TEXT_FONT_COLOR = '#031125';
export const BARCHART_TEXT_FONT_COLOR = '#dddddd';
export const MAX_METRICS_SELECTED = 5;
