import { DocumentType } from '@app/documents/types/enums';

export const DOCUMENTS_LABEL = 'Document(s)';
export const DOCUMENTS_MODAL_MAX_WIDTH = '497px';

export const KB_IN_MB = 1024;

export const DOCUMENTS_TITLE = {
  [DocumentType.PERSONAL]: 'personal',
  [DocumentType.COMMON]: 'company',
  [DocumentType.ESOP]: 'esop',
  [DocumentType.ONBOARDING]: 'onboarding',
};

export const ALLOWED_DOCUMENT_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.apple.keynote',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/html',
  'text/plain',
  'application/zip',
  'application/vnd.rar',
  'application/x-rar-compressed',
  'application/octet-stream',
  'application/x-zip-compressed',
  'multipart/x-zip',
  'application/x-7z-compressed',
  'image/*',
  'application/pdf',
];

export const ALLOWED_PREVIEW_TYPES = ['application/pdf', 'image/'];
export const PREVIEW_MAX_WIDTH = '700px';
