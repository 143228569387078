import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { switchMap, startWith, filter, take } from 'rxjs/operators';

import { ImportService } from '@app/shared/services/import.service';
import { IAuditImportParameters } from '@app/shared/types/interfaces/import-parameters.interface';
import { IImportDetails } from '@app/shared/types/interfaces/import-details.interface';
import { ImportStatus } from '@app/shared/types/enums/import-status.enum';
import { FETCH_DETAILS_AFTED_DELAY_MSEC } from '@app/shared/utils/constants/import.constants';

@Injectable({
  providedIn: 'root',
})
export class RunImportService {
  constructor(private importService: ImportService) {}

  public runImport(companyId: number, importParams: IAuditImportParameters): Observable<IImportDetails> {
    return this.importService
      .importProfitAndLoss(companyId, importParams)
      .pipe(switchMap(() => this.getImportResult$(companyId)));
  }

  private getImportResult$(companyId: number): Observable<IImportDetails> {
    return interval(FETCH_DETAILS_AFTED_DELAY_MSEC).pipe(
      startWith(0),
      switchMap(() => this.importService.getProfitAndLossImportDetails(companyId)),
      filter((importDetails) => this.isImportFinished(importDetails)),
      take(1),
    );
  }

  private isImportFinished(importDetails?: IImportDetails): boolean {
    return importDetails?.status !== ImportStatus.IN_PROGRESS;
  }
}
