import { Component, Input } from '@angular/core';

@Component({
  selector: 'wevestr-field-explainer',
  templateUrl: './field-explainer.component.html',
  styleUrls: ['./field-explainer.component.scss'],
})
export class FieldExplainerComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public extraDescription: string;
  @Input() public boldDescription: string;

  constructor() {}
}
