<app-dialog [title]="'Set up phases timeline'" [closeOnEsc]="!form.dirty">
  <form class="full-height flex-column">
    <section class="form modal-body__form phases__content flex-column">
      <div class="flex-column full-width">
        <div
          *ngFor="let phase of form.controls.phases.controls; let index = index"
          class="flex-column margin-top-m"
          [formGroup]="phase"
        >
          <div class="full-width margin-bottom-s phase-name">
            <div class="display-block phase app-h4">{{ phases[index].name }}</div>
          </div>
          <div class="flex-row position-relative">
            <wevestr-date-picker
              type="year"
              formControlName="startDate"
              class="phase-date margin-bottom-s margin-right-m"
              [min]="form.controls.phases.at(index - 1)?.controls?.endDate?.value | nextMonthDate"
            >
              <span label class="app-body-bold margin-bottom-s display-block">Start Date</span>
              <ng-container errors>
                <div wvFormError="required" class="warning app-body-small-const">
                  {{ ERRORS.REQUIRED }}
                </div>
                <div wvFormError="wrongPhaseStartDate" class="warning app-body-small-const">
                  {{ ERRORS.WRONG_START_DATE }}
                </div>
              </ng-container>
            </wevestr-date-picker>
            <wevestr-date-picker
              formControlName="endDate"
              type="year"
              class="phase-date margin-bottom-s"
              [min]="phase.controls.startDate?.value | nextMonthDate"
            >
              <span label class="app-body-bold margin-bottom-s display-block">End Date</span>
              <ng-container errors>
                <div wvFormError="required" class="warning app-body-small-const">
                  {{ ERRORS.REQUIRED }}
                </div>
                <div wvFormError="notEmpty" class="warning app-body-small-const">
                  {{ ERRORS.LAST_PHASE_EMPTY_END_DATE }}
                </div>
                <div wvFormError="tooEarly" class="warning app-body-small-const">
                  {{ ERRORS.TOO_EARLY_END_DATE }}
                </div>
              </ng-container>
            </wevestr-date-picker>
            <div
              *ngIf="phaseCouldBeDeleted(index)"
              class="warning-color phases__delete-button"
              (click)="handleDeletePhase(index)"
            >
              <span>&#10005;</span>
            </div>
          </div>
        </div>
        <div
          class="anchor anchor--with-decoration app-body-medium-weight margin-top-m has-text-right"
          *ngIf="phases.length > form.controls.phases.controls.length"
          (click)="handleAddPhase()"
        >
          + Add phase
        </div>
      </div>
      <div class="modal-footer">
        <button class="button primary-button button__small app-body" (click)="setPhases()" type="submit">
          Set up phases
        </button>
      </div>
    </section>
  </form>
</app-dialog>
