<div class="flex" [style.min-width]="sizeInPx">
  <div
    *ngIf="backgroundImageUrl; else nameInitialsOnly"
    class="avatar__image avatar--border-radius"
    [style.backgroundImage]="backgroundImageUrl"
    [style.height]="sizeInPx"
    [style.width]="sizeInPx"
    [style.font-size]="sizeInPx"
    alt="Avatar"
  ></div>

  <ng-template #nameInitialsOnly>
    <div
      *ngIf="name; else elipsisPlaceholder"
      class="avatar__name avatar--border-radius"
      [style.height]="sizeInPx"
      [style.width]="sizeInPx"
      [style.font-size]="DEFAULT_FONT_SIZE"
    >
      <div>{{ name | initials }}</div>
    </div>
  </ng-template>

  <ng-template #elipsisPlaceholder>
    <div
      *ngIf="userElipsisPlaceholder; else baseIcon"
      class="avatar__name avatar__more app-body-bold avatar--border-radius"
      [style.height]="sizeInPx"
      [style.width]="sizeInPx"
      [style.font-size]="DEFAULT_FONT_SIZE"
    >
      <div>+{{ userElipsisPlaceholder }}</div>
    </div>
  </ng-template>

  <ng-template #baseIcon>
    <mat-icon
      *ngIf="!imageUrl && !name && !userElipsisPlaceholder"
      class="avatar--border-radius"
      svgIcon="account"
      [style.height]="sizeInPx"
      [style.width]="sizeInPx"
      [style.font-size]="sizeInPx"
    ></mat-icon>
  </ng-template>
</div>
