import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigService } from '@app/services/config.service';
import { CrudService } from '@app/shared/services/crud.service';
import { CurrencySettingsService } from '@app/shared/services/currency-settings.service';
import { FormatPercentagePipe, NumberFormatPipe } from '@app/shared/pipes';

import { ICopyLastKpisData, IKpi, ILastKpisParams } from '@app/performance/types/interfaces';
import { KpiType } from '@app/performance/types/enums';
import { isNil } from 'ramda';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable({
  providedIn: 'root',
})
export class KpisService extends CrudService<IKpi> {
  private typeToFormatter = new Map<KpiType, (value: number) => string>([
    [KpiType.CURRENCY, (value) => this.currencySettingsService.format(value)],
    [KpiType.NUMBER, (value) => this.numberFormatPipe.transform(value)],
    [KpiType.PERCENTAGE, (value) => this.percentagePipe.transform(value)],
  ]);

  constructor(
    http: HttpClient,
    configService: ConfigService,
    private currencySettingsService: CurrencySettingsService,
    private numberFormatPipe: NumberFormatPipe,
    private percentagePipe: FormatPercentagePipe,
  ) {
    super(http, configService, false);
  }

  public get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/kpis`;
  }

  public setValues(values: IKpi[]): void {
    this.values$.next(values);
  }

  public getLast(params: ILastKpisParams): Observable<IKpi[]> {
    return this._http.get<IKpi[]>(`${this.baseUrl}/${this.url}/last`, { params: params as Params });
  }

  public copyLast(data: ICopyLastKpisData): Observable<void> {
    return this._http.put<void>(`${this.baseUrl}/${this.url}/copy-last`, data);
  }

  public deleteYear(id: number, year: number): Observable<void> {
    return this._http
      .delete<void>(`${this.baseUrl}/${this.url}/${id}/years/${year}`)
      .pipe(tap(() => this.deleteValue(id)));
  }

  public move(id: number, payload: { afterPositionId: number }): Observable<void> {
    return this._http.post<void>(`${this.baseUrl}/${this.url}/${id}/move`, payload);
  }

  public getFormattedKpiValue = (type: KpiType, value: number): string => {
    const formatter = this.typeToFormatter.get(type);
    return isNaN(value) || isNil(value) ? '' : formatter(value);
  };
}
