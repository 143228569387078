import { IInactiveInvitationResponse, IInvitationResponse } from '@app/auth/types/interfaces';
import { InvitationStatus } from '@app/auth/types/enums/invitation-status.enum';
import { ICompany } from '@app/shareholder-management/types/interfaces';

const COMPANY_NAME_BASIS = 'New Company';
const COMPANY_NAME_REGEXP = /^(New Company){1}(\s{1}\d+)?$/;

export const isInactiveInvitationResponse = (
  invitationResponse: IInvitationResponse | IInactiveInvitationResponse,
): invitationResponse is IInactiveInvitationResponse => invitationResponse.status !== InvitationStatus.PENDING;

export const getNextCompanyName = (companies: ICompany[]): string => {
  const existingCompaniesWithoutName = companies.filter(({ name }) => COMPANY_NAME_REGEXP.test(name));
  return `${COMPANY_NAME_BASIS}${
    existingCompaniesWithoutName.length > 0 ? ` ${existingCompaniesWithoutName.length + 1}` : ''
  }`;
};
