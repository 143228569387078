<div class="app-menu">
  <ng-content select="[appMenuTrigger]"></ng-content>
  <div *ngIf="opened" class="position-fixed z-index-3" [ngStyle]="{ 'top.px': menuTop, 'left.px': menuLeft }">
    <div #menu class="app-menu__actions" [ngClass]="{ 'menu-on-bottom': !isMenuOnTop }">
      <ng-content select="[appMenuContent]"></ng-content>
    </div>
    <div
      class="app-menu__arrow"
      [ngClass]="{ 'arrow-on-bottom': isMenuOnTop }"
      [ngStyle]="{ 'top.px': arrowTop }"
    ></div>
  </div>
</div>
