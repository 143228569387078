export interface IStepperItem<TDisplay = string | number> {
  display: TDisplay;
  status: StepStatus;
  disabled?: boolean;
  order?: number;
}

export enum StepStatus {
  PASSED = 'passed',
  ACTIVE = 'active',
  PENDING = 'pending',
  ERROR = 'error',
  SKIPPED = 'skipped',
}

type TNoIconStatusNames = StepStatus.ACTIVE | StepStatus.PENDING;

export type TStepperIconsMap = Partial<Omit<Record<StepStatus, string>, TNoIconStatusNames>>;

export interface IStepClickEvent {
  targetStep: IStepperItem;
  stepIndex: number;
}
