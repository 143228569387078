import { BytesUnit } from '@app/shared/types/enums/bytes-unit.enum';

export const UNITS_SORTED_IN_ASCENDING_ORDER = Object.freeze([
  BytesUnit.bytes,
  BytesUnit.kb,
  BytesUnit.mb,
  BytesUnit.gb,
  BytesUnit.tb,
]);
export const BYTES_IN_KILOBYTE = 1024;
export const DECIMALS_NUMBER_FOR_UNITS = 2;
export const UNRECOGNIZED_EXTENSION: string = null;
