<ng-container *ngIf="!isInterimScreenVisible && !isRightSideCoverVisible && !isFullScreenVisible">
  <!-- TODO:remove welcome-cover when all routes have new design -->
  <wevestr-welcome-cover *ngIf="!isSidebarVisible">
    <div class="welcome-card-wrapper">
      <div class="welcome-card__logo">
        <img *ngIf="isLogoShown" class="full-width" src="assets/images/logo.svg" />
      </div>

      <div class="welcome-card wizard__card card">
        <router-outlet></router-outlet>
      </div>
      <wevestr-policy-disclaimer [pageName]="pageName" class="welcome-card-disclaimer"></wevestr-policy-disclaimer>
    </div>
  </wevestr-welcome-cover>

  <wevestr-auth-cover *ngIf="isSidebarVisible">
    <div class="flex-column align-items-center auth-container">
      <div class="auth-content flex align-items-center justify-content-center">
        <router-outlet></router-outlet>
      </div>
      <div class="margin-top-m">
        <wevestr-terms-policy-disclaimer [pageName]="pageName" class="policy"></wevestr-terms-policy-disclaimer>
      </div>
    </div>
  </wevestr-auth-cover>
</ng-container>

<div class="interim-screen" *ngIf="isInterimScreenVisible">
  <wevestr-interim-screen
    [title]="interimScreenParams.title"
    [description]="interimScreenParams.description"
    (continue)="handleRedirect()"
  ></wevestr-interim-screen>
</div>

<wevestr-cover-with-sidecard *ngIf="isRightSideCoverVisible">
  <div class="flex-1"> <router-outlet></router-outlet> </div>
</wevestr-cover-with-sidecard>

<div class="full-screen" *ngIf="isFullScreenVisible">
  <router-outlet></router-outlet>
</div>
