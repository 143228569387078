import { Component } from '@angular/core';
import { loginPath } from '@app/shared/utils/constants/paths.constants';

import { HttpLoadingService } from '@app/shared/services/http-loading.service';

@Component({
  selector: 'wevestr-accepted-invite',
  templateUrl: './accepted-invite.component.html',
  styleUrls: ['./accepted-invite.component.scss'],
})
export class AcceptedInviteComponent {
  public loginPath = loginPath;
  public isHTTPLoading = false;

  constructor(private httpLoadingService: HttpLoadingService) {
    this.httpLoadingService.isLoading$.subscribe((loading) => {
      this.isHTTPLoading = loading;
    });
  }
}
