import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

import { ITableColumn } from '@app/shared/types/interfaces/table-columns.interface';
import { CellValueDirective } from '@app/shared/directives';
import { ITableOptions, NestingType } from '@app/types/interfaces/table-options.interface';
import { IRowAction } from '@app/types/interfaces/row-action.interface';
import { IPageParams, ITableValue } from '@app/shared/types/interfaces';
import { EditModeTableAction } from '@app/shared/types/enums/edit-mode-table-actions.enum';
import { TableElementRowComponent } from '@app/shared/components/table/table-element-row/table-element-row.component';
import { ValidatorFn } from '@angular/forms';
import { ColumnOrdering } from '@app/shared/types/enums';
import { ANY } from '@app/shared/types/any';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() columns: ITableColumn[];
  @Input() data: ITableValue[] | Record<string, ANY>[];
  @Input() options: Partial<ITableOptions> = {};
  @Input() editMode = false;
  @Input() defaultAddValues: Record<string, ANY> = {};
  @Input() validations: Record<string, ValidatorFn[]> = {};
  @Input() errorMessages: Record<string, string> = {};
  @Input() entityName = 'Data';
  @Input() hasRoundedCorners = false;
  @Input() isEmptyState = false;
  @Input() requiredFields: string[];

  @Output() rowHover = new EventEmitter<{ index: number; row: ANY }>();
  @Output() selectAction = new EventEmitter<IRowAction>();
  @Output() addValue = new EventEmitter<Record<string, ANY>>();
  @Output() editValue = new EventEmitter<{
    data: Record<string, ANY>;
    row: Record<string, ANY>;
    index: number;
  }>();
  @Output() deleteValue = new EventEmitter<Record<string, ANY>>();
  @Output() reorder = new EventEmitter<{ previousIndex: number; currentIndex: number }>();
  @Output() toggle = new EventEmitter<{ isCategory: boolean; id: number }>();
  @Output() pageChange = new EventEmitter<IPageParams>();
  @Output() sortBy = new EventEmitter<ITableColumn>();

  @ViewChildren('th', { read: ElementRef }) headerColumns: QueryList<ElementRef>;
  @ViewChildren(TableElementRowComponent) rows: QueryList<TableElementRowComponent>;
  @ContentChild(CellValueDirective, { static: true, read: TemplateRef }) cellValueTemplate: TemplateRef<ANY>;

  public editRowIndex: number;
  public columnWidths: number[];
  public editActions: { [key in EditModeTableAction]?: boolean } = {};
  public newRows: Record<string, ANY>[] = [];
  public columnOrdering = ColumnOrdering;
  public NESTING_TYPES = NestingType;

  private unsubscribe$ = new Subject<void>();

  public ngOnInit(): void {
    this.initEditActions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.editMode && !changes.editMode.currentValue) {
      this.newRows = [];
      this.updateEditRowIndex();
    }

    if (changes.options) {
      this.initEditActions();
    }
  }

  public ngAfterViewInit(): void {
    this.initColumnWidths();
  }

  public handleSort(column: ITableColumn, columnIndex: number): void {
    switch (column.sortDetails.order) {
      case ColumnOrdering.none: {
        column.sortDetails.order = ColumnOrdering.ascending;
        break;
      }
      case ColumnOrdering.ascending: {
        column.sortDetails.order = ColumnOrdering.descending;
        break;
      }
      case ColumnOrdering.descending: {
        column.sortDetails.order = ColumnOrdering.none;
        break;
      }
      default: {
        column.sortDetails.order = ColumnOrdering.none;
      }
    }
    this.columns
      .filter((column, index) => column.sortDetails && index !== columnIndex)
      .forEach((column) => (column.sortDetails.order = ColumnOrdering.none));
    this.sortBy.emit(column);
  }

  public addNewRow(isCategory = false): void {
    const newRow = {} as { isCategory?: boolean };

    if (isCategory) {
      newRow.isCategory = isCategory;
    }

    this.newRows.push(newRow);
    this.updateEditRowIndex();
  }

  public updateNewRow(updatedRow: Record<string, unknown>, rowIndex: number): void {
    this.newRows = this.newRows.map((row: Record<string, unknown>, index: number) => {
      if (index === rowIndex) {
        return {
          ...row,
          ...updatedRow,
        };
      }

      return row;
    });
  }

  public saveNewRow(data: Record<string, ANY>, index: number): void {
    this.addValue.emit(data);
    this.data.push(data);
    this.newRows.splice(index, 1);
    this.updateEditRowIndex();
  }

  public editRow(data: Record<string, ANY>, row: Record<string, ANY>, index: number): void {
    this.editValue.emit({ data, row, index });
    this.updateEditRowIndex();
  }

  public removeNewRow(index: number): void {
    this.newRows.splice(index, 1);
    this.updateEditRowIndex();
  }

  public updateEditRowIndex(): void {
    this.editRowIndex = this.newRows.length > 0 ? this.data.length + this.newRows.length - 1 : null;
  }

  // TODO: refactor this to reduce complexity
  // eslint-disable-next-line complexity
  public getThStyle(column?: ITableColumn, index?: number, last?: boolean): Record<string, string> {
    let style: Record<string, string> = {};

    if (this.options.getHeaderStyle) {
      style = { ...this.options.getHeaderStyle() };
    }

    if (index === 0 && this.options.tablePaddingLeft) {
      style['padding-left'] = this.options.tablePaddingLeft;
    }

    if (last && this.options.tablePaddingRight) {
      style['padding-right'] = this.options.tablePaddingRight;
    }

    if (column) {
      style['width'] = column.width ? column.width : null;
      style['text-align'] = column.textAlign ? column.textAlign : null;
      style['font-family'] = column.fontFamily ? column.fontFamily : null;
    }

    return style;
  }

  public getColumnValues(column?: ITableColumn): string[] | number[] {
    return (this.data as Record<string, ANY>[]).map((row: ITableValue | Record<string, ANY>) => {
      if (column.value) {
        return typeof column.value in ['string', 'number'] ? column.value : (column.value as ANY)(row);
      }

      return column.renderer ? column.renderer(row) : row[column.field];
    });
  }

  // TODO: refactor this to reduce complexity
  // eslint-disable-next-line complexity
  public getFooterStyle(column?: ITableColumn, index?: number, last?: boolean): Record<string, string> {
    let style = this.options.getFooterStyle ? this.options.getFooterStyle() : {};

    if (index === 0 && this.options.tablePaddingLeft) {
      style['padding-left'] = this.options.tablePaddingLeft;
    }

    if (last && this.options.tablePaddingRight) {
      style['padding-right'] = this.options.tablePaddingRight;
    }

    if (column) {
      const valueStyle = column.valuesStyle || {};

      style = {
        ...style,
        ...valueStyle,
        'text-align': column.textAlign || null,
        'font-family': column.fontFamily || null,
      };
    }

    return style;
  }

  public handleResize(headerCell: ANY, column: ITableColumn): void {
    const index = this.columns.filter((column) => column.hasRightAlignment).indexOf(column);
    const cellsWithRightAlignment = this.rows.toArray().map((row) => row.cellsWithRightAlignment.toArray()[index]);
    const widths = cellsWithRightAlignment.map((cell) => cell?.nativeElement.getBoundingClientRect().width);
    const maxWidth = Math.max(...widths, headerCell.getBoundingClientRect().width);

    headerCell.setAttribute('style', `margin-left: ${maxWidth - headerCell.getBoundingClientRect().width}px`);

    cellsWithRightAlignment.forEach((cell) => {
      const nativeElement = cell?.nativeElement;
      nativeElement?.setAttribute('style', `margin-left: ${maxWidth - nativeElement.getBoundingClientRect().width}px`);
    });
  }

  public handleReorder(event: CdkDragDrop<ITableValue[] | Record<string, ANY>[]>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    this.reorder.emit(event);
  }

  public handlePageChanges(pageParams: IPageParams): void {
    this.pageChange.emit(pageParams);
  }

  private initColumnWidths(): void {
    this.headerColumns.changes
      .pipe(startWith(null as unknown), debounceTime(50), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.columnWidths = this.headerColumns.toArray().map((value) => {
          return this.getElementWidth(value.nativeElement);
        });
      });
  }

  private initEditActions(): void {
    if (this.options.editModeActions) {
      this.editActions = this.options.editModeActions.reduce((result, value) => {
        result[value] = true;
        return result;
      }, {} as Record<EditModeTableAction, boolean>);
    }
  }

  private getElementWidth(element: HTMLElement): number {
    const style = getComputedStyle(element);

    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);

    return parseFloat(style.width) - padding;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
