import { Pipe, PipeTransform } from '@angular/core';

type CallbackFn = (...args: unknown[]) => unknown;

@Pipe({
  name: 'callback',
})
export class CallbackPipe implements PipeTransform {
  public transform(value: unknown, callback: CallbackFn, ...args: unknown[]): unknown {
    return callback(value, ...args);
  }
}
