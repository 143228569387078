import { Injectable } from '@angular/core';

import { CrudService } from '@app/shared/services/crud.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { IFinancialMetric } from '@app/performance/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FinancialMetricsService extends CrudService<IFinancialMetric> {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService, false);
  }

  public get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/financials`;
  }
}
