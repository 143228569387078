import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DEFAULT_DECIMALS_NUMBER } from '@app/settings/constants/settings.constants';

// Current state of decimals:
// - all indicators in cash (with currency sign) have 2 decimals from company settings service - currency - decimals
// - Price per share in cap table always has 4 decimals
// - Other indicators follow this BASE_DIGITS_CONFIGURATION
// All other indicators (% in kpis; indicators, using | numberFormat without 2nd arg)
// have BASE_DIGITS_CONFIGURATION.defaultDecimals
const BASE_DIGITS_CONFIGURATION = Object.freeze(<const>{
  percentageInput: 6,
  numberInput: 0,
  numberDisplay: 0,
  interestInput: 2,
  interestDisplay: 2,
  defaultDecimals: DEFAULT_DECIMALS_NUMBER,
});

const DIGITS_CONFIGURATION = new InjectionToken('DIGITS_CONFIGURATION', {
  providedIn: 'root',
  factory: () => BASE_DIGITS_CONFIGURATION,
});

@Injectable({
  providedIn: 'root',
})
export class DigitsConfigService {
  constructor(@Inject(DIGITS_CONFIGURATION) private digitsConfig: typeof BASE_DIGITS_CONFIGURATION) {}

  public get percentageMask(): string {
    return `separator.${this.digitsConfig.percentageInput}`;
  }

  public get wholeNumberMask(): string {
    return `separator.${this.digitsConfig.numberInput}`;
  }

  public get interestPercetangeMask(): string {
    return `separator.${this.digitsConfig.interestInput}`;
  }

  public get ngPercentageFormat(): string {
    return `1.${this.digitsConfig.defaultDecimals}-${this.digitsConfig.defaultDecimals}`;
  }

  public get ngWholeNumberFormat(): string {
    return `1.${this.digitsConfig.numberDisplay}-${this.digitsConfig.numberDisplay}`;
  }

  public get ngInterestPercentageFormat(): string {
    return `1.${this.digitsConfig.interestDisplay}-${this.digitsConfig.interestDisplay}`;
  }
}
