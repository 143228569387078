import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalMode } from '@app/shared/types/enums/modal-mode.enum';
import { REQUEST_FAILED_ERROR } from '@app/shared/utils/constants/modal.constants';
import { ShareTypeService } from '@app/shared/services/shareType.service';
import { IShareType } from '@app/shareholder-management/types/interfaces/share-type.interface';
import { ISharesTypeModalOptions } from '@app/shareholder-management/types/interfaces/shares-type-modal.interface copy';
import { Observable } from 'rxjs';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import {
  AnalyticsJourney,
  AnalyticsSubJourney,
  AnalyticsSubSubJourney,
} from '@app/shared/types/enums/analytics-journey.enum';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { PageName } from '@app/shared/types/enums/page-name.enum';
import { getReasonPhrase } from 'http-status-codes';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'wevestr-shares-type-modal',
  templateUrl: './shares-type-modal.component.html',
  styleUrls: ['./shares-type-modal.component.scss'],
})
export class SharesTypeModalComponent implements OnInit {
  public readonly REQUEST_FAILED_ERROR = REQUEST_FAILED_ERROR;
  public readonly modes = ModalMode;

  public mode: string;
  public isRequestAlive = false;
  public shareType: IShareType;
  public title: string;

  private readonly TITLE = {
    CREATE: 'Create new Share Type',
    EDIT: 'Edit new Share Type',
  };

  constructor(
    public dialogRef: MatDialogRef<SharesTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISharesTypeModalOptions,
    private shareTypeService: ShareTypeService,
    private analyticsService: AnalyticsService,
  ) {}

  public ngOnInit(): void {
    this.mode = this.data.mode;
    this.title = this.mode === this.modes.ADD ? this.TITLE.CREATE : this.TITLE.EDIT;
    this.shareType = this.data.shareType;
  }

  public handleShareTypeSubmitted(rawShareType: Partial<IShareType>): void {
    this.isRequestAlive = true;
    this.analyticsService.track(AnalyticsEvent.AddSharesShareTypeAdded, {
      journey: AnalyticsJourney.CapTable,
      subJourney: AnalyticsSubJourney.AddShares,
      subSubJourney: AnalyticsSubSubJourney.AddNewShareType,
      pageName: PageName.CapTable,
    });
    this.saveShareType(rawShareType).subscribe(this.handleSuccess.bind(this), this.handleError.bind(this));
  }

  private saveShareType(shareTypePayload: Partial<IShareType>): Observable<IShareType> {
    if (this.mode === this.modes.ADD) {
      return this.shareTypeService.create(shareTypePayload);
    } else {
      return this.shareTypeService.edit(shareTypePayload);
    }
  }

  private handleError(error: HttpErrorResponse): void {
    this.isRequestAlive = false;
    this.analyticsService.track(AnalyticsEvent.AddShareTypeFailure, {
      journey: AnalyticsJourney.CapTable,
      subJourney: AnalyticsSubJourney.AddShares,
      subSubJourney: AnalyticsSubSubJourney.AddNewShareType,
      pageName: PageName.CapTable,
      failureReason: getReasonPhrase(error.status),
    });
  }

  private handleSuccess(shareType: IShareType): void {
    this.isRequestAlive = false;
    this.analyticsService.track(AnalyticsEvent.AddShareTypeSuccess, {
      journey: AnalyticsJourney.CapTable,
      subJourney: AnalyticsSubJourney.AddShares,
      subSubJourney: AnalyticsSubSubJourney.AddNewShareType,
      pageName: PageName.CapTable,
    });
    this.dialogRef.close(shareType);
  }
}
