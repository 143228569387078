<form class="register-container flex-column justify-content-space-between">
  <div>
    <h5 class="margin-top-m margin-bottom-m">
      It must be at least 8 characters, include at least 1 number or symbol, and it cannot be your name or email
      address.
    </h5>
    <div class="form" [formGroup]="form" *wvLet="showPassword ? 'text' : 'password' as passwordType">
      <wevestr-input
        class="login__input margin-top-m-const input-container password-input"
        [type]="passwordType"
        [showAllErrors]="false"
        formControlName="password"
        label="Password"
        placeholder="Type your Password"
      >
        <button
          addon
          [tabindex]="ignoreTabIndex"
          class="button-reset-styles addon"
          (click)="togglePasswordVisibility()"
        >
          <mat-icon [svgIcon]="showPassword ? passwordIcons.invisible : passwordIcons.visible"></mat-icon>
        </button>
        <ng-container errors>
          <div class="error-message app-body-small-const" wvFormError="required">{{ ERRORS_MAP.required }}</div>
          <div class="error-message app-body-small-const" wvFormError="minlength">{{ ERRORS_MAP.minLength }}</div>
          <div class="error-message app-body-small-const" wvFormError="badPasswordCharacters">
            {{ ERRORS_MAP.badPasswordCharacters }}
          </div>
        </ng-container>
      </wevestr-input>
      <wevestr-input
        class="login__input margin-top-m-const input-container password-input"
        [type]="passwordType"
        [showAllErrors]="false"
        formControlName="confirmPassword"
        label="Confirm Password"
        placeholder="Re-type your password"
      >
        <button
          [tabindex]="ignoreTabIndex"
          addon
          class="button-reset-styles addon"
          (click)="togglePasswordVisibility()"
        >
          <mat-icon [svgIcon]="showPassword ? passwordIcons.invisible : passwordIcons.visible"></mat-icon>
        </button>
        <ng-container errors>
          <div class="error-message app-body-small-const" wvFormError="noMatch">{{ ERRORS_MAP.noMatch }}</div>
        </ng-container>
      </wevestr-input>
    </div>
    <div
      class="margin-top-m flex-row justify-content-space-between"
      *ngIf="passwordStrength$ | async as passwordStrength"
    >
      <div class="flex-row justify-content-space-between">
        <div class="line one-third" [ngClass]="passwordStrength"></div>
        <div class="line one-third" [ngClass]="passwordStrength"></div>
        <div class="line two-thirds" [ngClass]="passwordStrength"></div>
        <div class="line two-thirds" [ngClass]="passwordStrength"></div>
        <div class="line" [ngClass]="passwordStrength"></div>
        <div class="line" [ngClass]="passwordStrength"></div>
      </div>
      <div class="strength-title" [ngClass]="passwordStrength">
        {{ passwordStrength | titlecase }}
      </div>
    </div>
    <div *ngIf="!form.controls.password.valid && !form.pristine" class="margin-top-m margin-bottom-m flex-column">
      <p class="hint">Length must be greater than 8 characters;</p>
      <p class="hint">Password must contain numbers;</p>
      <p class="hint">Password must contain uppercase letters;</p>
      <p class="hint">Password must contain lowercase letters;</p>
    </div>
  </div>
  <div class="margin-top-l flex justify-content-space-between">
    <button *ngIf="showBackButton" (click)="handleBack.emit()" class="button cancel-button button__small app-body">
      Back
    </button>
    <div class="is-flex-grow-1"></div>
    <button
      (click)="submitCredentials()"
      [disabled]="form.invalid"
      class="button primary-button button__small app-body"
      type="submit"
    >
      Continue
    </button>
  </div>
</form>
