import { InvitationGuard } from '@app/auth/guards/invitation.guard';
import { DemoInvitationGuard } from '@app/auth/guards/demo-invitation.guard';
import { VerifyCodeComponent } from '@app/auth/containers';
import { ResetPasswordGuard } from '@app/auth/guards/reset-password.guard';
import { TokenGuard } from '@app/auth/guards/token.guard';
import { RedirectToNewInvitationGuard } from '@app/auth/guards/redirect-to-new-invitation.guard';

export const guards = [
  InvitationGuard,
  VerifyCodeComponent,
  ResetPasswordGuard,
  DemoInvitationGuard,
  TokenGuard,
  RedirectToNewInvitationGuard,
];

export * from '@app/auth/guards/invitation.guard';
export * from '@app/auth/guards/demo-invitation.guard';
export * from '@app/auth/guards/reset-password.guard';
export * from '@app/auth/guards/verify-code.guard';
export * from '@app/auth/guards/token.guard';
export * from '@app/auth/guards/redirect-to-new-invitation.guard';
