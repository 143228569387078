import { ILegendOption } from '@app/shared/types/interfaces/legend-option.interface';
import {
  BARCHART_COLOR_PALETTES,
  BARCHART_MAX_VALUES_DISPLAYED,
  CHART_PALETTE,
} from '@app/shared/utils/constants/chart.constants';

export function areOptionsTheSame(option1: Partial<ILegendOption>, option2: Partial<ILegendOption>): boolean {
  return option1 && option2 && option1.name === option2.name && option1.isCategory === option2.isCategory;
}

export const getBarchartColorByIndex = (index: number, quantity: number): string => {
  const key = quantity > BARCHART_MAX_VALUES_DISPLAYED ? BARCHART_MAX_VALUES_DISPLAYED + 1 : quantity;
  const chartPalette = BARCHART_COLOR_PALETTES.get(key);
  return chartPalette[index % chartPalette.length];
};

export const getBarchartValueOptionId = ({
  id,
  barchartOptionId,
}: {
  id?: string | number;
  barchartOptionId?: string | number;
}): string | number => {
  return barchartOptionId || id;
};

const getColorPicker = (colors: readonly string[]) => (index: number) => colors[index % colors.length];

export const getChartColorByIndex = getColorPicker(CHART_PALETTE);

export const shortifyYear = (year: number): string => {
  return String(year).slice(-2);
};
