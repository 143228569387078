export interface ITooltipValue {
  label: string;
  value?: number;
  displayedValue: string | number;
}

export interface ITooltipDataLabelsPercents {
  currentDateLabelPercent: number;
  previousDateLabelPercent: number;
  yoYPercent: number;
}
