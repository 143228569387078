import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@app/services/config.service';
import { IAuditImportParameters } from '@app/shared/types/interfaces/import-parameters.interface';
import { IImportDetails } from '@app/shared/types/interfaces/import-details.interface';
import { toFormData } from '@app/shared/utils/helpers/common.helpers';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  protected readonly baseUrl: string;

  constructor(private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getFullBaseUrl();
  }

  getUrl(companyId: number): string {
    return `${this.baseUrl}/companies/${companyId}/import`;
  }

  importProfitAndLoss(companyId: number, importParameters: IAuditImportParameters): Observable<unknown> {
    const data = toFormData(importParameters);

    return this.http.post(`${this.getUrl(companyId)}/performance/profit-n-loss`, data);
  }

  getProfitAndLossImportDetails(companyId: number): Observable<IImportDetails> {
    return this.http.get<IImportDetails>(`${this.getUrl(companyId)}/performance/profit-n-loss`);
  }
}
