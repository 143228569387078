import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wevestr-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input('toggled')
  private _toggled = false;
  @Input()
  public controlColor: 'primary' | 'secondary' = 'primary';
  @Input()
  public disabled = false;
  @Input()
  public id: string;

  @Output() public onToggled = new EventEmitter<boolean>();

  public get toggled(): boolean {
    return this._toggled;
  }

  public set toggled(value: boolean) {
    this._toggled = value;
    this.onToggled.emit(value);

    if (this.onToggle && this.onTouch) {
      this.onToggle(this._toggled);
      this.onTouch();
    }
  }

  private onToggle: (value: boolean) => void;
  private onTouch: () => void;

  public writeValue(toggled: boolean): void {
    this.toggled = toggled;
  }

  public registerOnChange(onChange: () => void): void {
    this.onToggle = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  public onBlur(): void {
    this.onTouch();
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
