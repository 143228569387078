<div class="wevestr-checkbox" [class.is-rounded]="isRounded" [ngClass]="additionalClass">
  <input type="checkbox" [(ngModel)]="checked" [id]="id" [attr.disabled]="disabled ? '' : null" />
  <label
    *ngIf="type !== CheckboxTypes.base"
    [for]="id"
    class="fake-checkbox"
    [ngStyle]="checked && color && {'background-color': color}"
    [class.no-color]="!color || null"
  >
  </label>
  <label class="wevestr-checkbox__label" [class.disabled]="disabled">
    <ng-content label></ng-content>
  </label>
</div>
