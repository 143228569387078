import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from '@app/services/config.service';

export type AppVersion = {
  appVersion: string;
};

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private readonly baseUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.baseUrl = this.config.getFullBaseUrl();
  }

  private get url(): string {
    return `${this.baseUrl}/app-version`;
  }

  public version$(): Observable<AppVersion> {
    return this.http.get<AppVersion>(this.url);
  }
}
