import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { getLocale } from '@app/shared/utils/helpers/common.helpers';
import { UserSettingsService } from '@app/shared/services/user-settings.service';
import { DigitsConfigService } from '@app/shared/services/digits-config.service';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private userSettingsService: UserSettingsService, private digitsConfigService: DigitsConfigService) {}

  public transform(value: number, format = this.digitsConfigService.ngPercentageFormat): string {
    const { formatting } = this.userSettingsService.settings,
      locale = getLocale(formatting);
    return formatNumber(value, locale, format);
  }
}
