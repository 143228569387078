import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { REQUIRED_ERROR } from '@app/forms/constants';

import { ShareTypeService } from '@app/shared/services/shareType.service';
import { ToastService } from '@app/shared/services/toast.service';
import { IFictiveModalData } from '@app/shared/types/interfaces';
import { REQUEST_FAILED_ERROR } from '@app/shared/utils/constants/modal.constants';
import { IShareType } from '@app/shareholder-management/types/interfaces/share-type.interface';

interface FictiveSharesTypeForm {
  name: FormControl<string | null>;
}

@Component({
  selector: 'wevestr-fictive-shares-type-modal',
  templateUrl: './fictive-shares-type-modal.component.html',
  styleUrls: ['./fictive-shares-type-modal.component.scss'],
})
export class FictiveSharesTypeModalComponent implements OnInit {
  public TITLE: string;
  public TEXT: string;
  public readonly ERROR_REQUIRED = REQUIRED_ERROR;
  public isRequestAlive = false;
  public form: FormGroup<FictiveSharesTypeForm>;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FictiveSharesTypeModalComponent>,
    private shareTypeService: ShareTypeService,
    @Inject(MAT_DIALOG_DATA) public data: IFictiveModalData,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    const { title, text } = this.data;
    this.TITLE = title;
    this.TEXT = text;
    this.form = this.initForm();
  }

  private initForm(): FormGroup<FictiveSharesTypeForm> {
    return this.formBuilder.group({
      name: this.formBuilder.control<string | null>(null, [Validators.required]),
    });
  }

  public handleSubmitForm(): void {
    if (this.form.valid) {
      const { name } = this.form.getRawValue();
      this.isRequestAlive = true;
      this.createFictiveSharesType(name);
    }
  }

  private createFictiveSharesType(name: string): void {
    this.shareTypeService
      .create({ name, usageType: this.data.usageType })
      .subscribe(this.handleSuccess.bind(this), this.handleError.bind(this));
  }

  private handleError(): void {
    this.toastService.error(REQUEST_FAILED_ERROR);
    this.isRequestAlive = false;
  }

  private handleSuccess(shareType: Partial<IShareType>): void {
    this.isRequestAlive = false;
    this.dialogRef.close(shareType);
  }
}
