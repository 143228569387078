import { AccessType, Permission } from '@app/shared/types/enums';
import { Section } from '@app/shared/types/enums/section.enum';
import { IPermissionsAccessConfiguration } from '@app/shared/types/interfaces';

export const EDIT_ACCESS_TYPES = [AccessType.fullAccess];
export const VIEW_ACCESS_TYPES = [AccessType.viewOnly, AccessType.fullAccess];

export enum ModuleName {
  COMPANY = 'company',
  PARTICIPANT = 'participant',
}

export const PERMISSION_CONFIGURATIONS_FOR_SECTION: { [key in Section]?: IPermissionsAccessConfiguration[] } = {
  [Section.home]: [{ [Permission.homeAccess]: VIEW_ACCESS_TYPES }],
  [Section.dashboard]: [{ [Permission.dashboardAccess]: VIEW_ACCESS_TYPES }],
  [Section.loansAndConvertibles]: [{ [Permission.debtsAccess]: VIEW_ACCESS_TYPES }],
  [Section.capTable]: [{ [Permission.capTableAccess]: VIEW_ACCESS_TYPES }],
  [Section.financials]: [{ [Permission.financialsAccess]: VIEW_ACCESS_TYPES }],
  [Section.kpis]: [{ [Permission.kpisAccess]: VIEW_ACCESS_TYPES }],
  [Section.documents]: [
    { [Permission.documentsAccess]: VIEW_ACCESS_TYPES },
    { [Permission.esopAdminAccess]: VIEW_ACCESS_TYPES },
  ],
  [Section.slicingPie]: [{ [Permission.frameworkAccess]: VIEW_ACCESS_TYPES }],
  [Section.scenarioModeling]: [{ [Permission.scenarioModelingAccess]: VIEW_ACCESS_TYPES }],
  [Section.esop]: [{ [Permission.esopAdminAccess]: VIEW_ACCESS_TYPES }],
  [Section.stakeholders]: [{ [Permission.stakeholdersAccess]: VIEW_ACCESS_TYPES }],
  [Section.companySettings]: [
    { [Permission.companySettingsAccess]: VIEW_ACCESS_TYPES },
    { [Permission.capTableAccess]: EDIT_ACCESS_TYPES },
    { [Permission.frameworkAccess]: EDIT_ACCESS_TYPES },
    { [Permission.financialsAccess]: EDIT_ACCESS_TYPES },
  ],
  [Section.participant]: [{ [Permission.esopParticipantAccess]: VIEW_ACCESS_TYPES }],
};

export const PERMISSION_CONFIGURATION_FOR_MODULE_ACCESS: { [key in ModuleName]?: IPermissionsAccessConfiguration[] } = {
  [ModuleName.COMPANY]: [
    { [Permission.homeAccess]: VIEW_ACCESS_TYPES },
    { [Permission.dashboardAccess]: VIEW_ACCESS_TYPES },
    { [Permission.capTableAccess]: VIEW_ACCESS_TYPES },
    { [Permission.debtsAccess]: VIEW_ACCESS_TYPES },
    { [Permission.esopAdminAccess]: VIEW_ACCESS_TYPES },
    { [Permission.scenarioModelingAccess]: VIEW_ACCESS_TYPES },
    { [Permission.financialsAccess]: VIEW_ACCESS_TYPES },
    { [Permission.kpisAccess]: VIEW_ACCESS_TYPES },
    { [Permission.documentsAccess]: VIEW_ACCESS_TYPES },
    { [Permission.frameworkAccess]: VIEW_ACCESS_TYPES },
    { [Permission.stakeholdersAccess]: VIEW_ACCESS_TYPES },
  ],
  [ModuleName.PARTICIPANT]: [{ [Permission.esopParticipantAccess]: VIEW_ACCESS_TYPES }],
};
