import { Component, Input } from '@angular/core';
import { IStakeholder } from '@app/types/interfaces/stakeholder';

@Component({
  selector: 'app-detailed-info-side-card',
  templateUrl: './detailed-info-side-card.component.html',
  styleUrls: ['./detailed-info-side-card.component.scss'],
})
export class DetailedInfoSideCardComponent {
  @Input() public entityType: string;
  @Input() public shareholder: IStakeholder;
  @Input() public showInformationWord?: boolean = true;
}
