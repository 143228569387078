import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  ARROW_DOWN_CODE,
  ARROW_TAB_CODE,
  ARROW_UP_CODE,
  ENTER_CODE,
} from '@app/shared/utils/constants/key-codes.constants';
import { isValueDefined } from '@app/shared/utils/helpers/common.helpers';

const specialCodes = [ARROW_UP_CODE, ARROW_DOWN_CODE, ARROW_TAB_CODE, ENTER_CODE];

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent<T> implements OnInit, ControlValueAccessor {
  @Input() public placeholder: string;
  @Input() public autocomplete: string;
  @Input() public options: T[];
  @Input() public disabled: boolean;
  @Input() public isSearchIconVisible = false;
  @Input() public isDropdownIconVisible = false;
  @Input() public displayFn = (item: T): string => <string>(<Record<string, unknown>>item).name;
  @Input() public compareFn: (item: T, userInput: string) => boolean;
  @Input() public additionalCompareFn: (item: T, userInput: string) => boolean;
  @Input() public shouldFilterOnFocus = true;

  public filteredOptions: T[];
  public opened = false;
  public userInput: string;
  public highlightIndex = 0;

  private value: T;

  public onTouched: () => void;
  private propagateChange: (value: T) => void;

  public ngOnInit(): void {
    this.filteredOptions = this.options;
  }

  public handleSelectItem(option: T): void {
    this.opened = false;
    this.value = option;
    this.userInput = this.displayFn(this.value);
    this.handleChangeOption(option);
  }

  public handleGoUp(): void {
    if (this.highlightIndex - 1 < 0) {
      this.highlightIndex = this.filteredOptions.length - 1;
    } else {
      this.highlightIndex -= 1;
    }
  }

  public handleGoDown(): void {
    if (this.highlightIndex + 1 === this.filteredOptions.length) {
      this.highlightIndex = 0;
    } else {
      this.highlightIndex += 1;
    }
  }

  public handleSelectHighlighted(): void {
    if (this.opened) {
      const option = this.filteredOptions[this.highlightIndex];
      this.handleSelectItem(option);
    } else {
      this.openDropdown();
    }
  }

  private equalFn = (item: T, value: string): boolean => <string>(<Record<string, unknown>>item)?.name === value;
  private equalByDisplayFn = (item: T, value: string): boolean => this.displayFn(item) === value;

  public handleKeyUp(event: KeyboardEvent, value: string): void {
    if (specialCodes.includes(event.code)) {
      return;
    }
    const validEnteredOption =
      this.options.find((el) => this.equalFn(el, value)) || this.options.find((el) => this.equalByDisplayFn(el, value));
    if (validEnteredOption) {
      this.handleSelectItem(validEnteredOption);
    } else {
      this.openDropdown();
      this.handleChangeOption(null);
    }
    this.filterOptions();
    this.highlightIndex = 0;
  }

  private handleChangeOption(option: T): void {
    if (this.propagateChange) {
      this.propagateChange(option);
    }
  }

  private openDropdown(): void {
    if (!this.opened) {
      this.opened = true;
    }
  }

  private filterOptions(): void {
    const suitableOptions = new Set<T>();
    this.options.forEach((option) => {
      const startsWith = this.compareFn(option, this.userInput);
      if (startsWith) {
        suitableOptions.add(option);
      }
    });
    if (this.additionalCompareFn) {
      this.options.forEach((option) => {
        const includes = this.additionalCompareFn(option, this.userInput);
        if (includes) {
          suitableOptions.add(option);
        } else {
          return;
        }
      });
    }

    this.filteredOptions = [...suitableOptions];
  }

  public handleFocus(): void {
    this.updateOptions();
    this.openDropdown();
  }

  private updateOptions(): void {
    if (this.shouldFilterOnFocus) {
      this.filterOptions();
    } else {
      this.showAllOptions();
      this.setHighlightOnSelectedOption();
    }
  }

  private showAllOptions(): void {
    const suitableOptions = new Set(this.options);
    this.filteredOptions = [...suitableOptions];
  }

  private setHighlightOnSelectedOption(): void {
    const index = this.filteredOptions.findIndex((option) => this.equalByDisplayFn(option, this.userInput));
    const isValidOption = index !== -1;
    this.highlightIndex = isValidOption ? index : 0;
  }

  public handleBlur(): void {
    if (this.onTouched) {
      this.onTouched();
      this.opened = false;
    }
  }

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: T): void {
    if (isValueDefined(value)) {
      this.value = value;
      this.userInput = this.displayFn(this.value);
    }
  }
}
