import { Injectable } from '@angular/core';

import { UserSettingsService } from '@app/shared/services/user-settings.service';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { ICurrency } from '@app/shared/types/interfaces/currency.interface';
import {
  formatCurrencyValue,
  getLocale,
  getNarrowCurrencySymbol,
  isNumericValue,
} from '@app/shared/utils/helpers/common.helpers';
import { NOT_AVAILABLE_OR_APPLICABLE_SIGN } from '@app/shared/utils/constants/common.constants';
import { MasksService } from '@app/shared/services/masks.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencySettingsService {
  constructor(
    private userSettingsService: UserSettingsService,
    private companySettingsService: CompanySettingsService,
    private masksService: MasksService,
  ) {}

  public getCurrentCurrency(): ICurrency {
    const { currency } = this.companySettingsService.settings;
    return currency;
  }

  public format(value: number, fallback = NOT_AVAILABLE_OR_APPLICABLE_SIGN, decimals?: number): string {
    const { currency } = this.companySettingsService.settings;
    const { formatting } = this.userSettingsService.settings;
    if (!currency?.code || !formatting || !isNumericValue(decimals ?? currency.decimals) || !isNumericValue(value)) {
      return fallback;
    }
    const locale = getLocale(formatting);
    const format = this.masksService.ngCashFormat(decimals);
    return formatCurrencyValue(value, locale, currency.code, format);
  }

  public getCurrentCurrencySymbol(): string {
    const { currency } = this.companySettingsService.settings;
    return getNarrowCurrencySymbol(currency.code);
  }
}
