import { Directive, HostListener, Input, OnChanges } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

import { DatePickerComponent } from '@app/forms/components/date-picker/date-picker.component';
import { WevestrDateIntervalValidationService } from '@app/forms/services/wevestr-date-interval-validation.service';
import { TDateInterval, TDateLike } from '@app/forms/types';
import { now } from '@app/shared/utils/helpers/dates.helpers';

@Directive({
  selector: 'wevestr-date-picker[wvDatePickerIntervalFilter]',
})
export class DatePickerIntervalFilterDirective implements OnChanges {
  @Input() public dateIntervals: TDateInterval<TDateLike>[];
  @Input() public selectedDateIntervalId: number;

  constructor(
    private host: DatePickerComponent,
    private dateIntervalValidationService: WevestrDateIntervalValidationService,
  ) {}

  private isDateIntervalSelected(): boolean {
    return Array.isArray(this.dateIntervals) && !!this.selectedDateIntervalId;
  }

  public ngOnChanges(): void {
    const isIntervalSelected = this.isDateIntervalSelected();
    if (isIntervalSelected) {
      this.setDateIntervalsValidator();
      this.setDateFilter();
    }
    this.setViewDate();
  }

  @HostListener('dateChange')
  public onDateChange(): void {
    this.setViewDate();
  }

  private setViewDate(): void {
    const selectedDate = this.getDatePickerControl()?.value;
    this.host.viewDate = this.isDateIntervalSelected()
      ? this.getViewDateWithinSelectedInterval(selectedDate)
      : this.getViewDate(selectedDate);
  }

  private getViewDateWithinSelectedInterval(selectedDate: string): Date {
    const isDateWithinSelectedInterval = this.isDateWithinSelectedInterval(selectedDate);
    return isDateWithinSelectedInterval
      ? new Date(selectedDate)
      : this.dateIntervalValidationService.getLastAvailableDateOfInterval(
          this.selectedDateIntervalId,
          this.dateIntervals,
        );
  }

  private isDateWithinSelectedInterval(selectedDate: string): boolean {
    return (
      selectedDate &&
      !this.dateIntervalValidationService.validateDateAccordingSelectedInterval(
        new Date(selectedDate),
        this.selectedDateIntervalId,
        this.dateIntervals,
      )
    );
  }

  private getViewDate(selectedDate: string): Date {
    return selectedDate ? new Date(selectedDate) : now();
  }

  private getDatePickerControl(): AbstractControl {
    return this.host.abstractControl.control;
  }

  private setDateIntervalsValidator(): void {
    const datePickerControl = this.getDatePickerControl();

    datePickerControl.setErrors(null);
    datePickerControl.addValidators(this.dateIntervalsValidator);
    datePickerControl.updateValueAndValidity();
  }

  private setDateFilter(): void {
    this.host.dateFilter = (date: Date): boolean =>
      date &&
      !this.dateIntervalValidationService.validateDateAccordingSelectedInterval(
        date,
        this.selectedDateIntervalId,
        this.dateIntervals,
      );
  }

  public dateIntervalsValidator: ValidatorFn = ({ value }) => {
    return this.dateIntervalValidationService.validateDateAccordingSelectedInterval(
      value,
      this.selectedDateIntervalId,
      this.dateIntervals,
    );
  };
}
