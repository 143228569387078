import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdownButton } from '@app/types/interfaces/dropdown-button.interface';

@Component({
  selector: 'wevestr-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent {
  @Input() buttonTitle: string = null;
  /**
   *  emitButtonId - when the emitButtonId is true, the emitButtonId emits the buttonId to the onClick listerner function.
   */
  @Input() emitButtonId = false;
  @Input() dropdownButtons: IDropdownButton[] = [];

  @Output() onClick: EventEmitter<string> = new EventEmitter(null);

  public opened = false;

  public handleOnClick(action: IDropdownButton): void {
    if (this.emitButtonId) {
      this.onClick.emit(action.buttonId);
    } else {
      action.event();
    }
  }

  public handleActiveTemplate($event: boolean): void {
    this.opened = $event;
  }
}
