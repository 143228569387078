import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FinancialsViewStateService {
  private _isFinancialsEmptyState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() {}

  isFinancialsEmptyState$(): Observable<boolean> {
    return this._isFinancialsEmptyState$.asObservable();
  }

  setIsFinancialsEmptyState(value: boolean): void {
    this._isFinancialsEmptyState$.next(value);
  }
}
