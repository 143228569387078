import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wevestr-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent {
  @Input() public documentURL: string;
  @Input() public title: string;

  @Output() public onClose = new EventEmitter<void>();
}
