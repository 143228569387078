import { Component, Input, TemplateRef } from '@angular/core';
import { UserRole } from '@app/shared/types/classes';

@Component({
  selector: 'app-table-empty-state',
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.scss'],
})
export class TableEmptyStateComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() isImageVisible = true;
  @Input() imageSrc: string;
  @Input() buttonTemplate: TemplateRef<unknown>;
  userRoles = UserRole;
}
