import { formatNumber, getCurrencySymbol } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { isNil, equals, pick } from 'ramda';

import { NumberFormatting } from '@app/settings/types/enums';
import { getMonthNames } from '@app/performance/helpers/dates.helpers';
import { SortingDirection } from '@app/shared/types/enums';
import { IItemWithName } from '@app/shared/types/interfaces/item-with-name.interface';
import { NUMEROUS_WHITESPACES_REGEX } from '@app/shared/utils/helpers/regex.helpers';
import { ICountry } from '@app/types/interfaces/profile.interface';
import {
  CURRENCY_MAX_SYMBOLS_WITHOUT_WHITESPACE,
  DEFAULT_DIGITS_FORMAT,
} from '@app/shared/utils/constants/common.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ICurrency } from '@wevestr/bff-types/models/interfaces/currency.interface';
import { findById } from '@app/shared/utils/helpers/arrays.helpers';

export const findCurrencyByCountry =
  (currencies: ICurrency[]) =>
  ({ currencyId }: ICountry) => {
    return findById(currencies, currencyId);
  };

export const getCurrencySymbolAndCode = (currencyCode: string): string =>
  `${currencyCode} (${getNarrowCurrencySymbol(currencyCode)})`;

export const displayCurrency = ({ code }: ICurrency): string => getCurrencySymbolAndCode(code);

const addWhitespaceAfterCurrency = (currencySymbol: string, maxLength: number): string => {
  return currencySymbol.length > maxLength ? `${currencySymbol} ` : `${currencySymbol}`;
};

export const formatCurrencyValue = (
  value: number | string | null | undefined,
  locale: string,
  currencyCode: string,
  baseDigitsInfo = DEFAULT_DIGITS_FORMAT,
): string => {
  if (!isNumericValue(value)) {
    return null;
  }

  const numericValue = Number(value),
    formattedValue = formatNumber(Math.abs(numericValue), locale, baseDigitsInfo),
    currencySymbol = getNarrowCurrencySymbol(currencyCode),
    formattedCurrencySymbol = addWhitespaceAfterCurrency(currencySymbol, CURRENCY_MAX_SYMBOLS_WITHOUT_WHITESPACE);

  return `${getSignPrefix(numericValue)}${formattedCurrencySymbol}${formattedValue}`;
};

export const isNumericValue = (value: number | string | null | undefined): boolean =>
  !(value === null || value === void 0 || isNaN(Number(value)));

const getSignPrefix = (value: number): string => (value < 0 ? '- ' : '');

export const getNarrowCurrencySymbol = (currencyCode: string) => getCurrencySymbol(currencyCode, 'narrow') || '';

export const enumToObject = (enumType: unknown): { name: string }[] => {
  return Object.values(enumType).map((value) => ({ name: value }));
};

export const toTitleCase = (value: string): string => value.charAt(0).toUpperCase() + value.substring(1);

export const getLocale = (formatting: NumberFormatting): string =>
  formatting === NumberFormatting.EUROPEAN ? 'nl' : 'en-US';

export const getMonthYear = (date: string): string => {
  const _date = new Date(date);
  const now = new Date();
  const isFutureMonth = now.toISOString() < _date.toISOString();
  const months = getMonthNames();

  return `${toTitleCase(months[_date.getMonth()])} ${_date.getFullYear()}${isFutureMonth ? '*' : ''}`;
};

export const addRecurringSymbol = (value: string, recurringParentId: number): string => {
  return recurringParentId ? `<span class='repeat'>${value} </span>` : `<span>${value}</span>`;
};

export function toFormData<T>(object: T, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData();
  for (const property in object) {
    if (!Object.prototype.hasOwnProperty.call(object, property)) {
      continue;
    }
    const formKey = namespace ? `${namespace}[${property}]` : property;
    const propertyValue = object[property];
    if (propertyValue instanceof Date) {
      formData.append(formKey, propertyValue.toISOString());
    } else if (typeof propertyValue === 'object' && !(propertyValue instanceof File)) {
      toFormData(propertyValue, formData, formKey);
    } else {
      formData.append(formKey, propertyValue as unknown as Blob);
    }
  }
  return formData;
}

export const download = (file: Blob, contentDispositionValue: string): void => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = contentDispositionValue.split(';')[1].split('=')[1].replace(/"/g, '');

  a.click();
};

export function deepClone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export const isAcceptedMimeType = (contentType: string, allowedTypes: string[]): boolean =>
  allowedTypes.some((allowedType: string) => contentType.startsWith(allowedType));

export function isEmptyIndicatorState(value: number | string): boolean {
  return value === null || value === '' || value === undefined || value === 'unknown';
}

export function checkDiscountIntervalsOverlap(control1: AbstractControl, control2: AbstractControl): boolean {
  const startDate1 = new Date(control1.get('discountStartDate').value);
  const endDate1 = new Date(control1.get('discountEndDate').value);
  const startDate2 = new Date(control2.get('discountStartDate').value);
  const endDate2 = new Date(control2.get('discountEndDate').value);

  return startDate1.getTime() <= endDate2.getTime() && endDate1.getTime() >= startDate2.getTime();
}

export function sortByComparator<T>(field: keyof T, direction: SortingDirection): (objA: T, objB: T) => number {
  return (objA: T, objB: T) => {
    const a = direction === SortingDirection.asc ? objA : objB;
    const b = direction === SortingDirection.asc ? objB : objA;

    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  };
}

export const togglePasswordType = (inputType: string): string => {
  return inputType === 'password' ? 'text' : 'password';
};

export function compareByName<T extends IItemWithName>(comparable: T, userInput = ''): boolean {
  return comparable.name.toLowerCase().includes(userInput.toLowerCase());
}

export function compareFnNumbers(value1: number, value2: number): boolean {
  return value1 === value2;
}

export function displayBasicItemName(value: { name: string }): string {
  return toTitleCase(value?.name ?? '');
}

export function displayFnNumber(value: number): number {
  return value;
}

export function deleteWhitespaces(value: string): string {
  return value.replace(NUMEROUS_WHITESPACES_REGEX, ' ').trim();
}

export function countryStartsWithCompare(country: ICountry, userInput = ''): boolean {
  return country.name.toLowerCase().startsWith(userInput.toLowerCase());
}

export function countryIncludesCompare(country: ICountry, userInput = ''): boolean {
  return country.name.toLowerCase().includes(userInput.toLowerCase());
}

export const currencyStartsWithCompare =
  (renderFn: (currency: ICurrency) => string = null) =>
  (currency: ICurrency, userInput = ''): boolean => {
    const renderedCurrency = renderFn?.(currency) || currency.code;
    return renderedCurrency.toLowerCase().startsWith(userInput.toLowerCase());
  };

export const currencyIncludesCompare =
  (renderFn: (currency: ICurrency) => string = null) =>
  (currency: ICurrency, userInput = ''): boolean => {
    const renderedCurrency = renderFn?.(currency) || currency.code;
    return renderedCurrency.toLowerCase().includes(userInput.toLowerCase());
  };

export const isValueDefined = (value: unknown): boolean => {
  return !isNil(value);
};

export function hasNElements(numberOfElements: number) {
  return (elements: unknown[]): boolean => elements.length === numberOfElements;
}

export const hasOneElement = hasNElements(1);

export function isObject(value: unknown): boolean {
  return value instanceof Object;
}

export function isString(value: unknown): boolean {
  return typeof value === 'string';
}

export function isNumber(value: unknown): boolean {
  return typeof value === 'number';
}

export function getObjectsDifference<T extends Record<string, unknown>>(previous: T, next: T): Partial<T> {
  const differenceKeys = Object.keys(next).filter((key) => !equals(previous[key], next[key]));

  return <Partial<T>>pick(differenceKeys, next);
}

export function isFile(file: string | File): file is File {
  return file instanceof File;
}

export const getErrorMessage = (error: HttpErrorResponse): string => {
  let errorMessage = error?.error?.message;

  if (errorMessage && typeof errorMessage === 'object') {
    errorMessage = Object.values(errorMessage).join();
  }
  return errorMessage;
};

export const focusElementByName = (name: string): void => {
  document.getElementById(`${name}`).focus();
};

export const getSelectButtonIdByControlName = (name: string): string => {
  return `select-button-${name}`;
};

export function compareStringsInAlphabeticalOrderWithoutCase(string1: string, string2: string): number {
  if (!string1) {
    return 1;
  }

  if (!string2) {
    return -1;
  }

  if (string1.toLowerCase() > string2.toLowerCase()) {
    return 1;
  } else if (string1.toLowerCase() === string2.toLowerCase()) {
    return 0;
  } else {
    return -1;
  }
}

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank');
};

export function getRandomNumber(min = 1, max = 2147483646): number {
  // PostgreSQL integer range min = -2147483648, max = 2147483647
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
