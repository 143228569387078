import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FrameworkPhasesService } from '@app/framework/services/framework-phases.service';
import { IFrameworkPhase } from '@app/framework/types/interfaces';
import { ToastService } from '@app/shared/services/toast.service';
import {
  PHASES_DATES_SET_UP_MESSAGE,
  EMPTY_LAST_PHASE_END_DATE_MESSAGE,
} from '@app/shared/utils/constants/toast-messages.constants';
import { DateValidator } from '@app/forms/validators/date.validator';
import { isEmptyValidator, phaseDatesValidator } from '@app/framework/validators';
import { getNextMonthDate } from '@app/shared/utils/helpers/dates.helpers';
import { phaseDatesTimelineValidator } from '@app/shared/utils/helpers/validation.helpers';
import { YEAR_MONTH_DAY_FORMAT } from '@app/shared/utils/constants/date.constants';
import { DateFormatPipe } from '@app/shared/pipes';

interface PhasesFormItem {
  startDate: FormControl<Date | null>;
  endDate: FormControl<Date | null>;
}

interface PhasesForm {
  phases: FormArray<FormGroup<PhasesFormItem>>;
}

@Component({
  selector: 'app-set-phases',
  templateUrl: './set-phases-timeline.component.html',
  styleUrls: ['./set-phases-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPhasesTimelineComponent implements OnInit {
  public phases: IFrameworkPhase[] = [];
  public form: FormGroup<PhasesForm>;

  private unsubscribe$ = new Subject();

  public readonly ERRORS = {
    REQUIRED: 'This field is required',
    TOO_EARLY_END_DATE: 'The end date should not be earlier than start date',
    WRONG_START_DATE: 'The start date should be the next month after previous phase end date',
    LAST_PHASE_EMPTY_END_DATE: 'Last phase end date should be empty. Please add phase or clear the value',
  };

  private get phasesArray(): FormArray<FormGroup<PhasesFormItem>> {
    return this.form.controls.phases;
  }

  constructor(
    private dialogRef: MatDialogRef<SetPhasesTimelineComponent>,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private frameworkPhasesService: FrameworkPhasesService,
    private changeDetectorRef: ChangeDetectorRef,
    private dateFormat: DateFormatPipe,
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        phases: this.formBuilder.array<FormGroup<PhasesFormItem>>([]),
      },
      { validators: [phaseDatesTimelineValidator] },
    );

    this.getPhases();
  }

  public handleClose(): void {
    const shouldEmitUpdate = false;
    this.dialogRef.close(shouldEmitUpdate);
  }

  public handleAddPhase(): void {
    const lastPhaseIndex = this.phasesArray.length - 1;
    const lastPhaseEndDateControl = this.getEndDateControl(lastPhaseIndex);
    const isActivePhase = true;
    if (lastPhaseEndDateControl.value) {
      lastPhaseEndDateControl.setValidators(Validators.required);
      lastPhaseEndDateControl.updateValueAndValidity();
      this.phasesArray.push(this.getPhaseForm(isActivePhase, lastPhaseEndDateControl.value));
    } else {
      this.toastService.error(EMPTY_LAST_PHASE_END_DATE_MESSAGE);
    }
  }

  public handleDeletePhase(index: number): void {
    const previousPhaseEndDateControl = this.getEndDateControl(index - 1);
    previousPhaseEndDateControl.setValidators(isEmptyValidator);
    previousPhaseEndDateControl.updateValueAndValidity();
    this.phasesArray.removeAt(index);
  }

  private getStartDateControl(index: number): FormControl<Date | null> {
    return this.phasesArray.at(index)?.controls.startDate;
  }

  private getEndDateControl(index: number): FormControl<Date | null> {
    return this.phasesArray.at(index)?.controls.endDate;
  }

  public setPhases(): void {
    if (this.form.valid) {
      const phases = this.phases.map((phase, index) => {
        const startDate = index < this.phasesArray.length ? this.getStartDateControl(index).value : null;
        const endDate = index < this.phasesArray.length ? this.getEndDateControl(index).value : null;
        return {
          ...phase,
          startDate: this.dateFormat.transform(startDate, YEAR_MONTH_DAY_FORMAT), // used deprecated format because BE requires it,
          endDate: this.dateFormat.transform(endDate, YEAR_MONTH_DAY_FORMAT), // used deprecated format because BE requires it,
        };
      });
      this.frameworkPhasesService.setPhasesDates(phases).subscribe(() => {
        this.toastService.success(PHASES_DATES_SET_UP_MESSAGE);
        const shouldEmitUpdate = true;
        this.dialogRef.close(shouldEmitUpdate);
      });
    }
  }

  public phaseCouldBeDeleted(index: number): boolean {
    return index > 0 && index === this.phasesArray.controls.length - 1;
  }

  private getPhases(): void {
    this.frameworkPhasesService
      .list()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((phases: IFrameworkPhase[]) => {
        this.phases = phases;
        const activatedPhases = phases.filter((phase) => !!phase.startDate);
        for (let index = 0; index < activatedPhases.length; index++) {
          const isLastPhase = index === activatedPhases.length - 1;
          this.phasesArray.push(this.getPhaseForm(isLastPhase));
        }
        if (!activatedPhases.length) {
          this.phasesArray.push(this.getPhaseForm());
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  private getPhaseForm(isActivePhase = true, previousPhaseEnd: string | Date = null): FormGroup<PhasesFormItem> {
    const nextPhaseIndex = this.phasesArray ? this.phasesArray.length : 0;
    const nextPhase = this.phases[nextPhaseIndex];
    const previousPhaseEndDate = new Date(previousPhaseEnd);
    const startDate = nextPhase.startDate ? new Date(nextPhase.startDate) : getNextMonthDate(previousPhaseEndDate);

    const endDate = nextPhase.endDate ? new Date(nextPhase.endDate) : null;
    const endDateValidators = isActivePhase ? isEmptyValidator : Validators.required;
    return this.formBuilder.group(
      {
        startDate: [startDate, [Validators.required, DateValidator]],
        endDate: [endDate, endDateValidators],
      },
      { validators: [phaseDatesValidator] },
    );
  }
}
