import { AbstractControl } from '@angular/forms';

export const phaseDatesValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const startDate = control.get('startDate');
  const endDate = control.get('endDate');
  endDate.setErrors({ tooEarly: null });
  endDate.updateValueAndValidity({ onlySelf: true });

  if (endDate.value && endDate.value <= startDate.value) {
    endDate.setErrors({ tooEarly: true });
  }
  return null;
};
