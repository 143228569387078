import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { getStringValidatorByRegExp, passwordMatcherValidator, PASSWORD_VALIDATION_RULES } from '@app/auth/validators';
import { PASSWORD_ICONS } from '@app/shared/utils/constants/auth.constants';
import { ICreatePasswordPayload } from '@app/shared/types/interfaces';
import { IGNORE_TAB_INDEX } from '@app/shared/utils/constants/common.constants';
import { TypeToFormControls } from '@app/forms/types';

enum PasswordStrength {
  NONE = 'none',
  WEAK = 'weak',
  MEDIUM = 'medium',
  GREAT = 'great',
}

type CredentialsFormData = {
  password: string | null;
  confirmPassword: string | null;
};
type CredentialsForm = TypeToFormControls<CredentialsFormData>;

@Component({
  selector: 'wevestr-credentials-form',
  templateUrl: './credentials-form.component.html',
  styleUrls: ['./credentials-form.component.scss'],
})
export class CredentialsFormComponent implements OnInit {
  @Input() public showBackButton = true;

  @Output() public saveCredentials = new EventEmitter<Omit<ICreatePasswordPayload, 'token'>>();
  @Output() public handleBack = new EventEmitter<void>();

  public form: FormGroup<CredentialsForm>;
  public showPassword = false;
  public passwordIcons = PASSWORD_ICONS;

  public readonly PASSWORD_STRENGTH = PasswordStrength;
  public readonly ignoreTabIndex = IGNORE_TAB_INDEX;

  public passwordStrength$: Observable<PasswordStrength>;
  private PASSWORD_LENGTH = {
    MEDIUM: 8,
    GREAT: 12,
  };

  public readonly ERRORS_MAP = {
    minLength: 'Minimum 8 symbols long',
    badPasswordCharacters: 'Password should contain at least 1: lowercase, uppercase, number',
    noMatch: 'Passwords should be the same',
    required: 'This field is required',
  };

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.initForm();
  }

  public submitCredentials(): void {
    if (this.form.valid) {
      this.emitSaveCredentials();
    }
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  private emitSaveCredentials(): void {
    const { password } = this.form.getRawValue();

    this.saveCredentials.emit({ password });
  }

  private initForm(): FormGroup<CredentialsForm> {
    const form = this.formBuilder.group(
      {
        password: this.formBuilder.control<string | null>(null, [
          Validators.required,
          Validators.minLength(8),
          getStringValidatorByRegExp(PASSWORD_VALIDATION_RULES, 'badPasswordCharacters'),
        ]),
        confirmPassword: this.formBuilder.control<string | null>(null, [Validators.required, Validators.minLength(8)]),
      },
      { validators: [passwordMatcherValidator] },
    );

    this.passwordStrength$ = form.controls.password.valueChanges.pipe(
      debounceTime(500),
      map((password) => {
        const length = password ? (<string>password).length : 0;
        let strength = PasswordStrength.NONE;
        switch (true) {
          case length === 0:
            strength = PasswordStrength.NONE;
            break;
          case length < this.PASSWORD_LENGTH.MEDIUM:
            strength = PasswordStrength.WEAK;
            break;
          case length < this.PASSWORD_LENGTH.GREAT:
            strength = PasswordStrength.MEDIUM;
            break;
          case length >= this.PASSWORD_LENGTH.GREAT:
            strength = PasswordStrength.GREAT;
            break;
        }
        return strength;
      }),
      startWith(PasswordStrength.NONE),
    );

    return form;
  }
}
