<nav class="navbar light-border-t light-border-b" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <div class="navbar-item light-border-r cursor-pointer top-bar__hamburger-menu" (click)="handleToggleSidenav()">
      <mat-icon [svgIcon]="'menu'"></mat-icon>
    </div>
    <div *ngIf="logoUrl" class="navbar-item padding-left-l">
      <img class="top-bar__logo" [src]="logoUrl" />
    </div>
  </div>

  <div class="navbar-end">
    <div class="navbar-item has-dropdown is-hoverable">
      <div class="navbar-link">
        <app-avatar
          class="top-bar__avatar"
          [name]="name"
          [image]="user?.profile?.avatarUrl ? user.profile.avatarUrl : null"
        ></app-avatar>
        <div class="app-body">{{ name }}</div>
      </div>

      <div class="navbar-dropdown">
        <a class="navbar-item" (click)="logout.emit()">Logout</a>
      </div>
    </div>
  </div>
</nav>
