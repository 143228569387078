import { ISidenavOption } from '@app/types/interfaces/sidenav-option.interface';
import { Section } from '@app/shared/types/enums/section.enum';
import { CAP_TABLE_MANAGE_SHARES_URL } from '@app/shared/utils/constants/paths.constants';
import { CompanyDashboardType } from '@wevestr/bff-types/enums/companyDashboardType.enum';

export type TSidebarSection = Exclude<Section, Section.settings | Section.companySettings | Section.participant>;

export const SIDEBAR_SECTIONS: Record<TSidebarSection, Omit<ISidenavOption, 'routerLink'>> = {
  [Section.home]: {
    name: 'Home',
    id: 'section-Home',
    icon: 'home',
  },
  [Section.dashboard]: {
    name: 'Dashboard',
    id: 'section-dashboard',
    icon: 'dashboard',
    description: 'The Dashboard shows your cap table, financials, KPIs and company details in one place.',
  },
  [Section.capTable]: {
    name: 'Cap Table',
    id: 'section-cap-table',
    icon: 'chart',
    description: 'Manage your Cap Table.',
    children: [
      {
        name: 'Manage Shares',
        isBreadcrumbHidden: true,
        routerLink: CAP_TABLE_MANAGE_SHARES_URL,
        ignoreExpand: true,
      },
    ],
  },
  [Section.loansAndConvertibles]: {
    name: 'Notes & Loans',
    id: 'section-notes-and-loans',
    icon: 'loans',
    description: 'Track Notes & Loans.',
  },
  [Section.esop]: {
    name: 'ESOP',
    id: 'section-esop',
    icon: 'building_solid',
    description: 'Manage your stock option Pools & Plans.',
  },
  [Section.scenarioModeling]: {
    name: 'Modeling',
    id: 'section-scenario-modeling',
    icon: 'scenario_icon',
    description: 'Simulate and evaluate future Cap Table scenarios.',
    isMaintenance: true,
    disabled: true,
  },
  [Section.financials]: {
    name: 'Financials',
    id: 'section-financials',
    icon: 'financials',
    description: 'Enter and track your company Financials.',
  },
  [Section.kpis]: {
    name: 'KPIs',
    id: 'section-kpis',
    icon: 'performance',
    description: 'Enter and track your company KPIs.',
  },
  [Section.documents]: {
    name: 'Documents',
    id: 'section-documents',
    icon: 'documents',
    description: 'Upload contracts and any other company or personal documents.',
  },
  [Section.slicingPie]: {
    name: 'Slicing Pie',
    id: 'section-slicing-pie',
    icon: 'extension',
    description: 'Set the equity framework of your business.',
  },
  [Section.stakeholders]: {
    name: 'Stakeholders',
    id: 'section-stakeholders',
    icon: 'user',
    description: 'Manage your Stakeholders.',
  },
};

export const SECTION_NAVIGATION_ORDER_FOR_SLICING_PIE_COMPANY = [
  Section.dashboard,
  Section.slicingPie,
  Section.loansAndConvertibles,
  Section.esop,
  Section.financials,
  Section.kpis,
  Section.documents,
  Section.stakeholders,
  Section.participant,
  Section.companySettings,
];

export const SECTIONS_NAVIGATION_MIXED_ORDER = [
  Section.home,
  Section.dashboard,
  Section.capTable,
  Section.loansAndConvertibles,
  Section.esop,
  Section.scenarioModeling,
  Section.financials,
  Section.kpis,
  Section.documents,
  Section.slicingPie,
  Section.stakeholders,
  Section.participant,
  Section.companySettings,
];

export const SECTIONS_NAVIGATION_CAP_TABLE_ORDER = [
  Section.dashboard,
  Section.capTable,
  Section.loansAndConvertibles,
  Section.esop,
  Section.scenarioModeling,
  Section.financials,
  Section.kpis,
  Section.documents,
  Section.stakeholders,
  Section.participant,
  Section.companySettings,
];

export const TYPE_TO_SECTIONS_ORDER: Record<CompanyDashboardType, Section[]> = {
  [CompanyDashboardType.CAP_TABLE_STARTING]: SECTIONS_NAVIGATION_CAP_TABLE_ORDER,
  [CompanyDashboardType.SLICING_PIE_STARTING]: SECTION_NAVIGATION_ORDER_FOR_SLICING_PIE_COMPANY,
  [CompanyDashboardType.MIXED]: SECTIONS_NAVIGATION_MIXED_ORDER,
};
