import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ANY } from '@app/shared/types/any';

@Component({
  selector: 'app-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrls: ['./chart-wrapper.component.scss'],
})
export class ChartWrapperComponent implements OnInit, OnDestroy {
  // TODO: figure out type of context, none is provided by library
  @Input() public chartContext: ANY;
  @Input() public debounceResizeTime = 100;

  public resizeChart$: BehaviorSubject<ResizedEvent> = new BehaviorSubject(null);

  private unsubscribe$ = new Subject<void>();

  constructor() {}

  public ngOnInit(): void {
    this.subscribeResizeChart();
  }

  public handleDivResize(event: ResizedEvent): void {
    this.resizeChart$.next(event);
  }

  private subscribeResizeChart(): void {
    this.resizeChart$
      .pipe(
        filter((event: ResizedEvent) => !!event),
        debounceTime(this.debounceResizeTime),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.chartContext) {
          this.resizeChart();
        }
      });
  }

  private resizeChart(): void {
    this.chartContext.w.globals.resized = true;
    this.chartContext.w.globals.dataChanged = false;
    this.chartContext.update();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
