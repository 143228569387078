import { inject, InjectionToken } from '@angular/core';
import { tap } from 'rxjs/operators';

import { PermissionsService } from '@app/shared/services';
import { SectionsNavigationService } from '@app/shared/services/section-navigation.service';
import { CanActivateCallback } from '@app/services/feature-control.guard';

const _hasViewAccessGuardFactory = (): CanActivateCallback => {
  const permissionsService = inject(PermissionsService);
  const sectionNavigationService = inject(SectionsNavigationService);

  return (route) => {
    const { data: { permission } = {} } = route;

    return permissionsService.hasViewAccess$(permission).pipe(
      tap((hasAccess) => {
        if (!hasAccess) {
          sectionNavigationService.navigateToFirstAvailableSection$().subscribe();
        }
      }),
    );
  };
};

/** Has View Access Guard token injected in the root of application
 * @example
 * {
 *   path: routeName, // name of your route
 *   canActivate: [HasViewAccessGuard], // provide HasViewAccessGuard as CanActivate guard
 *   data: { permission: Permission } // permission that would be checked with user's company role
 * }
 */
export const HasViewAccessGuard = new InjectionToken('hasViewAccessGuard', {
  providedIn: 'root',
  factory: _hasViewAccessGuardFactory,
});
