<div
  #categorizedSelect
  class="dropdown full-width full-height"
  [ngClass]="{ 'is-active': opened }"
  (keydown.arrowup)="handleGoUp($event)"
  (keydown.arrowdown)="handleGoDown($event)"
  (keydown.arrowright)="handleRightArrowExpand($event)"
  (keydown.arrowleft)="handleLeftArrowCollapse($event)"
  (keydown.enter)="handleSelectHighlighted()"
>
  <div class="dropdown-trigger full-width" (click)="toggleOpened($event)">
    <button
      class="button full-width full-height"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      [disabled]="disabled"
      type="button"
    >
      <div *ngIf="!isMultiselect && selectedOption; else placehold" class="flex-row align-items-center flex-1">
        <div class="color-toggle" [ngStyle]="{ backgroundColor: selectedOption.color }"></div>
        <div class="categorised-select__selected-name text-ellipsis">{{ selectedOption.name }}</div>
      </div>
      <ng-template #placehold>
        <span *ngIf="placeholder" class="placeholder full-width has-text-left app-h5">
          {{ placeholder }}
        </span>
      </ng-template>
      <mat-icon svgIcon="dropdown" class="selectable-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-menu full-width" [id]="id" role="menu">
    <div class="dropdown-content app-body">
      <div
        [ngClass]="{ 'is-active': option.isSelected, 'is-highlighted': highlightIndex === index }"
        *ngFor="let option of options; index as index"
      >
        <div *ngIf="option.isVisible" class="flex-row align-items-center">
          <div
            *ngIf="!option.isCategory; else categoryOption"
            class="option-item flex-row align-items-center flex-1"
            [ngClass]="{ 'has-parent': option.categoryId }"
            (click)="onSelectItem(option)"
          >
            <div
              class="color-toggle"
              [ngClass]="{ 'is-selected': option.isSelected }"
              [ngStyle]="{ backgroundColor: option.isSelected ? option.color : 'white' }"
            ></div>
            <div class="flex-1">{{ option.name }}</div>
          </div>
          <ng-template #categoryOption>
            <div
              class="option-item is-category flex-row align-items-center flex-1"
              [ngClass]="{
                'is-expanded': option.isExpanded
              }"
              (click)="onToggleCategory(option, $event)"
            >
              <mat-icon
                (click)="toggleExpandCategory(option, $event)"
                class="category-chevron"
                svgIcon="chevron-right"
              ></mat-icon>
              <div
                class="color-toggle"
                [ngClass]="{ 'is-selected': option.isSelected, 'is-partially-selected': option.isPartiallySelected }"
                [ngStyle]="{
                  backgroundColor: option.isSelected || option.isPartiallySelected ? option.color : 'white'
                }"
              ></div>
              <div class="flex-1">{{ option.name }}</div>
              <div class="category__children-number app-caption">
                {{ option.childrenNumber }}
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
