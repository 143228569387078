import { AbstractControl } from '@angular/forms';
import { removeFormControlError } from '@app/shared/utils/helpers/validation.helpers';

export const invoicedSalaryValidator = (salaryGroupControl: AbstractControl): { [key: string]: boolean } => {
  const invoicedSalary = salaryGroupControl.get('invoicedSalary');
  const baseSalary = salaryGroupControl.get('baseSalary');
  if (+invoicedSalary.value >= +baseSalary.value) {
    invoicedSalary.setErrors({ largerAmount: true });
  } else {
    removeFormControlError(invoicedSalary, 'largerAmount');
  }
  return null;
};
