import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IStakeholder } from '@app/types/interfaces/stakeholder';

@Component({
  selector: 'wevestr-selected-stakeholders',
  templateUrl: './selected-stakeholders.component.html',
  styleUrls: ['./selected-stakeholders.component.scss'],
})
export class SelectedStakeholdersComponent {
  @Input() public stakeholders: IStakeholder[];
  @Input() public label: string;
  @Input() public required = true;

  @Output() private removeStakeholder = new EventEmitter<number>();

  public onRemove(index: number): void {
    this.removeStakeholder.emit(index);
  }
}
