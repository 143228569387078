import { Component, Input } from '@angular/core';
import { DAY_MONTH_YEAR_DATE_FORMAT } from '@app/shared/utils/constants/date.constants';
import { InputType } from '@app/shared/types/enums';

@Component({
  selector: 'wevestr-key-value-pair',
  templateUrl: './key-value-pair.component.html',
  styleUrls: ['./key-value-pair.component.scss'],
})
export class KeyValuePairComponent {
  @Input()
  public key: string;

  @Input()
  public value: string;

  @Input()
  public type = InputType.TEXT;

  public readonly InputType = InputType;
  public readonly DATE_FORMAT = DAY_MONTH_YEAR_DATE_FORMAT;
}
