import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISidenavOption } from '@app/types/interfaces';
import { INavOption } from '@app/shared/types/interfaces';
import { isEmpty } from 'ramda';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { ANALYTICS_LOOKUP } from '@app/components/components/sidenav-item/sidenav-item.constants';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavItemComponent implements OnInit {
  @Input('option') public set navOption(option: ISidenavOption) {
    this.option = option?.children ? this.getOptionWithExpandableChildren(option) : option;
  }
  @Input() set active(isActive: boolean) {
    this.isActive = isActive;
    this.option.expanded = this.isActive;
  }
  @Input() public isSideNavCollapsed: boolean;
  @Input() public url: string;

  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

  public isActive = false;
  public option: ISidenavOption;

  constructor(private analyticsService: AnalyticsService, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.option.expanded = !this.isSideNavCollapsed && !!this.option.children && this.isActive;
  }

  public handleSidenavItemClicked(): void {
    this.performAnalytics();

    if (this.option.children) {
      if (this.isSideNavCollapsed) {
        this.option.expanded = true;
        this.toggleSidenav.emit();
      }
    }
  }

  private performAnalytics(): void {
    const optionName = this.option.name;

    const analyticsData = ANALYTICS_LOOKUP[optionName];
    if (analyticsData) {
      const { event, journey, subJourney } = analyticsData;
      this.analyticsService.track(event, {
        journey,
        subJourney,
        pageName: this.route.snapshot.data.pageName,
      });
    }
  }

  public subcategoryActive(subcategory: INavOption['children'][number]): boolean {
    return this.url.startsWith(subcategory.routerLink);
  }

  private getOptionWithExpandableChildren(option: ISidenavOption): ISidenavOption {
    const expandableChildren = option.children.filter((child) => !child.ignoreExpand);
    return { ...option, children: isEmpty(expandableChildren) ? void 0 : expandableChildren };
  }
}
