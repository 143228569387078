import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRole } from '@wevestr/bff-types/enums/companyRole.enum';

@Pipe({
  name: 'companyRoleLabel',
})
export class CompanyRoleLabelPipe implements PipeTransform {
  transform(role: CompanyRole): string {
    return role;
  }
}
