import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';

enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  transparent = 'transparent',
  blue = 'blue',
  cancel = 'cancel',
  base = 'base',
  reverse = 'reverse',
  filter = 'filter',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() public width: string;
  @Input('loading')
  public get loading(): boolean {
    return this._loading;
  }

  public set loading(loading: boolean) {
    this._loading = loading;
    if (loading) {
      this.initProgressUpdate();
    } else {
      this.clearProgressBar();
      this.unsubscribeTimer();
    }
  }

  private _loading = false;
  public classes = 'button';

  //TODO: on Anguular 13+ router link could be disabled by passing null/undefined, query params merge wouldn't be needed
  @Input() public routerLink: string[];
  @Input() public disabled = false;
  @Input() public icon: string;
  @Input() public type: ButtonTypes = ButtonTypes.primary;
  @Input() public isSubmit = false;
  @Input() public buttonDataId: string;
  @Input() public stopPropagation = true;
  @Input() public class: string;

  @Output() public onClick: EventEmitter<MouseEvent> = new EventEmitter();

  // TODO: move progressbar painting logic to directive
  @ViewChild('progress', { read: ElementRef }) public progressElement: ElementRef;

  private subscription = new Subscription();

  constructor() {}

  public handleClick(event: MouseEvent): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    if (!this.disabled && !this.loading) {
      this.onClick.emit(event);
    }
  }

  public ngOnInit(): void {
    this.setClasses();
  }

  private unsubscribeTimer(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initProgressUpdate(): void {
    this.unsubscribeTimer();

    this.subscription = interval(100).subscribe((value) => {
      this.progressElement.nativeElement.style.width = `${(value % 100) + 1}%`;
    });
  }

  private clearProgressBar(): void {
    if (this.progressElement?.nativeElement) {
      this.progressElement.nativeElement.style.width = 0;
    }
  }

  public setClasses(): void {
    this.classes = `${this.classes} ${ButtonTypes[this.type]}-button`;
  }

  public ngOnDestroy(): void {
    this.unsubscribeTimer();
  }
}
