import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Directive({
  selector: '[wvMonthPickerFormat]',
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: { month: 'long', year: 'numeric', day: undefined } },
        display: {
          dateInput: { month: 'long', year: 'numeric', day: undefined },
          monthYearLabel: { year: 'numeric' },
        },
      },
    },
  ],
})
export class MonthPickerFormatDirective {}
