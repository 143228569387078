export function compareById<T extends { id: number }>(o1: T, o2: T): boolean {
  return o1 && o2 && o1.id === o2.id;
}

export function compareByValue<T extends { value: string }>(o1: T, o2: T): boolean {
  return o1 && o2 && o1.value === o2.value;
}

export function compareByName<T extends { name: string }>(o1: T, o2: T): boolean {
  return o1 && o2 && o1.name === o2.name;
}

export function compareByItself<T extends { name: string }>(o1: T, o2: T): boolean {
  return o1 && o2 && o1 === o2;
}
