<div #dropdown class="dropdown full-width full-height" [ngClass]="{ 'is-active': opened }">
  <div class="dropdown-trigger full-width full-height dropdown_grouped-width">
    <button
      class="button full-width full-height"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      [disabled]="disabled"
    >
      <div *ngIf="value; else showPlaceholder" class="flex-row is-align-items-center full-width">
        <span class="remove-selection-button" (click)="onReset()">
          <mat-icon svgIcon="cross" class="remove-selection-icon"></mat-icon>
        </span>
        <span class="full-width has-text-left app-body dropdown-selected-item">{{ value.value | callback : displayFn }}</span>
      </div>
      <ng-template #showPlaceholder>
        <span class="full-width has-text-left app-body placeholder" *ngIf="placeholder">{{ placeholder }}</span>
      </ng-template>
      <span class="icon is-small">
        <mat-icon [svgIcon]="icon" class="selectable-icon"></mat-icon>
      </span>
    </button>
  </div>
  <div class="dropdown-menu full-width" id="dropdown-menu" role="menu">
    <div class="dropdown-content app-body">
      <div class="app-body" *ngFor="let option of options; index as index">
        <div class="app-body-small-medium-weight-const grey-color dropdown-category">{{ option.name }}</div>
        <div
          class="dropdown-item cursor-pointer app-body"
          [ngClass]="{ 'is-active': value && (item | callback: compareFn : value.value) && index === value.groupIndex }"
          *ngFor="let item of option.options"
          (click)="onSelectItem(index, item)"
        >
          {{ item | callback : displayFn }}
        </div>
        <hr class="navbar-divider" />
      </div>
    </div>
  </div>
</div>
