<div class="flex-column start-product-tour-modal">
  <img class="margin-bottom-m" src="assets/images/product-tour/science-discovery.svg" />
  <p>Your dashboard is all set and ready for you to use.</p>
  <p
    >Take a quick tour of the platform to get your up to speed and make sure you're taking full advantage of all its
    features.</p
  >

  <div class="flex justify-content-space-between margin-top-l">
    <button type="cancel" (click)="close()" class="button cancel-button"
      ><div class="start-product-tour-button">Not now</div></button
    >
    <button type="primary" (click)="startTour()" class="button primary-button">
      <div class="start-product-tour-button">Let's go!</div>
    </button>
  </div>
</div>
