import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ButtonService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoading.asObservable();

  constructor(private router: Router) {
    // Listen for route changes and reset loading state
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      // Set loading to false when navigation ends
      this.setLoading(false);
    });
  }

  public setLoading(loading: boolean): void {
    this._isLoading.next(loading);
  }
}
