import { Injectable } from '@angular/core';
import { UserService } from '@app/shared/services/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyIdService {
  constructor(private userService: UserService) {}

  public get$(): Observable<number> {
    return this.userService.currentCompanyId$;
  }
}
