import { Pipe, PipeTransform } from '@angular/core';
import { getPercentageFromAmount } from '@app/shared/utils/helpers/stakeholder.helpers';
import { NumberFormatPipe } from '@app/shared/pipes/number-format.pipe';

@Pipe({
  name: 'percentage',
})
export class PercentageCalculationPipe implements PipeTransform {
  constructor(private numberFormatPipe: NumberFormatPipe) {}

  transform(value: number, total: number, format?: string): string {
    const percentage = getPercentageFromAmount(value, total);

    return `${this.numberFormatPipe.transform(percentage, format)}`;
  }
}
