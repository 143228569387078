import { DatePickerType } from '@app/forms/types';
import { FIRST_DAY_IN_MONTH_NUMBER, UTC_TIMEZONE } from '@app/shared/utils/constants/date.constants';
import { zonedTimeToUtc } from 'date-fns-tz';
import * as dateFns from 'date-fns';

export const isSameDay = dateFns.isSameDay;
export const isBefore = dateFns.isBefore;
export const isAfter = dateFns.isAfter;
export const addDays = dateFns.addDays;
export const isEqual = dateFns.isEqual;
export const isValid = dateFns.isValid;
export const minDate = dateFns.min;

export const formatDateStringToTimestamp = (date: string | Date): number => new Date(date).getTime();

export function addDaysToDate(date: Date, daysNumber: number): Date {
  const newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + daysNumber);
  return newDate;
}

export function toUtcDate(date: string | number | Date): Date {
  return zonedTimeToUtc(date, UTC_TIMEZONE);
}

export function getNextDay(date: Date): Date {
  return dateFns.addDays(date, 1);
}

export const subtractDaysFromDate = (date: Date, daysNumber: number): Date => addDaysToDate(date, -daysNumber);

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function isDateInTimePeriod({
  date,
  startDate,
  endDate,
}: {
  date: Date;
  startDate: Date;
  endDate: Date;
}): boolean {
  return startDate <= date && date <= endDate;
}

export function getNextMonthDate(date = new Date()): Date {
  const nextMonth = new Date(date);
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  return nextMonth;
}

export function now(): Date {
  return new Date();
}

export const convertStringToLocalDate = (stringified?: string | number | Date): Date => {
  if (stringified) {
    const dateInPseudoUtcTimeZone = new Date(stringified),
      pseudoUtcYear = dateInPseudoUtcTimeZone.getUTCFullYear(),
      pseudoUtcMonth = dateInPseudoUtcTimeZone.getUTCMonth(),
      pseudoUtcDay = dateInPseudoUtcTimeZone.getUTCDate();
    return new Date(pseudoUtcYear, pseudoUtcMonth, pseudoUtcDay);
  }
};

export const formatDateWithOffset = (date: Date, dateFormatType = DatePickerType.date): Date => {
  if (!date) {
    return null;
  }

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = dateFormatType === DatePickerType.date ? date.getDate() : FIRST_DAY_IN_MONTH_NUMBER;

  return new Date(Date.UTC(year, month, day, 0));
};

export function isDateFitDayRange(date: Date, { startDate, endDate }: { startDate: Date; endDate: Date }): boolean {
  return isAfter(date, startDate) && isBefore(date, endDate);
}

export const isAfterOrEqual = (date: Date, dateAfter: Date): boolean =>
  isAfter(date, dateAfter) || isEqual(date, dateAfter);

export const isBeforeOrEqual = (date: Date, dateBefore: Date): boolean =>
  isBefore(date, dateBefore) || isEqual(date, dateBefore);

export const getYearRange = (pastYearsAmount: number, nextYearsAmount: number): number[] => {
  const yearAmount = pastYearsAmount + nextYearsAmount + 1;
  const maxYear = getCurrentYear() + nextYearsAmount;

  return Array.from({ length: yearAmount }, (_, index) => maxYear - index);
};

export const convertStringToDateIfValid = (stringified?: string | number | Date): Date => {
  return isValid(stringified) ? new Date(stringified) : null;
};
