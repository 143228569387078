<div class="dnd-area" [ngClass]="{ 'full-height': isFullHeight }" wevestrDnd (fileDropped)="handleFilesUpload($event)">
  <input
    id="file_upload"
    type="file"
    multiple
    #file
    [accept]="allowedFileExtensions"
    (change)="handleFilesUpload($event.target.files)"
  />
  <div class="input-overlay" [ngClass]="{ 'disabled-dnd': disabled }" (click)="handleOverlayClick($event)">
    <mat-icon [svgIcon]="svgIcon" class="document-image"></mat-icon>
    <div class="description">
      <span>Drop your {{ filesLabel }} here,</span>
      <br />
      <span>
        or
        <a class="cursor-pointer" [ngClass]="{ 'disabled-link': disabled }" (click)="handleLinkClick()">click here</a>
        to select
      </span>
    </div>
  </div>
</div>
