import { Injectable } from '@angular/core';

import { ConfigService } from '@app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsService {
  constructor(private configService: ConfigService) {}

  private get url(): string {
    return `${this.configService.getFullBaseUrl()}/docs`;
  }

  public get privacyPolicy(): string {
    return `${this.url}/privacy-policy`;
  }

  public get cookiePolicy(): string {
    return `${this.url}/cookie-policy`;
  }

  public get termsAndConditions(): string {
    return `${this.url}/terms-and-conditions`;
  }
}
