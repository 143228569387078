import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { IBasicItem } from '@app/shared/types/interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { compareById } from '@app/shared/utils/helpers/compare.helpers';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleComponent),
      multi: true,
    },
  ],
})
export class ButtonToggleComponent implements OnInit, ControlValueAccessor {
  @Input() values: IBasicItem[];
  @Input() compareFn = compareById;
  @Input('value') set _value(value: IBasicItem) {
    this.value = value;
    this.selectedValue = value;
  }
  @Input() valueFn = (value: unknown): unknown => value;
  @Input() public disabled = false;
  @Input() public tooltipText: string;

  @Output() select = new EventEmitter();

  selectedValue: IBasicItem;
  propagateChange: (arg1: unknown) => void;
  value: IBasicItem;

  constructor() {}

  ngOnInit(): void {
    this.selectedValue = this.value;
  }

  public handleSelectOption(option: IBasicItem): void {
    const selectedValue = this.valueFn(option);
    this.selectedValue = option;
    if (this.propagateChange) {
      this.propagateChange(selectedValue);
    }
    this.select.emit(selectedValue);
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  writeValue(obj: IBasicItem): void {
    if (obj !== void 0) {
      if (typeof obj === 'object') {
        this.selectedValue = obj as IBasicItem;
      } else {
        this.selectedValue = this.values.find((value) => this.valueFn(value) === obj);
      }
    }
  }
}
