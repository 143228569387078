<div class="input-container flex-row is-align-items-center input-container_attached-inputs">
  <wevestr-input
    class="wevestr-input-with-addon app-numbers-font"
    [formControl]="inputControl"
    [addonInput]="selectionControl"
    [label]="label"
    [placeholder]="inputPlaceholder"
    [mask]="mask"
    [type]="type"
    [dataId]="inputDataId"
    [isRequired]="isRequired"
    [tooltipText]="tooltipText"
  >
    <div addon class="addon addon_left">
      <ng-content select="[addon]"></ng-content>
    </div>
    <div addon class="addon addon_right addon_with-select">
      <app-select
        class="modal-select"
        [placeholder]="selectPlaceholder"
        [options]="selectOptions"
        [formControl]="selectionControl"
        [displayFn]="displayFn"
        [removeSelection]="true"
        (reset)="reset.emit()"
        [attr.data-id]="selectDataId"
        [menuDataId]="selectMenuDataId"
      ></app-select>
    </div>
    <ng-container errors>
      <div class="error-message app-body-small" wvFormError="required">
        {{ customRequiredError ?? ERRORS.REQUIRED }}
      </div>
      <ng-content select="[additionalErrors]"></ng-content>
    </ng-container>
  </wevestr-input>
</div>
