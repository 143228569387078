import { Injectable } from '@angular/core';
import { CrudService } from '@app/shared/services/crud.service';
import { IShareType } from '@app/shareholder-management/types/interfaces/share-type.interface';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable({
  providedIn: 'root',
})
export class ShareTypeService extends CrudService<IShareType> {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService, false);
  }

  get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/cap-table/shares-types`;
  }
}
