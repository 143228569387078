import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ApexAxisChartSeries, ChartComponent, ApexLegend } from 'ng-apexcharts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IColumnChartData } from '@app/shared/types/interfaces/column-chart-data.interface';
import { IAxisChartOptions, IChart } from '@app/shared/types/interfaces';
import { GREY_COLOR, LIGHT_GREY_COLOR } from '@app/shared/utils/constants/colors.constants';
import { IColumnChartOptions } from '@app/shared/types/interfaces/column-chart-options.interface';
import {
  CHART_FONT_FAMILY,
  MAX_METRICS_SELECTED,
  LABEL_TEXT_FONT_SIZE,
  LEGEND_MARKER_SIZE,
  XAXIS_TEXT_FONT_SIZE,
  LABEL_FONT_FAMILY,
  CHART_TEXT_FONT_COLOR,
  AXIS_FONT_SIZE,
} from '@app/shared/utils/constants/chart.constants';
import { ILegendOption } from '@app/shared/types/interfaces/legend-option.interface';
import { ChartLegendService } from '@app/shared/services/chart-legend.service';
import { areOptionsTheSame, getChartColorByIndex } from '@app/shared/utils/helpers/chart.helpers';
import { Frequency } from '@app/shared/types/enums';
import { NumberFormatPipe } from '@app/shared/pipes';
import { MILLION_ABBREV, MILLION_NUMBER } from '@app/settings/constants/settings.constants';
import { mergeDeepRight } from 'ramda';
import { ANY } from '@app/shared/types/any';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() private options: IColumnChartOptions = {};
  @Input() private values: IChart[];
  @Input() private labels: string[];
  @Input() public isGroupedBySeries = false;
  @Input() private useLegendWithSelect = false;
  @Input() public isEmptyState = false;
  @Input() public viewType: Frequency;

  public chartOptions: Partial<IAxisChartOptions>;

  private tooltipSeries: number[][];
  private tooltipValues: IChart[];
  public categories: string[];
  public colors: string[];
  // TODO: figure out type of context, none is provided by library
  public chartContext: ANY;

  private legendOptions: ILegendOption[];
  public chartValues: IChart[];

  private unsubscribe$ = new Subject<void>();

  @ViewChild(ChartComponent) public chart: ChartComponent;

  constructor(private chartLegendService: ChartLegendService, private numberFormatPipe: NumberFormatPipe) {}

  public ngOnInit(): void {
    if (this.useLegendWithSelect) {
      // TODO refactor to perform all neccessary updates on legendOptions update
      this.chartLegendService.selectedOption$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((option: ILegendOption) => this.selectOption(option));

      this.chartLegendService.legendOptions$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((options: ILegendOption[]) => this.updateOptions(options));
    }
  }

  // TODO: refactor this to reduce complexity
  // eslint-disable-next-line complexity
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.values && this.labels && (!this.chartOptions || changes.labels)) {
      this.initColors();

      if (this.useLegendWithSelect) {
        this.initChartsAndLegend();
      }

      this.initChart();
      this.initTooltipSeries();
    } else if (this.values && changes.values) {
      this.initColors();
      this.initTooltipSeries();

      if (this.useLegendWithSelect) {
        this.initLegendOptions();
      }

      this.chart.updateSeries(this.values);
    }
  }

  private initChartsAndLegend(): void {
    this.chartValues = this.values.filter((value) => value.visible).slice(0, MAX_METRICS_SELECTED);
    this.initLegendOptions();
  }

  private getChartLegendConfiguration(): ApexLegend {
    const commonConfiguration = {
      fontSize: LABEL_TEXT_FONT_SIZE,
      fontFamily: LABEL_FONT_FAMILY,
      labels: {
        colors: CHART_TEXT_FONT_COLOR,
      },
      markers: {
        height: LEGEND_MARKER_SIZE,
        width: LEGEND_MARKER_SIZE,
        radius: 0,
      },
      itemMargin: {
        horizontal: 8,
      },
    };

    return this.useLegendWithSelect
      ? {
          ...commonConfiguration,
          position: 'bottom',
          horizontalAlign: 'center',
          showForSingleSeries: true,
          showForNullSeries: false,
          onItemClick: {
            toggleDataSeries: false,
          },
        }
      : {
          ...commonConfiguration,
          position: 'top',
          horizontalAlign: 'right',
        };
  }

  private get seriesAndCategories() {
    const values = this.useLegendWithSelect ? this.chartValues : this.values;
    let categories = this.labels;
    let series: ApexAxisChartSeries = values;
    if (this.isGroupedBySeries) {
      categories = values.map((value) => value.name);
      series = [
        this.values.reduce(
          (result, data: IColumnChartData, index: number) => {
            const _values = data.data.map((value) => ({
              x: categories[index],
              y: value,
              fillColor: this.colors[index],
            }));

            result.data.push(..._values);

            return result;
          },
          { data: [] },
        ),
      ];
    }
    return { series, categories };
  }

  private axisNumberFormatter(value: number): string {
    let formattedValue: string;
    if (value >= MILLION_NUMBER || value <= -MILLION_NUMBER) {
      formattedValue = `${this.numberFormatPipe.transform(value / MILLION_NUMBER)}${MILLION_ABBREV}`;
    } else {
      formattedValue = `${this.numberFormatPipe.transform(value)}`;
    }
    return formattedValue;
  }

  private initColors(): void {
    this.colors = this.values.map((_, index: number) => getChartColorByIndex(index));
  }

  private initTooltipSeries(): void {
    if (this.chartValues) {
      this.tooltipSeries = this.chartValues.map((value) => value.data);
      this.tooltipValues = this.chartValues;
    } else {
      this.tooltipSeries = this.values.map((value) => value.data);
      this.tooltipValues = this.values;
    }
  }

  private getTooltip(
    tooltipValues = this.tooltipValues,
    series: number[][],
    seriesIndex: number,
    dataPointIndex: number,
  ) {
    if (!this.options.tooltip?.custom) {
      return;
    }
    const tooltipFn = this.options.tooltip.custom as (...args: unknown[]) => string;
    if (this.isGroupedBySeries) {
      const _seriesindex = Math.floor(dataPointIndex / this.labels.length);
      const _dataPointIndex = dataPointIndex % this.labels.length;
      const data = {
        tooltipValues: this.tooltipValues,
        series: this.tooltipSeries,
        seriesIndex: _seriesindex,
        dataPointIndex: _dataPointIndex,
      };
      return tooltipFn(data);
    } else {
      return tooltipFn({ tooltipValues, series, seriesIndex, dataPointIndex });
    }
  }

  //eslint-disable-next-line max-lines-per-function
  private initChart(): void {
    const { series, categories } = this.seriesAndCategories;
    this.categories = categories;
    this.chartOptions = mergeDeepRight(
      {
        series,
        colors: this.colors,
        chart: {
          type: 'bar',
          height: this.options.height,
          toolbar: {
            show: false,
          },
          fontFamily: CHART_FONT_FAMILY,
          foreColor: GREY_COLOR,
          redrawOnParentResize: true,
          events: {
            mounted: (chartContext) => {
              this.chartContext = chartContext;
              this.hideSeries();
            },
            // TODO: figure out type of context, none is provided by library
            mouseMove(_, chartContext: ANY): void {
              const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');

              if (tooltip && parseFloat(tooltip.style.top) < 0) {
                tooltip.style.top = 0;
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          decimalsInFloat: 2,
          labels: {
            style: {
              fontSize: AXIS_FONT_SIZE,
              cssClass: 'apex-dark-blue-color',
            },

            // TODO: find way to limit this callback calls
            formatter: (value: number) => this.axisNumberFormatter(value),
          },
        },
        xaxis: {
          categories,
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              fontSize: XAXIS_TEXT_FONT_SIZE,
              cssClass: 'apex-dark-blue-color',
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: this.getChartLegendConfiguration(),
        grid: {
          borderColor: LIGHT_GREY_COLOR,
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
      this.options ?? {},
    ) as Partial<IAxisChartOptions>;

    if (this.options.tooltip) {
      this.chartOptions.tooltip = {
        ...this.options.tooltip,
        custom: ({ tooltipValues, series, seriesIndex, dataPointIndex }) =>
          this.getTooltip(tooltipValues, series, seriesIndex, dataPointIndex),
      };
    }
  }

  private hideSeries(): void {
    this.chartValues.filter((value) => value.visible === false).forEach((value) => this.chart.hideSeries(value.name));
  }

  private initLegendOptions(): void {
    this.legendOptions = this.values.map((value, index: number) => ({
      id: value.id,
      color: getChartColorByIndex(index),
      name: value.name,
      isCategory: value.isCategory,
      isSelected: Boolean(this.chartValues.find((chartValue) => areOptionsTheSame(value, chartValue))),
      categoryId: value.categoryId,
    }));

    this.chartLegendService.setLegendOptions(this.legendOptions);
  }

  public selectOption(option: ILegendOption): void {
    this.legendOptions = this.legendOptions.map((legendOption) => {
      if (areOptionsTheSame(legendOption, option)) {
        return {
          ...legendOption,
          isSelected: !option.isSelected,
        };
      }

      return legendOption;
    });
    this.updateChart();
    this.chartLegendService.setLegendOptions(this.legendOptions);
  }

  public updateOptions(options: ILegendOption[]): void {
    this.legendOptions = options;
    if (options.length) {
      this.updateChart();
    }
  }

  private updateChart(): void {
    this.chartValues = this.values.filter((metric) => {
      const legendOption = this.legendOptions.find((legendOption) => areOptionsTheSame(legendOption, metric));

      return metric.visible && legendOption?.isSelected;
    });
    this.chart?.updateSeries(this.chartValues);
    this.initTooltipSeries();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.useLegendWithSelect) {
      this.chartLegendService.cleanupLegend();
    }
  }
}
