<section class="verify-container flex-column is-align-items-center">
  <h2 class="app-h4-bold text-align-center margin-bottom-s verify-container__title">{{ verifyCopyright.title }}</h2>
  <h5 *ngIf="verifyCopyright.message" class="app-body text-align-center margin-bottom-l verify-container__description">
    {{ verifyCopyright.message }}
  </h5>
  <div *ngIf="verifyCopyright.messages" class="app-body margin-bottom-l verify-container__description">
    <p *ngFor="let message of verifyCopyright.messages" class="text-align-center">
      {{ message }}
    </p>
  </div>

  <div *ngIf="verifyCopyright.isVerificationNeeded" class="margin-top-s margin-bottom-l flex">
    <div class="integration margin-right-m" (click)="openOutlook()">
      <mat-icon class="mail-icon margin-right-s" svgIcon="outlook"></mat-icon>
      <span class="app-body">Open Outlook</span>
    </div>
    <div class="integration" (click)="openGmail()">
      <mat-icon class="mail-icon margin-right-s" svgIcon="gmail"></mat-icon>
      <span class="app-body">Open Gmail</span>
    </div>
  </div>
  <p class="app-body text-align-center">
    Wrong email? Please
    <a class="anchor" [routerLink]="verifyCopyright.forgotCredentialsLink">re-enter your address.</a>
  </p>
  <p class="app-body text-align-center margin-top-m no-email" *ngIf="verifyCopyright.isVerificationNeeded">
    If you haven't received the verification email, please check your spam or junk folder. For further assistance, reach
    us at
    <a class="anchor" href="mailto:{{SUPPORT_EMAIL_ADDRESS}}" target="_blank">{{SUPPORT_EMAIL_ADDRESS}}</a>
    or open the <a class="anchor">chat</a> window.
  </p>
  <div *ngIf="!verifyCopyright.isVerificationNeeded" class="buttons-container margin-top-l">
    <button class="button primary-button app-body" [routerLink]="verifyCopyright.loginLink">Login</button>
  </div>
</section>
