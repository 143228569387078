export const IMPORT_MODAL_MAX_WIDTH = '600px';
export const ALLOWED_AUDIT_IMPORT_TYPES = ['.xml', '.xaf'];
export const MAX_FILES_COUNT = 3;
export const MAX_FILES_COUNT_ERROR = `Maximum ${MAX_FILES_COUNT} files can be selected`;
export const MAX_TOTAL_FILES_SIZE_MB = 100;
export const FETCH_DETAILS_AFTED_DELAY_MSEC = 3000;

export const IMPORT_TABLE_ROW_HEIGHT = '56px';
export const IMPORT_TABLE_HEADER_ROW_HEIGHT = '48px';
export const IMPORT_AUDIT_FILES_DND_FILE_LABEL = 'Audit File';
export const IMPORT_AUDIT_FILES_TABLE_COLUMNS = {
  NAME: {
    field: 'name',
    headerName: 'Audit File Name',
  },
  REPLACE: {
    field: 'replace',
    headerName: 'Replace Data',
  },
  UPDATE: {
    field: 'update',
    headerName: 'Update Data',
  },
};
