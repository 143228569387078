import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpLoadingService } from '@app/shared/services/http-loading.service';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  constructor(private httpLoadingService: HttpLoadingService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // Set loading to true before the request
    this.httpLoadingService.setLoading(true);

    return next.handle(req).pipe(
      finalize(() => {
        // Set loading to false once the request completes
        this.httpLoadingService.setLoading(false);
      }),
    );
  }
}
