import { UserRole } from '@app/shared/types/classes';
import { IProfile } from '@app/types/interfaces/profile.interface';

export function isViewOnlyRole(role: number): boolean {
  return role === UserRole.SHAREHOLDER;
}

export const isSuperAdminRole = (role: number): boolean => {
  return role === UserRole.SUPERADMIN;
};

export function isSuperAdminUser({ role }: Partial<IProfile>): boolean {
  return isSuperAdminRole(role?.role);
}
