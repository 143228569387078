import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PermissionsService } from '@app/shared/services/permissions.service';
import { COMPANY_SETTINGS_TABS_ACCESS_CONFIGURATIONS } from '@app/settings/constants/access-configurations.constants';
import { SettingsRouteNames } from '@app/settings/types/enums';
import { IPermissionsMap } from '@app/shared/types/interfaces';
import { PERMISSION_CONFIGURATIONS_FOR_SECTION } from '@app/shared/utils/constants/permission.constants';
import { Section } from '@app/shared/types/enums/section.enum';

// TODO: inject service in needed modules only(settings, home)
@Injectable({
  providedIn: 'root',
})
export class SettingsAccessService {
  constructor(private permissionsService: PermissionsService) {}

  public hasAccessToCompanySettings$(): Observable<boolean> {
    return this.permissionsService.isUserFitAnyAccessConfiguration$(
      PERMISSION_CONFIGURATIONS_FOR_SECTION[Section.companySettings],
    );
  }

  public canEditCompanySettingsTab$(tabName: SettingsRouteNames): Observable<boolean> {
    return this.permissionsService.isUserFitAnyAccessConfiguration$(
      COMPANY_SETTINGS_TABS_ACCESS_CONFIGURATIONS[tabName].canEdit,
    );
  }

  public canViewCompanySettingsTab(tabName: SettingsRouteNames, permissionMap: IPermissionsMap): boolean {
    return this.permissionsService.isUserPermissionsFitAnyAccessConfiguration(
      permissionMap,
      COMPANY_SETTINGS_TABS_ACCESS_CONFIGURATIONS[tabName].canRead,
    );
  }

  public canEditPersonalSettingsTab$(): Observable<boolean> {
    return of(true);
  }
}
