import { ESOPVestingDistribution } from '@app/esop/plan-management/types/distribution-type.enum';
import { Frequency } from '@app/shared/types/enums';
import { VestingDirectionsType } from '@app/esop/plan-management/types/vesting-direction.interface';
import { TypeToFormControls } from '@app/forms/types';
import { scheduleOptions } from '@app/esop/plan-management/components/linear-vesting-form/linear-vesting-form.constants';
import { distributionOptions } from '@app/esop/plan-management/constants';

export interface ILinearVestingFormValues {
  vestingDirection?: VestingDirectionsType;
  vestingFrequency: Frequency;
  numberOfVestings: number;
  firstVestingDate: Date;
  distribution: ESOPVestingDistribution;
  cliffPeriodType?: string;
  cliffPeriod?: number;
}

export type LinearVestingFormData = {
  vestingFrequency: typeof scheduleOptions[number] | null;
  numberOfEvents: number | null;
  vestingDate: Date | null;
  distribution: typeof distributionOptions[number] | null;
  cliffPeriodType: string;
  cliffPeriod: number;
};
export type LinearVestingForm = TypeToFormControls<LinearVestingFormData>;
