<wevestr-dropdown [placeholder]="placeholder" menuPosition="left">
  <ng-container *ngIf="displayTypes?.length">
    <p class="margin-bottom-m app-body-small-bold">{{ placeholder }}</p>
    <wevestr-radio-button-group
      [options]="displayTypes"
      size="small"
      [ngModel]="selectedDisplayType"
      (ngModelChange)="onDisplayTypeChanged($event)"
    ></wevestr-radio-button-group>
  </ng-container>

  <div
    *ngIf="displayTypes?.length && displayedMetrics?.length"
    class="chart-selector-separator margin-top-s margin-bottom-s"
  ></div>

  <div *ngFor="let metric of displayedMetrics" class="margin-bottom-xs">
    <ng-container
      *ngIf="metric.isCategory || !metric.categoryId"
      [ngTemplateOutlet]="metricTemplate"
      [ngTemplateOutletContext]="{metric}"
    ></ng-container>
  </div>
</wevestr-dropdown>

<ng-template #metricTemplate let-metric="metric" let-parentCategory="parentCategory">
  <div
    [class.chart-selector-category-field]="metric.isCategory"
    [class.expanded]="metric.isExpanded"
    class="chart-selector-checkbox-wrapper"
    *wvLet="
      !metric.isVisibleInChart &&
      !canSelectMetric &&
      !metric.isAnyNestedMetricVisibleInChart &&
      !metric.isParentCategoryVisibleInChart as isSelectionDisabled
    "
  >
    <wevestr-checkbox
      id="chart-selector-metric-{{ metric.id }}"
      [checked]="metric.isVisibleInChart"
      type="colored"
      [color]="metric.color"
      (click)="toggleMetricVisibility(metric, parentCategory)"
      [disabled]="isSelectionDisabled"
      [matTooltip]="disabledMetricTooltip"
      [matTooltipDisabled]="!isSelectionDisabled"
      matTooltipPosition="left"
      class="cursor-pointer"
    >
      <span
        *ngIf="metric.isCategory"
        role="button"
        class="chart-selector-category-toggle"
        (click)="toggleIsExpanded(metric)"
      >
        <mat-icon class="chart-selector-category-chevron" svgIcon="chevron-right"></mat-icon>
        {{ metric.name }}
      </span>

      <span class="padding-left-s cursor-pointer" *ngIf="!metric.isCategory">
        {{ metric.name }}
      </span>
    </wevestr-checkbox>

    <ng-container
      *ngIf="metric.isCategory"
      [ngTemplateOutlet]="categoryContentTemplate"
      [ngTemplateOutletContext]="{category: metric}"
    ></ng-container>
  </div>
</ng-template>

<ng-template #categoryContentTemplate let-category="category">
  <div class="chart-selector-category-content padding-top-xs">
    <div *ngFor="let nestedMetric of category.nestedMetrics" class="margin-bottom-xs">
      <ng-container
        [ngTemplateOutlet]="metricTemplate"
        [ngTemplateOutletContext]="{metric: nestedMetric, parentCategory: category}"
      ></ng-container>
    </div>
  </div>
</ng-template>
