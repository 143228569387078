import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditModeService {
  private _editMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  get editMode$(): Observable<boolean> {
    return this._editMode$.asObservable();
  }

  get editMode(): boolean {
    return this._editMode$.value;
  }

  setEditMode(value: boolean): void {
    this._editMode$.next(value);
  }
}
