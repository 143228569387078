import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KpiViewStateService {
  private _isKpiEmptyState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  isKpiEmptyState$(): Observable<boolean> {
    return this._isKpiEmptyState$.asObservable();
  }

  setIsKpiEmptyState(value: boolean): void {
    this._isKpiEmptyState$.next(value);
  }
}
