import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { CellValueDirective } from '@app/shared/directives';
import { ITableColumn } from '@app/shared/types/interfaces/table-columns.interface';
import { ITableAction } from '@app/shared/types/interfaces/table-action.interface';
import { IRowAction } from '@app/types/interfaces/row-action.interface';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CellValueContext } from '@app/shared/types/classes/cell-value-context.class';
import { TableBaseRow } from '@app/shared/types/classes/table-row-base.component.class';
import { isEmpty } from 'ramda';
import { ANY } from '@app/shared/types/any';
import { ITableHoverEvent } from '@app/shared/types/interfaces';

@Component({
  selector: 'app-table-element-row',
  templateUrl: './table-element-row.component.html',
  styleUrls: ['./table-element-row.component.scss'],
})
export class TableElementRowComponent extends TableBaseRow implements OnInit, OnChanges {
  @Input() columnWidths: number[];
  @Input() childValue: Record<string, ANY>;
  @Input() childIndex: number;
  @Input() defaultAddValues: Record<string, ANY> = {};
  @Input() roundedTopCorners = false;
  @Input() roundedBottomCorners = false;
  @Input() validations: Record<string, ValidatorFn[]> = {};
  @Input() errorMessages: Record<string, string> = {};
  @Input() requiredFields: string[];
  @Input() sendInstantNewRowUpdate = false;

  @Output() selectAction: EventEmitter<IRowAction> = new EventEmitter<IRowAction>();
  @Output() instantRowUpdateData: EventEmitter<Record<string, unknown>> = new EventEmitter();

  @ContentChild(CellValueDirective, { static: true, read: TemplateRef })
  cellValueTemplate: TemplateRef<CellValueContext>;

  @ViewChildren('cellWithRightAlignment') cellsWithRightAlignment: QueryList<ElementRef>;

  form: UntypedFormGroup;
  isHovered: boolean;
  classes: ANY;

  @HostListener('keyup.enter', ['$event'])
  submitForm(): void {
    if (this.form && this.form.valid) {
      this.handleSaveRowData();
    }
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    if (this.editMode && this.editRowIndex === this.index) {
      this.handleEnterRowEditMode();
    }

    this.initClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.rowValue && (changes.rowValue || changes.editRowIndex)) {
      this.initForm();
    }
  }

  isFormEmpty(): boolean {
    return !Object.values(this.form.value).reduce((rowHasData, value) => value || rowHasData, false);
  }

  isFieldEmpty(fieldName: string): boolean {
    return !this.form.value[fieldName]?.trim();
  }

  areFieldsNotEmpty(fieldNames: string[]): boolean {
    return fieldNames.every((fieldName) => !this.isFieldEmpty(fieldName));
  }

  public onHover(event: ITableHoverEvent): void {
    if (this.sendInstantNewRowUpdate && this.isMouseLeave(event)) {
      this.instantRowUpdateData.emit(this.getFormattedFormValues());
    }

    this.handleHover(event);
  }

  private isMouseLeave(event: ITableHoverEvent): boolean {
    return !event;
  }

  private getFormattedFormValues(): Record<string, string | number> {
    let formattedValues = this.form.value;

    if (this.options.formFormatter) {
      formattedValues = this.options.formFormatter.formatter(this.form);
    }

    return formattedValues;
  }

  public isRowValid = (isFormValid: boolean, requiredFields: string[]): boolean => {
    let isValid: boolean;

    if (!isFormValid) {
      isValid = false;
    } else if (requiredFields && !isEmpty(requiredFields)) {
      isValid = this.areFieldsNotEmpty(requiredFields);
    } else {
      isValid = !this.isFormEmpty();
    }

    return isValid;
  };

  // TODO: refactor this to reduce complexity
  // eslint-disable-next-line complexity
  public getTdStyle = (
    column?: ITableColumn,
    index?: number,
    last?: boolean,
    rowValue?: ANY,
  ): Record<string, string | number> => {
    let style: Record<string, string | number> = {};
    const paddingRight = last && this.options.tablePaddingRight ? this.options.tablePaddingRight : null;
    const rowStyle = this.options.getRowStyle ? this.options.getRowStyle({ index: this.index }) : {};
    let paddingLeft = null;

    if (index === 0 && this.options.tablePaddingLeft) {
      paddingLeft = this.options.tablePaddingLeft;
    }

    if (last && this.options.lastColumnPaddingLeft) {
      paddingLeft = this.options.lastColumnPaddingLeft;
    }

    if (rowValue) {
      style = { ...style, 'border-bottom': rowStyle['border-bottom'] };

      if (
        index === 0 &&
        this.options.isCategorized &&
        rowValue.isCategorized &&
        this.options.categorizedElementPaddingLeft
      ) {
        paddingLeft = this.options.categorizedElementPaddingLeft;
      }
    }

    if (column) {
      const valueStyle = column.valuesStyle || {};

      style = {
        ...style,
        'text-align': column.textAlign || null,
        ...valueStyle,
      };
    }

    return {
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      ...style,
    };
  };

  handleAction({
    row,
    rowIndex,
    action,
    childIndex,
  }: {
    row: Record<string, ANY>;
    rowIndex: number;
    action: ITableAction;
    childIndex: number;
  }): void {
    this.selectAction.emit({ row, rowIndex, action, childIndex });
  }

  handleEnterRowEditMode(): void {
    this.form.enable();
    this.enterEditMode.emit();
  }

  handleSaveRowData(): void {
    const formattedValues = this.getFormattedFormValues();
    if (this.isRowValid(this.form.valid, this.requiredFields)) {
      this.saveRowData.emit(formattedValues);
    }
  }

  public isValueEditable = (column: ITableColumn, isEditMode: boolean, editRowIndex: number): boolean => {
    return isEditMode && editRowIndex === this.index && this.isColumnValueEditable(column);
  };

  public getErrorMessage = (controlErrors: ValidationErrors | null): string => {
    if (!controlErrors) {
      return;
    }
    const controlErrorKeys = Object.keys(controlErrors);
    const errorMessageKeys = Object.keys(this.errorMessages);
    const errorKey = errorMessageKeys.find((key: string) => controlErrorKeys.includes(key));
    if (errorKey) {
      return this.errorMessages[errorKey];
    }
  };

  private isColumnValueEditable(column: ITableColumn): boolean {
    return (
      column.editable === void 0 ||
      column.editable === null ||
      (typeof column.editable === 'function' && column.editable(this.rowValue)) ||
      (typeof column.editable === 'boolean' && column.editable)
    );
  }

  private initForm(): void {
    this.form = this.formBuilder.group({});
    const isNewRow = Object.keys(this.rowValue).length === 0;
    this.columns.forEach((column) => {
      const value = this.getInitialFormControlValue(column, isNewRow);
      this.form.addControl(
        column.field,
        this.formBuilder.control(
          { value, disabled: this.editRowIndex !== this.index },
          this.validations[column.field] ? this.validations[column.field] : null,
        ),
      );
    });
  }

  private getInitialFormControlValue(column: ITableColumn, isNewRow: boolean): void {
    let value;
    if (isNewRow) {
      value = this.defaultAddValues[column.field] || '';
    } else if (column.dataType === 'financials') {
      value = this.rowValue[column.field];
    } else {
      value = column.renderer ? column.renderer(this.rowValue, this.childValue) : this.rowValue[column.field];
    }
    return value;
  }

  private initClasses(): void {
    const classes = { 'table__odd-tr': this.index % 2 === 1, 'table__even-tr': this.index % 2 === 0 } as ANY;

    if (this.options.isCategorized && !this.rowValue.isCategory) {
      classes['table-row--categorized'] = true;
    }

    this.classes = classes;
  }
}
