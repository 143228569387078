<div class="auth-cover flex">
  <div class="sidebar flex-column">
    <div class="circles-img"></div>
    <p class="app-h4-bold about-text">{{ABOUT_WEVESTR}}</p>
    <p class="app-body-small-const quote">{{QUOTE}}</p>
    <div class="cap-table-img"></div>
  </div>
  <div class="main-content">
    <ng-content></ng-content>
  </div>
</div>
