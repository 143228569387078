import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { IBarchartValue } from '@app/shared/types/interfaces';
import { IBarchartOptions } from '@app/shared/types/interfaces/barchart-options.interface';
import { BARCHART_TEXT_FONT_COLOR } from '@app/shared/utils/constants/chart.constants';
import { ANY } from '@app/shared/types/any';
import { DigitsConfigService } from '@app/shared/services/digits-config.service';

const TITLE_MARGIN_BOTTOM = 16;
const MARKER_HEIGHT = 22;
const TITLE_HEIGHT = 17;
const ANIMATION_DELAY_MSEC = 210;

interface ITitleTextPosition {
  left: string;
  right: string;
}

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarchartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  public static currentId = 0;
  public noDecimalMask: string;
  readonly id: number;
  @Input() options: IBarchartOptions = this._defaultOptions;
  @Input() isEmptyState = false;
  @Input('values') set _values(values: IBarchartValue[]) {
    this.activeSliceIndex = void 0;
    if (values) {
      this.initData(values);
    }
  }

  @Output() hoverIndex: EventEmitter<string | number> = new EventEmitter<string | number>();

  totalAmount: number;
  percentages: number[];
  values: IBarchartValue[];
  defaultOptions: IBarchartOptions;
  chartHeight: number;
  activeSliceIndex: number;
  activeValue: { value: IBarchartValue; percentage: number };
  markerStyles: ANY = {};
  titleStyles: ANY = {};
  emptySlices = false;
  isCurrency = false;
  sliceDetailsVisibility: boolean[];
  public titleTextStyle: ITitleTextPosition;
  private checkTitleTextPosition$ = new Subject<void>();
  private unsubscribe$ = new Subject<void>();

  @ViewChild('barChart', { read: ElementRef, static: true }) barChart: ElementRef;
  @ViewChild('titleText', { read: ElementRef }) titleText: ElementRef;
  @ViewChildren('slice', { read: ElementRef }) slices: QueryList<ElementRef>;

  constructor(digitsConfigService: DigitsConfigService, private changeDetectorRef: ChangeDetectorRef) {
    BarchartComponent.currentId += 1;
    this.id = BarchartComponent.currentId;
    this.noDecimalMask = digitsConfigService.ngWholeNumberFormat;
  }

  ngOnInit(): void {
    this.isCurrency = this.options.isCurrency;
    this.defaultOptions = this._defaultOptions;
    this.options = { ...this._defaultOptions, ...this.options };
    this.setChartHeight();
    this.checkTitleTextPosition$
      .pipe(debounceTime(ANIMATION_DELAY_MSEC), takeUntil(this.unsubscribe$))
      .subscribe(() => this.handleUpdateTitleTextPosition());
  }

  public ngAfterViewInit(): void {
    this.setInitialStyles();
  }

  private handleUpdateTitleTextPosition(): void {
    if (this.barChart && this.titleText) {
      const barchartRect = this.barChart.nativeElement.getBoundingClientRect();
      const titleTextRect = this.titleText.nativeElement.getBoundingClientRect();

      const isTitleTextOverlapRight = titleTextRect.right > barchartRect.right;
      const isTitleTextOverlapLeft = titleTextRect.left < barchartRect.left;
      const titleTextOffsetRight = titleTextRect.right - barchartRect.right;
      const titleTextOffsetLeft = barchartRect.left - titleTextRect.left;

      this.titleTextStyle = {
        left: isTitleTextOverlapLeft ? `${titleTextOffsetLeft}px` : 'unset',
        right: isTitleTextOverlapRight ? `${titleTextOffsetRight}px` : 'unset',
      };
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.options) {
      this.options = { ...this._defaultOptions, ...this.options };
    }
  }

  public getValueStyles = (value: IBarchartValue, index: number): Record<string, string> => {
    const barChartWidth: number = this.barChart.nativeElement.offsetWidth;
    const percentage = this.emptySlices ? 100 / this.values.length : this.percentages[index];
    const widthPercentage: number = (barChartWidth * percentage) / 100;

    return {
      'background-color': value.color,
      width: `${widthPercentage}px`,
      height: `${this.options.height}px`,
      color: BARCHART_TEXT_FONT_COLOR,
    };
  };

  // TODO refactor component to precalculate all values not in template
  public onSliceEntered(index: number, sliceElement: HTMLDivElement): void {
    const value: IBarchartValue = this.values[index];
    this.activeSliceIndex = index;
    this.activeValue = {
      value,
      percentage: this.percentages[index],
    };

    const rightPosition = this.barChart.nativeElement.offsetWidth - sliceElement.offsetWidth - sliceElement.offsetLeft;
    this.titleStyles = {
      left: `${sliceElement.offsetLeft}px`,
      right: `${rightPosition}px`,
    };
    this.markerStyles = {
      'border-right': `1px solid ${value.color}`,
      'border-left': `1px solid ${value.color}`,
      'border-top': `1px solid ${value.color}`,
    };
    this.hoverIndex.emit(value.barchartOptionId ?? value.id);
    this.checkTitleTextPosition$.next();
  }

  public onSliceLeft(): void {
    this.setInitialStyles();
    this.hoverIndex.emit(null);
  }

  private setInitialStyles(): void {
    this.titleTextStyle = {
      left: 'unset',
      right: 'unset',
    };
    const rightPosition =
      this.barChart.nativeElement.offsetWidth -
      this.slices.reduce((width, el) => (width += el.nativeElement.offsetWidth), 0);

    this.titleStyles = { left: 0, right: `${rightPosition}px` };
    this.markerStyles = {};
    this.activeSliceIndex = null;
  }

  onLegendEntered(index: number): void {
    const slice = document.getElementById(`chart-slice-${index}-${this.id}`) as HTMLDivElement;
    this.onSliceEntered(index, slice);
  }

  onLegendLeft(): void {
    this.onSliceLeft();
  }

  highlightSlice(index: number): void {
    if (index !== null && index !== void 0) {
      const sliceElement: ElementRef = this.slices.find((_, i) => i === index);
      if (sliceElement) {
        this.onSliceEntered(index, sliceElement.nativeElement);
      }
    } else {
      this.onSliceLeft();
    }
  }

  handleDivResize(): void {}

  private initData(values: IBarchartValue[]): void {
    this.values = values;
    this.totalAmount = this.values.reduce((sum: number, value: IBarchartValue) => {
      sum += value.amount;

      return sum;
    }, 0);

    this.emptySlices = this.totalAmount === 0;

    this.percentages = this.values.map((value: IBarchartValue) => (value.amount * 100) / this.totalAmount);

    this.setChartHeight();
  }

  private setChartHeight(): void {
    const titleMarkerMargin: number = this.options.titleMarkerMargin;
    const barHeight: number = this.options.height;

    this.chartHeight = MARKER_HEIGHT + TITLE_MARGIN_BOTTOM + TITLE_HEIGHT + titleMarkerMargin + barHeight;
  }

  private get _defaultOptions(): IBarchartOptions {
    return {
      height: 40,
      titleMarkerMargin: 32,
      titleLabel: 'Total amount',
      sliceLabel: 'Amount',
      noValuesText: 'There are no amounts',
      sliceTitleFontSize: 14,
      legended: false,
      legendCircleSize: 14,
      legendCircleMargin: 8,
      titleFormatter: (value) => value,
    };
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
