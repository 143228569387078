import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { AnalyticsJourney, AnalyticsSubJourney } from '@app/shared/types/enums/analytics-journey.enum';
import {
  AnalyticsCalculatedEventProperties,
  IAnalyticsEventProperties,
} from '@app/shared/types/interfaces/analytics-event-properties.interface';
import { ITab } from '@app/shared/types/interfaces/tab.interface';
import { CapTableWidgetTabs } from '@app/shareholder-management/containers/cap-table-widget/cap-table-widget.component';

const tabAnalyticsMap: Record<
  string,
  Omit<IAnalyticsEventProperties, AnalyticsCalculatedEventProperties | 'pageName'> & {
    eventName: AnalyticsEvent;
  }
> = {
  [CapTableWidgetTabs.Shareholders]: {
    eventName: AnalyticsEvent.CTViewShareholders,
    journey: AnalyticsJourney.CapTable,
    subJourney: AnalyticsSubJourney.ViewCaptable,
  },
  [CapTableWidgetTabs.Rounds]: {
    eventName: AnalyticsEvent.CTViewRounds,
    journey: AnalyticsJourney.CapTable,
    subJourney: AnalyticsSubJourney.ViewCaptable,
  },
};
/**
 * TODO: the fire dumpster incarnation, get rid of this, tabs should not rely on route nor force to use it.
 * P.S. Who thought this is going to be a good idea to use 3rd party styles for tabs? why bother and not just use material instead?
 */
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() public tabs: ITab[];
  @Input() public activeRoute: string;
  @Input() public queryParameter: string;
  @Input() public queryParameterValue: string;
  @Input() public isSmall: boolean;
  @Input() public fullHeight: boolean;
  @Input() public isFinancials: boolean;
  @Input() public suppressNavigation = false;
  @Output() public tabChange = new EventEmitter<ITab>();

  constructor(private analyticsService: AnalyticsService, private route: ActivatedRoute) {}

  public handleTabClick(tab: ITab): void {
    this.performAnalytics(tab);
    this.tabChange.emit(tab);
  }

  private performAnalytics(tab: ITab<string>): void {
    const mapping = tabAnalyticsMap[tab.name];
    if (mapping) {
      const { eventName, journey, subJourney } = mapping;
      this.analyticsService.track(eventName, {
        journey,
        subJourney,
        pageName: this.route.snapshot.data.pageName,
      });
    }
  }
}
