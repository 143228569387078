import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-failed',
  templateUrl: './verify-failed.component.html',
  styleUrls: ['./verify-failed.component.scss'],
})
export class VerifyFailedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
