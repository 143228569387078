<div class="full-width paginator flex-row is-align-items-center is-justify-content-flex-end padding-right-m">

  <div class="flex-row is-align-items-center" [class.padding-right-l]="_pagesNumber > 1">
    <div class="margin-right-s app-body-small">Show:</div>
    <app-select icon="angle_down"
                [selectedValue]="pageSize"
                [options]="pageSizeOptions"
                [compareFn]="compareFn"
                [displayFn]="displayFn"
                [isUnifiedHeightSet]="false"
                (select)="handlePageSizeChange($event)"
    >
    </app-select>
  </div>

  <ng-container *ngIf="_pagesNumber > 1">
    <div *ngIf="(_pagesNumber <= MAX_NUMBER_PAGES_DISPLAYED); else notAllPages" class="padding-right-l">
      <ng-template *ngFor="let page of pages" [ngTemplateOutlet]="pageTemplate"
                   [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
    </div>

    <ng-template #notAllPages>
      <div class="padding-right-l">
        <mat-icon svgIcon="chevron" class="arrow-icon chevron-left margin-right-s"
                  [class.arrow-icon_active]="selectedPage !== pages[0]"
                  [class.cursor-pointer]="selectedPage !== pages[0]" (click)="handlePreviousPage()"></mat-icon>

        <ng-container *ngIf="selectedPage < 5">
          <ng-template *ngFor="let page of pages | slice:0:selectedPage+1" [ngTemplateOutlet]="pageTemplate"
                       [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
          <span class="paginator__page margin-right-s">...</span>
          <ng-container *ngIf="selectedPage < 3">
            <ng-template
              *ngFor="let page of pages | slice:(selectedPage+pages.length) / 2 - 1: (selectedPage+pages.length) / 2 + 2"
              [ngTemplateOutlet]="pageTemplate" [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
            <span class="paginator__page margin-right-s">...</span>
          </ng-container>
          <ng-template *ngFor="let page of pages | slice:pages.length - 2:pages.length"
                       [ngTemplateOutlet]="pageTemplate"
                       [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
        </ng-container>

        <ng-container *ngIf="selectedPage > pages.length - 4">
          <ng-template *ngFor="let page of pages | slice:0:2" [ngTemplateOutlet]="pageTemplate"
                       [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
          <span class="paginator__page margin-right-s">...</span>
          <ng-container *ngIf="selectedPage > pages.length -2">
            <ng-template
              *ngFor="let page of pages | slice:(selectedPage+pages.length) / 2 - 6: (selectedPage+pages.length) / 2 - 3"
              [ngTemplateOutlet]="pageTemplate" [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
            <span class="paginator__page margin-right-s">...</span>
          </ng-container>
          <ng-template *ngFor="let page of pages | slice:selectedPage - 2:pages.length"
                       [ngTemplateOutlet]="pageTemplate"
                       [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
        </ng-container>

        <ng-container *ngIf="selectedPage >=5 && selectedPage <= pages.length - 4">
          <ng-template *ngFor="let page of pages | slice:0:2" [ngTemplateOutlet]="pageTemplate"
                       [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
          <span class="paginator__page margin-right-s">...</span>
          <ng-template *ngFor="let page of pages | slice:selectedPage - 2: selectedPage + 1"
                       [ngTemplateOutlet]="pageTemplate" [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
          <span class="paginator__page margin-right-s">...</span>
          <ng-template *ngFor="let page of pages | slice:pages.length - 2:pages.length"
                       [ngTemplateOutlet]="pageTemplate"
                       [ngTemplateOutletContext]="{$implicit: page}"></ng-template>
        </ng-container>

        <mat-icon svgIcon="chevron" class="arrow-icon chevron-right"
                  [class.arrow-icon_active]="selectedPage !== pages[pages.length-1]"
                  [class.cursor-pointer]="selectedPage !== pages[pages.length-1]" (click)="handleNextPage()"></mat-icon>

      </div>
    </ng-template>

    <div class="flex-row is-align-items-center">
      <div class="margin-right-s app-body-small">Jump to:</div>
      <app-select icon="angle_down"
                  class="margin-right-s"
                  [selectedValue]="selectedPage"
                  [options]="pages"
                  [compareFn]="compareFn"
                  [displayFn]="displayFn"
                  [isUnifiedHeightSet]="false"
                  (select)="handleJumpToPage($event)"
      >
      </app-select>
    </div>
  </ng-container>

</div>

<ng-template #pageTemplate let-page>
  <span
    class="app-body-small paginator__page margin-right-s"
    [ngClass]="{'paginator__page--selected': selectedPage === page, 'cursor-pointer': selectedPage !== page}"
    (click)="handleSelectPage(page)"
  >
    {{page}}
  </span>
</ng-template>
