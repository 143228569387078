import { Permission, EditablePermission } from '@app/shared/types/enums/permission.enum';
import { AccessType } from '@app/shared/types/enums/access-type.enum';
import { TAccessTypeOptionsMap } from '@app/stakeholders/types/interfaces/permissions.interface';
import { without } from 'ramda';

export interface PermissionCopy {
  title: string;
  icon: string;
  description?: string;
}

export const PERMISSIONS = Object.values(Permission);
export const EDITABLE_PERMISSIONS = without([Permission.homeAccess], [...PERMISSIONS]);
export const PERMISSIONS_WITH_ORDER: Permission[] = [
  Permission.dashboardAccess,
  Permission.capTableAccess,
  Permission.debtsAccess,
  Permission.esopAdminAccess,
  Permission.esopParticipantAccess,
  Permission.scenarioModelingAccess,
  Permission.financialsAccess,
  Permission.kpisAccess,
  Permission.documentsAccess,
  Permission.frameworkAccess,
  Permission.stakeholdersAccess,
  Permission.companySettingsAccess,
];

export const PERMISSION_COPY_MAP: Record<EditablePermission, PermissionCopy> = {
  [Permission.dashboardAccess]: {
    title: 'Dashboard',
    icon: 'dashboard',
    description: '* Requires Financials and KPIs.',
  },
  [Permission.capTableAccess]: {
    title: 'Cap Table',
    icon: 'chart',
  },
  [Permission.debtsAccess]: {
    title: 'Notes & Loans',
    icon: 'loans',
  },
  [Permission.esopAdminAccess]: {
    title: 'ESOP',
    icon: 'building_solid',
  },
  [Permission.esopParticipantAccess]: {
    title: 'ESOP Participant',
    icon: 'building_solid_person',
  },
  [Permission.scenarioModelingAccess]: {
    title: 'Scenario Modeling',
    icon: 'scenario_icon',
    description: '* Requires Cap Table and Notes & Loans.',
  },
  [Permission.financialsAccess]: {
    title: 'Financials',
    icon: 'financials',
  },
  [Permission.kpisAccess]: {
    title: 'KPIs',
    icon: 'performance',
  },
  [Permission.documentsAccess]: {
    title: 'Documents',
    icon: 'documents',
  },
  [Permission.frameworkAccess]: {
    title: 'Slicing Pie',
    icon: 'extension',
  },
  [Permission.stakeholdersAccess]: {
    title: 'Stakeholders',
    icon: 'user',
  },
  [Permission.companySettingsAccess]: {
    title: 'Company Settings',
    icon: 'settings',
  },
};

export const ACCESS_TYPE_OPTIONS_MAP: TAccessTypeOptionsMap = {
  [AccessType.noAccess]: { id: AccessType.noAccess, name: 'No Access' },
  [AccessType.viewOnly]: { id: AccessType.viewOnly, name: 'View Only' },
  [AccessType.fullAccess]: { id: AccessType.fullAccess, name: 'Full Access' },
};
export const ACCESS_TYPE_SELECT_OPTIONS = Object.values(ACCESS_TYPE_OPTIONS_MAP);
export const ACCESS_TYPE_BULK_SELECT_OPTIONS = Object.values(ACCESS_TYPE_OPTIONS_MAP).map((option) => {
  return {
    ...option,
    name: `${option.name} for All`,
  };
});

export const REQUIRED_PERMISSIONS_MAP: Partial<Record<Permission, Permission[]>> = {
  [Permission.dashboardAccess]: [Permission.capTableAccess, Permission.financialsAccess, Permission.kpisAccess],
  [Permission.scenarioModelingAccess]: [Permission.capTableAccess, Permission.debtsAccess],
};

export interface PermissionMapItem {
  name: Permission;
  accessType: AccessType;
}
