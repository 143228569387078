<section class="login__container">
  <h2 class="app-h4-bold text-align-center">{{ loginCopyright.title }}</h2>
  <h4 class="margin-top-s text-align-center app-body">{{ onboardingLoginText || loginCopyright.text }}</h4>
  <form [formGroup]="form" class="login__form form">
    <wevestr-input
      dataId="Input-EmailAddress"
      class="login__input input-container"
      formControlName="email"
      label="Email Address"
      type="text"
      autocomplete="email"
      placeholder="Enter your Email Address"
      showAutofillEvent="true"
      (onAutofill)="isEmailAutofilled = $event"
    >
      <ng-container errors>
        <div class="error-message app-body-small-const" wvFormError="required">{{ ERRORS.REQUIRED }}</div>
        <div class="error-message app-body-small-const" wvFormError="email">{{ ERRORS.EMAIL }}</div>
      </ng-container>
    </wevestr-input>
    <wevestr-input
      dataId="Input-Password"
      class="login__input margin-top-m-const input-container password-input"
      [type]="inputType"
      formControlName="password"
      label="Password"
      placeholder="Enter your Password"
      showAutofillEvent="true"
      (onAutofill)="isPasswordAutofilled = $event"
    >
      <button addon class="button-reset-styles addon" type="button" (click)="togglePasswordVisibility()">
        <mat-icon
          data-id="Icon-ShowHidePassword"
          [svgIcon]="inputType === passwordType ? passwordIcons.invisible : passwordIcons.visible"
        ></mat-icon>
      </button>
      <ng-container errors>
        <div class="error-message app-body-small-const" wvFormError="required">{{ ERRORS.REQUIRED }}</div>
      </ng-container>
    </wevestr-input>
    <a
      data-id="Link-ForgotCredentials"
      class="app-body forgot-credentials margin-top-s anchor"
      routerLink="/auth/forgot-credentials"
      >Forgot password?
    </a>
    <div class="login__form-field login__form-field_checkbox margin-top-l-const">
      <div class="checkbox-wrapper full-width">
        <input data-id="Checkbox-KeepMeSignedIn" id="keep_login" type="checkbox" formControlName="keepSignedIn" />
        <label for="keep_login" class="app-body-bold">Keep me signed in</label>
        <p class="margin-top-xs app-body">Not recommended on Shared Computers</p>
      </div>

      <wevestr-button
        class="button primary-button app-body full-width margin-top-l-const"
        type="submit"
        buttonDataId="Button-Continue"
        (onClick)="loginUser()"
        [disabled]="form.invalid && !(isEmailAutofilled && isPasswordAutofilled)"
        [buttonText]="loginCopyright.buttonText"
      ></wevestr-button>
    </div>
  </form>
  <div class="margin-top-l-const app-body login__suggestion text-align-center">
    Don’t have an account? <span><a class="anchor" (click)="handleSignupClick()">Sign up for free</a></span>
  </div>
</section>
