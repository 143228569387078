import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { InvitationsService } from '@app/auth/services/invitations.service';
import { ICompany } from '@app/shareholder-management/types/interfaces';
import { SectionsNavigationService } from '@app/shared/services/section-navigation.service';
import { CompanyService } from '@app/shared/services/company.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectToCompanyOnboardingGuard {
  constructor(
    private sectionNavigationService: SectionsNavigationService,
    private companyService: CompanyService,
    private invitationService: InvitationsService,
  ) {}
  public canActivate(): Observable<boolean> {
    // TODO: add company guard to load companies right after login to avoid repeatable companies fetching
    return this.companyService.list().pipe(
      switchMap((companies: ICompany[]) => {
        const companyToOnboard = this.getCompanyToOnboard(companies);

        return companyToOnboard ? this.redirectToCompanyOnboarding$(companyToOnboard) : of(true);
      }),
    );
  }

  private getCompanyToOnboard(availableCompanies: ICompany[]): ICompany {
    let companyToOnboard: ICompany;

    if (this.invitationService.isOnboardingLinkTokenPresent()) {
      const onboardingLinkToken = this.invitationService.getOnboardingLinkToken();
      const company = this.findCompanyByOnboardingToken(availableCompanies, onboardingLinkToken);

      if (company && !company.onboarding.isOnboardingFinished) {
        companyToOnboard = company;
      }

      this.invitationService.removeOnboardingLinkToken();
    } else if (this.noOnboardedCompanies(availableCompanies)) {
      companyToOnboard = availableCompanies[0];
    }

    return companyToOnboard;
  }

  private findCompanyByOnboardingToken(companies: ICompany[], token: string): ICompany {
    return companies.find(({ onboarding }) => onboarding?.onboardingToken === token);
  }

  private noOnboardedCompanies(companies: ICompany[]): boolean {
    const availableCompaniesCount = companies.length;
    const companiesToOnboardCount = companies.filter(this.isCompanyNotOnboarded).length;

    return availableCompaniesCount === companiesToOnboardCount;
  }

  private isCompanyNotOnboarded({ onboarding }: ICompany): boolean {
    return onboarding && !onboarding.isOnboardingFinished;
  }

  private redirectToCompanyOnboarding$(company: ICompany): Promise<boolean> {
    return this.sectionNavigationService
      .navigateToCompanyOnboarding(company.onboarding.onboardingToken)
      .then(() => false);
  }
}
