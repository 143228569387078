import { Directive, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

const defaultPositions: ConnectedPosition[] = [
  { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' },
  { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
  { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' },
  { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' },
];

@Directive({
  selector: '[wvContentTooltip]',
})
export class ContentTooltipDirective implements OnInit {
  @Input()
  public wvContentTooltip!: TemplateRef<unknown>;

  @Input()
  public tooltipPositions = defaultPositions;

  @HostListener('mouseenter')
  public showTooltip(): void {
    this.attachOverlay();
  }

  @HostListener('mouseleave')
  public hideTooltip(): void {
    this.detachOverlay();
  }

  private overlayRef: OverlayRef;

  constructor(private elementRef: ElementRef, private overlay: Overlay, private vcr: ViewContainerRef) {}

  public ngOnInit(): void {
    this.createOverlay();
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.tooltipPositions);

    this.overlayRef = this.overlay.create({
      positionStrategy,
    });
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(this.wvContentTooltip, this.vcr);
      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
