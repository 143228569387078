import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  UserGuard,
  RedirectToCompanyOnboardingGuard,
  LoginGuard,
  LoginToOnboardedCompanyGuard,
} from '@app/company/guards';
import { AuthBaseComponent } from '@app/auth/components';
import { CompanyResolver, CompanySettingsResolver, UserSettingsResolver } from '@app/shared/resolvers';
import { OnboardingRouteNames } from '@app/shared/types/enums/onboarding-route-names.enum';
import { ErrorPageComponent } from '@app/page-not-found/error-page.component';
import { MaintenanceWarningComponent } from '@app/shared/components';
import { IWVRoutes } from '@app/shared/types/interfaces/wevestr-route.interface';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';
import { ParticipantRouteNames } from '@app/participant/types/enums/participant-route-names.enum';
import { HasViewAccessGuard } from '@app/services/has-view-access.guard';
import { Permission } from '@app/shared/types/enums';
import { SelfOnboardingRouteNames } from '@app/shared/types/enums/self-onboarding-route-names.enum';
import { PageName } from '@app/shared/types/enums/page-name.enum';

const routes: IWVRoutes = [
  {
    path: '',
    redirectTo: CompanyRouteNames.COMPANY,
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: CompanyRouteNames.COMPANY,
  },
  {
    path: 'auth',
    component: AuthBaseComponent,
    data: { pageName: PageName.WelcomeDemo },
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: OnboardingRouteNames.ONBOADING,
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: SelfOnboardingRouteNames.SELF_ONBOARDING,
    redirectTo: OnboardingRouteNames.ONBOADING,
  },
  {
    path: CompanyRouteNames.COMPANY,
    canLoad: [UserGuard],
    canActivate: [UserGuard, LoginGuard, RedirectToCompanyOnboardingGuard, LoginToOnboardedCompanyGuard],
    canActivateChild: [LoginGuard],
    resolve: { userSettings: UserSettingsResolver, company: CompanyResolver, companySettings: CompanySettingsResolver },
    loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: ParticipantRouteNames.PARTICIPANT,
    canLoad: [UserGuard],
    canActivate: [HasViewAccessGuard],
    canActivateChild: [LoginGuard],
    resolve: { userSettings: UserSettingsResolver, company: CompanyResolver, companySettings: CompanySettingsResolver },
    loadChildren: () => import('./participant/participant.module').then((m) => m.ParticipantModule),
    data: { permission: Permission.esopParticipantAccess },
  },
  {
    path: 'access-denied',
    component: ErrorPageComponent,
    data: { pageName: PageName.ErrorPage },
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: { pageName: PageName.ErrorPage },
  },
];

export const MAINTENANCE_ROUTE: IWVRoutes = [
  {
    path: '**',
    component: MaintenanceWarningComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // NOTE: This is remove as it is not needed anymore, its standard in v15+ and is remove from route-config:
  // https://v15.angular.io/guide/update-to-version-15#v15-bc-10
  // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
