import {
  generateCreatedSuccessfullyMessage,
  generateSavedSuccessfullyMessage,
  generateUpdatedSuccessfullyMessage,
  generateRemovedSuccessfullyMessage,
  generateReorderedSuccessfullyMessage,
  generateSavedSuccessfullyMessageForBulk,
} from '@app/shared/utils/helpers/toast-messages.helpers';
import { USER_SETTINGS_FIELDS_LABELS, COMPANY_FIELDS_LABELS } from '@app/settings/constants/settings.constants';

export const LOAN_REMOVED_MESSAGE = 'Loan removed successfully!';
export const CONVERTIBLE_REMOVED_MESSAGE = 'Convertible removed successfully!';
export const SAFE_NOT_REMOVED_MESSAGE = 'SAFE note removed successfully!';
export const INTERNAL_SERVER_ERROR_MESSAGE = 'Something went wrong!';
export const CONVERTIBLE_SAVED_MESSAGE = 'Convertible saved successfully!';
export const CONVERTIBLE_UPDATED_MESSAGE = 'Convertible updated successfully!';
export const LOAN_SAVED_MESSAGE = 'Loan saved successfully';
export const LOAN_UPDATED_MESSAGE = 'Loan updated successfully!';
export const CONVERT_LOAN_MESSAGE = 'Loan converted successfully!';
export const CONVERTED_TO_SHARES_MESSAGE = 'Convertible successfully converted to Shares!';
export const SAFE_NOTES_CONVERTED_TO_SHARES_MESSAGE = 'SAFE notes successfully converted to Shares!';
export const SHARES_GRANT_REMOVED_MESSAGE = 'Shares deleted successfully';
export const PHASE_SAVED_MESSAGE = 'Phase saved successfully!';
export const SHARE_TYPE_SAVED_MESSAGE = 'Share type saved successfully!';
export const STAKEHOLDER_SAVED_MESSAGE = 'Stakeholder saved successfully!';
export const INVALID_FILE_TYPE_MESSAGE = 'Invalid file type!';
export const DOCUMENT_TITLE_UPDATE_MESSAGE = 'Document title updated successfully!';
export const FOLDER_ADDED_MESSAGE = 'Folder added successfully!';
export const FILES_MOVED_MESSAGE = 'Files moved successfully!';
export const FOLDER_UPDATED_MESSAGE = 'Folder updated successfully!';
export const CONTRIBUTION_ADDED_MESSAGE = 'Contribution added successfully!';
export const CONTRIBUTION_UPDATED_MESSAGE = 'Contribution updated successfully!';
export const DEMO_INVITATION_INVALID = 'Demo invitation is not valid!';
export const CONTRIBUTOR_REMOVED_MESSAGE = 'Contributor removed successfully!';
export const MONTH_CONTRIBUTION_REMOVED_MESSAGE = 'Month contribution removed successfully!';
export const TWO_FACTOR_AUTHENTICATION_ENABLED_MESSAGE = 'Two-Factor authentication enabled!';
export const TWO_FACTOR_AUTHENTICATION_DISABLED_MESSAGE = 'Two-Factor authentication disabled!';
export const PHASES_DATES_SET_UP_MESSAGE = 'Phases dates set up successfully!';
export const EMPTY_LAST_PHASE_END_DATE_MESSAGE = 'Please set the end date for the last phase!';
export const STAKEHOLDER_DELETED_MESSAGE = 'Stakeholder deleted successfully!';
export const USER_GROUP_DELETED_MESSAGE = 'User group deleted successfully!';
export const ACCEPTED_INVITATION_SUCCESSFULLY = 'Invitation accepted successfully!';
export const INVOICES_RESENT_SUCCESSFULLY = 'Invoices resent successfully!';
export const INVITATION_NOT_FOR_USER = 'Invitation not for current user!';

export const stakeholderAccessGrantedFormatter = (name: string): string => `${name} access granted successfully!`;
export const stakeholderAccessRevokedFormatter = (name: string): string => `${name} access revoked successfully!`;

export const kpiSaveMessageFormatter = (name: string): string => `${name} KPI saved successfully!`;
export const kpiUpdateMessageFormatter = (name: string): string => `${name} KPI updated successfully!`;
export const settingsFieldUpdateMessageFormatter = (fieldName: string): string =>
  `${USER_SETTINGS_FIELDS_LABELS[fieldName]} updated successfully!`;

export const companySettingsFieldUpdateMessageFormatter = (fieldName: string): string =>
  `${COMPANY_FIELDS_LABELS[fieldName]} updated successfully!`;

export const phaseMultipliersUpdateMessageFormatter = (multiplierType: string): string =>
  `${multiplierType} multipliers updated successfully`;

function generateActionMessages(entityName: string) {
  return {
    savedSuccessfully: generateSavedSuccessfullyMessage(entityName),
    updatedSuccessfully: generateUpdatedSuccessfullyMessage(entityName),
    deletedSuccessfully: generateRemovedSuccessfullyMessage(entityName),
    bulkSavedSuccessfully: generateSavedSuccessfullyMessageForBulk(entityName),
  };
}

export const documentMessage = generateActionMessages('Document');

export const kpi = {
  removedSuccessfully: generateRemovedSuccessfullyMessage('KPI'),
};

export const kpis = {
  reorderedSuccessfully: generateReorderedSuccessfullyMessage('KPIs'),
};

export const categories = {
  createdSuccessfully: generateCreatedSuccessfullyMessage('Category'),
  removedSuccessfully: generateRemovedSuccessfullyMessage('Category'),
  updatedSuccessfully: generateUpdatedSuccessfullyMessage('Category'),
};

export const plans = generateActionMessages('Plan');

export const pools = generateActionMessages('Pool');

export const grants = generateActionMessages('Grant');

export const orders = generateActionMessages('Order');

export const actions = {
  reorderedSuccessfully: (elementName: string): string => generateReorderedSuccessfullyMessage(elementName),
};

export const scenarioMessage = generateActionMessages('Scenario');

export const groupMessage = generateActionMessages('Group');
export const sharesMessage = generateActionMessages('Shares');
export const stakeholderMessage = generateActionMessages('Stakeholder');
