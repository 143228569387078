<app-dialog [title]="title" [closeOnEsc]="true">
  <wevestr-name-comment-form
    nameTitle="Share Type Name"
    namePlaceholder="e.g. Common Shares"
    commentTitle="Internal Note"
    commentPlaceholder="Type any additional internal information about this Share Type."
    (saveEntity)="handleShareTypeSubmitted($event)"
    [isRequestAlive]="isRequestAlive"
  ></wevestr-name-comment-form>
</app-dialog>
