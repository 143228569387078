<ng-container *ngIf="chartData">
  <app-column-chart
    *ngIf="chartMode === ChartDisplayMode.BAR; else lineChart"
    [values]="chartData"
    [options]="chartOptions"
    [labels]="labels"
    [isGroupedBySeries]="isGroupedByMetric"
    [useLegendWithSelect]="useLegendWithSelect"
    [isEmptyState]="isEmptyState"
    [viewType]="viewType"
  >
  </app-column-chart>
  <ng-template #lineChart>
    <app-line-chart
      [charts]="chartData"
      [labels]="labels"
      [height]="chartOptions.height"
      [tooltip]="chartOptions.tooltip"
      [useLegendWithSelect]="useLegendWithSelect"
      [isEmptyState]="isEmptyState"
      [viewType]="viewType"
      [additionalChartOptions]="lineChartAdditionalOptions"
    >
    </app-line-chart>
  </ng-template>
</ng-container>
