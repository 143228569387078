import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ICreatePasswordPayload } from '@app/shared/types/interfaces';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LegalDocumentsService } from '@app/shared/services/legal-documents.service';
import { RESET_PASSWORD_SUCCESS_PATH } from '@app/shared/utils/constants/paths.constants';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  public token$: Observable<string>;

  public privacyPolicyUrl = this.legalDocumentsService.privacyPolicy;
  public cookieUrl = this.legalDocumentsService.cookiePolicy;
  public termsAndConditionsUrl = this.legalDocumentsService.termsAndConditions;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private legalDocumentsService: LegalDocumentsService,
  ) {}

  public ngOnInit(): void {
    this.token$ = this.getToken$();
  }

  public handleResetPassword({ password }: Omit<ICreatePasswordPayload, 'token'>): void {
    this.token$.pipe(switchMap((token) => this.auth.savePassword({ password, token }))).subscribe(() => {
      this.router.navigate(RESET_PASSWORD_SUCCESS_PATH);
    });
  }

  private getToken$(): Observable<string> {
    return this.route.queryParamMap.pipe(map((params) => params.get('token')));
  }
}
