import { Component, HostListener } from '@angular/core';
import { IconRegistryService } from '@app/shared/services/icon-registry.service';
import localNl from '@angular/common/locales/nl';
import localEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

import { DEVICE_MIN_WIDTH } from '@app/shared/utils/constants/common.constants';
import { CompanySidenavService } from '@app/company/services';
import { ACCESSS_TOKEN, AuthService, REFRESH_TOKEN } from '@app/auth/services/auth.service';
import { EventType } from '@app/shared/types/enums/event-type.enum';
import { LocalStorageService } from '@app/shared/services';
import { AnalyticsService } from '@app/shared/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isMobileResolution = false;

  constructor(
    private iconRegistry: IconRegistryService,
    private companySidenavService: CompanySidenavService,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private analyticsService: AnalyticsService,
  ) {
    this.analyticsService.init();
    this.iconRegistry.addSvgIcons();
    this.registerLocale();
    this.checkScreenWidth();
    this.sendAuthStateSessionStorage();
  }

  private registerLocale(): void {
    registerLocaleData(localNl, 'nl');
    registerLocaleData(localEn, 'en-US');
  }

  @HostListener('window:resize', [])
  private checkScreenWidth(): void {
    this.companySidenavService.deviceWidth = window.screen.width;

    // NOTE: uncomment when mobile version is implemented, at the moment dead code
    // if (window.screen.width < DESKTOP_WIDTH) {
    //   this.companySidenavService.collapsed = true;
    // }

    this.isMobileResolution = window.screen.width < DEVICE_MIN_WIDTH;
  }

  private sendAuthStateSessionStorage(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      const access = sessionStorage.getItem(ACCESSS_TOKEN);
      const refresh = sessionStorage.getItem(REFRESH_TOKEN);

      switch (event.key) {
        case EventType.NEW_TAB:
          if (access && refresh) {
            this.localStorage.broadcast(EventType.TOKENS, JSON.stringify({ access, refresh }));
          }
          break;
        case EventType.TOKENS:
          if (this.authService.areSessionStorageTokensSet()) {
            const data = JSON.parse(event.newValue);
            this.authService.setTokens(false, data);
          }
          break;
        case EventType.LOG_OUT:
          this.authService.removeTokens();
          break;
      }
    });

    if (this.authService.areSessionStorageTokensSet()) {
      this.localStorage.broadcast(EventType.NEW_TAB, EventType.NEW_TAB);
    }
  }
}
