import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'confirmAction',
})
export class ConfirmActionPipe implements PipeTransform {
  public transform(value: string, confirmation: string): boolean {
    return value.toLowerCase() === confirmation;
  }
}
