<div class="input-container flex-row is-align-items-center input-container_attached-inputs">
  <wevestr-combined-select-input
    class="full-width"
    [inputControl]="valuation"
    [selectionControl]="valuationTiming"
    [label]="FIELD_DETAILS.VALUATION_AMOUNT.label"
    [inputPlaceholder]="FIELD_DETAILS.VALUATION_AMOUNT.placeholder"
    [selectPlaceholder]="FIELD_DETAILS.VALUATION_AMOUNT.secondaryPlaceholder"
    [customRequiredError]="customError"
    [mask]="valuationMask"
    [isRequired]="isRequired"
    [selectOptions]="valuationTimingOptions"
    [displayFn]="displayFnForEnum"
    (reset)="handleResetOption()"
    inputDataId="Input-Amount"
    selectDataId="Select-Type"
    selectMenuDataId="SelectMenu-Type"
    [tooltipText]="valuation.disabled && valuationTooltip"
  >
    <wevestr-addon-with-currency addon class="addon addon_left addon-wrapper"></wevestr-addon-with-currency>
    <ng-container additionalErrors>
      <div class="error-message app-body-small" *ngIf="valuation.value && valuation.errors?.postMoneyBigInvestment">
        {{ ERRORS.POST_MONEY }}
      </div>
    </ng-container>
  </wevestr-combined-select-input>
</div>
