export const REQUIRED_ERROR = 'This field is required';
export const POSITIVE_NUMBER_ERROR = 'Should be a positive number';
export const INFINITY_ERROR = 'Should not be infinity';
export const NAN_ERROR = 'Should be a valid number';
export const DATE_INVALID_SHORT_ERROR = 'Date format is invalid';
export const DATE_INVALID_FULL_ERROR = 'Date format should be dd-mm-yyyy';
export const MAXIMUM_NUMBER_OF_SHARES = 'The value exceeds the maximum accepted';
export const MAXIMUM_NUMBER_OF_SHARES_IN_POOL =
  'Your quantity is exceeding the available shares in Pool. Please change the number of shares in the pool or change the quantity of the participant.';
export const POST_MONEY_ERROR = 'Valuation should be more than total investment';
export const LENGTH_ERROR = 'The field should be between 3 and 63 characters long';
export const WHITESPACES_ERROR = 'Please, add some text. Only whitespaces are not allowed';
export const LOWER_THEN_GRANT_DATE_ERROR = 'Date should be greater then Grant Date';
export const MINIMAL_CASH_AMOUNT = 0.01;
export const PHONE_ERROR = 'Please, provide a valid phone number e.g. +31612345678 (7-14 digits)';
