import { Pipe, PipeTransform } from '@angular/core';

import { convertStringToLocalDate } from '@app/shared/utils/helpers/dates.helpers';

@Pipe({
  name: 'dateToLocalFormat',
})
export class DateToLocalFormatPipe implements PipeTransform {
  transform(value: string | number | Date): Date {
    return convertStringToLocalDate(value);
  }
}
