import { MAX_FILES_COUNT, ALLOWED_AUDIT_IMPORT_TYPES } from '@app/shared/utils/constants/import.constants';
import { getFileExtension, isAllowedFileExtension } from '@app/shared/utils/helpers/files.helpers';

export function isMoreFilesThanAllowed(filesCount: number): boolean {
  return filesCount > MAX_FILES_COUNT;
}

export const isAcceptableFileExtension = (extension: string): boolean =>
  isAllowedFileExtension(ALLOWED_AUDIT_IMPORT_TYPES, extension);

export function isImportFileTypeValid(file: File): boolean {
  const extension = getFileExtension(file);
  return isAcceptableFileExtension(extension);
}

export const getInvalidFileTypeError = (fileName: string): string => `File ${fileName} has invalid type`;
export const getDuplicateFileError = (fileName: string): string => `File ${fileName} is already selected`;
