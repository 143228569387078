import { AuthBaseComponent } from '@app/auth/components/auth-base/auth-base.component';
import { PaymentNeededComponent } from '@app/auth/components/payment-needed/payment-needed.component';
import { VerifyEmailComponent } from '@app/auth/components/verify-email/verify-email.component';
import { VerifyFailedComponent } from '@app/auth/components/verify-failed/verify-failed.component';
import { AcceptedInviteComponent } from '@app/auth/components/accepted-invite/accepted-invite.component';
import { ExpiredInviteComponent } from '@app/auth/components/expired-invite/expired-invite.component';
import { AuthCoverComponent } from '@app/auth/components/auth-cover/auth-cover.component';

export const components = [
  AuthBaseComponent,
  AuthCoverComponent,
  PaymentNeededComponent,
  VerifyEmailComponent,
  VerifyFailedComponent,
  AcceptedInviteComponent,
  ExpiredInviteComponent,
];

export * from '@app/auth/components/auth-base/auth-base.component';
export * from '@app/auth/components/payment-needed/payment-needed.component';
export * from '@app/auth/components/verify-email/verify-email.component';
export * from '@app/auth/components/verify-failed/verify-failed.component';
export * from '@app/auth/components/accepted-invite/accepted-invite.component';
export * from '@app/auth/components/expired-invite/expired-invite.component';
