import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { AppRoutingModule, MAINTENANCE_ROUTE } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AuthModule } from '@app/auth/auth.module';
import { AuthInterceptor } from '@app/auth/services/interceptors/auth.interceptor';
import { errorHandlerFactory } from '@app/services/error-handler';
import { ErrorPageComponent } from '@app/page-not-found/error-page.component';
import { MobileBlockComponent } from '@app/mobile-block/mobile-block.component';
import { SharedModule } from '@app/shared/shared.module';
import { ApiResponseInterceptor } from '@app/shared/interceptors/api-response.interceptor';
import { ConfigService } from '@app/services/config.service';
import { FlagsmithService } from '@app/services/flagsmith.service';
import { CompanyIdInterceptor } from '@app/services/company-id/company-id.interceptor';
import { ApiResponseUnwrapInterceptor } from '@app/services/interceptors/api-response-unwrap.interceptor';
import { HttpLoadingInterceptor } from '@app/shared/interceptors/http-loading.interceptor';

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, MobileBlockComponent],
  imports: [BrowserAnimationsModule, BrowserModule, AppRoutingModule, AuthModule, HttpClientModule, SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseUnwrapInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompanyIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [ConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupRoutesFactory,
      multi: true,
      deps: [Router, ConfigService, FlagsmithService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function setupRoutesFactory(
  router: Router,
  configService: ConfigService,
  flagsmithService: FlagsmithService,
): () => Promise<void> {
  return async () => {
    await flagsmithService.init();

    if (configService.isMaintenanceModeEnabled()) {
      router.resetConfig(MAINTENANCE_ROUTE);
    }
  };
}
