export enum VestingDirections {
  FORWARD = 'forward',
  REVERSE = 'reverse',
}

export enum VestingDirectionsStrings {
  FORWARD = 'Forward',
  REVERSE = 'Reverse',
}

export const VestingDirectionsDisplay: Readonly<Record<VestingDirections, VestingDirectionsStrings>> = {
  [VestingDirections.FORWARD]: VestingDirectionsStrings.FORWARD,
  [VestingDirections.REVERSE]: VestingDirectionsStrings.REVERSE,
} as const;

export interface IVestingDirectionOption {
  value: VestingDirections;
  name: VestingDirectionsStrings;
}

export const vestingDirectionOptions = <IVestingDirectionOption[]>[
  { value: VestingDirections.FORWARD, name: VestingDirectionsStrings.FORWARD },
  { value: VestingDirections.REVERSE, name: VestingDirectionsStrings.REVERSE },
];

export type VestingDirectionsType = `${VestingDirections}`;
