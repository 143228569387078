import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { DatePickerType } from '@app/forms/types';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

/** this component adds both header and footer for calendar
 * TODO: please check metrial issue https://github.com/angular/components/issues/23593
 * and if possibility for custom actions is added refactor this component
 */

enum ButtonLabel {
  today = 'Today',
  currentMonth = 'This Month',
}

interface IHeaderControlsState {
  nextYear: boolean;
  nextMonth: boolean;
  previousYear: boolean;
  previousMonth: boolean;
  currentDate: boolean;
}

@Component({
  selector: 'wevestr-date-picker-header',
  templateUrl: './date-picker-header.component.html',
  styleUrls: ['./date-picker-header.component.scss'],
})
export class DatePickerHeaderComponent implements OnDestroy, OnInit {
  public periodLabel: string;
  public buttonLabel: ButtonLabel = ButtonLabel.today;
  public type: DatePickerType;
  public pickerType = DatePickerType;
  public controlsEnabled$: Observable<IHeaderControlsState>;

  private _destroyed = new Subject<void>();
  private currentDate: Date;

  constructor(
    private _calendar: MatCalendar<Date>,
    private _dateAdapter: DateAdapter<Date>,
    private _datepicker: MatDatepicker<Date>,
    @Inject(MAT_DATE_FORMATS) private _formats: MatDateFormats,
    changeDatector: ChangeDetectorRef,
  ) {
    const update$ = this.getLabelUpdate$();
    update$.subscribe(() => changeDatector.markForCheck());
  }

  public setCurrent(): void {
    this._datepicker.select(this.currentDate);
    this._datepicker.close();
  }

  public ngOnInit(): void {
    this.setPeriodLabel();
    this.type = this._calendar.startView as DatePickerType;
    this.setButtonText(this.type);
    const today = this._dateAdapter.today();
    today.setHours(0, 0, 0, 0);
    this.currentDate = today;

    this.controlsEnabled$ = this.getControlsState$();
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public handleNextMonthChange(): void {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }

  public handlePreviousMonthChange(): void {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  public handleNextYearChange(): void {
    this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

  public handlePreviousYearChange(): void {
    this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  public resetDate(): void {
    this._datepicker.select(null);
    this._datepicker.close();
  }

  private setPeriodLabel(): void {
    this.periodLabel = this._dateAdapter
      .format(this._calendar.activeDate, this._formats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  private setButtonText(viewOption: DatePickerType): void {
    this.buttonLabel = viewOption === DatePickerType.date ? ButtonLabel.today : ButtonLabel.currentMonth;
  }

  private getLabelUpdate$(): Observable<void> {
    return this._calendar.stateChanges.pipe(
      tap(() => {
        this.setPeriodLabel();
      }),
      takeUntil(this._destroyed),
    );
  }

  private isCurrentDateDisabled(): boolean {
    const filtered = !this._calendar.dateFilter(this.currentDate),
      laterThenMaxDate = this._calendar.maxDate < this.currentDate && !!this._calendar.maxDate,
      previousToMinDate = this._calendar.minDate > this.currentDate && !!this._calendar.minDate;
    return laterThenMaxDate || previousToMinDate || filtered;
  }

  private isPreviousMonthDisabled(): boolean {
    const possiblePreviousDate = new Date(this._calendar.activeDate);
    possiblePreviousDate.setDate(possiblePreviousDate.getDate() - 1);
    return this._calendar.minDate ? this._calendar.minDate > possiblePreviousDate : false;
  }

  private isPreviousYearDisabled(): boolean {
    const minDateYear = this._calendar.minDate ? this._calendar.minDate.getFullYear() : -Infinity;
    const previousYear = this._calendar.activeDate.getFullYear() - 1;
    return minDateYear > previousYear;
  }

  private isNextMonthDisabled(): boolean {
    const possibleNextDate = new Date(this._calendar.activeDate);
    possibleNextDate.setDate(1);
    possibleNextDate.setMonth(possibleNextDate.getMonth() + 1);
    return this._calendar.maxDate ? possibleNextDate > this._calendar.maxDate : false;
  }

  private isNextYearDisabled(): boolean {
    const possibleNextDate = new Date(this._calendar.activeDate);
    possibleNextDate.setDate(1);
    possibleNextDate.setMonth(0);
    possibleNextDate.setFullYear(possibleNextDate.getFullYear() + 1);
    return this._calendar.maxDate ? this._calendar.maxDate < possibleNextDate : false;
  }

  private getControlsState$(): Observable<IHeaderControlsState> {
    return this._calendar.stateChanges.pipe(
      startWith(void 0 as void),
      map(() => {
        return {
          nextYear: this.isNextYearDisabled(),
          nextMonth: this.isNextMonthDisabled(),
          previousYear: this.isPreviousYearDisabled(),
          previousMonth: this.isPreviousMonthDisabled(),
          currentDate: this.isCurrentDateDisabled(),
        };
      }),
    );
  }
}
