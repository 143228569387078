<app-table-empty-state
  class="margin-top-m empty-state"
  *ngIf="chartSeries | isEmptyArray; else chart"
  title="Vesting schedule is empty"
  description="Please add some data"
></app-table-empty-state>

<ng-template #chart>
  <div #chartGrid>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [markers]="chartOptions.markers"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [grid]="chartOptions.grid"
      [annotations]="chartOptions.annotations"
      [legend]="chartOptions.legend"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>

    <div
      class="annotations-grid has-text-grey-light app-body-small"
      *ngIf="xAxisLabels$ | async as labels"
      [ngStyle]="{'grid-template-columns': labels.gridValue}"
    >
      <span>
        <span>{{ _grantDate | dateFormat }}</span>
      </span>
      <span>
        <span>{{ labels.firstVestingDateLabel | dateFormat }}</span>
      </span>
      <span>
        <span>{{ labels.lastVestingDateLabel | dateFormat }}</span>
      </span>
    </div>
  </div>
</ng-template>
