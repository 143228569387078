import { Inject, Injectable } from '@angular/core';

import { FlagsmithService } from '@app/services/flagsmith.service';
import { EnvironmentType } from '@app/shared/types/enums/environment-type.enum';
import { APP_FEATURES, APP_FEATURE_TO_FLAGSMITH_FEATURE_MAP } from '@app/services/flagsmith.service.constants';
import { environment } from '@environments/environment';

export type IConfig = typeof environment;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly config: IConfig;
  private isFlagsmithEnabled: boolean;

  constructor(@Inject(FlagsmithService) private flagsmithService: FlagsmithService) {
    this.config = Object.freeze(environment);
    this.isFlagsmithEnabled = this.config.isFlagsmithEnabled;
  }

  public isDemoModeEnabled(): boolean {
    if (this.isFlagsmithEnabled) {
      return this.flagsmithService.isDemoModeEnabled();
    }
    return environment.environment === EnvironmentType.DEMO;
  }

  public isMaintenanceModeEnabled(): boolean {
    if (this.isFlagsmithEnabled) {
      return this.flagsmithService.isMaintenanceModeEnabled();
    }
    return environment.isMaintenanceEnabled;
  }

  public getFullBaseUrl(): string {
    return this.config.baseUrl + this.config.prefix;
  }

  public getSentryDSN(): string {
    return this.config.sentryDSN;
  }

  public getSegmentToken(): string {
    return this.config.segmentToken;
  }

  public getUserbackToken(): string {
    return this.config.userbackToken;
  }

  public isFeatureEnabled(featureName: keyof IConfig): boolean {
    if (this.isFlagsmithEnabled && APP_FEATURES.includes(featureName)) {
      return this.isFlagsmithFeatureEnabled(featureName);
    }
    return !!this.config[featureName];
  }

  public isManageSharesDocumentsEnabled(): boolean {
    return this.config.isManageSharesDocumentsEnabled;
  }

  public get stakeholderTemplateUrl(): string {
    return this.config.stakeholderTemplateUrl;
  }

  private isFlagsmithFeatureEnabled(featureName: string): boolean {
    return this.flagsmithService.isFeatureEnabled(APP_FEATURE_TO_FLAGSMITH_FEATURE_MAP[featureName]);
  }
}
