import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DOCUMENTS_LABEL } from '@app/shared/utils/constants/documents.constants';

@Component({
  selector: 'wevestr-files-dnd',
  templateUrl: './files-dnd.component.html',
  styleUrls: ['./files-dnd.component.scss'],
})
export class FilesDndComponent {
  @Input()
  public filesLabel = DOCUMENTS_LABEL;
  @Input()
  public allowedFileExtensions: string[];
  @Input()
  public isFullHeight = false;

  @Input()
  public disabled = false;

  @Input()
  public svgIcon = 'import_document';

  @Output('uploadFiles')
  private uploadFiles = new EventEmitter<FileList>();

  @ViewChild('file', { static: true })
  private fileInput: ElementRef;

  public handleFilesUpload(files: FileList): void {
    if (!this.disabled) {
      this.uploadFiles.emit(files);
      this.allowLoadingTheSameFileAgain();
    }
  }

  public handleOverlayClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public handleLinkClick(): void {
    if (!this.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  private allowLoadingTheSameFileAgain(): void {
    this.fileInput.nativeElement.value = '';
  }
}
