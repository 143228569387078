import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit {
  @Input() labels: string[];
  @Input() colors: string[];

  constructor() {}

  ngOnInit(): void {}
}
