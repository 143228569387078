<div *ngIf="fileUrl; else addAvatar" class="upload cursor-pointer" (click)="file.click()">
  <img
    class="upload__img"
    [src]="fileUrl"
    alt="Avatar"
  />
  <div *ngIf="isAddVisibleOnUploadedFile" class="upload__icon-container flex app-body-small">
    <mat-icon class="upload__icon" svgIcon="plus"></mat-icon>
  </div>
</div>
<ng-template #addAvatar>
  <button class="upload upload__add-file cursor-pointer" (click)="file.click()">
    <div class="upload__custom app-body-small">
      <mat-icon class="upload__person-icon" [svgIcon]="ICONS[type]"></mat-icon>
      <div class="upload__subtitle text-align-center app-body-small">{{ subtitle || SUBTITLES[type] }}</div>
    </div>
    <div class="upload__icon-container flex app-body-small">
      <mat-icon class="upload__icon" svgIcon="plus"></mat-icon>
    </div>
  </button>
</ng-template>
<input
  [accept]="acceptedInput"
  class="upload__file"
  [id]="formControlName"
  type="file"
  #file
  (change)="onFileUpload($event.target.files)"
/>
