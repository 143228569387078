import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'wevestr-explained-input',
  templateUrl: './explained-input.component.html',
  styleUrls: ['./explained-input.component.scss'],
  animations: [
    trigger('openClosed', [
      state('shownOnHover', style({})),
      state('closed', style({ display: 'none' })),
      state('open', style({ display: 'block' })),
      transition('open <=> closed', [animate('0.1s')]),
    ]),
  ],
})
export class ExplainedInputComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public extraDescription: string;
  @Input() public boldDescription: string;
  @Input() public isShownOnHover = false;
  @Input() public isOpen = false;
  @Input() public disabled = false;

  public isInFocus = false;

  public get explainerState(): string {
    if (this.disabled) {
      return 'closed';
    } // Force closed if disabled
    if (this.isShownOnHover) {
      return 'shownOnHover';
    }
    return this.isOpen || this.isInFocus ? 'open' : 'closed';
  }
}
