import { Injectable } from '@angular/core';
import { EventType } from '@app/shared/types/enums/event-type.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  broadcast(eventType: EventType, value: string): void {
    localStorage.setItem(eventType, value);
    localStorage.removeItem(eventType);
  }
}
