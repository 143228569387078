import { Pipe, PipeTransform } from '@angular/core';
import { EXTENSION_ICONS } from '@app/documents/constants/documents.constants';

@Pipe({
  name: 'documentExtensionIcon',
})
export class DocumentExtensionIconPipe implements PipeTransform {
  transform(extension: string): string {
    const extensionHasIcon = Object.prototype.hasOwnProperty.call(EXTENSION_ICONS, extension);
    return extensionHasIcon ? EXTENSION_ICONS[extension] : EXTENSION_ICONS.default;
  }
}
