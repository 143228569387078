import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  AfterContentInit,
  Self,
  Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TInputValue } from '@app/forms/types';
import { StakeholderType } from '@wevestr/bff-types/enums/stakeholderType.enum';

@Component({
  selector: 'wevestr-add-avatar',
  templateUrl: './add-avatar.component.html',
  styleUrls: ['./add-avatar.component.scss'],
})
export class AddAvatarComponent implements AfterContentInit {
  public fileToUpload: File;
  public fileUrl: SafeResourceUrl;
  public propagateChange: (value: File) => void;
  public acceptedInput = 'image/*';
  public formControlName: TInputValue;

  public SUBTITLES = {
    [StakeholderType.INDIVIDUAL]: 'Add your photo here',
    [StakeholderType.ORGANISATION]: 'Add your logo here',
  };

  public ICONS = {
    [StakeholderType.INDIVIDUAL]: 'individual',
    [StakeholderType.ORGANISATION]: 'non_personal',
  };

  @Input() public type = StakeholderType.INDIVIDUAL;
  @Input() public subtitle = '';
  @Input() public isAddVisibleOnUploadedFile = false;
  @Output() public fileUploaded = new EventEmitter<File>();

  @ViewChild('file', { static: false }) public file: ElementRef;

  constructor(@Optional() @Self() private abstractControl: NgControl, private domSanitizer: DomSanitizer) {
    if (abstractControl) {
      abstractControl.valueAccessor = this;
    }
  }

  public ngAfterContentInit(): void {
    if (this.abstractControl) {
      this.formControlName = this.abstractControl.name;
    }
  }

  get value(): File {
    return this.fileToUpload;
  }

  set value(value: File) {
    this.writeValue(value);
  }

  public onFileUpload([file]: File[]): void {
    this.fileToUpload = file;
    this.propagateChange(file);
    this.fileUploaded.emit(file);
    this.file.nativeElement.value = null;
    this.fileUrl = this.getUrl();
  }

  public getUrl(): SafeResourceUrl {
    if (this.fileToUpload) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.fileToUpload));
    }
  }

  public registerOnChange(fn: (value: File) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(obj: File | string): void {
    if (obj === void 0) {
      return;
    }

    if (typeof obj === 'string') {
      this.fileUrl = obj;
    } else {
      this.fileToUpload = obj;
      this.fileUrl = this.getUrl();
    }
  }
}
