import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterService<T extends string = string> {
  private _activeRoute$ = new BehaviorSubject<T>(null);
  private childRoutePosition: number;

  constructor(private router: Router) {}

  get activeRoute$(): Observable<T> {
    return this._activeRoute$.asObservable();
  }

  /**@deprecated don't you fluppy dare to use syncrounous value */
  get activeRoute(): T {
    return this._activeRoute$.value;
  }

  setChildRoutePosition(position: number): void {
    this.childRoutePosition = position;
  }

  subscribeToRouterNavigationEnd(unsubscribe$: Subject<void>): void {
    this.setActiveChild(this.router.url);
    this.router.events.pipe(takeUntil(unsubscribe$)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveChild(event.urlAfterRedirects);
      }
    });
  }

  private setActiveChild(url: string): void {
    const urlWithoutQueryParams = url.split('?')[0];
    const urlSegments = urlWithoutQueryParams.split('/');

    if (urlSegments.length > this.childRoutePosition) {
      this._activeRoute$.next(urlSegments[this.childRoutePosition] as T);
    }
  }
}
