export enum AnalyticsEvent {
  CTViewShareholders = 'CT | View Shareholders',
  CTViewRounds = 'CT | View Rounds',
  CTViewMore = 'CT Txn | View More',
  EditTxnStarted = 'Edit Txn | Started',
  EditTxnFundingRoundSelected = 'Edit Txn | Funding Round Selected',
  EditTxnShareTypeSelected = 'Edit Txn | Share Type Selected',
  EditTxnCompleted = 'Edit Txn | Completed',
  EditTxnSuccess = 'Edit Txn | Success',
  EditTxnFailure = 'Edit Txn | Failure',
  DeleteTxnStarted = 'Delete Txn | Started',
  DeleteTxnCompleted = 'Delete Txn | Completed',
  DeleteTxnSuccess = 'Delete Txn | Success',
  DeleteTxnFailure = 'Delete Txn | Failure',
  AddSharesStarted = 'Add Shares | Started',
  AddSharesFundingRoundAdded = 'Add Shares | Funding Round Added',
  AddFundingRoundSuccess = 'Add Funding Round | Success',
  AddFundingRoundFailure = 'Add Funding Round | Failure',
  AddSharesFundingRoundSelected = 'Add Shares | Funding Round Selected',
  AddSharesShareTypeAdded = 'Add Shares | Share Type Added',
  AddShareTypeSuccess = 'Add Share Type | Success',
  AddShareTypeFailure = 'Add Share Type | Failure',
  AddSharesShareTypeSelected = 'Add Shares | Share Type Selected',
  AddSharesCompleted = 'Add Shares | Completed',
  AddSharesSuccess = 'Add Shares | Success',
  AddSharesFailure = 'Add Shares | Failure',
  ViewPrivacyPolicy = 'View Privacy Policy',
  ViewTermsAndConditions = 'View Terms & Conditions',
  LoginHere = 'Login Here',
  SignupStarted = 'Signup | Started',
  SignupCaptureContext1 = 'Signup | Capture Context [1]',
  SignupCaptureContext2 = 'Signup | Capture Context [2]',
  SignupCaptureContext3 = 'Signup | Capture Context [3]',
  SignupCaptureContext4 = 'Signup | Capture Context [4]',
  SignupCaptureContext5 = 'Signup | Capture Context [5]',
  SignupCaptureContext6 = 'Signup | Capture Context [6]',
  SignupCaptureContext7 = 'Signup | Capture Context [7]',
  BackFromContext = 'Back from Context',
  SignupCreateCompanyProfile = 'Signup | Create Company Profile',
  DuplicateCompanyDetails = 'Duplicate Company Details',
  BackFromCompanyProfile = 'Back from Company Profile',
  DuplicateEmailAddress = 'Duplicate email address',
  BackFromUserProfile = 'Back from User Profile',
  BackFromCreatePassword = 'Back from Create Password',
  ViewCookiePolicy = 'View Cookie Policy',
  SendInvite = 'Send Invite',
  DeleteInvite = 'Delete Invite',
  AddAnother = 'Add Another',
  BackFromCoFounderInvite = 'Back from Co-founder Invite',
  SignUpClick = 'Signup Click',
  SignupCompleted = 'Signup | Completed',
  SignupSuccess = 'Signup | Success',
  SignupFailure = 'Signup | Failure',
  SignupCreatePassword = 'Signup | Create Password',
  SignupCreateUserProfile = 'Signup | Create User Profile',
  SignupInviteCoFounders = 'Signup | Invite Co-founders',
  ContinueToPlatform = 'Continue to Platform',
  ViewCompanyHomePage = 'View Company Home Page',
  SignIn = 'Sign In',
  SignInSuccess = 'Sign In | Success',
  SignInFailure = 'Sign In | Failure',
  ExpiredInvitation = 'Expired Invitation',
  SignupConfirmProfileDetails = 'Signup | Confirm Profile Details',
  SignupCreateNewPassword = 'Signup | Create New Password',
  ForgotPassword = 'Forgot Password',
  ResetPassword = 'Reset Password',
  BackFromForgotPassword = 'Back from Forgot Password',
  CreateNewPassword = 'Create New Password',
  InitiateChat = 'Initiate Chat',
  ViewHome = 'View Home',
  ViewDashboard = 'View Dashboard',
  ViewCapTable = 'View Cap Table',
  ViewNotesAndLoans = 'View Notes & Loans',
  ViewESOP = 'View ESOP',
  ViewScenarioModeling = 'View Scenario Modeling',
  ViewFinancials = 'View Financials',
  ViewKPIs = 'View KPIs',
  ViewDocuments = 'View Documents',
  ViewSlicingPie = 'View Slicing Pie',
  ViewStakeholders = 'View Stakeholders',
  ViewModeAdminView = 'View Mode | Admin View',
  ViewModeParticipantView = 'View Mode | Participant View',
  Overview = 'Overview',
  SelectCompany = 'Select Company',
  AddNewCompany = 'Add New Company',
  ViewCompanySettings = 'View Company Settings',
  ViewUserSettings = 'View User Settings',
  LogOut = 'Log Out',
  EditCompanySettingsStarted = 'Edit Company Settings | Started',
  EditCompanySettingsCompleted = 'Edit Company Settings | Completed',
  SetSlicingPiePhaseStarted = 'Set Slicing Pie Phase | Started',
  SetSlicingPiePhaseCompleted = 'Set Slicing Pie Phase | Completed',
  ImportAuditFileStarted = 'Import Audit File | Started',
  ImportAuditFileCompleted = 'Import Audit File | Completed',
  ConfigureConnectionStarted = 'Configure Connection | Started',
  ConfigureConnectionCompleted = 'Configure Connection | Completed',
  EditUserSettingsStarted = 'Edit User Settings | Started',
  EditUserSettingsCompleted = 'Edit User Settings | Completed',
  SHViewStakeholders = 'SH | View Stakeholders',
  SHViewGroups = 'SH | View Groups',
  EditStakeholderStarted = 'Edit Stakeholder | Started',
  EditStakeholderGeneralInfoCaptured = 'Edit Stakeholder | General Info Captured',
  EditStakeholderPermissionsSelected = 'Edit Stakeholder | Permissions Selected',
  EditStakeholderCompleted = 'Edit Stakeholder | Completed',
  EditStakeholderSuccess = 'Edit Stakeholder | Success',
  EditStakeholderFailure = 'Edit Stakeholder | Failure',
  EditStakeholderInvited = 'Edit Stakeholder | Invited',
  DeleteStakeholderStarted = 'Delete Stakeholder | Started',
  DeleteStakeholderCompleted = 'Delete Stakeholder | Completed',
  DeleteStakeholderSuccess = 'Delete Stakeholder | Success',
  DeleteStakeholderFailure = 'Delete Stakeholder | Failure',
  AddStakeholderGeneralInfoCaptured = 'Add Stakeholder | General Info Captured',
  AddStakeholderPermissionsSelected = 'Add Stakeholder | Permissions Selected',
  AddStakeholderCompleted = 'Add Stakeholder | Completed',
  AddStakeholderStarted = 'Create New Stakeholder | Started',
  AddStakeholderSuccess = 'Create New Stakeholder | Success',
  AddStakeholderFailure = 'Create New Stakeholder | Failure',
  AddStakeholderInvited = 'Add Stakeholder | Invited',
  InviteStakeholderStarted = 'Invite Stakeholder | Started',
  InviteStakeholderSuccess = 'Invite Stakeholder | Success',
  InviteStakeholderFailure = 'Invite Stakeholder | Failure',
  RevokeAccessStarted = 'Revoke Access | Started',
  RevokeAccessCompleted = 'Revoke Access | Completed',
  RevokeAccessSuccess = 'Revoke Access | Success',
  RevokeAccessFailure = 'Revoke Access | Failure',
  GrantAccessStarted = 'Grant Access | Started',
  GrantAccessCompleted = 'Grant Access | Completed',
  GrantAccessSuccess = 'Grant Access | Success',
  GrantAccessFailure = 'Grant Access | Failure',
  ViewSHGroup = 'View SH Group',
  EditSHGroupStarted = 'Edit SH Group | Started',
  EditSHGroupGeneralInfoCaptured = 'Edit SH Group | General Info Captured',
  EditSHGroupStakeholdersSelected = 'Edit SH Group | Stakeholders Selected',
  EditSHGroupCompleted = 'Edit SH Group | Completed',
  EditSHGroupSuccess = 'Edit SH Group | Success',
  EditSHGroupFailure = 'Edit SH Group | Failure',
  DeleteSHGroupStarted = 'Delete SH Group | Started',
  DeleteSHGroupCompleted = 'Delete SH Group | Completed',
  DeleteSHGroupSuccess = 'Delete SH Group | Success',
  DeleteSHGroupFailure = 'Delete SH Group | Failure',
  AddSHGroupStarted = 'Add SH Group | Started',
  AddSHGroupGeneralInfoCaptured = 'Add SH Group | General Info Captured',
  AddSHGroupStakeholdersSelected = 'Add SH Group | Stakeholders Selected',
  AddSHGroupCompleted = 'Add SH Group | Completed',
  AddSHGroupSuccess = 'Add SH Group | Success',
  AddSHGroupFailure = 'Add SH Group | Failure',
  BuildCapTable = 'Build Cap Table',
  AddPool = 'Add Pool',
  AddStakeholder = 'Add Stakeholder',
  ManageSettings = 'Manage Settings',
  SupportEmail = 'Support Email',
  StartChat = 'Start Chat',
  ViewProductDocumentation = 'View Product Documentation',
  ProductTourStarted = 'Product Tour | Started',
  ProductTourCompleted = 'Product Tour | Completed',
  ProductTourNotNow = 'Product Tour | Not Now',
  ProductTourCancelled = 'Product Tour | Cancelled',
  ProductTourNextStep = 'Product Tour | Next Step',
  ProductTourPreviousStep = 'Product Tour | Previous Step',
  UploadDocumentStarted = 'Upload Document | Started',
  UploadDocumentSuccess = 'Upload Document | Success',
  UploadDocumentFailure = 'Upload Document | Failure',
  DeleteDocumentStarted = 'Delete Document | Started',
  DeleteDocumentSuccess = 'Delete Document | Success',
  DeleteDocumentFailure = 'Delete Document | Failure',
  SetupSlicingPieStarted = 'Setup Slicing Pie | Started',
  SetupSlicingPieSuccess = 'Setup Slicing Pie | Success',
  SetupSlicingPieFailure = 'Setup Slicing Pie | Failure',
  EditContributionSuccess = 'Edit Contribution | Success',
  EditContributionStarted = 'Edit Contribution | Started',
  EditContributionFailure = 'Edit Contribution | Failure',
  DeleteContributionSuccess = 'Delete Contribution | Success',
  DeleteContributionFailure = 'Delete Contribution | Failure',
  DeleteContributionStarted = 'Delete Contribution | Started',
  AddContributionSuccess = 'Add Contribution | Success',
  AddContributionStarted = 'Add Contribution | Started',
  AddContributionFailure = 'Add Contribution | Failure',
  AddKpiSuccess = 'Add KPI | Success',
  AddKpiFailure = 'Add KPI | Failure',
  AddKpiStarted = 'Add KPI | Started',
  DeleteKpiSuccess = 'Delete KPI | Success',
  DeleteKpiFailure = 'Delete KPI | Failure',
  DeleteKpiStarted = 'Delete KPI | Started',
  EditKpiSuccess = 'Edit KPI | Success',
  EditKpiFailure = 'Edit KPI | Failure',
  EditKpiStarted = 'Edit KPI | Started',
  DeleteCategorySuccess = 'Delete Category | Success',
  DeleteCategoryFailure = 'Delete Category | Failure',
  DeleteCategoryStarted = 'Delete Category | Started',
  EditCategorySuccess = 'Edit Category | Success',
  EditCategoryFailure = 'Edit Category | Failure',
  EditCategoryStarted = 'Edit Category | Started',
  AddCategorySuccess = 'Add Category | Success',
  AddCategoryFailure = 'Add Category | Failure',
  AddCategoryStarted = 'Add Category | Started',
  EnableApiSuccess = 'Enable API Connection | Success',
  EnableApiFailure = 'Enable API Connection | Failure',
  EnableApiStarted = 'Enable API Connection | Started',
  DisableApiSuccess = 'Disable API Connection | Success',
  DisableApiFailure = 'Disable API Connection | Failure',
  DisableApiStarted = 'Disable API Connection | Started',
  ImportFileSuccess = 'Import File | Success',
  ImportFileFailure = 'Import File | Failure',
  ImportFileStarted = 'Import File | Started',
  AddRowSuccess = 'Add Row | Success',
  AddRowFailure = 'Add Row | Failure',
  AddRowStarted = 'Add Row | Started',
  SaveNewScenarioSuccess = 'Save New Scenario | Success',
  SaveNewScenarioFailure = 'Save New Scenario | Failure',
  SaveNewScenarioStarted = 'Save New Scenario | Started',
  EditScenarioSuccess = 'Edit Scenario | Success',
  EditScenarioFailure = 'Edit Scenario | Failure',
  EditScenarioStarted = 'Edit Scenario | Started',
  DeleteScenarioSuccess = 'Delete Scenario | Success',
  DeleteScenarioFailure = 'Delete Scenario | Failure',
  DeleteScenarioStarted = 'Delete Scenario | Started',
  AddNewConvertibleNotesSuccess = 'Add New Convertible Notes | Success',
  AddNewConvertibleNotesFailure = 'Add New Convertible Notes | Failure',
  AddNewConvertibleNotesStarted = 'Add New Convertible Notes | Started',
  EditConvertibleNotesSuccess = 'Edit Convertible Notes | Success',
  EditConvertibleNotesFailure = 'Edit Convertible Notes | Failure',
  EditConvertibleNotesStarted = 'Edit Convertible Notes | Started',
  DeleteConvertibleNotesSuccess = 'Delete Convertible Notes | Success',
  DeleteConvertibleNotesFailure = 'Delete Convertible Notes | Failure',
  DeleteConvertibleNotesStarted = 'Delete Convertible Notes | Started',
  AddNewSafeNotesSuccess = 'Add New SAFE Notes | Success',
  AddNewSafeNotesFailure = 'Add New SAFE Notes | Failure',
  AddNewSafeNotesStarted = 'Add New SAFE Notes | Started',
  EditSafeNotesSuccess = 'Edit SAFE Notes | Started',
  EditSafeNotesFailure = 'Edit SAFE Notes | Started',
  EditSafeNotesStarted = 'Edit SAFE Notes | Started',
  DeleteNewSafeNotesSuccess = 'Delete SAFE Notes | Success',
  DeleteNewSafeNotesFailure = 'Delete SAFE Notes | Failure',
  DeleteNewSafeNotesStarted = 'Delete SAFE Notes | Started',
  ConvertSafeSuccess = 'Convert SAFE | Success',
  ConvertSafeFailure = 'Convert SAFE | Failure',
  ConvertSafeStarted = 'Convert SAFE | Started',
  AddNewLoanSuccess = 'Add New Loan | Success',
  AddNewLoanFailure = 'Add New Loan | Failure',
  AddNewLoanStarted = 'Add New Loan | Started',
  EditLoanSuccess = 'Edit Loan | Success',
  EditLoanFailure = 'Edit Loan | Failure',
  EditLoanStarted = 'Edit Loan | Started',
  DeleteLoanSuccess = 'Delete Loan | Success',
  DeleteLoanFailure = 'Delete Loan | Failure',
  DeleteLoanStarted = 'Delete Loan | Started',
  AddNewPoolSuccess = 'Add New Pool | Success',
  AddNewPoolFailure = 'Add New Pool | Failure',
  AddNewPoolStarted = 'Add New Pool | Started',
  EditPoolSuccess = 'Edit Pool | Success',
  EditPoolFailure = 'Edit Pool | Failure',
  EditPoolStarted = 'Edit Pool | Started',
  DeletePoolSuccess = 'Delete Pool | Success',
  DeletePoolFailure = 'Delete Pool | Failure',
  DeletePoolStarted = 'Delete Pool | Started',
  AddNewPlanSuccess = 'Add New Plan | Success',
  AddNewPlanFailure = 'Add New Plan | Failure',
  AddNewPlanStarted = 'Add New Plan | Started',
  EditPlanSuccess = 'Edit Plan | Success',
  EditPlanFailure = 'Edit Plan | Failure',
  EditPlanStarted = 'Edit Plan | Started',
  DeletePlanSuccess = 'Delete Plan | Success',
  DeletePlanFailure = 'Delete Plan | Failure',
  DeletePlanStarted = 'Delete Plan | Started',
  AddNewGrantSuccess = 'Add New Grant | Success',
  AddNewGrantFailure = 'Add New Grant | Failure',
  AddNewGrantStarted = 'Add New Grant | Started',
  EditGrantSuccess = 'Edit Grant | Success',
  EditGrantFailure = 'Edit Grant | Failure',
  EditGrantStarted = 'Edit Grant | Started',
  StopGrantSuccess = 'Stop Grant | Success',
  StopGrantFailure = 'Stop Grant | Failure',
  StopGrantStarted = 'Stop Grant | Started',
  AddNewOrderSuccess = 'Add New Order | Success',
  AddNewOrderFailure = 'Add New Order | Failure',
  AddNewOrderStarted = 'Add New Order | Started',
  EditOrderSuccess = 'Edit Order | Success',
  EditOrderFailure = 'Edit Order | Failure',
  EditOrderStarted = 'Edit Order | Started',
  DeleteOrderSuccess = 'Delete Order | Success',
  DeleteOrderFailure = 'Delete Order | Failure',
  DeleteOrderStarted = 'Delete Order | Started',
}
