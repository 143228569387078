<div class="flex-column full-height margin-bottom-m-const">
  <div class="app-body">
    You can upload up to {{ maxFilesCount }} Audit Files with a maximum of {{ maxTotalFilesSizeMb }}mb per file.
  </div>
  <div class="dnd-area-wrapper padding-top-m full-width">
    <wevestr-files-dnd
      [isFullHeight]="true"
      [allowedFileExtensions]="allowedAuditTypes"
      [disabled]="isImportInProgress"
      [filesLabel]="filesLabel"
      (uploadFiles)="handleFilesUpload($event)"
    ></wevestr-files-dnd>
  </div>
</div>

<app-table *ngIf="auditFiles?.length" [data]="auditFiles" [columns]="columns" [options]="tableOptions">
  <ng-template appCellValue let-auditFile let-column="column">
    <ng-container [ngSwitch]="column.field">
      <div *ngSwitchCase="filesTableConstants.NAME.field" class="flex-column is-justify-content-space-between">
        <div class="flex-row is-justify-content-space-between align-items-center margin-top-m">
          <span class="app-h5-medium-weight margin-right-s text-ellipsis">{{ auditFile.file.name }}</span>
          <mat-icon svgIcon="cross" class="file__remove" (click)="handleRemoveFile(auditFile.file)"></mat-icon>
        </div>
        <span class="file__size app-h5-medium-weight margin-bottom-m">
          {{ auditFile.file?.size | formatToFileSizeString }}
        </span>
      </div>
      <div
        *ngSwitchCase="filesTableConstants.REPLACE.field"
        class="flex-row is-justify-content-space-around align-items-center"
      >
        <wevestr-radio-button
          [theme]="RADIO_BUTTON_THEMES.blue"
          [valueName]="OverrideModes.REPLACE"
          [checked]="auditFile.overrideMode === OverrideModes.REPLACE"
          [disabled]="isImportInProgress"
          groupName="audit-files-mode"
          (click)="handleSelectOverrideMode(auditFile.file, OverrideModes.REPLACE)"
        >
        </wevestr-radio-button>
      </div>
      <div
        *ngSwitchCase="filesTableConstants.UPDATE.field"
        class="flex-row is-justify-content-space-around align-items-center"
      >
        <wevestr-radio-button
          [theme]="RADIO_BUTTON_THEMES.blue"
          [valueName]="OverrideModes.UPDATE"
          [checked]="auditFile.overrideMode === OverrideModes.UPDATE"
          [disabled]="isImportInProgress"
          groupName="audit-files-mode"
          (click)="handleSelectOverrideMode(auditFile.file, OverrideModes.UPDATE)"
        >
        </wevestr-radio-button>
      </div>
    </ng-container>
  </ng-template>
</app-table>

<div class="modal-footer">
  <app-button
    class="is-primary-button"
    [disabled]="!auditFiles || auditFiles.length === 0"
    [loading]="isImportInProgress"
    (onClick)="handleImportData()"
  >
    {{ isImportInProgress ? 'Importing...' : 'Import' }}
  </app-button>
</div>
