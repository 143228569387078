import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent implements OnInit {
  @Input() image: string;
  @Input() name: string;
  @Input() description: string;
  @Input() size = 32;

  constructor() {}

  ngOnInit(): void {}
}
