export const VERTICAL_MODAL_DIMENSIONS = Object.freeze({
  width: '500px',
});

export const SIDE_MODAL_DIMENSIONS = Object.freeze({
  position: { right: '0', top: '0' },
  height: '100%',
  width: '424px',
});

export const LARGE_SIDE_MODAL_DIMENSIONS = Object.freeze({
  position: { right: '0', top: '0' },
  height: '100%',
  width: '650px',
  // Remove maxWidth to allow the modal to expand to the full width of the screen, this issue is reference to: https://wevestr.atlassian.net/browse/WEVAPP-3657
  // maxWidth: '40%',
});

export const REQUEST_FAILED_ERROR = 'Something went wrong';
