import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContributionType } from '@app/framework/types/interfaces/contribution-type.interface';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable()
export class ContributionTypesService {
  protected readonly baseUrl: string;

  constructor(private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getFullBaseUrl();
  }

  private get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/framework/contribution-types`;
  }

  public list(): Observable<IContributionType[]> {
    return this.http.get<IContributionType[]>(`${this.baseUrl}/${this.url}`);
  }
}
