import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrencySettingsService } from '@app/shared/services/currency-settings.service';

@Component({
  selector: 'wevestr-addon-with-currency',
  templateUrl: './addon-with-currency.component.html',
  styleUrls: ['./addon-with-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonWithCurrencyComponent {
  public currencySymbol = this.currencySettingsService.getCurrentCurrencySymbol();

  constructor(private currencySettingsService: CurrencySettingsService) {}
}
