<div class="flex-row is-justify-content-flex-end chart-toggle">
  <div
    *ngFor="let chart of charts; let i = index"
    (click)="handleSelectChart(chart, i)"
    class="margin-left-m flex-row is-align-items-center chart__element"
    [ngClass]="{ 'chart--active': activeChartIndex === i }"
    [ngStyle]="{ 'background-color': activeChartIndex === i ? chart.color : null }"
  >
    <div class="chart__marker" [ngStyle]="{ 'background-color': chart.color }"></div>
    <div class="app-caption chart__name">{{ chart.name }}</div>
  </div>
</div>
