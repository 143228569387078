import { DATE_INVALID_FULL_ERROR, REQUIRED_ERROR } from '@app/forms/constants';

const DATE_ERROR_MESSAGES = {
  MAX_DATE_OF_ISSUE: 'Date of issue should be equal or less than today',
  MIN_DATE_OF_ISSUE: 'Shares with valuation can be set only after',
};

const VALUATION_ERROR_MESSAGES = {
  BOTH_REQUIRED: 'Both valuation related values are required',
};

export const MANAGE_SHARES_ERROR_MESSAGES = {
  REQUIRED: REQUIRED_ERROR,
  DATE_INVALID: DATE_INVALID_FULL_ERROR,
  MAX_DATE_OF_ISSUE: DATE_ERROR_MESSAGES.MAX_DATE_OF_ISSUE,
  MIN_DATE_OF_ISSUE: DATE_ERROR_MESSAGES.MIN_DATE_OF_ISSUE,
  REQUIRED_TYPE: VALUATION_ERROR_MESSAGES.BOTH_REQUIRED,
};

export const ISSUE_DATE_ERROR_KEYS = {
  DATE_BEFORE_PREVIOUS_INTERVAL: 'dateBeforePreviousInterval',
  DATE_AFTER_NEXT_INTERVAL: 'dateAfterNextInterval',
  DATE_FROM_ANOTHER_INTERVAL: 'dateFromAnotherInterval',
  LOWER_THAN_MIN_DATE: 'lowerThanMinDate',
};
