import { Component, OnInit } from '@angular/core';
import { EditModeService } from '@app/shared/services/edit-mode.service';

@Component({
  selector: 'app-edit-mode-controls',
  templateUrl: './edit-mode-controls.component.html',
  styleUrls: ['./edit-mode-controls.component.scss'],
})
export class EditModeControlsComponent implements OnInit {
  editMode: boolean;

  constructor(private editModeService: EditModeService) {}

  ngOnInit(): void {
    this.editMode = this.editModeService.editMode;
  }

  handleToggleEditMode(): void {
    this.editMode = !this.editMode;
    this.editModeService.setEditMode(this.editMode);
  }

  ngOnDestroy(): void {
    const isEdit = false;
    this.editModeService.setEditMode(isEdit);
  }
}
