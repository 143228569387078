import { EnvironmentType } from '@app/shared/types/enums/environment-type.enum';
import packageConfig from '../../package.json'; // eslint-disable-line no-restricted-imports

export const environment = {
  environment: EnvironmentType.STAGING,
  isProduction: true,
  baseUrl: 'https://wevestrapp.com',
  prefix: '/api',
  sentryDSN: 'https://81d14836f42640d48bf28c34a278f618@o505067.ingest.sentry.io/5592822',
  segmentToken: <string>void 0,
  userbackToken: <string>void 0,
  isMaintenanceEnabled: false,
  appVersion: packageConfig.version,
  isManageSharesDocumentsEnabled: true,
  stakeholderTemplateUrl: 'https://wevestr.s3.eu-central-1.amazonaws.com/templates/Stakeholder+List.xlsx',
  flagsmithEnvID: 'h83EMR8QWYRCxBHFvDdFM6', // STAGING ENV
  isFlagsmithEnabled: true,
  flagsmithBaseUrl: 'https://flagsmith.wevestrapp.com/api/v1/',
};
