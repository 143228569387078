import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app/services/config.service';
import { IUserSettings } from '@app/settings/types/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NumberFormatting } from '@app/settings/types/enums';
import { SEPARATORS } from '@app/settings/constants/settings.constants';

const DEFAULT_USER_SETTINGS = {
  formatting: NumberFormatting.EUROPEAN,
  id: <number>null,
};

export interface INumberSeparators {
  thousands: SEPARATORS;
  decimal: SEPARATORS;
}

const SEPARATORS_MAP = new Map<NumberFormatting, INumberSeparators>([
  [NumberFormatting.EUROPEAN, { thousands: SEPARATORS.DOT, decimal: SEPARATORS.COMMA }],
  [NumberFormatting.AMERICAN, { thousands: SEPARATORS.COMMA, decimal: SEPARATORS.DOT }],
]);

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private settings$ = new BehaviorSubject<IUserSettings>(DEFAULT_USER_SETTINGS);
  private readonly baseUrl: string;

  constructor(config: ConfigService, private http: HttpClient) {
    this.baseUrl = config.getFullBaseUrl();
  }

  /**
   * Syncronous method @settings is allowed because of default value + resolver are taking care of it
   */
  public get settings(): IUserSettings {
    return this.settings$.value;
  }

  public getSettings(force = false): Observable<IUserSettings> {
    if (this.settings$.value && !force) {
      return this.settings$.asObservable();
    }

    return this.http
      .get<IUserSettings>(`${this.baseUrl}/user/settings`)
      .pipe(tap((settings: IUserSettings) => this.settings$.next(settings)));
  }

  public updateSettings(data: IUserSettings): Observable<IUserSettings> {
    return this.http
      .patch<IUserSettings>(`${this.baseUrl}/user/settings`, data)
      .pipe(tap((settings: IUserSettings) => this.settings$.next(settings)));
  }

  public get separators(): INumberSeparators {
    return SEPARATORS_MAP.get(this.settings.formatting);
  }
}
