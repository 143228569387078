import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { head, isEmpty } from 'ramda';

import { UserService } from '@app/shared/services/user.service';
import { CompanyService } from '@app/shared/services/company.service';
import { ICompany } from '@app/shareholder-management/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LoginToOnboardedCompanyGuard {
  constructor(private companyService: CompanyService, private userService: UserService) {}
  public canActivate(): Observable<boolean> {
    // TODO: add company guard to load companies right after login to avoid repeatable companies fetching
    return this.companyService.list().pipe(
      map((companies: ICompany[]) => {
        if (this.isCurrentCompanyNotOnboarded(companies) && this.hasActiveCompanies(companies)) {
          this.setFirstActiveCompanyAsCurrent(companies);
        }

        return true;
      }),
    );
  }

  private isCurrentCompanyNotOnboarded(availableCompanies: ICompany[]): boolean {
    const notOnboardedCompaniesIds = this.getNonOnboardedCompaniesIds(availableCompanies);

    if (isEmpty(notOnboardedCompaniesIds)) {
      return false;
    }

    let isCompanyNotOnboarded = false;
    const isCurrentCompanySet = !!this.userService.currentCompanyId;

    if (isCurrentCompanySet) {
      isCompanyNotOnboarded = !!notOnboardedCompaniesIds.find(
        (companyId) => companyId === this.userService.currentCompanyId,
      );
    }

    return isCompanyNotOnboarded;
  }

  private getNonOnboardedCompaniesIds(companies: ICompany[]): number[] {
    return companies.filter((company) => !this.isCompanyActive(company)).map(({ id }) => id);
  }

  private isCompanyActive({ onboarding }: ICompany): boolean {
    return !onboarding || onboarding.isOnboardingFinished;
  }

  private hasActiveCompanies(companies: ICompany[]): boolean {
    return this.getActiveCompanies(companies).length > 0;
  }

  private getActiveCompanies(companies: ICompany[]): ICompany[] {
    return companies.filter(this.isCompanyActive);
  }

  private setFirstActiveCompanyAsCurrent(companies: ICompany[]): void {
    const activeCompanies = this.getActiveCompanies(companies);
    // TODO: refactor setting company id in more obvious way
    this.userService.currentCompanyId = head(activeCompanies).id;
  }
}
