import { GREY_COLOR, LIGHT_GREY_COLOR } from '@app/shared/utils/constants/colors.constants';
import { AXIS_FONT_SIZE, CHART_FONT_FAMILY, XAXIS_TEXT_FONT_SIZE } from '@app/shared/utils/constants/chart.constants';
import { ApexOptions } from 'ng-apexcharts';

const STROKE_WIDTH = 4;
const MARKER_SIZE = 6;

export const STATIC_LINE_CHART_OPTIONS: Partial<ApexOptions> = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    fontFamily: CHART_FONT_FAMILY,
    foreColor: GREY_COLOR,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    width: STROKE_WIDTH,
  },
  markers: {
    shape: 'circle',
    size: MARKER_SIZE,
    strokeColors: '#222222',
    strokeWidth: STROKE_WIDTH,
    colors: ['#fff'],
  },
  grid: {
    padding: {
      right: 0,
    },
    borderColor: LIGHT_GREY_COLOR,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: AXIS_FONT_SIZE,
        cssClass: 'apex-dark-blue-color',
      },
    },
    decimalsInFloat: 2,
  },
  xaxis: {
    type: 'category',
    axisBorder: {
      color: LIGHT_GREY_COLOR,
    },
    axisTicks: {
      show: false,
    },
    tickPlacement: 'between',
    labels: {
      style: {
        fontSize: XAXIS_TEXT_FONT_SIZE,
        cssClass: 'apex-dark-blue-color',
      },
    },
  },
};
