import { NgModule } from '@angular/core';

import { InitialsPipe } from '@app/pipes/initials/initials.pipe';
import { CallbackPipe } from '@app/pipes/callback/callback.pipe';
import { ConfirmActionPipe } from '@app/pipes/confirm-action/confirm-action.pipe';
import { NextMonthDatePipe } from '@app/pipes/next-month-date/next-month-date.pipe';

@NgModule({
  declarations: [InitialsPipe, CallbackPipe, ConfirmActionPipe, NextMonthDatePipe],
  exports: [InitialsPipe, CallbackPipe, ConfirmActionPipe, NextMonthDatePipe],
  imports: [],
})
export class WevestrPipesModule {}
