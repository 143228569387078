import { Component, Input } from '@angular/core';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { LegalDocumentsService } from '@app/shared/services/legal-documents.service';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { PageName } from '@app/shared/types/enums/page-name.enum';
import { openInNewTab } from '@app/shared/utils/helpers/common.helpers';

@Component({
  selector: 'wevestr-policy-disclaimer',
  templateUrl: './policy-disclaimer.component.html',
  styleUrls: ['./policy-disclaimer.component.scss'],
})
export class PolicyDisclaimerComponent {
  public privacyPolicyUrl: string;
  public cookiePolicy: string;
  @Input() public pageName: PageName;

  constructor(private docsService: LegalDocumentsService, private analyticsService: AnalyticsService) {
    this.privacyPolicyUrl = this.docsService.privacyPolicy;
    //TODO: replace with terms & conditions once document is available
    this.cookiePolicy = this.docsService.cookiePolicy;
  }

  public handlePrivacyPolicyClick(event: Event): void {
    event.preventDefault();
    this.analyticsService.track(AnalyticsEvent.ViewPrivacyPolicy, {
      pageName: this.pageName,
    });
    openInNewTab(this.privacyPolicyUrl);
  }
}
