<app-dialog [title]="action.header" [closeOnEsc]="!inputControl.dirty">
  <form class="full-height flex-column">
    <section class="modal-body">
      <div class="app-body margin-bottom-m-const">{{ action.message }}</div>
      <div *ngIf="action.isPermanent" class="app-body">This action is permanent and can't be undone.</div>
      <div *ngIf="action.confirmationString" class="margin-top-l-const">
        <div class="app-body-bold margin-bottom-s">
          Type <b>{{ action.confirmationString | uppercase }}</b> to confirm
        </div>
        <div class="modal-body__form form">
          <input type="text" name="userInput" [formControl]="inputControl" autocomplete="off" />
        </div>
      </div>
    </section>
    <section class="modal-footer">
      <button type="button" class="button cancel-button" (click)="handleClose()">Cancel</button>
      <button
        class="button primary-button button__small app-body"
        [disabled]="action.confirmationString && !(inputControl.value | confirmAction : confirmation)"
        (click)="handleConfirm()"
        type="submit"
      >
        {{ action.buttonText || 'Confirm' }}
      </button>
    </section>
  </form>
</app-dialog>
