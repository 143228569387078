import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[wvFormError]',
})
export class FormErrorDirective implements AfterViewInit {
  @Input() public set wvFormError(errorIds: string) {
    this._errorIds = new Set(errorIds.split(' '));
  }

  @Input() order = Infinity;

  @Input() showErrorAfterViewInit = false;

  public get errorIds(): Set<string> {
    return this._errorIds;
  }

  private _errorIds: Set<string>;

  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    if (!this.showErrorAfterViewInit) {
      this.toggleError(false);
    }
  }

  public toggleError(hasError: boolean): void {
    this._elementRef.nativeElement.hidden = !hasError;
  }
}
