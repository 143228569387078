import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { PreviewDocumentComponent } from '@app/components/containers/preview-document/preview-document.component';
import { LayoutComponent } from '@app/components/containers/layout/layout.component';

import { SidenavComponent } from '@app/components/components/sidenav/sidenav.component';
import { SidenavItemComponent } from '@app/components/components/sidenav-item/sidenav-item.component';
import { TopSectionComponent } from '@app/components/components/top-section/top-section.component';
import { TopControlsComponent } from '@app/components/components/top-controls/top-controls.component';
import { WevestrDirectivesModule } from '@app/directives/wevestr-directives.module';
import { BreadcrumbsComponent } from '@app/components/components/breadcrumbs/breadcrumbs.component';
import { GreetingComponent } from '@app/components/components/greeting/greeting.component';
import { ModeSelectorComponent } from '@app/components/components/mode-selector/mode-selector.component';
import { ChartSelectorComponent } from '@app/components/components/chart-selector/chart-selector.component';
import { DropdownComponent } from '@app/components/components/dropdown/dropdown.component';
import { AvatarComponent } from '@app/components/components/avatar/avatar.component';
import { PreviewPdfComponent } from '@app/components/components/preview-pdf/preview-pdf.component';
import { SidePreviewDocumentComponent } from '@app/components/containers/side-preview-document/side-preview-document.component';
import { SideCoverComponent } from '@app/components/components/side-cover/side-cover.component';
import { CoverWithSidecardComponent } from '@app/components/components/cover-with-sidecard/cover-with-sidecard.component';

import { WevestrPipesModule } from '@app/pipes/wevestr-pipes.module';
import { WevestrFormsModule } from '@app/forms/wevestr-forms.module';

const components = [
  PreviewDocumentComponent,
  LayoutComponent,
  SidenavComponent,
  SidenavItemComponent,
  TopSectionComponent,
  TopControlsComponent,
  BreadcrumbsComponent,
  GreetingComponent,
  ModeSelectorComponent,
  DropdownComponent,
  ChartSelectorComponent,
  AvatarComponent,
  PreviewPdfComponent,
  SidePreviewDocumentComponent,
  SideCoverComponent,
  CoverWithSidecardComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    WevestrDirectivesModule,
    RouterModule,
    WevestrPipesModule,
    WevestrFormsModule,
    NgxExtendedPdfViewerModule,
  ],
})
export class WevestrComponentsModule {}
