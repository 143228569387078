import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { isNil } from 'ramda';
import { ILegendOption } from '@app/shared/types/interfaces';
import { areOptionsTheSame } from '@app/shared/utils/helpers/chart.helpers';

@Component({
  selector: 'wevestr-categorized-select',
  templateUrl: './categorized-select.component.html',
  styleUrls: ['./categorized-select.component.scss'],
})
export class CategorizedSelectComponent {
  @Input() public placeholder: string;
  @Input() public id: string;
  @Input() public isMultiselect = false;
  @Input() public areCategoriesIndependentlySelectable = false;
  private _disabled: boolean;
  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() public set disabled(value: boolean) {
    this._disabled = value || this._options?.length > 0;
  }

  private _options: ILegendOption[];
  public get options(): ILegendOption[] {
    return this._options;
  }
  @Input() public set options(values: ILegendOption[]) {
    if (!this.isMultiselect && values?.length > 0) {
      this.selectedOption = values.find(
        (option) =>
          option.isSelected &&
          // TODO: get rid of this check after setLegendOptions is refactored
          (this.areCategoriesIndependentlySelectable ||
            (!this.areCategoriesIndependentlySelectable && !option.isCategory)),
      );
      // TODO: get rid of this check after setLegendOptions is refactored. Is need now for
      // selectedOption in categorized-select to be the same as selectedOption for line-chart
      // onInit for non-independentlySelectable categories, which are not visible and filtered in
      // line-chart series preparation
      this.selectedOption = isNil(this.selectedOption)
        ? values.find((option) => option.isVisible)
        : this.selectedOption;
    }

    this._options = values?.map((value, _, values) => {
      if (value.isCategory) {
        const categoryChildren = values.filter((option) => this.isChildOfCategory(option, value));
        const isCategoryPartiallySelected = this.isCategoryPartiallySelected(categoryChildren);
        const isCategorySelected = this.isCategorySelected(value, categoryChildren);

        return {
          ...value,
          isSelected: isCategorySelected,
          isPartiallySelected: isCategoryPartiallySelected,
          childrenNumber: categoryChildren.length,
          isVisible: true,
        };
      }

      return { ...value, isVisible: value.isVisible || !value.categoryId };
    });
  }

  @Output() private update = new EventEmitter();
  @Output() private select = new EventEmitter();

  @ViewChild('categorizedSelect', { static: true, read: ElementRef }) private categorizedSelect: ElementRef;

  public selectedOption: ILegendOption;
  public opened = false;
  public highlightIndex: number = null;
  private get highlightedOption(): ILegendOption {
    return this.options[this.highlightIndex];
  }

  @HostListener('document:click', ['$event'])
  public hide(event: MouseEvent): void {
    const isClickOutside = this.clickedOutsideMenu(event);
    this.opened = !this.disabled && this.opened && !isClickOutside;
  }

  private clickedOutsideMenu(event: MouseEvent): boolean {
    return event.composedPath().indexOf(this.categorizedSelect.nativeElement) === -1;
  }

  public toggleOpened(event?: Event): void {
    event?.stopPropagation();
    this.opened = !this.opened;
    if (!this.opened) {
      this.highlightIndex = null;
    }
  }

  public onSelectItem(selectedOption: ILegendOption): void {
    if (!this.isMultiselect) {
      this.selectOptionInSingleMode(selectedOption);
    } else {
      this.selectOptionInMultiMode(selectedOption);
    }
  }

  private selectOptionInSingleMode(selectedOption: ILegendOption): void {
    this.selectedOption = selectedOption;
    const updatedOptions = this.options.map((option) => ({
      ...option,
      isSelected: areOptionsTheSame(option, selectedOption),
    }));

    this.select.emit(selectedOption);
    this.update.emit(updatedOptions);
    this.toggleOpened();
  }

  private selectOptionInMultiMode(selectedOption: ILegendOption): void {
    const updatedSelectedOption = { ...selectedOption, isSelected: !selectedOption.isSelected };
    const optionsToUpdate = [updatedSelectedOption];

    if (selectedOption.categoryId) {
      const selectedOptionCategory = this.options.find((option) => this.isChildOfCategory(selectedOption, option));
      const categoryChildren = this.options.filter(
        (option) =>
          this.isChildOfCategory(option, selectedOptionCategory) && !areOptionsTheSame(selectedOption, option),
      );
      categoryChildren.push(updatedSelectedOption);

      const isCategoryPartiallySelected = this.isCategoryPartiallySelected(categoryChildren);
      const isCategorySelected = this.isCategorySelected(selectedOptionCategory, categoryChildren);

      const updatedCategory = {
        ...selectedOptionCategory,
        isSelected: isCategorySelected,
        isPartiallySelected: isCategoryPartiallySelected,
      };
      optionsToUpdate.push(updatedCategory);
    }

    this.updateWithOptions(optionsToUpdate);
  }

  private isCategorySelected(category: ILegendOption, categoryChildren: ILegendOption[]): boolean {
    const selectedChildrenNumber = categoryChildren.filter((option) => option.isSelected).length;
    return this.areCategoriesIndependentlySelectable
      ? category.isSelected
      : categoryChildren.length && selectedChildrenNumber === categoryChildren.length;
  }

  private isCategoryPartiallySelected(categoryChildren: ILegendOption[]): boolean {
    const selectedChildrenNumber = categoryChildren.filter((option) => option.isSelected).length;
    const isCategoryPartiallySelected = selectedChildrenNumber > 0 && selectedChildrenNumber < categoryChildren.length;
    const isCategorySelected = selectedChildrenNumber === categoryChildren.length;

    return isCategoryPartiallySelected || (this.areCategoriesIndependentlySelectable && isCategorySelected);
  }

  public onToggleCategory(category: ILegendOption, event?: Event): void {
    event?.stopPropagation();
    if (this.isMultiselect) {
      if (!this.areCategoriesIndependentlySelectable) {
        this.onToggleCategoryGroup(category);
      } else {
        this.onSelectItem(category);
      }
    } else {
      this.toggleExpandCategory(category);
    }
  }

  public onToggleCategoryGroup(category: ILegendOption): void {
    const toggledState = category.isPartiallySelected || !category.isSelected;
    const optionsToUpdate = this.options
      .filter((option) => this.isChildOfCategory(option, category))
      .map((option) => ({ ...option, isSelected: toggledState }));
    const updatedCategory = {
      ...category,
      isSelected: toggledState,
      isPartiallySelected: false,
    };
    optionsToUpdate.push(updatedCategory);

    this.updateWithOptions(optionsToUpdate);
  }

  private updateWithOptions(optionsToUpdate: ILegendOption[]): void {
    const updatedOptions = this.options.map(
      (option) => optionsToUpdate.find((updatedOption) => areOptionsTheSame(updatedOption, option)) || option,
    );

    this.update.emit(updatedOptions);
  }

  public toggleExpandCategory(category: ILegendOption, event?: Event): void {
    event?.stopPropagation();

    const optionsToUpdate: ILegendOption[] = this.options
      .filter((option) => this.isChildOfCategory(option, category))
      .map((option) => ({ ...option, isVisible: !category.isExpanded }));
    const updatedCategory = { ...category, isExpanded: !category.isExpanded };
    optionsToUpdate.push(updatedCategory);

    this.updateWithOptions(optionsToUpdate);
  }

  private isOptionVisible(option: ILegendOption): boolean {
    return option.isVisible;
  }

  private isChildOfCategory(option: ILegendOption, category: ILegendOption): boolean {
    return category.isCategory && option.categoryId === category.id;
  }

  private isHighlightedOptionVisible(): boolean {
    return this.isOptionVisible(this.highlightedOption);
  }

  private isExpandedCategory(option: ILegendOption): boolean {
    return option.isCategory && option.isExpanded;
  }

  private isUnexpandedCategory(option: ILegendOption): boolean {
    return option.isCategory && !option.isExpanded;
  }

  private isLastElementIndex(index: number, array: unknown[]): boolean {
    return index + 1 === array.length;
  }

  private isFirstElementIndex(index: number): boolean {
    return index === 0;
  }

  private preventDefaultIfOpened($event: KeyboardEvent, callback: () => void): void {
    if (this.opened) {
      $event?.preventDefault();
      callback();
    }
  }

  public handleGoUp($event?: KeyboardEvent): void {
    this.preventDefaultIfOpened($event, () => {
      if (isNil(this.highlightIndex)) {
        this.highlightIndex = 0;
      } else if (this.isFirstElementIndex(this.highlightIndex)) {
        this.highlightIndex = this.options.length - 1;
      } else {
        this.highlightIndex -= 1;
      }
      if (!this.isHighlightedOptionVisible()) {
        this.handleGoUp();
      }
    });
  }

  public handleGoDown($event?: KeyboardEvent): void {
    this.preventDefaultIfOpened($event, () => {
      if (isNil(this.highlightIndex)) {
        this.highlightIndex = 0;
      } else if (this.isLastElementIndex(this.highlightIndex, this.options)) {
        this.highlightIndex = 0;
      } else {
        this.highlightIndex += 1;
      }
      if (!this.isHighlightedOptionVisible()) {
        this.handleGoDown();
      }
    });
  }

  public handleRightArrowExpand($event?: KeyboardEvent): void {
    this.preventDefaultIfOpened($event, () => {
      const highlightedOption = this.highlightedOption;
      if (this.isUnexpandedCategory(highlightedOption)) {
        this.toggleExpandCategory(highlightedOption);
      } else if (this.isExpandedCategory(highlightedOption)) {
        this.handleGoDown();
      }
    });
  }

  public handleLeftArrowCollapse($event?: KeyboardEvent): void {
    this.preventDefaultIfOpened($event, () => {
      const highlightedOption = this.highlightedOption;
      if (this.isExpandedCategory(highlightedOption)) {
        this.toggleExpandCategory(highlightedOption);
      } else if (!highlightedOption.isCategory && highlightedOption.categoryId) {
        this.highlightIndex = this.options.findIndex((option) => this.isChildOfCategory(highlightedOption, option));
      }
    });
  }

  public handleSelectHighlighted(): void {
    const option = this.highlightedOption;
    this.onSelectItem(option);
  }
}
