import { FormControlStatus } from '@angular/forms';

// Reference: https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/#how-to-use-regex-to-validate-urls
export const URL_PATTERN_VALIDATOR =
  '^([a-zA-Z]+:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$'; // fragment locator

export const VALIDITY_STATUS: Record<string, FormControlStatus> = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  DISABLED: 'DISABLED',
  PENDING: 'PENDING',
};

export type VALIDITY_STATUS = FormControlStatus;

export const MIN_YEAR = 1900;
