<div class="flex-row">
  <div
    class="card full-width indicator flex-column padding-left-l padding-right-l"
    *ngFor="let indicator of indicators"
  >
    <div class="indicator__label" [class]="indicator.color ? 'app-body-medium-weight' : 'app-body'">
      {{ indicator.label }}
      <span
        *ngIf="indicator.tooltip"
        [matTooltip]="indicator.tooltip"
        [matTooltipClass]="['info-tooltip', 'margin-bottom-s']"
        matTooltipPosition="above"
        class="info-icon"
      >
        <span>i</span>
      </span>
    </div>
    <div class="indicator__value app-numbers-l-bold">
      {{ indicator.value | indicatorFormat }}
    </div>
  </div>
</div>
