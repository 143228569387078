<div
  class="radio-button-group cursor-pointer radio-button-group-size-{{ size }} margin-bottom-s"
  *ngFor="let option of options"
  (click)="setValue(option.id)"
>
  <input
    [id]="option.id"
    type="radio"
    class="radio-button-group-input margin-right-s"
    [checked]="option.id === value"
    [value]="option.id === value"
  />
  <label *ngIf="option.name" class="cursor-pointer radio-button-group-label">
    {{ option.name }}
  </label>
</div>
