<div #chartGrid>
  <apx-chart
    [series]="series"
    [colors]="colors"
    [chart]="chartOptions"
    [legend]="legendOptions"
    [dataLabels]="dataLabelsOptions"
    [plotOptions]="plotOptions"
    [grid]="gridOptions"
    [states]="statesOptions"
    [tooltip]="tooltipOptions"
  ></apx-chart>
</div>
