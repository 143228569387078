<div class="card-with-borders form padding-m" [formGroup]="form">
  <div class="checkbox-field inline-form-field flex-row is-justify-content-space-between">
    <div class="flex-row">
      <div class="checkbox-wrapper">
        <input
          type="checkbox"
          (change)="toggleChecked()"
          [checked]="termControl.isChecked"
          [formControl]="termControl.checkbox"
          [attr.data-id]="checkboxDataId"
        />
        <label class="app-body-bold">{{ title }}</label>
      </div>
      <div
        *ngIf="isEditNoteVisible && termControl.isChecked && !isNoteOpened"
        class="checkbox__edit-button"
        (click)="toggleNoteVisibility(true)"
        [ngClass]="{
          'checkbox__edit-button_invalid': termControl.note.invalid && termControl.note.touched,
          'checkbox__edit-button_valid': termControl.note.valid && termControl.note.touched
        }"
      >
        (Edit comment)
      </div>
    </div>

    <div class="info-icon"
         *ngIf="infoTooltip"
         [matTooltip]="infoTooltip"
         [matTooltipClass]="['info-tooltip', 'info-tooltip_top-alignment']"
         matTooltipPosition="right"
    >
      <span>i</span>
    </div>

    <wevestr-input
      *ngIf="includePercentNumber && termControl.isChecked"
      class="wevestr-input-with-addon percent-input app-numbers-font"
      [mask]="percentageMask"
      formControlName="percent"
      [showValidityImmediately]="showValidityImmediately"
      ngDefaultControl
    >
      <span class="addon addon_right addon-wrapper" addon>%</span>
      <ng-container errors>
        <div wvFormError="required" class="error-message app-body-small">
          {{ ERRORS.REQUIRED }}
        </div>
        <div wvFormError="nonPositive" class="error-message app-body-small">
          {{ ERRORS.POSITIVE }}
        </div>
        <div wvFormError="max" class="error-message app-body-small">
          {{ ERRORS.GREATER_THAN_LIMIT }}
        </div>
      </ng-container>
    </wevestr-input>
  </div>
  <div
    *ngIf="isNoteOpened"
    [ngClass]="{
      'invalid-form-field': termControl.note.invalid && termControl.note.touched,
      'valid-form-field': termControl.note.valid && termControl.note.touched
    }"
  >
    <div class="app-body-bold margin-top-m note">Note</div>
    <textarea
      class="basic-input margin-top-s input-comment"
      [formControl]="termControl.note"
      [placeholder]="notePlaceholder || 'Here you can add extra information as a note to this additional term or just leave it empty.'"
      [maxlength]="rightsMaxLength"
      data-id="Input-Note"
    ></textarea>
    <div class="error-message app-body-small-const" *ngIf="termControl.note.invalid && termControl.note.touched">
      <div *ngIf="termControl.note?.errors?.whitespace">{{ ERRORS.WHITESPACES }}</div>
    </div>
  </div>
</div>
