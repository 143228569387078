<div
  class="flex-column full-width position-relative is-justify-content-flex-end barchart__bar"
  #barChart
  [ngStyle]="{ height: isEmptyState ? 'auto' : chartHeight + 'px' }"
  (resized)="handleDivResize()"
>
  <div *ngIf="!isEmptyState" class="barchart__title" [ngStyle]="titleStyles">
    <div class="full-width flex-column is-justify-content-center is-align-items-center">
      <div
        #titleText
        class="app-body barchart__title__text margin-bottom-m"
        [ngStyle]="titleTextStyle"
        *ngIf="activeSliceIndex !== null && activeSliceIndex !== undefined; else chartTitle"
      >
        <span *ngIf="options.titleFormatter" class="font-weight-medium"> {{ activeValue.value?.title }}: </span>
        <span *ngIf="!isCurrency" class="app-numbers-medium">
          {{ activeValue.value?.amount | numberFormat: noDecimalMask }}
          <span class="app-body" *ngIf="activeValue.value?.type">
            {{ activeValue.value?.type }}
          </span>
          <span class="app-body">
            {{ options.sliceLabel }}
          </span>
          ({{ activeValue.percentage | numberFormat }}%)
        </span>
        <span *ngIf="isCurrency && activeValue.value && options.valueFormatter" class="app-numbers-medium-weight">
          {{ activeValue.value | callback : options.valueFormatter : activeValue.percentage }}
        </span>
        <span *ngIf="isCurrency && activeValue.value && !options.valueFormatter" class="app-numbers-medium-weight">
          <wevestr-currency [value]="activeValue.value?.amount"></wevestr-currency>
          <span>{{ ' (' + (activeValue.percentage | numberFormat) + '%)' }}</span>
        </span>
        <span *ngIf="isCurrency && !activeValue.value" class="app-numbers-medium-weight">0</span>
      </div>
      <ng-template #chartTitle>
        <div *ngIf="!isCurrency" class="app-body barchart__title__text margin-bottom-m">
          {{ options.titleLabel }}:
          <span class="app-numbers-medium-weight">{{ totalAmount | numberFormat: noDecimalMask }}</span>
        </div>
        <div *ngIf="isCurrency" class="app-body barchart__title__text margin-bottom-m">
          {{ options.titleLabel }}:
          <span class="app-numbers-medium-weight"><wevestr-currency [value]="totalAmount"></wevestr-currency></span>
        </div>
      </ng-template>
      <div class="barchart__marker" [ngStyle]="markerStyles"></div>
    </div>
  </div>
  <div class="flex-row" *ngIf="values && values.length !== 0; else emptyBarChart">
    <div
      class="
        flex-column
        is-justify-content-center is-align-items-center
        barchart__slice-label
        padding-left-s padding-right-s
      "
      [ngClass]="{ 'rounded-left-corners': i === 0, 'rounded-right-corners': i === values.length - 1 }"
      #slice
      id="chart-slice-{{ i }}-{{ id }}"
      *ngFor="let value of values; index as i"
      [ngStyle]="value | callback : getValueStyles : i"
      (mouseenter)="onSliceEntered(i, slice)"
      (mouseleave)="onSliceLeft()"
    >
      <div
        class="app-body-medium-weight barchart__slice-label__title"
        *ngIf="
          (!options.legended && options.titleFormatter && !options.barChartValuesHidden) ||
          (options.barChartValuesShownOnHover && activeSliceIndex === i)
        "
        [ngStyle]="{ 'font-size': options.sliceTitleFontSize + 'px' }"
      >
        {{ value.title | callback : options.titleFormatter }}
      </div>
      <div
        *ngIf="!options.barChartValuesHidden || (options.barChartValuesShownOnHover && activeSliceIndex === i)"
        class="app-body-medium-weight"
        [ngStyle]="{ 'font-size': options.sliceTitleFontSize + 'px' }"
      >
        {{
          options.valueFormatter ? (value | callback : options.valueFormatter : percentages[i]) : (percentages[i] | numberFormat) + '%'
        }}
      </div>
    </div>
  </div>
  <ng-template #emptyBarChart>
    <div
      class="
        flex
        full-width
        barchart__empty-state
        is-justify-content-center is-align-items-center
        rounded-left-corners rounded-right-corners
      "
      [ngStyle]="{ height: options.height + 'px' }"
    >
      {{ options.noValuesText }}
    </div>
  </ng-template>
</div>
<div class="flex-row justify-content-space-between legend margin-top-m" *ngIf="options.legended">
  <div
    *ngFor="let value of values; index as i"
    class="legend__item flex-row"
    (mouseenter)="onLegendEntered(i)"
    (mouseleave)="onLegendLeft()"
  >
    <div
      class="legend__circle"
      [ngStyle]="{
        'background-color': value.color,
        'width.px': options.legendCircleSize,
        'height.px': options.legendCircleSize,
        'margin-right.px': options.legendCircleMargin
      }"
    ></div>
    <div
      class="legend__title"
      [ngStyle]="{ 'max-width.px': barChart.offsetWidth / values.length - options.legendCircleSize - options.legendCircleMargin }"
    >
      {{ value.title | callback : options.titleFormatter}}
    </div>
  </div>
</div>
