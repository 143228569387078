import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';

import { AuthService, REFRESH_TOKEN } from '@app/auth/services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.auth.accessToken;

    if (this.shouldRefreshToken(token, request.url)) {
      return this.auth.refreshAccessToken(this.auth.refreshToken).pipe(
        switchMap(() => {
          request = this.setTokenHeader(request);
          return next.handle(request);
        }),
        catchError(() => {
          this.auth.removeTokens();
          this.router.navigate(loginPath);
          return EMPTY;
        }),
      );
    } else {
      if (this.auth.accessToken) {
        request = this.setTokenHeader(request);
      }

      return next.handle(request);
    }
  }

  private setTokenHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.accessToken}`,
      },
    });
  }

  private shouldRefreshToken(token: string, url: string): boolean {
    return (
      token &&
      this.auth.isTokenExpired(token) &&
      (localStorage.getItem(REFRESH_TOKEN) || sessionStorage.getItem(REFRESH_TOKEN)) &&
      !/^(.*)refresh/.test(url)
    );
  }
}
