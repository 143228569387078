<app-dialog title="Delete {{ entityName | titlecase }}" [closeOnEsc]="!confirmDeleteControl.dirty">
  <section class="modal-body">
    <ng-template #messageContainer></ng-template>

    <div class="app-body-bold margin-bottom-s margin-top-l-const">Type DELETE to confirm</div>
    <form class="modal-body__form form">
      <input type="text" name="userInput" ngDefaultControl [formControl]="confirmDeleteControl" autocomplete="off" />
    </form>
  </section>
  <section class="modal-footer">
    <button
      class="is-primary-button button base-button app-body"
      [disabled]="isButtonDisabled$ | async"
      (click)="handleConfirm()"
    >
      Confirm
    </button>
  </section>
</app-dialog>

<ng-template #defaultMessage>
  <div class="app-body medium-weight margin-bottom-m-const">Are you sure you want to delete this {{ entityName }}?</div>
  <div class="app-body">This action is permanent and can't be undone.</div>
</ng-template>
