import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';
import { IPhase } from '@app/types/interfaces/profile.interface';
import { DAY_MONTH_YEAR_DATE_FORMAT } from '@app/shared/utils/constants/date.constants';

interface IPhaseOrderErrorFormatParams {
  firstAvailableDateForPhase?: Date;
  lastAvailableDateForPhase?: Date;
  phaseContainingSelectedDate?: number;
  selectedPhaseId: number;
  phases: IPhase[];
}

@Pipe({
  name: 'phaseOrderErrorFormat',
})
export class PhaseOrderErrorFormatPipe implements PipeTransform {
  constructor(private dateFormatPipe: DateFormatPipe) {}

  public transform({
    firstAvailableDateForPhase,
    lastAvailableDateForPhase,
    phaseContainingSelectedDate,
    selectedPhaseId,
    phases,
  }: IPhaseOrderErrorFormatParams): string {
    if (firstAvailableDateForPhase) {
      const selectedPhase = phases.find((phase) => phase.id === selectedPhaseId);

      return `First available date for round ${selectedPhase.name} is ${this.dateFormatPipe.transform(
        firstAvailableDateForPhase,
        DAY_MONTH_YEAR_DATE_FORMAT,
      )}. Please select another date or round`;
    }

    if (lastAvailableDateForPhase) {
      const selectedPhase = phases.find((phase) => phase.id === selectedPhaseId);

      return `Last available date for round ${selectedPhase.name} is ${this.dateFormatPipe.transform(
        lastAvailableDateForPhase,
        DAY_MONTH_YEAR_DATE_FORMAT,
      )}. Please select another date or round`;
    }

    if (phaseContainingSelectedDate) {
      const phaseForSelectedDate = phases.find((phase) => phase.id === phaseContainingSelectedDate);

      return `The date is from ${phaseForSelectedDate.name} round. Please select another date or round`;
    }
  }
}
