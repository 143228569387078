<button
  *ngIf="editMode; else notEditMode"
  class="button secondary-button button__small"
  type="button"
  (click)="handleToggleEditMode()"
>
  <span class="app-body">View</span>
</button>
<ng-template #notEditMode>
  <button class="button primary-button control-button" (click)="handleToggleEditMode()">
    <div class="flex-row is-align-items-center">
      <mat-icon class="control-button__icon" svgIcon="edit"></mat-icon>
      <span class="app-body">Edit</span>
    </div>
  </button>
</ng-template>
