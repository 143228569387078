<div [style.height]="this.chartOptions.chart.height + 'px'">
  <apx-chart
    *ngIf="chartOptions"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [legend]="chartOptions.legend"
    [grid]="chartOptions.grid"
    [colors]="chartOptions.colors"
    [states]="chartOptions.states"
    [fill]="chartOptions.fill"
    [tooltip]="chartOptions.tooltip"
    [markers]="chartOptions.markers"
    [stroke]="chartOptions.stroke"
    [annotations]="chartOptions.annotations"
  ></apx-chart>
</div>
