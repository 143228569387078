import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@app/shared/types/classes';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  @Input() user: User;
  @Input() logoUrl: string;
  @Input() canToggleSidenav = true;

  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  public name: string;

  public ngOnInit(): void {
    const shareholder = this.user?.profile?.shareholders?.[0];
    this.name =
      shareholder?.displayName ||
      [shareholder?.firstName, shareholder?.lastName].filter(Boolean).join(' ').trim() ||
      'User';
  }

  handleToggleSidenav() {
    if (this.canToggleSidenav) {
      this.toggleSidenav.emit();
    }
  }
}
