<div class="explained-field">
  <div class="field" (focusin)="isInFocus = true" (focusout)="isInFocus = false">
    <ng-content></ng-content>
  </div>
  <wevestr-field-explainer
    [@openClosed]="isShownOnHover ? 'shownOnHover' : isOpen ? 'open' : isInFocus ? 'open' : 'closed'"
    class="explainer padding-left-m padding-bottom-m"
    [ngClass]="{'is-shown': isShownOnHover}"
    [title]="title"
    [description]="description"
    [extraDescription]="extraDescription"
    [boldDescription]="boldDescription"
  ></wevestr-field-explainer>
</div>
