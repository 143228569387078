<div class="document">
  <div
    class="flex-row is-align-items-center document_header"
    *ngIf="documentType !== DocumentType.PDF"
  >
    <mat-icon svgIcon="cross" class="margin-right-m cursor-pointer close-icon" (click)="handleClose()"></mat-icon>
    <mat-icon svgIcon="file_type_image" class="header_icon margin-right-xs"></mat-icon>
    <div class="app-h4-medium-weight header_title">{{ document.source.title }}</div>
  </div>
  <div class="document_content flex-column is-justify-content-center is-align-items-center">
    <ng-container *ngIf="documentSrc">
      <wevestr-preview-pdf
        *ngIf="documentType === DocumentType.PDF"
        class="document_content_item-doc"
        [documentURL]="documentSrc"
        [title]="document.source.title"
        (onClose)="handleClose()"
      ></wevestr-preview-pdf>
      <img
        *ngIf="documentType === DocumentType.IMAGE"
        class="document_content_item_img"
        [src]="documentSrc"
      />
    </ng-container>
  </div>
</div>
