import { Frequency } from '@app/shared/types/enums';
import { ICurrency } from '@app/shared/types/interfaces/currency.interface';
import { OnboardingStatus } from '@wevestr/bff-types/models/enums/onboardingStatus';

export interface ICompanySettings {
  id: number;
  currencyId?: number;
  currency?: ICurrency;
  dataEditMode: Frequency;
  isFrameworkSetup: boolean;
  isSinglePhasedSlicingPie: boolean;
  maxStakeholdersAmount?: number;
  onboardingStatus: OnboardingStatus;
}
