import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IStakeholder } from '@app/types/interfaces/stakeholder';
import { getDisplayName, isTheSameStakeholder } from '@app/shared/utils/helpers/stakeholder.helpers';

interface IExplainedSelectCopyright {
  multiselectLabel: string;
  placeholder: string;
  selectedLabel: string;
  descriptionLabel?: string;
  description?: string;
}

@Component({
  selector: 'wevestr-explained-multiselect',
  templateUrl: './explained-multiselect.component.html',
  styleUrls: ['./explained-multiselect.component.scss'],
})
export class ExplainedMultiselectComponent {
  @Input() public copyright: IExplainedSelectCopyright;
  @Input() public options: IStakeholder[];
  @Input() public control: AbstractControl;
  @Input() public required = true;
  @Input() public emptyOptionText: string;

  public isTheSameStakeholder = isTheSameStakeholder;

  @Output() public removeStakeholder = new EventEmitter<number>();

  public formatShareholderName = getDisplayName;
}
