import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

const PREFERED_FISRT_DAY_OF_WEEK = 'Monday';

@Injectable({
  providedIn: 'root',
})
export class WevestrDateAdapterService extends NativeDateAdapter {
  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(PLATFORM_ID) public platform: Platform) {
    super(locale, platform);
  }

  public getFirstDayOfWeek(): number {
    return this.getDayOfWeekNames('long').indexOf(PREFERED_FISRT_DAY_OF_WEEK);
  }
}
