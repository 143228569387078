<div class="radio-container flex-row is-justify-content-start is-align-items-center">
  <input
    #radioInput
    type="radio"
    class="radio-input {{theme}}"
    [id]="groupName + valueName"
    [value]="valueName"
    [name]="groupName"
    (change)="onInputChange()"
    [disabled]="disabled"
  />
  <ng-content></ng-content>
</div>
