<div class="flex-row">
  <div
    *ngFor="let control of digitsArray.controls; index as index"
    class="digit-container flex-row is-align-items-center margin-right-s"
  >
    <input
      class="digit app-h4-medium-weight"
      id="{{ DIGIT_ID_PREFIX }}{{ index }}"
      mask="0"
      [formControl]="control"
      (keyup)="handleKeyUp($event)"
      (keydown)="handleKeyDown($event)"
      (focus)="focusedDigitIndex = index"
    />
    <div *ngIf="index === middleVisualSeparatorIndex" class="margin-left-s separator"></div>
  </div>
</div>
