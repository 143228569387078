<div class="flex-column is-align-items-center full-height">
  <h3 class="app-h3-medium-weight padding-bottom-m">Invitation Accepted</h3>
  <p class="app-body text">
    This invitation is already accepted and you are ready to start using the WE.VESTR Platform. Please feel free to
    contact us anytime when you have questions! We are happy to help you.
  </p>
  <img src="assets/images/thumb-up.svg" class="icon" />
  <app-button
    class="login-button"
    type="primary"
    [routerLink]="loginPath"
    [loading]="isHTTPLoading"
    [disabled]="isHTTPLoading"
    >Go to Login</app-button
  >
</div>
