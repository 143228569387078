<div class="full-height welcome-container flex-column is-justify-content-center is-align-items-center">
  <div class="app-h4-medium-weight">Welcome to</div>
  <img class="welcome-container__logo" src="assets/images/logo.svg"/>
  <div class="margin-top-xl app-h5-medium-weight">Log-in Below to Start Simplifying Your Equity</div>
  <div class="margin-top-m margin-bottom-l app-body">
    After clicking below, you will receive your temporary log-in credentials.
  </div>
  <div class="margin-top-l button-container">
    <app-button type="primary" (onClick)="startDemo()" [disabled]="isStartButtonDisabled">
      <span class="padding-left-m padding-right-m">Take Me to the Demo!</span>
    </app-button>
  </div>
</div>

