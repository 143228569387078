import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { POST_MONEY_ERROR, REQUIRED_ERROR } from '@app/forms/constants';
import { manageSharesFieldDetails, FIRST_VALUATION_DISABLED_TOOLTIP } from '@app/manage-shares/constants';
import { displayBasicItemName, enumToObject } from '@app/shared/utils/helpers/common.helpers';
import { ValuationTiming } from '@app/shareholder-management/types/enums/valuation-type.enum';
import { MasksService } from '@app/shared/services/masks.service';
import { ControlNames } from '@app/shareholder-management/types/enums/issue-shares.enum';

interface ValuationBasicForm {
  [ControlNames.VALUATION_AMOUNT]: FormControl<number | null>;
  [ControlNames.VALUATION_TIMING]: FormControl<{ name: ValuationTiming } | null>;
  [otherProps: string]: AbstractControl;
}

// TODO: refactor this component to get rid of addonInput in wevestr-input,
// after that delete form common styles for .wevestr-input-with-addon if not needed
@Component({
  selector: 'wevestr-valuation-attached-to-timing-input',
  templateUrl: './valuation-attached-to-timing-input.component.html',
  styleUrls: ['./valuation-attached-to-timing-input.component.scss'],
})
export class ValuationAttachedToTimingInputComponent implements OnInit {
  @Input() public form: FormGroup<ValuationBasicForm>;
  @Input() public customError: string;
  @Input() public isRequired = false;

  public valuationMask: string;
  public valuation: AbstractControl;
  public valuationTiming: AbstractControl;
  public displayFnForEnum = displayBasicItemName;
  public valuationTimingOptions = enumToObject(ValuationTiming);
  public readonly FIELD_DETAILS = manageSharesFieldDetails;
  public valuationTooltip = FIRST_VALUATION_DISABLED_TOOLTIP;

  public readonly ERRORS = {
    REQUIRED: REQUIRED_ERROR,
    POST_MONEY: POST_MONEY_ERROR,
  };

  constructor(private masksService: MasksService) {}

  public ngOnInit(): void {
    this.valuationMask = this.masksService.cashMask();
    this.valuation = this.form.get(ControlNames.VALUATION_AMOUNT);
    this.valuationTiming = this.form.get(ControlNames.VALUATION_TIMING);
  }

  public handleResetOption(): void {
    this.valuationTiming.setValue(null);
  }
}
