export enum Frequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export const FREQUENCY_TO_MONTH = new Map<Frequency, number>([
  [Frequency.MONTHLY, 1],
  [Frequency.QUARTERLY, 3],
  [Frequency.YEARLY, 12],
]);
