import { IBarchartValue } from '@app/shared/types/interfaces/barchart-value.interface';

export interface IBarchartOptions {
  height?: number;
  titleMarkerMargin?: number;
  titleLabel?: string;
  sliceLabel?: string;
  noValuesText?: string;
  sliceTitleFontSize?: number;
  legended?: boolean;
  legendCircleSize?: number;
  legendCircleMargin?: number;
  titleFormatter?: (string: string) => string;
  valueFormatter?: (value: IBarchartValue, percentage: number) => string;
  isCurrency?: boolean;
  barChartValuesHidden?: boolean;
  barChartValuesShownOnHover?: boolean;
}
