import { Injectable } from '@angular/core';
import { ConfigService } from '@app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface IEmailChangeRequestState {
  isFinished: boolean;
  newEmail?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailChangeService {
  readonly baseUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.baseUrl = `${this.config.getFullBaseUrl()}/auth/email-change-request`;
  }

  approveEmail(token: string, isOldEmailApproval: boolean): Observable<IEmailChangeRequestState> {
    return this.http.patch<IEmailChangeRequestState>(this.baseUrl, { token, isOldEmailApproval });
  }
}
