import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { EMAIL_CHANGE_ROUTES } from '@app/auth/types/enums/email-change-routes.enum';
import { EmailChangeService, IEmailChangeRequestState } from '@app/auth/services/email-change.service';
import { catchError, map, startWith } from 'rxjs/operators';

interface IEmailChangeCopy {
  title: string;
  message?: string;
  messages?: string[];
  isVerificationNeeded: boolean;
}

@Component({
  selector: 'app-email-change-approval',
  templateUrl: './email-change-approval.component.html',
  styleUrls: ['./email-change-approval.component.scss'],
})
export class EmailChangeApprovalComponent implements OnInit {
  public verifyEmailCopy$: Observable<IEmailChangeCopy>;

  private readonly FINISHED_COPY: IEmailChangeCopy = {
    title: 'Email change approved!',
    message: 'You can login with your new credentials!',
    isVerificationNeeded: false,
  };

  private readonly INVALID_REQUEST_COPY: IEmailChangeCopy = {
    title: 'Invalid(expired) link',
    message: 'However you can login with your credentials!',
    isVerificationNeeded: false,
  };

  private readonly OLD_APPROVED_COPY: IEmailChangeCopy = {
    title: 'Already approved!',
    message: 'Please check your new email address',
    isVerificationNeeded: true,
  };

  private readonly DEFAULT_COPY: IEmailChangeCopy = {
    title: 'Please wait a moment.',
    message: '',
    isVerificationNeeded: false,
  };

  private readonly VERIFICATION_ERRORS = {
    oldAlreadyApproved: 'Old email is already approved',
    newAlreadyApproved: 'New email is already approved',
    bothAlreadyApproved: 'Email change is already approved',
  };

  constructor(private router: Router, private route: ActivatedRoute, private emailChangeService: EmailChangeService) {}

  public ngOnInit(): void {
    const isOldEmailApproval = this.isOldEmailApproval(this.router.url),
      token = this.route.snapshot.queryParams.token;

    this.verifyEmailCopy$ = this.sendVerificationRequest(token, isOldEmailApproval).pipe(
      map((changeRequestState) => this.getVerificationCopyByState(changeRequestState)),
      startWith(this.DEFAULT_COPY),
      catchError((response) => this.handleVerificationRequestError(response)),
    );
  }

  private isOldEmailApproval(url: string): boolean {
    return url.indexOf(EMAIL_CHANGE_ROUTES.old) > -1;
  }

  private sendVerificationRequest(token: string, isOldEmailApproval: boolean): Observable<IEmailChangeRequestState> {
    return this.emailChangeService.approveEmail(token, isOldEmailApproval);
  }

  private getVerificationCopyByState(changeRequestState: IEmailChangeRequestState): IEmailChangeCopy {
    return changeRequestState.isFinished
      ? this.FINISHED_COPY
      : this.getApproveNewEmailCopy(changeRequestState.newEmail);
  }

  private handleVerificationRequestError(response: HttpErrorResponse): Observable<IEmailChangeCopy> {
    let copy;
    switch (response.error.message) {
      case this.VERIFICATION_ERRORS.newAlreadyApproved:
        copy = of(this.FINISHED_COPY);
        break;
      case this.VERIFICATION_ERRORS.oldAlreadyApproved:
        copy = of(this.OLD_APPROVED_COPY);
        break;
      case this.VERIFICATION_ERRORS.bothAlreadyApproved:
        copy = of(this.FINISHED_COPY);
        break;
      default:
        copy = of(this.INVALID_REQUEST_COPY);
        break;
    }

    return copy;
  }

  private getApproveNewEmailCopy(newEmail: string): IEmailChangeCopy {
    return {
      title: 'Please confirm new email',
      message: '',
      messages: [
        `We have sent you a verification email to ${newEmail}.`,
        'Please go to your inbox and click the verification link.',
      ],
      isVerificationNeeded: true,
    };
  }
}
