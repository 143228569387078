import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumb } from '@app/shared/types/interfaces/breadcrumb.interface';
import { INavOption } from '@app/shared/types/interfaces';
import { SettingsRouteNames } from '@app/settings/types/enums';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private _breadcrumbs$ = new BehaviorSubject<IBreadcrumb[]>([]);
  static readonly navOptions: INavOption[] = [
    {
      name: 'User Settings',
      routerLink: `${CompanyRouteNames.COMPANY}/settings/${SettingsRouteNames.PERSONAL_SETTINGS}`,
    },
    {
      name: 'Company Settings',
      routerLink: `${CompanyRouteNames.COMPANY}/settings/${SettingsRouteNames.COMPANY_SETTINGS}`,
    },
  ];

  constructor() {}

  public get breadcrumbs$(): Observable<IBreadcrumb[]> {
    return this._breadcrumbs$.asObservable();
  }

  public push(breadcrumb: IBreadcrumb): void {
    this._breadcrumbs$.next([...this._breadcrumbs$.value, breadcrumb]);
  }

  public pop(): void {
    const breadcrumbs: IBreadcrumb[] = this._breadcrumbs$.value;
    breadcrumbs.pop();
    this._breadcrumbs$.next(breadcrumbs);
  }

  public init(breadcrumb: IBreadcrumb): void {
    this._breadcrumbs$.next([breadcrumb]);
  }

  public erase(): void {
    this._breadcrumbs$.next([]);
  }
}
