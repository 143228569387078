import { Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexOptions, XAxisAnnotations } from 'ng-apexcharts';
import { head, last, isEmpty, gt, groupBy } from 'ramda';
import { identity, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { addDays, differenceInCalendarDays, differenceInMilliseconds } from 'date-fns';
import { toUtcDate, formatDateStringToTimestamp } from '@app/shared/utils/helpers/dates.helpers';

import {
  ANNOTATION_LABELS,
  STATIC_CLIFF_PERIOD_SERIES_OPTIONS,
  STATIC_VESTED_SERIES_OPTIONS,
  STATIC_VESTING_STEP_LINE_CHART_OPTIONS,
  STATIC_X_AXIS_ANNOTATION_OPTIONS,
  GRANT_DATE_LABEL_PERCENTAGE,
  DEFAULT_X_AXIS_LABEL_GRID_ARRAY,
  GRANT_DATE_ANNOTATION_APPROXIMATION,
  FIRST_VESTING_DATE_ANNOTATION_APPROXIMATION,
  LAST_VESTING_DATE_ANNOTATION_APPROXIMATION,
  NUMBER_OF_DUMMY_POINTS,
  MIN_OF_VESTING_POINTS,
  GRANT_DATE_ANNOTATION_WIDTH,
  FIRST_VESTING_DATE_ANNOTATION_WIDTH,
  LAST_VESTING_DATE_ANNOTATION_WIDTH,
  SECOND_LINE_ANNOTATION_Y_OFFSET,
  STATIC_REVERSE_UNCONDITIONAL_VESTED_SERIES_OPTIONS,
} from '@app/shared/components/vesting-schedule-chart/vesting-schedule-chart.constants';
import { NumberFormatPipe } from '@app/shared/pipes';
import { DAY_FULL_MONTH_YEAR_DATE_FORMAT } from '@app/shared/utils/constants/date.constants';
import {
  IVestingEvent,
  XAxisLabelOptions,
  XAxisLabelPosition,
  VestingDirectionsType,
  VestingDirections,
} from '@app/esop/plan-management/types';
import { HUNDRED_PERCENT } from '@app/shared/utils/constants/common.constants';

type VestingScheduleTiming = 'current' | 'projected';

@Component({
  selector: 'wevestr-vesting-schedule-chart',
  templateUrl: './vesting-schedule-chart.component.html',
  styleUrls: ['./vesting-schedule-chart.component.scss'],
})
export class VestingScheduleChartComponent implements OnChanges {
  @ViewChild('chartGrid')
  public apxChart: ElementRef;

  @Input() set vestingSchedule(vestingSchedule: IVestingEvent[]) {
    if (vestingSchedule) {
      this.chartSeries = vestingSchedule.reduce(
        (chartPoints, { allocation, vestingDate }) => [
          ...chartPoints,
          {
            vestingDate: toUtcDate(vestingDate),
            allocation: this.getTotalAllocationForEvent(allocation, last(chartPoints)?.allocation),
          },
        ],
        [],
      );
    }
  }
  @Input() public set grantDate(grantDate: string | Date) {
    if (grantDate) {
      this._grantDate = toUtcDate(grantDate);
    }
  }

  @Input() public vestingDirection: VestingDirectionsType;

  @Input() public isFutureProjected = false;
  @Input() public chartHeight?: number;

  public chartOptions: Partial<ApexOptions> = STATIC_VESTING_STEP_LINE_CHART_OPTIONS;
  public chartSeries: IVestingEvent[] = [];
  public chartSeriesExtended: IVestingEvent[] = [];
  public _grantDate: Date;
  public xAxisLabels$: Observable<XAxisLabelOptions>;
  public chartInited = false;

  private readonly xAxisUpdates$ = new ReplaySubject<void>();

  private chartWidth: number;

  @HostListener('window:resize')
  public onResize(): void {
    this.setChartWidthPxAndRedraw();
  }

  constructor(private numberFormatPipe: NumberFormatPipe) {
    this.setXAxisLabels$();
  }

  public ngOnChanges(): void {
    if (this._grantDate && this.chartSeries) {
      this.initChartOptions();
    }
  }

  private setChartWidthPxAndRedraw(): void {
    if (this.apxChart) {
      this.chartWidth = this.apxChart.nativeElement.offsetWidth;
      this.initXaxisOptions();
      this.initAnnotationOptions();
      this.initTooltipOptions();
      this.xAxisUpdates$.next();
    }
  }

  private initChartOptions(): void {
    if (!isEmpty(this.chartSeries)) {
      this.chartSeriesExtended = this.addDummyVesting(this.chartSeries);

      const points: [number, number][] = this.chartSeriesExtended.map(({ allocation, vestingDate }) => [
        vestingDate.getTime(),
        allocation,
      ]);

      const { current, projected } = groupBy(this.vestingScheduleComporator.bind(this), points);

      const series = this.getSeries(current, projected);
      this.chartOptions.series = series;

      if (this.chartHeight) {
        this.chartOptions.chart.height = this.chartHeight;
      }

      if (!this.chartInited) {
        this.chartOptions.chart.events = {
          mounted: () => {
            if (!this.chartInited) {
              this.setChartWidthPxAndRedraw();
              this.chartInited = true;
            }
          },
        };
      } else {
        this.setChartWidthPxAndRedraw();
      }
    }
  }

  private vestingScheduleComporator([timestamp]: [number, number]): VestingScheduleTiming {
    if (this.isFutureProjected) {
      return timestamp < Date.now() ? 'current' : 'projected';
    }
    return 'current';
  }

  private addDummyVesting(chartSeries: IVestingEvent[]): IVestingEvent[] {
    if (isEmpty(chartSeries)) {
      return [];
    }
    const numberOfVestings = chartSeries.length;
    const dateToSubtract =
      numberOfVestings > NUMBER_OF_DUMMY_POINTS
        ? chartSeries[numberOfVestings - MIN_OF_VESTING_POINTS].vestingDate
        : this._grantDate;
    const lastVesting = last(chartSeries);
    const lastDate = addDays(
      lastVesting.vestingDate,
      differenceInCalendarDays(lastVesting.vestingDate, dateToSubtract),
    );

    return [
      ...chartSeries,
      {
        vestingDate: lastDate,
        allocation: lastVesting.allocation,
      },
    ];
  }

  private getSeries(allocations: [number, number][], futureAllocations?: [number, number][]): ApexOptions['series'] {
    const series =
      this.vestingDirection === VestingDirections.REVERSE
        ? this.getReverseVestingSeries(allocations, futureAllocations)
        : this.getForwardVestingSeries(allocations, futureAllocations);
    return [...series].filter(identity);
  }

  private getForwardVestingSeries(
    allocations: [number, number][],
    futureAllocations?: [number, number][],
  ): ApexAxisChartSeries {
    const grantDateTimestamp = this._grantDate.getTime();
    const firstVestingTimestamp = head(this.chartSeries).vestingDate.getTime();
    const lastVestedPoint =
      allocations && futureAllocations && this.getLastVestedPoint(last(allocations), head(futureAllocations));
    const vestedSeries = allocations ? this.addFirstPointToAllocations(allocations, grantDateTimestamp) : [];

    return [
      futureAllocations && {
        data: futureAllocations,
        type: 'area',
        color: '#E4E6EE',
        name: 'Unvested',
      },
      {
        ...STATIC_VESTED_SERIES_OPTIONS,
        data: lastVestedPoint ? [...vestedSeries, lastVestedPoint] : vestedSeries,
        type: futureAllocations ? 'area' : 'line',
      },
      {
        ...STATIC_CLIFF_PERIOD_SERIES_OPTIONS,
        data: [
          [grantDateTimestamp, 0],
          [firstVestingTimestamp, 0],
        ],
      },
    ];
  }

  private getReverseVestingSeries(
    allocations: [number, number][],
    futureAllocations?: [number, number][],
  ): ApexAxisChartSeries {
    const grantDateTimestamp = this._grantDate.getTime();
    const firstVestingTimestamp = head(this.chartSeries).vestingDate.getTime();
    const vestedOptions: [number, number][] = [[grantDateTimestamp, 0], ...this.getVestedSeriesForReverse()];

    const lastVestedPoint =
      allocations && futureAllocations && this.getLastVestedPoint(last(allocations), head(futureAllocations));

    const unconditionalVestedSeries = allocations
      ? this.addFirstPointToAllocations(allocations, grantDateTimestamp)
      : [];
    return [
      futureAllocations && {
        data: futureAllocations,
        color: '#E4E6EE',
        name: 'Unvested',
        type: 'area',
      },
      {
        ...STATIC_REVERSE_UNCONDITIONAL_VESTED_SERIES_OPTIONS,
        type: futureAllocations ? 'area' : 'line',
        data: lastVestedPoint ? [...unconditionalVestedSeries, lastVestedPoint] : unconditionalVestedSeries,
      },
      {
        ...STATIC_VESTED_SERIES_OPTIONS,
        data: vestedOptions,
      },
      {
        ...STATIC_CLIFF_PERIOD_SERIES_OPTIONS,
        data: [
          [grantDateTimestamp, 0],
          [firstVestingTimestamp, 0],
        ],
      },
    ];
  }

  private addFirstPointToAllocations(allocations: [number, number][], firstTimestamp: number): [number, number][] {
    return [[firstTimestamp, 0], ...allocations];
  }

  private getLastVestedPoint(lastVested: number[], firstUnvested: number[]): [number, number] {
    if (!lastVested || !firstUnvested) {
      return;
    }
    const [_, lastVestedValue] = lastVested;
    const [firstUnvestedTimestamp] = firstUnvested;

    return [firstUnvestedTimestamp, lastVestedValue];
  }

  private getVestedSeriesForReverse(): [number, number][] {
    return this.chartSeriesExtended.map(({ vestingDate }) => [vestingDate.getTime(), HUNDRED_PERCENT]);
  }

  private setXAxisLabels$(): void {
    this.xAxisLabels$ = this.xAxisUpdates$.pipe(
      map(() => this.getXAxisLabelGridArray()),
      map(this.convertGridArrayToGridTemplateColumnsString),
      map((gridValue) => this.getXAxisLabelOptions(gridValue)),
    );
  }

  private getXAxisLabelGridArray(): number[] {
    const positionValues: XAxisLabelPosition = {
      grantDatePosition: this._grantDate.getTime(),
      firstVestingDatePosition: this.getFirstVestingDatePosition(),
      lastVestingDatePosition: this.getLastVestingDatePosition(),
      lastDatePosition: this.getLastDatePosition(),
    };

    if (!positionValues.lastVestingDatePosition) {
      return DEFAULT_X_AXIS_LABEL_GRID_ARRAY;
    }

    const firstVestingPercentage = this.getFirstVestingDatePercentage(positionValues);
    const lastVestingPercentage = this.getLastVestingDatePercentage(positionValues, firstVestingPercentage);

    return [GRANT_DATE_LABEL_PERCENTAGE, firstVestingPercentage, lastVestingPercentage];
  }

  private getXAxisLabelOptions(gridValue: string): XAxisLabelOptions {
    return {
      gridValue,
      firstVestingDateLabel: this.getFirstVestingDateLabel(),
      lastVestingDateLabel: this.getLastVestingDateLabel(),
    };
  }

  private getFirstVestingDateLabel(): Date {
    return this.chartSeries?.length > MIN_OF_VESTING_POINTS ? this.getFirstVestingDate() : undefined;
  }

  private getLastVestingDateLabel(): Date {
    return !isEmpty(this.chartSeries) ? last(this.chartSeries).vestingDate : undefined;
  }

  private getFirstVestingDate(): Date {
    return head(this.chartSeries).vestingDate;
  }

  private convertGridArrayToGridTemplateColumnsString(gridArray: number[]): string {
    return gridArray.reduce((result, newPercentage) => `${result} ${newPercentage}%`, '');
  }

  private getFirstVestingDatePosition(): number {
    return this.chartSeries?.length > MIN_OF_VESTING_POINTS
      ? differenceInMilliseconds(this.getFirstVestingDate(), this._grantDate)
      : 0;
  }

  private getLastVestingDatePosition(): number {
    return !isEmpty(this.chartSeries)
      ? differenceInMilliseconds(last(this.chartSeries).vestingDate, this._grantDate)
      : 0;
  }

  private getLastDatePosition(): number {
    return !isEmpty(this.chartSeries)
      ? differenceInMilliseconds(last(this.chartSeriesExtended).vestingDate, this._grantDate)
      : 0;
  }

  private getFirstVestingDatePercentage(positionValues: XAxisLabelPosition): number {
    return positionValues.firstVestingDatePosition
      ? Math.round(
          (positionValues.firstVestingDatePosition * (HUNDRED_PERCENT - GRANT_DATE_LABEL_PERCENTAGE)) /
            positionValues.lastDatePosition,
        )
      : 0;
  }

  private getLastVestingDatePercentage(positionValues: XAxisLabelPosition, firstVestingDatePercentage: number): number {
    return positionValues.lastVestingDatePosition
      ? Math.round(
          (positionValues.lastVestingDatePosition *
            (HUNDRED_PERCENT - GRANT_DATE_LABEL_PERCENTAGE - firstVestingDatePercentage)) /
            positionValues.lastDatePosition,
        )
      : 0;
  }

  private initXaxisOptions(): void {
    this.chartOptions.xaxis = {
      ...this.chartOptions.xaxis,
      range: this.xAxisRange,
      categories: [
        this._grantDate.getTime(),
        ...this.chartSeriesExtended.map(({ vestingDate }) => vestingDate.getTime()),
      ],
    };
  }

  private initAnnotationOptions(): void {
    this.chartOptions.annotations = {
      ...this.chartOptions.annotations,
      xaxis: [
        this.getXaxisAnnotationOptions(
          this._grantDate,
          ANNOTATION_LABELS.GRANT_DATE,
          this.getGrantDateAnnotationOffsetX(),
          this.getGrantDateAnnotationOffsetY(),
        ),
        ...this.getVestingAnnotations(),
      ],
    };
  }

  private getGrantDateAnnotationOffsetY(): number {
    if (this.chartSeriesExtended.length <= MIN_OF_VESTING_POINTS) {
      return 0;
    }

    const grantDateTimestamp = formatDateStringToTimestamp(this._grantDate);
    const firstVestingTimestampDiff =
      formatDateStringToTimestamp(head(this.chartSeries).vestingDate) - grantDateTimestamp;
    const chartWidthTimestamp = formatDateStringToTimestamp(last(this.chartSeries).vestingDate) - grantDateTimestamp;
    const firstVestingDatePositionPx = Math.round((firstVestingTimestampDiff * this.chartWidth) / chartWidthTimestamp);

    const minOneLineAnnotationsDiff =
      GRANT_DATE_ANNOTATION_WIDTH / 2 + FIRST_VESTING_DATE_ANNOTATION_WIDTH / 2 + GRANT_DATE_ANNOTATION_APPROXIMATION;

    return gt(firstVestingDatePositionPx, minOneLineAnnotationsDiff) ? 0 : SECOND_LINE_ANNOTATION_Y_OFFSET;
  }

  private getGrantDateAnnotationOffsetX(): number {
    return isEmpty(this.chartSeriesExtended) ? 0 : GRANT_DATE_ANNOTATION_APPROXIMATION;
  }

  private getFirstVestingDateAnnotationOffsetX(): number {
    if (!this.chartWidth) {
      return 0;
    }

    const grantDate = this._grantDate;
    const firstVestingDate = head(this.chartSeries).vestingDate;
    const grantDateTimestamp = formatDateStringToTimestamp(grantDate);
    const firstVestingDateTimestamp = formatDateStringToTimestamp(firstVestingDate);
    const dummyLastVestingDate = formatDateStringToTimestamp(last(this.chartSeriesExtended).vestingDate);
    const chartWidthTimestamp = dummyLastVestingDate - grantDateTimestamp;
    const firstVestingDatePositionPx = Math.round(
      ((firstVestingDateTimestamp - grantDateTimestamp) * this.chartWidth) / chartWidthTimestamp,
    );

    if (firstVestingDatePositionPx < FIRST_VESTING_DATE_ANNOTATION_APPROXIMATION) {
      return FIRST_VESTING_DATE_ANNOTATION_APPROXIMATION - firstVestingDatePositionPx;
    }
    return 0;
  }

  private getLastVestingDateAnnotationOffsetX(): number {
    if (!this.chartWidth) {
      return 0;
    }
    const grantDate = this._grantDate;
    const lastVestingDate = last(this.chartSeries).vestingDate;
    const grantDateTimestamp = formatDateStringToTimestamp(grantDate);
    const lastVestingDateTimestamp = formatDateStringToTimestamp(lastVestingDate);
    const dummyLastVestingDate = formatDateStringToTimestamp(last(this.chartSeriesExtended).vestingDate);
    const chartWidthTimestamp = dummyLastVestingDate - grantDateTimestamp;
    const lastVestingDatePositionPx = Math.round(
      ((lastVestingDateTimestamp - grantDateTimestamp) * this.chartWidth) / chartWidthTimestamp,
    );

    const lastVestingDateDistanceToRightBorder = this.chartWidth - lastVestingDatePositionPx;

    if (lastVestingDateDistanceToRightBorder < LAST_VESTING_DATE_ANNOTATION_APPROXIMATION) {
      return -(LAST_VESTING_DATE_ANNOTATION_APPROXIMATION - lastVestingDateDistanceToRightBorder);
    }
    return 0;
  }

  private getVestingAnnotations(): Partial<XAxisAnnotations>[] {
    if (isEmpty(this.chartSeries)) {
      return [];
    }

    if (
      this.chartSeriesExtended.length === MIN_OF_VESTING_POINTS ||
      this.vestingDirection === VestingDirections.REVERSE
    ) {
      return [
        this.getXaxisAnnotationOptions(
          head(this.chartSeriesExtended).vestingDate,
          ANNOTATION_LABELS.SINGLE_VESTING,
          this.getFirstVestingDateAnnotationOffsetX(),
        ),
      ];
    }

    return [
      this.getXaxisAnnotationOptions(
        head(this.chartSeriesExtended).vestingDate,
        ANNOTATION_LABELS.FIRST_VESTING,
        this.getFirstVestingDateAnnotationOffsetX(),
      ),
      this.getXaxisAnnotationOptions(
        last(this.chartSeries).vestingDate,
        ANNOTATION_LABELS.LAST_VESTING,
        this.getLastVestingDateAnnotationOffsetX(),
        this.getLastVestingDateAnnotationOffsetY(),
      ),
    ];
  }

  private getDiffBetweenFirstAndLastVestingDates(firstVestingDateTimestamp: number): number {
    return formatDateStringToTimestamp(last(this.chartSeries).vestingDate) - firstVestingDateTimestamp;
  }

  private getLastVestingDateAnnotationOffsetY(): number {
    const grantDateTimestamp = formatDateStringToTimestamp(this._grantDate);

    const firstVestingDateTimestamp = formatDateStringToTimestamp(head(this.chartSeries).vestingDate);
    const diffBetweenFirstAndLastVestingDates = this.getDiffBetweenFirstAndLastVestingDates(firstVestingDateTimestamp);
    const chartWidthTimestamp = formatDateStringToTimestamp(last(this.chartSeries).vestingDate) - grantDateTimestamp;

    const diffBetweenFirstAndLastVestingDatesPx = Math.round(
      (diffBetweenFirstAndLastVestingDates / chartWidthTimestamp) * this.chartWidth,
    );

    const minOneLineAnnotationsDiff = LAST_VESTING_DATE_ANNOTATION_WIDTH / 2 + FIRST_VESTING_DATE_ANNOTATION_WIDTH / 2;

    return gt(diffBetweenFirstAndLastVestingDatesPx, minOneLineAnnotationsDiff) ? 0 : SECOND_LINE_ANNOTATION_Y_OFFSET;
  }

  private initTooltipOptions(): void {
    this.chartOptions.tooltip = {
      ...this.chartOptions.tooltip,
      x: {
        show: true,
        format: DAY_FULL_MONTH_YEAR_DATE_FORMAT,
      },
      y: {
        formatter: (value: number) => (value !== undefined ? `${this.numberFormatPipe.transform(value)}%` : null),
      },
    };
  }

  private get xAxisRange(): number {
    if (isEmpty(this.chartSeries)) {
      return 0;
    }

    const max = last(this.chartSeriesExtended).vestingDate;
    const min = this._grantDate;

    return differenceInMilliseconds(max, min);
  }

  private getXaxisAnnotationOptions(
    dateToAnnotate: Date,
    text: string,
    offsetX = 0,
    offsetY = 0,
    cssClassName?: string,
  ): Partial<XAxisAnnotations> {
    const xAxisAnnotationOptions = {
      ...STATIC_X_AXIS_ANNOTATION_OPTIONS,
      label: {
        ...STATIC_X_AXIS_ANNOTATION_OPTIONS.label,
        text,
        offsetX,
        offsetY,
        style: {
          ...STATIC_X_AXIS_ANNOTATION_OPTIONS.label.style,
          cssClass: cssClassName,
        },
      },
      x: dateToAnnotate.getTime(),
    };
    return xAxisAnnotationOptions;
  }

  private getTotalAllocationForEvent(allocation: number, prevAllocationsSum = 0): number {
    return prevAllocationsSum + allocation;
  }
}
