import { IBarchartValue } from '@app/shared/types/interfaces';
import { ICountry } from '@app/types/interfaces/profile.interface';
import { IStakeholder } from '@app/types/interfaces/stakeholder';
import { PERSONAL_ROLES } from '@app/shared/utils/constants/stakeholder.contants';
import { IPhase } from '@app/types/interfaces/profile.interface';
import { ICompanyContributor } from '@app/framework/types/interfaces/company-contributor.interface';
import { ICompanyRole } from '@app/shared/types/interfaces/company-role.interface';
import { compareStringsInAlphabeticalOrderWithoutCase } from '@app/shared/utils/helpers/common.helpers';
import { CompanyRole } from '@wevestr/bff-types/enums/companyRole.enum';

export function getPercentageFromAmount(value: number, total: number): number {
  return total === 0 ? null : (value / Math.abs(total)) * 100;
}

export function getDisplayName({ displayName }: Partial<IStakeholder>): string {
  return displayName || '';
}

function getNameWithInitialsForIndividual({ firstName, lastName }: Partial<IStakeholder>): string {
  return lastName ? `${firstName[0]}. ${lastName}` : firstName;
}

export function getNameWithInitials({ organisationName, firstName, lastName }: Partial<IStakeholder>): string {
  return organisationName ? organisationName : getNameWithInitialsForIndividual({ firstName, lastName });
}

export function formatShareholderForBarchart(shareholder: IStakeholder, color: string): IBarchartValue {
  const title = getDisplayName(shareholder);

  const isPhasesPresent = shareholder.phases && shareholder.phases.length > 0;

  return {
    id: shareholder.id,
    title,
    amount: shareholder.sharesAmount,
    color,
    type: isPhasesPresent ? getPhasesSharesType(shareholder.phases) : '',
  };
}

function getPhasesSharesType(phases: IPhase[]): string {
  return phases.every((phase) => phase.sharesType.every((shareType) => shareType === phases[0].sharesType[0]))
    ? phases[0].sharesType[0]
    : '';
}

export function formatContributorForBarchart(contributor: ICompanyContributor, color: string): IBarchartValue {
  const title = getNameWithInitials(contributor.stakeholder);
  return {
    id: contributor.id,
    title,
    amount: contributor.contributionTotal,
    color,
  };
}

export const booleanYesNoFormatter = (value: boolean): string => (value ? 'Yes' : 'No');

export const getTotalSharesAmount = (items: (IStakeholder | IPhase)[]): number =>
  items.reduce((result: number, item: IStakeholder | IPhase) => {
    result += item.sharesAmount;

    return result;
  }, 0);

export const sharesFormatter = (sharesAmount: string | number, percentage: string | number): string =>
  sharesAmount ? `${sharesAmount} (${percentage}%)` : null;

export function isPersonalProfileRole(role: CompanyRole): boolean {
  return PERSONAL_ROLES.includes(role);
}

export function getCompanyRolesOptions(companyRoles: ICompanyRole[]): { value: CompanyRole; name: string }[] {
  // Sort company roles by name in ascending order
  const sortedCompanyRoles = companyRoles.sort((a, b) => a.name.localeCompare(b.name));
  return sortedCompanyRoles.map(({ name }) => getCompanyRoleOption(name));
}

export function getCompanyRoleOption(companyRole: CompanyRole): { value: CompanyRole; name: string } {
  return {
    value: companyRole,
    name: companyRole,
  };
}

export function getCountryValue(country: ICountry | number, countries: ICountry[]): ICountry {
  let countryValue;

  if (country) {
    let countryId: number;

    if (typeof country === 'number') {
      countryId = country;
    } else {
      countryId = country.id;
    }

    countryValue = countries.find((c: ICountry) => c.id === countryId);
  }

  return countryValue;
}

export function isTheSameStakeholder(shareholder1: IStakeholder, shareholder2: IStakeholder): boolean {
  return shareholder1.id === shareholder2.id;
}

export function sortByNameInAlphabeticalOrder(stakeholders: Partial<IStakeholder>[]): Partial<IStakeholder>[] {
  return [...stakeholders].sort((stakeholder1, stakeholder2) =>
    compareStringsInAlphabeticalOrderWithoutCase(getDisplayName(stakeholder1), getDisplayName(stakeholder2)),
  );
}
