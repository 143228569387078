import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ImportDataService {
  private _importingInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  get importingInProgress$(): Observable<boolean> {
    return this._importingInProgress$.asObservable();
  }
  /**@deprecated don't you fluppy dare to use syncrounous value */
  get importingInProgress() {
    return this._importingInProgress$.value;
  }

  setImportingInProgress(value: boolean) {
    this._importingInProgress$.next(value);
  }
}
