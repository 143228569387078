import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ApexOptions, ApexTooltip } from 'ng-apexcharts';

import { getValuesForViewType } from '@app/performance/helpers/dates.helpers';
import { ChartDisplayMode } from '@app/performance/types/enums';
import { IFinancialMetric } from '@app/performance/types/interfaces';
import { ChartTooltipComponent } from '@app/shared/components/chart-tooltip/chart-tooltip.component';
import { LineChartComponent } from '@app/shared/components/line-chart/line-chart.component';
import { Frequency } from '@app/shared/types/enums';
import { IChart, IColumnChartOptions, ITooltipDataLabelsPercents } from '@app/shared/types/interfaces';
import { getChartColorByIndex, shortifyYear } from '@app/shared/utils/helpers/chart.helpers';
import { calculateAllPercentages } from '@app/performance/helpers/performance.helpers';
import { CurrencySettingsService } from '@app/shared/services/currency-settings.service';
const CHART_HEIGHT = 270;

@Component({
  selector: 'app-financial-metric-chart',
  templateUrl: './financial-metric-chart.component.html',
  styleUrls: ['./financial-metric-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialMetricChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chartMode: ChartDisplayMode;
  @Input() financialMetrics: IFinancialMetric[];
  @Input() isGroupedByMetric = false;
  @Input() labels: string[];
  @Input() previousYearFinancialMetrics: IFinancialMetric[];
  @Input() title: string;
  @Input() viewType: Frequency;
  @Input() year: number;
  @Input() useLegendWithSelect = false;
  @Input() isEmptyState = false;

  @ViewChild(LineChartComponent) lineChartComponent: LineChartComponent;

  chartData: IChart[];
  chartHeight = CHART_HEIGHT;
  chartOptions: IColumnChartOptions;
  lineChartAdditionalOptions: Partial<ApexOptions>;
  dateValues: number[][];
  previousYearFinancialMetricsValues: Record<string, number[]>;
  tooltip: ApexTooltip;
  shortifiedYear: string;
  shortifiedPreviousYear: string;

  private tooltipComponent: ComponentRef<ChartTooltipComponent>;
  public readonly ChartDisplayMode = ChartDisplayMode;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private currencySettingsService: CurrencySettingsService,
  ) {}

  public ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ChartTooltipComponent);
    this.tooltipComponent = factory.create(this.injector);
    this.initTooltip();
    this.initChartOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.viewType && this.financialMetrics && this.previousYearFinancialMetrics) {
      this.shortifiedYear = shortifyYear(this.year);
      this.shortifiedPreviousYear = shortifyYear(this.year - 1);
      this.initChartData();
      this.initPreviousYearFinancialMetrics();
      this.initChartOptions();
    }

    if (this.year && changes.year && this.lineChartComponent) {
      this.lineChartComponent.selectedChartIndex = 0;
    }
  }

  private initChartOptions(): void {
    this.chartOptions = {
      yaxis: { labels: { formatter: (v) => this.currencySettingsService.format(v) } },
      height: CHART_HEIGHT,
      title: this.title,
      tooltip: {
        custom: ({ tooltipValues, series, seriesIndex, dataPointIndex }) =>
          this.getTooltip(tooltipValues, series, seriesIndex, dataPointIndex),
      },
    };
    this.lineChartAdditionalOptions = { yaxis: this.chartOptions.yaxis };
  }

  private initTooltip(): void {
    this.tooltip = {
      custom: ({ tooltipValues, series, seriesIndex, dataPointIndex }) =>
        this.getTooltip(tooltipValues, series, seriesIndex, dataPointIndex),
    };
  }

  private getTooltip(tooltipValues: IChart[], series: number[][], seriesIndex: number, dataPointIndex: number): void {
    const metricIndex = this.lineChartComponent ? this.lineChartComponent.selectedChartIndex : seriesIndex;
    const metric = this.lineChartComponent ? this.financialMetrics[metricIndex] : tooltipValues[metricIndex];
    const metricKey = metric.id ? metric.id : metric.name;

    const previousYearValue = this.previousYearFinancialMetricsValues[metricKey]
      ? this.previousYearFinancialMetricsValues[metricKey][dataPointIndex]
      : 0;

    this.tooltipComponent.instance.name = metric.name;

    const currentDateLabel = {
      label: `${this.labels[dataPointIndex]}/${this.shortifiedYear}`,
      value: series[seriesIndex][dataPointIndex],
      displayedValue: this.currencySettingsService.format(series[seriesIndex][dataPointIndex]),
    };
    this.tooltipComponent.instance.currentDateLabel = currentDateLabel;

    const previousDateLabels = [
      this.getPreviousDateLabelsValue(metricKey, series, seriesIndex, dataPointIndex),
      this.getPreviousDateLabelsValue(metricKey, series, seriesIndex, dataPointIndex - 1),
      {
        label: `${this.labels[dataPointIndex]}/${this.shortifiedPreviousYear}`,
        value: previousYearValue,
        displayedValue: this.currencySettingsService.format(previousYearValue),
      },
    ];
    this.tooltipComponent.instance.previousDateLabels = previousDateLabels;

    this.tooltipComponent.instance.tooltipPercents =
      currentDateLabel && previousDateLabels
        ? calculateAllPercentages(currentDateLabel, previousDateLabels)
        : ({} as ITooltipDataLabelsPercents);
    this.tooltipComponent.instance.initPercents();
    this.tooltipComponent.instance.initIcons();

    this.tooltipComponent.changeDetectorRef.detectChanges();

    return this.tooltipComponent.location.nativeElement.innerHTML;
  }

  private initPreviousYearFinancialMetrics(): void {
    if (this.previousYearFinancialMetrics) {
      this.previousYearFinancialMetricsValues = this.previousYearFinancialMetrics.reduce(
        (result: Record<string, number[]>, metric: IFinancialMetric) => {
          const key = metric.id ? metric.id : metric.name;
          if (metric.years && metric.years.length !== 0) {
            result[key] = getValuesForViewType(metric.years[0].values, this.viewType, metric.viewTypeConversion);
          } else {
            result[key] = getValuesForViewType([], this.viewType);
          }

          return result;
        },
        {},
      );
    }
  }

  private getPreviousDateLabelsValue(
    metricKey: string | number,
    series: number[][],
    seriesIndex: number,
    dataPointIndex: number,
  ) {
    let previousDateLabelIndex = this.labels.length - 1;
    if (dataPointIndex === 0 || dataPointIndex === -1) {
      previousDateLabelIndex = dataPointIndex === 0 ? previousDateLabelIndex : previousDateLabelIndex - 1;
      return this.getPreviousYearDateLabel(previousDateLabelIndex, metricKey);
    } else {
      return {
        label: `${this.labels[dataPointIndex - 1]}/${this.shortifiedYear}`,
        value: series[seriesIndex][dataPointIndex - 1],
        displayedValue: this.currencySettingsService.format(series[seriesIndex][dataPointIndex - 1]),
      };
    }
  }

  private getPreviousYearDateLabel(
    previousDateLabelIndex: number,
    metricKey: string | number,
  ): { label: string; value: number; displayedValue: string } {
    const previousMetricValue = this.previousYearFinancialMetricsValues[metricKey]
      ? this.previousYearFinancialMetricsValues[metricKey][previousDateLabelIndex]
      : 0;
    return {
      label: `${this.labels[previousDateLabelIndex]}/${this.shortifiedPreviousYear}`,
      value: previousMetricValue,
      displayedValue: this.currencySettingsService.format(previousMetricValue),
    };
  }

  private initChartData(): void {
    this.chartData = this.financialMetrics.map((metric: IFinancialMetric, index: number): IChart => {
      const monthValues = metric.years && metric.years.length !== 0 ? metric.years[0].values : [];
      const viewTypeValues = getValuesForViewType(monthValues, this.viewType, metric.viewTypeConversion);
      return {
        id: metric.id,
        name: metric.name,
        color: getChartColorByIndex(index),
        visible: metric.isChartVisible !== false,
        data: viewTypeValues,
        isCategory: Boolean(metric.isCategory),
        categoryId: metric.categoryId,
      };
    });
  }

  ngOnDestroy(): void {
    this.tooltipComponent.destroy();
  }
}
