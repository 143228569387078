import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';

import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { CurrencySettingsService } from '@app/shared/services/currency-settings.service';

export type ICurencyComponentOptions = {
  decimals?: number;
};

@Component({
  selector: 'wevestr-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public emptyLabel: string;
  @Input() public value: number;
  @Input() public options: ICurencyComponentOptions = {};

  public display: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private currencySettingsService: CurrencySettingsService,
    companySettingsService: CompanySettingsService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    companySettingsService
      .getSettings$()
      .pipe(pairwise(), takeUntil(this.unsubscribe$))
      .subscribe(([previousSettings, nextSettings]) => {
        if (previousSettings && nextSettings && previousSettings.currencyId !== nextSettings.currencyId) {
          // Manually mark component to refresh to show updated currency sign
          this.ngOnChanges();
          changeDetectorRef.markForCheck();
        }
      });
  }

  public ngOnInit(): void {
    this.display = this.formatDisplay(this.value);
  }

  public ngOnChanges(): void {
    this.display = this.formatDisplay(this.value);
  }

  private formatDisplay(value: number): string {
    return this.currencySettingsService.format(value, this.emptyLabel, this.options.decimals);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
