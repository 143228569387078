import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { CrudService } from '@app/shared/services/crud.service';
import { convertStringToLocalDate } from '@app/shared/utils/helpers/dates.helpers';
import { IConvertible, ITriggerType } from '@app/shareholder-management/types/interfaces/convertible.interface';
import { IConvertedConvertibleToShares } from '@app/shareholder-management/types/interfaces';
import { ConfigService } from '@app/services/config.service';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { IConvertibleNoteConversionResult } from '@wevestr/bff-types/interfaces/conversion-preview.interface';

@Injectable({ providedIn: 'root' })
export class ConvertiblesService extends CrudService<IConvertible> {
  public convertiblesUpdated$: Subject<boolean> = new Subject();

  constructor(configService: ConfigService, http: HttpClient) {
    super(http, configService);
  }

  public get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/convertibles/convertible-notes`;
  }

  public getTriggerTypes(): Observable<{ triggers: ITriggerType[] }> {
    return this._http.get<{ triggers: ITriggerType[] }>(`${this.baseUrl}/${this.url}/trigger-types`);
  }

  public notifyAboutUpdates(isRefresh: boolean): void {
    this.convertiblesUpdated$.next(isRefresh);
  }

  public getEquityConversionPreview(
    convertibleId: number,
    conversionDate: string,
  ): Observable<IConvertibleNoteConversionResult> {
    const params = { conversionDate } as Params;
    return this._http.get<IConvertibleNoteConversionResult>(
      `${this.baseUrl}/${this.url}/${convertibleId}/equity-conversion-preview`,
      { params },
    );
  }

  public convertConvertibleToShares(
    convertibleId: number,
    convertedConvertible: IConvertedConvertibleToShares,
  ): Observable<void> {
    return this._http.post<void>(
      `${this.baseUrl}/${this.url}/${convertibleId}/convert-to-equity`,
      convertedConvertible,
    );
  }

  public get(loanId: number): Observable<IConvertible> {
    return super.get(loanId).pipe(map(ConvertiblesService.formatConvertibleDates));
  }

  public list(): Observable<IConvertible[]> {
    return super.list().pipe(map((loans) => loans.map(ConvertiblesService.formatConvertibleDates)));
  }

  private static formatConvertibleDates = (convertible: IConvertible): IConvertible => ({
    ...convertible,
    dateStart: convertStringToLocalDate(convertible.dateStart),
    dateEnd: convertStringToLocalDate(convertible.dateEnd),
    dateOfIssue: convertStringToLocalDate(convertible.dateOfIssue),
    discounts: convertible.discounts.map((discount) => ({
      ...discount,
      dateStart: convertStringToLocalDate(discount.dateStart),
      dateEnd: convertStringToLocalDate(discount.dateEnd),
    })),
  });
}
