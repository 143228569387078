import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { mergeDeepRight, prop } from 'ramda';
import { ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { ApexAxisChartSeries } from 'ng-apexcharts/lib/model/apex-types';

import { IChartValue } from '@app/shared/types/interfaces/chart-value.interface';
import { IAxisChartOptions } from '@app/shared/types/interfaces';
import {
  AXIS_GROUP_STYLE,
  BARCHART_OPTIONS,
  COLUMNS_IN_GROUP,
  DATA_LABELS_BARCHART_OPTIONS,
  FILL_BARCHART_OPTIONS,
  FULL_OPACITY,
  GRID_BARCHART_OPTIONS,
  HALF_OPACITY,
  LEGEND_BARCHART_OPTIONS,
  PLOT_BARCHART_OPTIONS,
  STATES_BARCHART_OPTIONS,
  XAXIS_BARCHART_OPTIONS,
  YAXIS_BARCHART_OPTIONS,
} from '@app/shared/components/stacked-barchart/stacked-barchart.constants';
import { NumberFormatPipe } from '@app/shared/pipes';

interface ApexChartEventConfig {
  dataPointIndex: number;
  w: { globals: { dom: { baseEl: HTMLElement } } };
}

interface AxisGroup {
  title: string;
  cols: number;
}

@Component({
  selector: 'wevestr-stacked-barchart',
  templateUrl: './stacked-barchart.component.html',
  styleUrls: ['./stacked-barchart.component.scss'],
})
export class StackedBarchartComponent implements OnInit, OnChanges {
  @Input() public labels: string[] = [];
  @Input() public groupLabels: string[] = [];
  @Input() public values: IChartValue[] = [];
  @Input() public defaultChartOptions: Partial<IAxisChartOptions>;

  public chartOptions: Partial<IAxisChartOptions>;

  constructor(private numberFormatPipe: NumberFormatPipe) {}

  public ngOnInit(): void {
    this.initChartOptions();
  }

  public ngOnChanges(): void {
    if (this.chartOptions) {
      this.updateChartOptions();
    }
  }

  private setElementOpacity =
    (opacity: string) =>
    (element: Element): void => {
      (element as HTMLElement).style.opacity = opacity;
    };

  private getDataPointsCollection<T extends ApexChartEventConfig>(config: T): NodeListOf<Element> {
    return config.w.globals.dom.baseEl.querySelectorAll('.apexcharts-series');
  }

  private handleDataPointMouseEnter<T extends ApexChartEventConfig>(config: T): void {
    const dataPointIndex = config.dataPointIndex;
    const dataPointGroups = this.getDataPointsCollection(config);
    dataPointGroups.forEach((dataPointGroup) =>
      Array.from(dataPointGroup.children).forEach((dataPoint, index) => {
        if (index === dataPointIndex) {
          this.setElementOpacity(FULL_OPACITY)(dataPoint);
        } else {
          this.setElementOpacity(HALF_OPACITY)(dataPoint);
        }
      }),
    );
  }

  private handleDataPointMouseLeave<T extends ApexChartEventConfig>(config: T): void {
    const dataPointGroups = this.getDataPointsCollection(config);
    dataPointGroups.forEach((dataPointGroup) =>
      Array.from(dataPointGroup.children).forEach(this.setElementOpacity(FULL_OPACITY)),
    );
  }

  private initChartOptions(): void {
    const initialChartOptions: Partial<IAxisChartOptions> = {
      series: this.getSeries(),
      chart: {
        ...BARCHART_OPTIONS,
        events: {
          dataPointMouseEnter: (_1, _2, config) => this.handleDataPointMouseEnter(config),
          dataPointMouseLeave: (_1, _2, config) => this.handleDataPointMouseLeave(config),
        },
      },
      dataLabels: DATA_LABELS_BARCHART_OPTIONS,
      plotOptions: PLOT_BARCHART_OPTIONS,
      xaxis: this.getXaxis(),
      yaxis: this.getYaxis(),
      legend: LEGEND_BARCHART_OPTIONS,
      grid: GRID_BARCHART_OPTIONS,
      colors: this.getColors(),
      states: STATES_BARCHART_OPTIONS,
      fill: FILL_BARCHART_OPTIONS,
    };
    this.chartOptions = mergeDeepRight(initialChartOptions, this.defaultChartOptions) as Partial<IAxisChartOptions>;
  }

  private updateChartOptions(): void {
    const chartOptions = mergeDeepRight(this.chartOptions, this.defaultChartOptions) as Partial<IAxisChartOptions>;
    this.chartOptions = {
      ...chartOptions,
      colors: this.getColors(),
      series: this.getSeries(),
    };
    this.chartOptions.xaxis.categories = this.labels;
    this.chartOptions.xaxis.group.groups = this.getGroups();
  }

  private getXaxis(): ApexXAxis {
    return {
      ...XAXIS_BARCHART_OPTIONS,
      categories: this.labels,
      group: {
        groups: this.getGroups(),
        style: AXIS_GROUP_STYLE,
      },
    };
  }

  private getYaxis(): ApexYAxis {
    return {
      ...YAXIS_BARCHART_OPTIONS,
      labels: {
        ...YAXIS_BARCHART_OPTIONS.labels,
        formatter: (value: number) => this.defaultYAxisFormatter(value),
      },
    };
  }

  private defaultYAxisFormatter(value: number): string {
    const formatWithoutDecimals = '1.0-0';
    return this.numberFormatPipe.transform(value, formatWithoutDecimals);
  }

  private getColors(): string[] {
    return this.values.map(prop('color'));
  }

  private getSeries(): ApexAxisChartSeries {
    return this.values.map(({ name, data }) => ({ name, data }));
  }

  private getGroups(): AxisGroup[] {
    return this.groupLabels.map((title) => ({ title, cols: COLUMNS_IN_GROUP }));
  }
}
