import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { ApexChart, ApexOptions, ApexTooltip, ChartComponent } from 'ng-apexcharts';
import { Subject } from 'rxjs';

import { IChart } from '@app/shared/types/interfaces';
import { ILegendOption } from '@app/shared/types/interfaces/legend-option.interface';
import { ChartLegendService } from '@app/shared/services/chart-legend.service';
import { areOptionsTheSame } from '@app/shared/utils/helpers/chart.helpers';
import { Frequency } from '@app/shared/types/enums';
import { NumberFormatPipe } from '@app/shared/pipes';
import { mergeDeepRight, reduce } from 'ramda';
import { takeUntil } from 'rxjs/operators';
import { STATIC_LINE_CHART_OPTIONS } from '@app/shared/components/line-chart/line-chart.constants';
import { MILLION_ABBREV, MILLION_NUMBER } from '@app/settings/constants/settings.constants';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() charts: IChart[];
  @Input() labels: string[];
  @Input() height: number;
  @Input() tooltip: ApexTooltip;
  @Input() useLegendWithSelect = false;
  @Input() isEmptyState = false;
  @Input() viewType: Frequency;
  @Input() additionalChartOptions: Partial<ApexOptions>;

  public selectedChartIndex = 0;
  public chartOptions: Partial<ApexOptions>;
  // TODO: figure out type of context, none is provided by library
  public chartContext: unknown;

  @ViewChild(ChartComponent) chart: ChartComponent;

  private selectedChartName: string;
  private visibleCharts: IChart[];
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private chartLegendService: ChartLegendService, private numberFormatPipe: NumberFormatPipe) {}

  public ngOnInit(): void {
    if (this.useLegendWithSelect) {
      this.chartLegendService.selectedOption$.pipe(takeUntil(this.unsubscribe$)).subscribe((option: ILegendOption) => {
        this.selectOption(option);
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.charts || changes.tooltip) && this.charts) {
      this.visibleCharts = this.charts.filter((chart) => chart.visible);
      this.initSelectedChartData();
      this.initChart();

      if (this.useLegendWithSelect) {
        this.initLegendOptions();
      }
    }
  }

  public handleSelectChart(data: { chart: IChart; index: number }): void {
    this.selectedChartIndex = data.index;
    this.selectedChartName = data.chart.name;
    this.chart.updateSeries(this.series);
  }

  public ngOnDestroy(): void {
    if (this.useLegendWithSelect) {
      this.chartLegendService.cleanupLegend();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initSelectedChartData(): void {
    const index = this.charts
      .filter((chart) => chart.visible)
      .findIndex((chart) => chart.name === this.selectedChartName);

    this.selectedChartIndex = index > 0 ? index : 0;
    this.selectedChartName = this.visibleCharts[this.selectedChartIndex]?.name;
  }

  private selectOption(option: ILegendOption): void {
    this.selectedChartIndex = this.visibleCharts.findIndex((chart) => areOptionsTheSame(option, chart));
    this.selectedChartName = this.visibleCharts[this.selectedChartIndex].name;

    this.chart.updateSeries(this.series);
  }

  private initLegendOptions(): void {
    const legendOptions = this.charts.map((value, index: number) => ({
      id: value.id,
      color: value.color,
      name: value.name,
      isCategory: value.isCategory,
      isSelected: this.isSelected(index),
      categoryId: value.categoryId,
      isVisible: value.visible,
    }));

    // TODO: refactor existing legend and select content management to decrease complexity
    // after that get rid of checks in categorized-select
    this.chartLegendService.setLegendOptions(legendOptions);
  }

  private isSelected(index: number): boolean {
    const numberOfCategoriesBeforeIndex = this.charts.filter((chart, i) => !chart.visible && i <= index).length;
    return index - numberOfCategoriesBeforeIndex === this.selectedChartIndex;
  }
  private defaultYAxisFormatter(value: number): string {
    let formattedValue: string;
    if (value >= MILLION_NUMBER || value <= -MILLION_NUMBER) {
      formattedValue = `${this.numberFormatPipe.transform(value / MILLION_NUMBER)}${MILLION_ABBREV}`;
    } else {
      formattedValue = `${this.numberFormatPipe.transform(value)}`;
    }
    return formattedValue;
  }

  private get series() {
    const selectedChart = this.visibleCharts?.[this.selectedChartIndex];

    return selectedChart
      ? [
          {
            data: selectedChart.data || [],
            color: selectedChart.color,
          },
        ]
      : [];
  }

  private initChart(): void {
    this.chartOptions = reduce<Partial<ApexOptions>, Partial<ApexOptions>>(
      mergeDeepRight,
      {},
    )([
      STATIC_LINE_CHART_OPTIONS,
      this.getDynamicChartOptions(),
      this.additionalChartOptions ?? {},
    ]) as Partial<ApexOptions>;

    if (this.chart && this.series) {
      this.chart.updateSeries(this.series);
    }
  }

  private getDynamicChartOptions(): Partial<ApexOptions> {
    return {
      series: this.series,
      chart: {
        height: this.height,
        events: {
          mounted: (chartContext: unknown) => {
            this.chartContext = chartContext;
          },
        },
      } as ApexChart,
      tooltip: this.tooltip,
      yaxis: {
        labels: {
          // TODO: find way to limit this callback calls
          formatter: (value: number) => this.defaultYAxisFormatter(value),
        },
      },
      xaxis: {
        categories: this.labels,
      },
    };
  }
}
