<div
  class="flex-column table-wrapper"
  [ngClass]="{
    scroll: !isEmptyState,
    'bottom-borders': options.hasFooter,
    'full-bottom-borders': options.showBottomBorders
  }"
>
  <table #tableElement class="full-width table">
    <thead *ngIf="!options.hideHeader" class="table__header table__header--rounded">
      <tr class="table__row" [ngStyle]="{height: options.headerHeight ? options.headerHeight : '40px'}">
        <ng-container *ngFor="let column of columns; index as i; last as last">
          <th
            #th
            class="app-body table__th"
            [ngStyle]="getThStyle(column, i, last && !editMode)"
            [ngClass]="{
              'cell-padding-l': options.cellPaddingSize === 'l',
              'cell-padding-m': options.cellPaddingSize === 'm',
              'cell-padding-s': options.cellPaddingSize === 's'
            }"
          >
            <div class="is-inline-block" [class.width-max-content]="options.singleLineHeader">
              <div
                class="flex-row is-align-items-center"
                [class.header]="
                  column.sortDetails &&
                  column.sortDetails.order === columnOrdering.none &&
                  (column.hasRightAlignment || column.textAlign)
                "
              >
                <ng-container *ngIf="column.hasRightAlignment; else noRightAlignment">
                  <div
                    #headerCellWithRightAlignment
                    (resized)="handleResize(headerCellWithRightAlignment, column)"
                    class="th-with-right-alignment"
                  >
                    {{ (column.headerName | isDefined) ? column.headerName : (column.field | titlecase) }}
                  </div>
                </ng-container>
                <ng-template #noRightAlignment>
                  {{ (column.headerName | isDefined) ? column.headerName : (column.field | titlecase) }}
                </ng-template>
                <div [class.sort__container]="!column.hasRightAlignment && !column.textAlign">
                  <div
                    *ngIf="column.sortDetails"
                    (click)="handleSort(column, i)"
                    class="sort__indicator cursor-pointer padding-left-s"
                    [ngClass]="{
                      'display-none': column.sortDetails.order === columnOrdering.none,
                      disabled: column.sortDetails.order === columnOrdering.none
                    }"
                  >
                    <mat-icon
                      [ngClass]="{rotate180: column.sortDetails.order !== columnOrdering.ascending}"
                      [svgIcon]="column.sortDetails.order === columnOrdering.none ? 'sorting' : 'sorting_asc'"
                    ></mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </th>
        </ng-container>
        <th
          *ngIf="options.actions && options.actions.length !== 0"
          [ngStyle]="getThStyle()"
          class="table__th table__th--action"
        ></th>
        <th *ngIf="editMode" [ngStyle]="getThStyle(null, null, true)" class="table__th"></th>
      </tr>
    </thead>
    <tbody
      cdkDropList
      (cdkDropListDropped)="handleReorder($event)"
      [ngClass]="{'bordered-body': options.hasBodyBorder}"
    >
      <ng-container *ngIf="isEmptyState && newRows?.length === 0">
        <tr>
          <td [attr.colspan]="columns.length + 1">
            <ng-container>
              <div class="app-body flex is-align-items-center is-justify-content-center empty-state">
                <app-table-empty-state
                  [title]="options.emptyTitle"
                  [description]="options.emptyCopy"
                  [isImageVisible]="options.displayEmptyImage"
                  [buttonTemplate]="options.emptyButtonTemplate"
                >
                </app-table-empty-state>
              </div>
            </ng-container>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let row of data; index as rowIndex">
        <!-- NOT NESTED AND OLD LOGIC: START -->
        <ng-container *ngIf="!options.nestingType">
          <ng-container *ngIf="!options.multipleValuesPerRow; else rowMultipleValues">
            <app-table-element-row
              #tableRow
              *ngIf="!options.isCategorized || !row.isCategory"
              [options]="options"
              [rowValue]="row"
              [columns]="columns"
              [index]="rowIndex"
              [editMode]="editMode"
              [editRowIndex]="editRowIndex"
              [columnWidths]="columnWidths"
              [canReorder]="editActions.reorder"
              [canEdit]="editActions.edit"
              [canDelete]="editActions.delete && !row.isDummy"
              [defaultAddValues]="defaultAddValues"
              [roundedCorners]="hasRoundedCorners"
              [validations]="validations"
              [errorMessages]="errorMessages"
              [requiredFields]="requiredFields"
              (rowHover)="rowHover.emit($event)"
              (selectAction)="selectAction.emit($event)"
              (saveRowData)="editRow($event, row, rowIndex)"
              (deleteRow)="deleteValue.emit(row)"
              (enterEditMode)="editRowIndex = rowIndex"
              (cancelSaveData)="editRowIndex = null"
            >
              <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                <ng-container
                  [ngTemplateOutlet]="cellValueTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: row,
                    childValue: childValue,
                    column: column,
                    editable: editRowIndex === rowIndex,
                    form: tableRow.form
                  }"
                >
                </ng-container>
              </ng-template>
            </app-table-element-row>
            <app-table-category-row
              *ngIf="options.isCategorized && row.isCategory"
              [options]="options"
              [rowValue]="row"
              [columns]="columns"
              [index]="rowIndex"
              [editMode]="editMode"
              [editRowIndex]="editRowIndex"
              [canReorder]="editActions.reorder"
              [canEdit]="editActions.edit"
              [canDelete]="editActions.delete"
              (rowHover)="rowHover.emit($event)"
              (selectAction)="selectAction.emit($event)"
              (saveRowData)="editRow($event, row, rowIndex)"
              (deleteRow)="deleteValue.emit(row)"
              (enterEditMode)="editRowIndex = rowIndex"
              (cancelSaveData)="editRowIndex = null"
              (toggle)="toggle.emit({isCategory: true, id: row.id})"
            >
            </app-table-category-row>
          </ng-container>

          <ng-template #rowMultipleValues>
            <ng-container *ngIf="row.values && row.values.length !== 0; else singleValue">
              <ng-container *ngFor="let value of row.values; index as j">
                <app-table-element-row
                  [roundedTopCorners]="j === 0 && hasRoundedCorners"
                  [roundedBottomCorners]="j === row.values.length - 1 && hasRoundedCorners"
                  [options]="options"
                  [rowValue]="row"
                  [childValue]="value"
                  [columns]="columns"
                  [index]="rowIndex"
                  [childIndex]="j"
                  [validations]="validations"
                  [errorMessages]="errorMessages"
                  [requiredFields]="requiredFields"
                  (rowHover)="rowHover.emit($event)"
                  (selectAction)="selectAction.emit($event)"
                >
                  <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                    <ng-container
                      [ngTemplateOutlet]="cellValueTemplate"
                      [ngTemplateOutletContext]="{$implicit: row, childValue: childValue, column: column}"
                    ></ng-container>
                  </ng-template>
                </app-table-element-row>
              </ng-container>
            </ng-container>
            <ng-template #singleValue>
              <app-table-element-row
                [options]="options"
                [rowValue]="row"
                [columns]="columns"
                [index]="rowIndex"
                [validations]="validations"
                [errorMessages]="errorMessages"
                [requiredFields]="requiredFields"
                (rowHover)="rowHover.emit($event)"
                (selectAction)="selectAction.emit($event)"
              >
                <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                  <ng-container
                    [ngTemplateOutlet]="cellValueTemplate"
                    [ngTemplateOutletContext]="{$implicit: row, childValue: childValue, column: column}"
                  ></ng-container>
                </ng-template>
              </app-table-element-row>
            </ng-template>
          </ng-template>
        </ng-container>
        <!-- NOT NESTED AND OLD LOGIC: END -->

        <!-- NEW NESTED LOGIC: START -->

        <ng-container *ngIf="options.nestingType">
          <ng-container *ngIf="!options.nestingType || options.nestingType === NESTING_TYPES.NONE">
            <!-- Simple table, row without children, only one line per row is shown  -->
            <!-- Might have additional actions like inline editing and reordering  -->
            <app-table-element-row
              #tableRow
              *ngIf="!options.isCategorized || !row.isCategory"
              [options]="options"
              [rowValue]="row"
              [columns]="columns"
              [index]="rowIndex"
              [editMode]="editMode"
              [editRowIndex]="editRowIndex"
              [columnWidths]="columnWidths"
              [canReorder]="editActions.reorder"
              [canEdit]="editActions.edit"
              [canDelete]="editActions.delete && !row.isDummy"
              [defaultAddValues]="defaultAddValues"
              [roundedCorners]="hasRoundedCorners"
              [validations]="validations"
              [errorMessages]="errorMessages"
              [requiredFields]="requiredFields"
              (rowHover)="rowHover.emit($event)"
              (selectAction)="selectAction.emit($event)"
              (saveRowData)="editRow($event, row, rowIndex)"
              (deleteRow)="deleteValue.emit(row)"
              (enterEditMode)="editRowIndex = rowIndex"
              (cancelSaveData)="editRowIndex = null"
            >
              <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                <ng-container
                  [ngTemplateOutlet]="cellValueTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: row,
                    childValue: childValue,
                    column: column,
                    editable: editRowIndex === rowIndex,
                    form: tableRow.form
                  }"
                >
                </ng-container>
              </ng-template>
            </app-table-element-row>
            <app-table-category-row
              *ngIf="options.isCategorized && row.isCategory"
              [options]="options"
              [rowValue]="row"
              [columns]="columns"
              [index]="rowIndex"
              [editMode]="editMode"
              [editRowIndex]="editRowIndex"
              [canReorder]="editActions.reorder"
              [canEdit]="editActions.edit"
              [canDelete]="editActions.delete"
              (rowHover)="rowHover.emit($event)"
              (selectAction)="selectAction.emit($event)"
              (saveRowData)="editRow($event, row, rowIndex)"
              (deleteRow)="deleteValue.emit(row)"
              (enterEditMode)="editRowIndex = rowIndex"
              (cancelSaveData)="editRowIndex = null"
              (toggle)="toggle.emit({isCategory: true, id: row.id})"
            >
            </app-table-category-row>
          </ng-container>
          <ng-container *ngIf="options.nestingType === NESTING_TYPES.NESTED">
            <!-- Table with possibility to nest rows, several lines per row might be shown  -->
            <ng-container *ngIf="!row.children || !row.children.length; else firstLevelNesting">
              <!-- One-line row without children  -->
              <app-table-element-row
                [options]="options"
                [rowValue]="row"
                [childValue]="row"
                [columns]="columns"
                [index]="rowIndex"
                [validations]="validations"
                [errorMessages]="errorMessages"
                [requiredFields]="requiredFields"
                (rowHover)="rowHover.emit($event)"
                (selectAction)="selectAction.emit($event)"
              >
                <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                  <ng-container
                    [ngTemplateOutlet]="cellValueTemplate"
                    [ngTemplateOutletContext]="{$implicit: row, childValue: childValue, column: column}"
                  ></ng-container>
                </ng-template>
              </app-table-element-row>
            </ng-container>
            <!-- Row without children  -->
            <ng-template #firstLevelNesting>
              <!-- Header element of nested row (display depends on isShownAsHeader prop)  -->
              <app-table-element-row
                *ngIf="row.isShownAsHeader || !row.isExpanded"
                [options]="options"
                [rowValue]="row"
                [childValue]="row"
                [columns]="columns"
                [index]="rowIndex"
                [validations]="validations"
                [errorMessages]="errorMessages"
                [requiredFields]="requiredFields"
                (rowHover)="rowHover.emit($event)"
                (selectAction)="selectAction.emit($event)"
              >
                <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                  <ng-container
                    [ngTemplateOutlet]="cellValueTemplate"
                    [ngTemplateOutletContext]="{$implicit: row, childValue: childValue, column: column}"
                  ></ng-container>
                </ng-template>
              </app-table-element-row>
              <!-- Children elements of nested row  -->
              <ng-container *ngIf="row.isExpanded">
                <ng-container *ngFor="let subrow of row.children; index as subrowIndex">
                  <!-- One-line subrow without children  -->
                  <!-- TODO unite with multiheader -->
                  <ng-container *ngIf="!subrow.children || !subrow.children.length; else secondLevelNesting">
                    <app-table-element-row
                      [options]="options"
                      [rowValue]="row"
                      [childValue]="subrow"
                      [columns]="columns"
                      [index]="rowIndex"
                      [childIndex]="subrowIndex"
                      [validations]="validations"
                      [errorMessages]="errorMessages"
                      [requiredFields]="requiredFields"
                      (rowHover)="rowHover.emit($event)"
                      (selectAction)="selectAction.emit($event)"
                      [ngClass]="{'first-level': !subrow.isShownAsHeader}"
                    >
                      <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                        <ng-container
                          [ngTemplateOutlet]="cellValueTemplate"
                          [ngTemplateOutletContext]="{
                            $implicit: row,
                            childValue: childValue,
                            column: column,
                            childIndex: subrowIndex
                          }"
                        ></ng-container>
                      </ng-template>
                    </app-table-element-row>
                  </ng-container>
                  <ng-template #secondLevelNesting>
                    <!-- Header element of nested subrow (display depends on isShownAsHeader prop)  -->
                    <app-table-element-row
                      *ngIf="subrow.isShownAsHeader || !subrow.isExpanded"
                      [options]="options"
                      [rowValue]="row"
                      [childValue]="subrow"
                      [columns]="columns"
                      [index]="rowIndex"
                      [validations]="validations"
                      [errorMessages]="errorMessages"
                      [requiredFields]="requiredFields"
                      (rowHover)="rowHover.emit($event)"
                      (selectAction)="selectAction.emit($event)"
                      class="first-level"
                    >
                      <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
                        <ng-container
                          [ngTemplateOutlet]="cellValueTemplate"
                          [ngTemplateOutletContext]="{
                            $implicit: row,
                            childValue: childValue,
                            column: column,
                            childIndex: subrowIndex
                          }"
                        ></ng-container>
                      </ng-template>
                    </app-table-element-row>
                    <!-- Children elements of nested subrow  -->
                    <ng-container *ngIf="subrow.isExpanded">
                      <ng-container *ngFor="let subrowValue of subrow.children; index as childIndex">
                        <app-table-element-row
                          [options]="options"
                          [rowValue]="subrow"
                          [childValue]="subrowValue"
                          [columns]="columns"
                          [index]="rowIndex"
                          [childIndex]="childIndex"
                          [validations]="validations"
                          [errorMessages]="errorMessages"
                          [requiredFields]="requiredFields"
                          (rowHover)="rowHover.emit($event)"
                          (selectAction)="selectAction.emit($event)"
                          class="second-level"
                        >
                          <ng-template appCellValue let-subrow let-childValue="childValue" let-column="column">
                            <ng-container
                              [ngTemplateOutlet]="cellValueTemplate"
                              [ngTemplateOutletContext]="{
                                $implicit: subrow,
                                childValue: childValue,
                                column: column,
                                childIndex: childIndex
                              }"
                            ></ng-container>
                          </ng-template>
                        </app-table-element-row>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- NEW NESTED LOGIC: END -->

      <ng-container *ngFor="let newRow of newRows; index as newRowIndex">
        <app-table-element-row
          #tableRow
          *ngIf="!options.isCategorized || !newRow.isCategory"
          [index]="editRowIndex + newRowIndex"
          [options]="options"
          [rowValue]="newRow"
          [columns]="columns"
          [editMode]="true"
          [editRowIndex]="editRowIndex + newRowIndex"
          [columnWidths]="columnWidths"
          [defaultAddValues]="defaultAddValues"
          [validations]="validations"
          [errorMessages]="errorMessages"
          [requiredFields]="requiredFields"
          [sendInstantNewRowUpdate]="true"
          (instantRowUpdateData)="updateNewRow($event, newRowIndex)"
          (saveRowData)="saveNewRow($event, newRowIndex)"
          (cancelSaveData)="removeNewRow(newRowIndex)"
        >
          <ng-template appCellValue let-row let-childValue="childValue" let-column="column">
            <ng-container
              [ngTemplateOutlet]="cellValueTemplate"
              [ngTemplateOutletContext]="{
                $implicit: row,
                childValue: childValue,
                column: column,
                editable: true,
                form: tableRow.form
              }"
            >
            </ng-container>
          </ng-template>
        </app-table-element-row>
        <app-table-category-row
          *ngIf="options.isCategorized && newRow.isCategory"
          [index]="editRowIndex + newRowIndex"
          [options]="options"
          [rowValue]="newRow"
          [editMode]="true"
          [editRowIndex]="editRowIndex + newRowIndex"
          (saveRowData)="saveNewRow($event, newRowIndex)"
          (cancelSaveData)="removeNewRow(newRowIndex)"
        >
        </app-table-category-row>
      </ng-container>
    </tbody>
    <tfoot *ngIf="options.hasFooter" class="table__footer--rounded">
      <tr class="table__row" [ngStyle]="{height: options.footerHeight ? options.footerHeight : '40px'}">
        <th
          class="app-body-small-const"
          *ngFor="let column of columns; index as i; last as last"
          [ngStyle]="getFooterStyle(column, i, last && !editMode)"
          [ngClass]="{
            'cell-padding-l': options.cellPaddingSize === 'l',
            'cell-padding-m': options.cellPaddingSize === 'm',
            'cell-padding-s': options.cellPaddingSize === 's'
          }"
        >
          <div [innerHTML]="column.footerValue(getColumnValues(column)) | safeHtml"></div>
        </th>
        <th *ngIf="options.actions && options.actions.length !== 0" class="table__th--action"></th>
        <th *ngIf="editMode" [ngStyle]="getFooterStyle(null, null, true)" class="table__th"></th>
      </tr>
    </tfoot>
  </table>
</div>
<ng-container *ngIf="options.pagination">
  <wevestr-paginator
    *ngIf="
      options.paginationOptions && (options.paginationOptions.pagesCount > 1 || data.length >= 5);
      else simplePagination
    "
    [pagesNumber]="options.paginationOptions.pagesCount"
    [pageSize]="options.paginationOptions.pageSize"
    [selectedPage]="options.paginationOptions.currentPage"
    (pageChanges)="handlePageChanges($event); tableElement.scrollIntoView()"
  ></wevestr-paginator>
  <ng-template #simplePagination>
    <div
      class="pagination-footer position-relative flex-row is-justify-content-flex-end is-align-items-center"
      [ngStyle]="{height: options.footerHeight ? options.footerHeight : '40px'}"
      [ngClass]="{
        'cell-padding-l': options.cellPaddingSize === 'l',
        'cell-padding-m': options.cellPaddingSize === 'm',
        'cell-padding-s': options.cellPaddingSize === 's',
        'bottom-borders': isEmptyState
      }"
    >
      <div class="pagination-footer__text app-body-medium-weight">{{ options.footerText }}</div>
      <div *ngIf="options.countFirstRowValues; else normalCount" class="app-body-small">
        {{ data.length !== 0 ? 1 : 0 }} - {{ data[0].values?.length }} of {{ data[0].values?.length }}
      </div>
      <ng-template #normalCount>
        <div class="app-body-small">{{ data.length !== 0 ? 1 : 0 }} - {{ data.length }} of {{ data.length }}</div>
      </ng-template>
    </div>
  </ng-template>
</ng-container>

<div
  *ngIf="editMode && editActions.add"
  class="buttons-group"
  [ngClass]="{
    'cell-padding-l': options.cellPaddingSize === 'l',
    'cell-padding-m': options.cellPaddingSize === 'm',
    'cell-padding-s': options.cellPaddingSize === 's'
  }"
>
  <app-button type="tertiary" icon="add" (onClick)="addNewRow()"> Add {{ entityName }} </app-button>

  <app-button
    *ngIf="options.isCategorized && options.allowCreateCategories"
    type="tertiary"
    icon="add"
    (onClick)="addNewRow(true)"
  >
    Add Category
  </app-button>
</div>
