import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { OnboardingButtonService } from '@app/onboarding/components/onboarding-button/onboarding-button.service';

@Component({
  selector: 'wevestr-interim-screen',
  templateUrl: './interim-screen.component.html',
  styleUrls: ['./interim-screen.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class InterimScreenComponent implements OnInit {
  @Input() public title: string;
  @Input() public description: string;

  @Output() public continue = new EventEmitter<void>();

  public isLoading = false;

  constructor(private onboardingButtonService: OnboardingButtonService) {}

  public ngOnInit(): void {
    this.onboardingButtonService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  public handleContinue(): void {
    this.onboardingButtonService.setLoading(true);
    this.continue.emit();
  }
}
