import { ITopControlOption } from '@app/types/interfaces/top-control-option.interface';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';

export const COMPANY_TOP_OPTION_KEYS = {
  COMPANY_SETTINGS: 'company_settings',
  USER_SETTINGS: 'user_settings',
} as const;

export const CompanyTopControlOptions: ITopControlOption[] = [
  {
    link: `/${CompanyRouteNames.COMPANY}/settings/company`,
    label: 'Company Settings',
    key: COMPANY_TOP_OPTION_KEYS.COMPANY_SETTINGS,
  },
  {
    link: `/${CompanyRouteNames.COMPANY}/settings`,
    label: 'User Settings',
    key: COMPANY_TOP_OPTION_KEYS.USER_SETTINGS,
  },
];
