<div class="interim-item flex-column align-items-center">
  <div class="flex-column align-items-center margin-bottom-l">
    <p class="app-h4-bold interim-item__title margin-bottom-l">{{ title }}</p>
    <div class="app-body interim-item__description margin-bottom-l" [innerHTML]="description"></div>

    <button class="button primary-button app-body" [disabled]="isLoading" (click)="handleContinue()">
      <ng-container *ngIf="!isLoading; else loadingTemplate"> Continue to Platform </ng-container>

      <ng-template #loadingTemplate>
        <mat-spinner diameter="14" strokeWidth="2"></mat-spinner>
        Please Wait
      </ng-template>
    </button>
  </div>
  <div class="interim-item__img"></div>
</div>
