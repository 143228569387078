export enum OverrideMode {
  REPLACE = 'replace',
  UPDATE = 'update',
}

export interface IAuditImportParameters {
  files: IImportAuditFile[];
}

export interface IImportAuditFile {
  file: File;
  overrideMode: OverrideMode;
}
