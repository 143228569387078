import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-block',
  templateUrl: './mobile-block.component.html',
  styleUrls: ['./mobile-block.component.scss'],
})
export class MobileBlockComponent {
  handleClick(): void {
    window.location.href = 'https://wevestr.com';
  }
}
