import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { StepStatus } from '@app/shared/types/interfaces';

@Component({
  selector: 'wevestr-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(1000)]),
      transition(':leave', animate(0, style({ opacity: 0 }))),
    ]),
  ],
})
export class WizardStepComponent implements OnInit {
  @Input()
  public stepName: string;
  @Input()
  public disabled = false;
  @Input()
  public stepStatus: StepStatus = StepStatus.PENDING;

  public stepActive: boolean;

  public ngOnInit(): void {
    this.stepActive = this.stepStatus === StepStatus.ACTIVE;
  }
}
