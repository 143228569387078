<div
  class="dropdown"
  (isActiveTemplate)="handleActiveTemplate($event)"
  [wvPopoverTriggerFor]="menu"
  [contentOffsetY]="2"
  [closeOnClickInside]="true"
>
  <button class="button primary-button button__small absolute-positioned-button" [class.opened]="opened">
    {{ buttonTitle }} <mat-icon svgIcon="chevron" class="collapse-icon"></mat-icon>
  </button>
</div>
<ng-template #menu>
  <wevestr-popover-content>
    <div class="full-width" role="menu">
      <div class="dropdown-content app-body">
        <div
          *ngFor="let dropdownButton of dropdownButtons"
          class="dropdown-item cursor-pointer app-body dropdown-item_regular-option"
          (click)="handleOnClick(dropdownButton)"
        >
          <mat-icon
            *ngIf="dropdownButton?.icon"
            (click)="toggleExpandCategory(option, $event)"
            class="dropdown-icon"
            [svgIcon]="dropdownButton?.icon"
          ></mat-icon>
          {{ dropdownButton?.label }}
        </div>
      </div>
    </div>
  </wevestr-popover-content>
</ng-template>
