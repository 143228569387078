import { Input, Component, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { map, pick, pipe } from 'ramda';

import { findById, keepInitialOrder } from '@app/shared/utils/helpers/arrays.helpers';
import {
  getVisibleMetricsCount,
  isAnyMetricVisibleInChart,
  toggleMetricVisibilityInChart,
  setMetricVisibilityInChart,
  getMetricsVisibleInChart,
} from '@app/shared/utils/helpers/chart-metric.helpers';
import { findMetricsByCategoryId } from '@app/shared/utils/helpers/category.helpers';

interface IChartSelectorMetric {
  id: number | string;
  name: string;
  categoryId?: number | string;
  isCategory?: boolean;
  color?: string;
  isVisibleInChart: boolean;
  isExpanded?: boolean;
  nestedMetrics?: IChartSelectorMetric[];
  isParentCategoryVisibleInChart?: boolean;
  isAnyNestedMetricVisibleInChart?: boolean;
}

interface IChartSelectorDisplayType {
  id: string;
  name: string;
}

interface IUpdateMetricEvent {
  metricsVisibleInChart: IChartSelectorMetric[];
}

const DISABLED_METRIC_TOOLTIP_TEXT = 'The maximum items that can be selected is ';

@Component({
  selector: 'wevestr-chart-selector',
  templateUrl: './chart-selector.component.html',
  styleUrls: ['./chart-selector.component.scss'],
})
export class ChartSelectorComponent implements OnInit, OnChanges {
  @Input() public placeholder: string;
  @Input() public displayTypes: IChartSelectorDisplayType[];
  @Input() public metrics: IChartSelectorMetric[];
  @Input() public maxNumberOfSelectedMetrics: number;

  @Output() public changeDisplayType = new EventEmitter<string>();
  @Output() public updateMetrics = new EventEmitter<IUpdateMetricEvent>();

  public selectedDisplayType: string;
  public displayedMetrics: IChartSelectorMetric[];
  public selectedMetricsCount: number;
  public disabledMetricTooltip: string;
  public canSelectMetric: boolean;

  public ngOnInit(): void {
    this.selectedDisplayType = this.displayTypes?.[0]?.id;

    this.disabledMetricTooltip = `${DISABLED_METRIC_TOOLTIP_TEXT}${this.maxNumberOfSelectedMetrics}.`;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.metrics) {
      const metrics = changes.metrics.currentValue || [];

      this.selectedMetricsCount = getVisibleMetricsCount(metrics);
      this.canSelectMetric = this.selectedMetricsCount < this.maxNumberOfSelectedMetrics;
      this.displayedMetrics = this.mapMetricsToDisplay(metrics);
    }
  }

  private mapMetricsToDisplay(metrics: IChartSelectorMetric[]): IChartSelectorMetric[] {
    return map((metric) => (metric.isCategory ? this.getCategoryToDisplay(metric, metrics) : { ...metric }), metrics);
  }

  private getCategoryToDisplay(category: IChartSelectorMetric, metrics: IChartSelectorMetric[]): IChartSelectorMetric {
    const nestedMetrics = this.getNestedMetrics(category, metrics);
    const isAnyNestedMetricVisibleInChart = isAnyMetricVisibleInChart(nestedMetrics);
    const isExpanded = findById(this.displayedMetrics, category.id)?.isExpanded;

    return {
      ...category,
      nestedMetrics,
      isExpanded,
      isAnyNestedMetricVisibleInChart,
    };
  }

  private getNestedMetrics(category: IChartSelectorMetric, metrics: IChartSelectorMetric[]): IChartSelectorMetric[] {
    return pipe<string | number, IChartSelectorMetric[], IChartSelectorMetric[], IChartSelectorMetric[]>(
      findMetricsByCategoryId,
      map((metric: IChartSelectorMetric) => ({
        ...metric,
        isParentCategoryVisibleInChart: category.isVisibleInChart,
      })),
    )(category.id, metrics);
  }

  public toggleIsExpanded(category: IChartSelectorMetric): void {
    this.displayedMetrics = keepInitialOrder(this.displayedMetrics, [category], (category) => ({
      isExpanded: !category.isExpanded,
    }));
  }

  public toggleMetricVisibility(metric: IChartSelectorMetric, categoryOfMetric?: IChartSelectorMetric): void {
    if (metric.isCategory) {
      this.toggleCategoryVisibility(metric);
    } else {
      this.toggleNestedMetricVisibility(metric, categoryOfMetric);
    }
  }

  public toggleNestedMetricVisibility(metric: IChartSelectorMetric, categoryOfMetric?: IChartSelectorMetric): void {
    this.onMetricsUpdate([
      toggleMetricVisibilityInChart(metric),
      ...(categoryOfMetric ? [setMetricVisibilityInChart(false)(categoryOfMetric)] : []),
    ]);
  }

  public toggleCategoryVisibility(category: IChartSelectorMetric): void {
    this.onMetricsUpdate([
      toggleMetricVisibilityInChart(category),
      ...map(setMetricVisibilityInChart(false), category.nestedMetrics),
    ]);
  }

  public onMetricsUpdate(updatedMetrics: IChartSelectorMetric[]): void {
    const metricsWithUpdatedVisibility = keepInitialOrder(this.metrics, updatedMetrics, pick(['isVisibleInChart']));

    this.updateMetrics.emit({
      metricsVisibleInChart: getMetricsVisibleInChart(metricsWithUpdatedVisibility),
    });
  }

  public onDisplayTypeChanged(displayType: string): void {
    this.selectedDisplayType = displayType;

    this.changeDisplayType.emit(displayType);
  }
}
