<div *ngIf="charts">
  <div *ngIf="!useLegendWithSelect" class="flex-row is-justify-content-flex-end">
    <app-chart-toggle
      *ngIf="!isEmptyState"
      class="full-width"
      [activeChartIndex]="selectedChartIndex"
      [charts]="charts"
      (selectChart)="handleSelectChart($event)"
    ></app-chart-toggle>
  </div>
  <app-chart-wrapper [chartContext]="chartContext" class="chart-wrapper">
    <app-empty-state-chart *ngIf="isEmptyState" [viewType]="viewType"></app-empty-state-chart>
    <apx-chart
      *ngIf="chartOptions"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [grid]="chartOptions.grid"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [markers]="chartOptions.markers"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  </app-chart-wrapper>
</div>
