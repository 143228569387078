import { Component } from '@angular/core';

@Component({
  selector: 'wevestr-maintenance-warning',
  templateUrl: './maintenance-warning.component.html',
  styleUrls: ['./maintenance-warning.component.scss'],
})
export class MaintenanceWarningComponent {
  public MAINTENANCE_COPY = {
    title: "We'll be back soon!",
    primaryText:
      "Sorry for the inconvenience but we're currently performing scheduled maintenance. We'll be back online shorty.",
    secondaryText: 'Thanks for your understanding!',
  };
}
