import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISidenavOption, ISidenavService } from '@app/types/interfaces';
import { CompanyService, PermissionsService } from '@app/shared/services';
import { IPermissionsMap } from '@app/shared/types/interfaces';
import {
  SIDEBAR_SECTIONS,
  TSidebarSection,
  TYPE_TO_SECTIONS_ORDER,
} from '@app/shared/utils/constants/sections.constants';
import { Section } from '@app/shared/types/enums/section.enum';
import { getUrlFromPath } from '@app/shared/utils/helpers/path.helpers';
import { SECTION_PATH } from '@app/shared/utils/constants/paths.constants';
import { CompanyDashboardType } from '@wevestr/bff-types/enums/companyDashboardType.enum';

@Injectable({
  providedIn: 'root',
})
export class CompanySidenavService implements ISidenavService {
  private sidebarSections: Record<TSidebarSection, ISidenavOption>;
  private _collapsed = new BehaviorSubject<boolean>(null);
  private _deviceWidth$ = new BehaviorSubject<number>(null);

  constructor(private permissionsService: PermissionsService, private companyService: CompanyService) {
    this.sidebarSections = this.getSidebarSections();
  }

  private getSidebarSections(): Record<TSidebarSection, ISidenavOption> {
    return Object.entries(SIDEBAR_SECTIONS).reduce((acc, [section, sectionContent]) => {
      acc[section as TSidebarSection] = this.addRouterLinkToOption(section as TSidebarSection, sectionContent);

      return acc;
    }, {} as Record<TSidebarSection, ISidenavOption>);
  }

  private addRouterLinkToOption(section: Section, option: Omit<ISidenavOption, 'routerLink'>): ISidenavOption {
    return {
      ...option,
      routerLink: getUrlFromPath(SECTION_PATH[section]),
    };
  }

  public omitSection(section: TSidebarSection): void {
    delete this.sidebarSections[section];
  }

  public get sidenavOptions$(): Observable<ISidenavOption[]> {
    return combineLatest([
      this.permissionsService.getPermissionMap$(),
      this.companyService.companyDashboardType$(),
    ]).pipe(map(([permissionMap, dashboardType]) => this.getPermittedSections(permissionMap, dashboardType)));
  }

  private getPermittedSections(permissionMap: IPermissionsMap, dashboardType: CompanyDashboardType): ISidenavOption[] {
    const orderedNavigation = TYPE_TO_SECTIONS_ORDER[dashboardType];

    return orderedNavigation.reduce((tabs, section) => {
      const isSectionPresentInSidebar = this.sidebarSections[section as TSidebarSection];

      if (isSectionPresentInSidebar && this.permissionsService.hasAccessToSection(section as Section, permissionMap)) {
        tabs.push(this.sidebarSections[section as TSidebarSection]);
      }

      return tabs;
    }, []);
  }

  public get collapsed$(): Observable<boolean> {
    return this._collapsed.asObservable();
  }

  public toggle(): void {
    this._collapsed.next(!this._collapsed.getValue());
  }

  public set collapsed(value: boolean) {
    this._collapsed.next(value);
  }

  public get deviceWidth$(): Observable<number> {
    return this._deviceWidth$.asObservable();
  }

  public set deviceWidth(value: number) {
    this._deviceWidth$.next(value);
  }
}
