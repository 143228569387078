import { Pipe, PipeTransform } from '@angular/core';
import { formatFileSizeToReadableFormat } from '@app/shared/utils/helpers/files.helpers';

@Pipe({
  name: 'formatToFileSizeString',
})
export class FormatToFileSizeStringPipe implements PipeTransform {
  transform(value: number): string {
    return formatFileSizeToReadableFormat(value);
  }
}
