import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexStates,
  ApexStroke,
  ApexTooltip,
  ApexAxisChartSeries,
  ApexXAxis,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexMarkers,
  ApexAnnotations,
} from 'ng-apexcharts';

export interface IChartOptions {
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  tooltip: ApexTooltip;
  colors: (string | ((value: number, seriesIndex: number, w: unknown) => string))[];
  stroke: ApexStroke;
  fill: ApexFill;
  states: ApexStates;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  grid: ApexGrid;
  title: ApexTitleSubtitle;
  markers: ApexMarkers;
  annotations: ApexAnnotations;
}

export interface IAxisChartOptions extends IChartOptions {
  series: ApexAxisChartSeries;
}

export interface INonAxisChartOptions extends IChartOptions {
  series: ApexNonAxisChartSeries;
}
