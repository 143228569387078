<div class="field-explainer__container">
  <div class="flex-row">
    <mat-icon svgIcon="light"></mat-icon>
    <h5 class="app-h5-bold padding-bottom-s padding-left-s">{{ title }}</h5>
  </div>

  <div class="app-body description">{{ description }}</div>
  <div *ngIf="extraDescription" class="app-body description margin-top-l">{{ extraDescription }}</div>
  <div *ngIf="boldDescription" class="app-body-bold description">{{ boldDescription }}</div>
</div>
