import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeToFormControls } from '@app/forms/types';

type CommentFormData = {
  name: string | null;
  comment: string | null;
};
type CommentForm = TypeToFormControls<CommentFormData>;

@Component({
  selector: 'wevestr-name-comment-form',
  templateUrl: './name-comment-form.component.html',
  styleUrls: ['./name-comment-form.component.scss', './name-comment-form.component.override.scss'],
})
export class NameCommentFormComponent implements OnInit {
  @Input() public nameTitle: string;
  @Input() public namePlaceholder: string;
  @Input() public commentTitle: string;
  @Input() public commentPlaceholder: string;
  @Input() public isRequestAlive: boolean;

  @Output() public saveEntity = new EventEmitter<{ name: string; comment: string }>();

  public form: FormGroup<CommentForm>;

  public readonly ERRORS_MAP = {
    required: 'This field is required',
  };

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.initForm();
  }

  public submitForm(): void {
    if (this.form.valid) {
      this.emitSave();
    }
  }

  private emitSave(): void {
    const { name, comment } = this.form.getRawValue();

    this.saveEntity.emit({ name, comment });
  }

  private initForm(): FormGroup<CommentForm> {
    return this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required]),
      comment: [null],
    });
  }
}
