import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { DemoService } from '@app/shared/services/demo.service';
import { IInvite } from '@app/auth/types/interfaces';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { ToastService } from '@app/shared/services/toast.service';
import { DEMO_INVITATION_INVALID } from '@app/shared/utils/constants/toast-messages.constants';
import { ConfigService } from '@app/services/config.service';

@Injectable()
export class DemoInvitationGuard {
  constructor(
    private demoService: DemoService,
    private toastService: ToastService,
    private router: Router,
    private configService: ConfigService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.configService.isDemoModeEnabled()) {
      this.router.navigate(loginPath);
      return of(false);
    }
    const demoInvitationCode: string = route.queryParams.code;

    if (!demoInvitationCode) {
      this.toastService.error(DEMO_INVITATION_INVALID);
      this.router.navigate(loginPath);
    }

    return this.demoService.validate(demoInvitationCode).pipe(
      map((invite: IInvite) => invite.valid),
      tap((valid: boolean) => {
        if (!valid) {
          this.toastService.error(DEMO_INVITATION_INVALID);
          this.router.navigate(loginPath);
        }
      }),
      catchError(() => this.router.navigate(loginPath)),
    );
  }
}
