import { LoginComponent } from '@app/auth/containers/login/login.component';
import { ForgotCredentialsComponent } from '@app/auth/containers/forgot-credentials/forgot-credentials.component';
import { PasswordResetComponent } from '@app/auth/containers/password-reset/password-reset.component';
import { WelcomeDemoComponent } from '@app/auth/containers/welcome-demo/welcome-demo.component';
import { VerifyCodeComponent } from '@app/auth/containers/verify-code/verify-code.component';
import { EmailChangeApprovalComponent } from '@app/auth/containers/email-change-approval/email-change-approval.component';
import { RevokeAccessComponent } from '@app/auth/containers/revoke-access/revoke-access.component';

export const containers = [
  LoginComponent,
  ForgotCredentialsComponent,
  PasswordResetComponent,
  VerifyCodeComponent,
  EmailChangeApprovalComponent,
  WelcomeDemoComponent,
  RevokeAccessComponent,
];

export * from '@app/auth/containers/login/login.component';
export * from '@app/auth/containers/forgot-credentials/forgot-credentials.component';
export * from '@app/auth/containers/password-reset/password-reset.component';
export * from '@app/auth/containers/welcome-demo/welcome-demo.component';
export * from '@app/auth/containers/verify-code/verify-code.component';
export * from '@app/auth/containers/email-change-approval/email-change-approval.component';
export * from '@app/auth/containers/revoke-access/revoke-access.component';
