import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, startWith, take, tap } from 'rxjs/operators';

import { DatePickerHeaderComponent } from '@app/forms/components/date-picker/date-picker-header/date-picker-header.component';
import { FormErrorDirective } from '@app/forms/directives/form-error/form-error.directive';
import { DatePickerType } from '@app/forms/types/datepicker.types';
import { VALIDITY_STATUS } from '@app/shared/utils/constants/validators.constants';
import { WevestrDateAdapterService } from '@app/forms/services/wevestr-date-adapter.service';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';
import {
  DAY_MONTH_YEAR_LENGTH,
  INVALID_DATE,
  DAY_MONTH_YEAR_DATE_FORMAT,
  MAX_NUMBER_OF_DAYS,
  NUMBER_OF_MONTH,
} from '@app/shared/utils/constants/date.constants';
import { VALIDATION_DELAY } from '@app/forms/constants';

@Component({
  selector: 'wevestr-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useExisting: WevestrDateAdapterService },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' } },
        display: {
          dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
          monthYearLabel: { year: 'numeric', month: 'long' },
        },
      },
    },
  ],
})
export class DatePickerComponent implements AfterContentInit {
  @Input()
  public inputClassName: string;
  @Input()
  public placeholder = '';
  @Input()
  public showAllErrors = true;
  @Input()
  public type: DatePickerType = DatePickerType.date;
  @Input()
  public min: Date;
  @Input()
  public max: Date;
  @Input()
  public dateFilter: (date: Date) => boolean = () => true;
  @Input()
  public highlightValidation = true;
  @Input()
  private showValidityOnInit = false;
  @Input()
  public disabled: boolean;
  @Input()
  public viewDate: Date = null;

  @Output()
  public dateChange = new EventEmitter<Date>();

  @ContentChildren(FormErrorDirective)
  public errors: QueryList<FormErrorDirective>;

  public pickerTypes = DatePickerType;

  public customHeaderComponent = DatePickerHeaderComponent;
  public formControlName: string | number;
  public isValid$: Observable<boolean>;
  private disabledControlStatus: boolean;
  public disabledState: boolean;

  private _display: string;
  private _date: Date;
  private _ngChangeCallback: (value: Date) => void;
  private _ngTouchCallback: () => void;
  private showValidation$ = new BehaviorSubject<boolean>(this.showValidityOnInit);

  public get display(): string {
    return this._display;
  }

  public set display(value: string) {
    this.writeValue(value);
  }

  public get date(): Date {
    return this._date;
  }

  public set date(date: Date) {
    this.writeValue(date);
  }

  constructor(@Optional() @Self() public abstractControl: NgControl, private dateFormat: DateFormatPipe) {
    if (abstractControl) {
      abstractControl.valueAccessor = this;
    }
  }

  public ngAfterContentInit(): void {
    if (this.abstractControl) {
      this.formControlName = this.abstractControl.name;
      if (this.abstractControl.value && !isNaN(Date.parse(this.abstractControl.value))) {
        this._display = this.convertDateToString(new Date(Date.parse(this.abstractControl.value)));
      }
      this.isValid$ = this._getValidity$();
      this.showValidation$.next(this.showValidityOnInit);
      this.abstractControl.valueChanges.pipe(take(1)).subscribe(() => this.showValidation$.next(true));
    }
  }

  public writeValue(value: string | Date): void {
    if (value instanceof Date) {
      this._display = this.convertDateToString(value);
      this._date = value;
      this.onChange(value);
      this.dateChange.emit(value);
    } else if (!value) {
      this.onChange(null);
      this._display = void 0;
      this._date = void 0;
    } else {
      const date = this.converStringToDate(value);
      this._display = value;
      this._date = date;
      this.onChange(date);
    }
  }

  public preventTextInput(event: KeyboardEvent): void {
    const allowedCharacters = /^[\d -]+$/;
    const isInputBlocked = !allowedCharacters.test(event.key);
    isInputBlocked && event.preventDefault();
  }

  public monthSelected(date: Date, picker: MatDatepicker<Date>): void {
    this.writeValue(date);
    picker.close();
  }

  public registerOnTouched(ngOnTouchCallback: () => void): void {
    this._ngTouchCallback = ngOnTouchCallback;
  }

  public registerOnChange(ngOnChangeCallback: (value: Date) => void): void {
    this._ngChangeCallback = ngOnChangeCallback;
  }

  public onTouch(): void {
    if (this._ngChangeCallback instanceof Function) {
      this.showValidation$.next(true);
      this._ngTouchCallback();
    }
  }

  public onChange(change: Date): void {
    if (this._ngChangeCallback instanceof Function) {
      this._ngChangeCallback(change);
    }
  }

  private _getValidity$(): Observable<boolean> {
    return combineLatest([this._getStatus$(), this.showValidation$.pipe(filter((show) => show))]).pipe(
      map(([status]) => status === VALIDITY_STATUS.VALID || status === VALIDITY_STATUS.DISABLED),
      filter(() => !this.abstractControl.pristine || this.showValidityOnInit),
      debounceTime(VALIDATION_DELAY),
      tap(() => this.errors.forEach((error) => error.toggleError(false))),
      filter(() => !this.disabled),
      tap(() => {
        const controlErrors = Object.keys(this.abstractControl.errors ?? {});

        controlErrors
          .map((error) => this.errors.find((errorDirective) => errorDirective.errorIds.has(error)))
          .sort((errorA, errorB) => errorA.order - errorB.order)
          .slice(0, this.showAllErrors ? Infinity : 1)
          .forEach((error) => error?.toggleError(true));
      }),
    );
  }

  private _getStatus$(): Observable<VALIDITY_STATUS> {
    return this.abstractControl.statusChanges.pipe(
      startWith(this.abstractControl.status),
      tap((status: VALIDITY_STATUS) => {
        this.disabledControlStatus = status === VALIDITY_STATUS.DISABLED;
        this.disabledState = this.disabled === void 0 ? this.disabledControlStatus : this.disabled;
      }),
    );
  }

  private converStringToDate(value: string): Date {
    const [day, month, year] = value?.split('-').map((datePart) => +datePart || void 0) ?? [];
    if (value.length !== DAY_MONTH_YEAR_LENGTH || day > MAX_NUMBER_OF_DAYS || month > NUMBER_OF_MONTH) {
      return INVALID_DATE;
    }
    return new Date(year, month - 1, day);
  }

  private convertDateToString(value: Date): string {
    return this.dateFormat.transform(value, DAY_MONTH_YEAR_DATE_FORMAT);
  }
}
