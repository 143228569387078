<ng-container *wvLet="isValid$ | async as valid">
  <div
    class="date-form-field"
    [ngClass]="{
      'valid-date-form-field': valid && highlightValidation && !disabledState,
      'invalid-date-form-field': valid === false && highlightValidation && !disabledState,
      'disabled-date-form-field': disabledState
    }"
  >
    <label [for]="formControlName"><ng-content select="[label]"></ng-content></label>
    <div class="date-input-wrapper" [ngClass]="inputClassName">
      <ng-container *ngIf="type === pickerTypes.date; else monthDatePicker">
        <input
          [(ngModel)]="display"
          type="text"
          [placeholder]="placeholder"
          [id]="formControlName"
          (keypress)="preventTextInput($event)"
          (blur)="onTouch()"
          (click)="picker.open()"
          class="date-input no-autofill-bkg"
          [disabled]="disabledState"
        />
        <input
          class="hidden"
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="date"
          [min]="min"
          [max]="max"
          [matDatepickerFilter]="dateFilter"
          disabled
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon
            data-id="Icon-DatePicker"
            matDatepickerToggleIcon
            svgIcon="datepicker"
            class="toggle calendar-icon"
          ></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker
          disabled="false"
          #picker
          [calendarHeaderComponent]="customHeaderComponent"
          [disabled]="disabledState"
          [startAt]="viewDate"
        >
        </mat-datepicker>
      </ng-container>
    </div>
  </div>
  <ng-content select="[errors]"></ng-content>

  <div class="input-descriptions">
    <ng-content select="[descriptions]"></ng-content>
  </div>
</ng-container>

<ng-template #monthDatePicker>
  <ng-container wvMonthPickerFormat>
    <span (click)="monthPicker.open()" class="full-width">
      <input
        matInput
        [matDatepicker]="monthPicker"
        [placeholder]="placeholder"
        [(ngModel)]="date"
        [min]="min"
        [max]="max"
        [matDatepickerFilter]="dateFilter"
        (click)="monthPicker.open()"
        class="date-input month-input"
      />
    </span>
    <mat-datepicker-toggle matSuffix [for]="monthPicker">
      <mat-icon matDatepickerToggleIcon svgIcon="dropdown" class="toggle"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker
      panelClass="hide-month-view"
      #monthPicker
      [calendarHeaderComponent]="customHeaderComponent"
      [startView]="type"
      (closed)="onTouch()"
      (monthSelected)="monthSelected($event, monthPicker)"
      [disabled]="disabledState"
    >
    </mat-datepicker>
  </ng-container>
</ng-template>
