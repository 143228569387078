import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpLoadingService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this._isLoading.asObservable();

  setLoading(isLoading: boolean): void {
    this._isLoading.next(isLoading);
  }
}
