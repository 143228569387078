import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { loginPath } from '@app/shared/utils/constants/paths.constants';

@Injectable({
  providedIn: 'root',
})
export class VerifyCodeGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (!this.authService.token2fa) {
      this.router.navigate(loginPath);
      return false;
    }

    return true;
  }
}
