import { Pipe, PipeTransform } from '@angular/core';
import { INDICATOR_EMPTY_STATE_DISPLAY_VALUE } from '@app/shared/utils/constants/common.constants';
import { isEmptyIndicatorState } from '@app/shared/utils/helpers/common.helpers';

@Pipe({
  name: 'indicatorFormat',
})
export class IndicatorFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    return isEmptyIndicatorState(value) ? INDICATOR_EMPTY_STATE_DISPLAY_VALUE : value.toString();
  }
}
