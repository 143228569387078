import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MenuTriggerDirective } from '@app/shared/directives';
import { MENU_BORDER_WIDTH, MENU_MARGIN_BOTTOM } from '@app/shared/utils/constants/menu.constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  arrowTop: number;
  isMenuOnTop = false;
  menuTop: number;
  menuLeft: number;
  opened = false;

  @ContentChild(MenuTriggerDirective, { read: ElementRef }) trigger: ElementRef;

  @ViewChild('menu') set menu(elementRef: ElementRef) {
    if (!elementRef) {
      return;
    }

    this._menu = elementRef;

    if (
      elementRef.nativeElement.offsetHeight <=
      document.body.clientHeight - this.eventTarget.getBoundingClientRect().bottom
    ) {
      this.menuTop = this.eventTarget.getBoundingClientRect().bottom;
      this.isMenuOnTop = false;
    } else {
      this.arrowTop = elementRef.nativeElement.offsetHeight - MENU_BORDER_WIDTH;
      this.menuTop =
        this.eventTarget.getBoundingClientRect().top - elementRef.nativeElement.offsetHeight - MENU_MARGIN_BOTTOM;
      this.isMenuOnTop = true;
    }

    this.menuLeft = this.eventTarget.getBoundingClientRect().x;
    this.changeDetectorRef.detectChanges();
  }

  private eventTarget: HTMLElement;
  private _menu: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('document:click', ['$event.target'])
  hide(eventTarget: HTMLElement) {
    this.opened = !this.clickedOutsideMenu(eventTarget) && !this.opened;

    if (this.opened) {
      this.eventTarget = eventTarget;
      document.addEventListener('scroll', this.handleScroll, true);
    } else {
      document.removeEventListener('scroll', this.handleScroll, true);
    }
  }

  ngOnInit(): void {
    this.handleScroll = this.handleScroll.bind(this);
  }

  private clickedOutsideMenu(eventTarget: HTMLElement): boolean {
    return !this.trigger.nativeElement.contains(eventTarget);
  }

  private handleScroll(): void {
    this.menuTop = this.isMenuOnTop
      ? this.eventTarget.getBoundingClientRect().top - this._menu.nativeElement.offsetHeight - MENU_MARGIN_BOTTOM
      : this.eventTarget.getBoundingClientRect().bottom;
    this.changeDetectorRef.detectChanges();
  }
}
