export interface XAxisLabelPosition {
  grantDatePosition: number;
  firstVestingDatePosition: number;
  lastVestingDatePosition: number;
  lastDatePosition: number;
}

export interface XAxisLabelOptions {
  gridValue: string;
  firstVestingDateLabel?: Date;
  lastVestingDateLabel?: Date;
}
