import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

import { Permission } from '@app/shared/types/enums/permission.enum';
import { takeUntil } from 'rxjs/operators';
import { PermissionsService } from '@app/shared/services/permissions.service';

export class WvHasEditAccessContext {
  public $implicit: Permission = null;
  public wvHasEditAccess: Permission = null;
}

/** Directive that allows template to display only if user has edit access for permission
 * @example
 * <div *wvHasEditAccess="Permission.dashboardAccess"></div>
 */
@Directive({
  selector: '[wvHasEditAccess]',
})
export class HasEditAccessDirective implements OnDestroy {
  private context: WvHasEditAccessContext = new WvHasEditAccessContext();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<WvHasEditAccessContext>,
    private permissionsService: PermissionsService,
  ) {}

  @Input('wvHasEditAccess')
  public set wvHasEditAccess(permission: Permission) {
    this.context.$implicit = this.context.wvHasEditAccess = permission;
    this.updateView(permission);
  }

  private updateView(permission: Permission): void {
    this.permissionsService
      .hasEditAccess$(permission)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((hasEditAccess) => {
        if (hasEditAccess) {
          this.showContent();
        } else {
          this.hideContent();
        }
      });
  }

  private hideContent(): void {
    this.viewContainerRef.clear();
  }

  private showContent(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }

  public static ngTemplateGuard_wvHasEditAccess: 'binding';

  public static ngTemplateContextGuard(_dir: WvHasEditAccessContext, _ctx: unknown): _ctx is keyof Permission {
    return true;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
