import { ITableColumnSortDetails } from '@app/shared/types/interfaces';
import { ColumnOrdering } from '@app/shared/types/enums';

export function initColumnSortDetails(sortLabel: string, order = ColumnOrdering.none): ITableColumnSortDetails {
  return <ITableColumnSortDetails>{
    sortBy: sortLabel,
    order: order,
  };
}

export const TABLE_DETAILS_COLOR = '#919394';
export const TABLE_DETAILS_STYLE = {
  'font-size': '12px',
  color: TABLE_DETAILS_COLOR,
};
export const TABLE_HEADER_S_BOLD = { 'font-size': '12px', 'font-weight': '600' };

export const TABLE_BOLD_FONT = { 'font-weight': '600' };
