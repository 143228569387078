import { Injectable, Type, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TopSectionService {
  /**
   * Dynamic components can be injected here to be displayed in the top right part
   */
  public topRightContent: ViewContainerRef;

  public addRightContentComponent<T>(component: Type<T>): void {
    this.topRightContent?.createComponent(component);
  }

  public clearRightContentComponent(): void {
    if (this.topRightContent) {
      this.topRightContent.clear();
    }
  }
}
