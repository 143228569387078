import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

enum DropdownMenuPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

@Component({
  selector: 'wevestr-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() placeholder: string;
  @Input() menuPosition: DropdownMenuPosition = DropdownMenuPosition.LEFT;

  public isOpened = false;

  @ViewChild('dropdown', { static: true, read: ElementRef }) private dropdown: ElementRef;

  @HostListener('document:click', ['$event'])
  public hide(event: MouseEvent): void {
    const isClickOutside = this.isClickOutsideDropdown(event);
    this.isOpened = this.isOpened && !isClickOutside;
  }

  private isClickOutsideDropdown(event: MouseEvent): boolean {
    return event.composedPath().indexOf(this.dropdown.nativeElement) === -1;
  }

  public toggleDropdown(event?: Event): void {
    event?.stopPropagation();
    this.isOpened = !this.isOpened;
  }
}
