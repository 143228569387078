import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFolderActionData } from '@app/documents/types/interfaces/folder-action-data.interface';

@Component({
  selector: 'app-delete-folder-confirmation-modal',
  templateUrl: './delete-folder-confirmation-modal.component.html',
  styleUrls: ['./delete-folder-confirmation-modal.component.scss'],
})
export class DeleteFolderConfirmationModalComponent {
  public inputValue = '';
  public optionChecked = false;
  public readonly CONFIRMATION_STRING = 'delete';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public folderData: IFolderActionData,
    private dialogRef: MatDialogRef<DeleteFolderConfirmationModalComponent>,
  ) {}

  public handleConfirm(): void {
    const isDeleteActionConfirmed = true;
    this.dialogRef.close({
      deleteConfirmed: isDeleteActionConfirmed,
      shouldDeleteContent: this.optionChecked,
    });
  }
}
