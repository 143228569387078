<div class="explained-field">
  <div class="field" (focusin)="!disabled && (isInFocus = true)" (focusout)="!disabled && (isInFocus = false)">
    <ng-content></ng-content>
  </div>
  <wevestr-field-explainer
    [@openClosed]="explainerState"
    class="explainer padding-left-m padding-bottom-m"
    [ngClass]="{'is-shown': isShownOnHover}"
    [title]="title"
    [description]="description"
    [extraDescription]="extraDescription"
    [boldDescription]="boldDescription"
  ></wevestr-field-explainer>
</div>
