<div class="tabs full-width" [ngClass]="{small: isSmall, 'full-height': fullHeight, tabs_financials: isFinancials}">
  <ul class="flex-row">
    <li
      *ngFor="let tab of tabs"
      (click)="handleTabClick(tab)"
      [ngClass]="{
        'is-active':
          tab.route === activeRoute ||
          (tab.queryParams && queryParameterValue && tab.queryParams[queryParameter] === queryParameterValue),
        'is-disabled': tab.disabled || tabs.length === 1,
        'full-height': fullHeight,
        'full-width': isFinancials
      }"
    >
      <div
        *ngIf="suppressNavigation; else navigatableTab"
        class="tab"
        [ngClass]="{'full-height': fullHeight, 'cursor-pointer': true}"
      >
        <span [class]="isSmall ? 'app-body-medium-weight' : 'app-h4-medium-weight'">{{ tab.name }}</span>
        <div *ngIf="tab.isBeta" class="beta-sign flex-column is-align-items-center is-justify-content-center">
          <div class="sign">Beta</div>
        </div>
      </div>
      <ng-template #navigatableTab>
        <a
          [routerLink]="tab.route"
          [queryParams]="tab.queryParams"
          queryParamsHandling="merge"
          class="tab"
          [ngClass]="{'full-height': fullHeight}"
        >
          <span [class]="isSmall ? 'app-body-medium-weight' : 'app-h4-medium-weight'">{{ tab.name }}</span>
          <div *ngIf="tab.isBeta" class="beta-sign flex-column is-align-items-center is-justify-content-center">
            <div class="sign">Beta</div>
          </div>
        </a>
      </ng-template>
    </li>
  </ul>
</div>
