<app-dialog title="Delete Folder" [closeOnEsc]="!confirmationInput.dirty && !confirmationCheckbox.dirty">
  <section class="modal-body">
    <div class="app-h4 medium-weight margin-bottom-m-const"
      >You are about to delete the Folder {{ folderData.title }}. This action is unreversable and will move the
      documents inside this folder to your main document folder.</div
    >

    <div class="app-body-bold margin-bottom-s">Please type DELETE and confirm.</div>
    <form class="modal-body__form form">
      <input type="text" #confirmationInput="ngModel" [(ngModel)]="inputValue" name="inputValue" autocomplete="off" />
    </form>
    <div class="checkbox-wrapper centered-checkbox margin-bottom-m margin-top-m">
      <input type="checkbox" #confirmationCheckbox="ngModel" [(ngModel)]="optionChecked" />
      <label class="app-body-medium-weight font-weight-bold">Delete folder and its contents</label>
    </div>
  </section>
  <section class="modal-footer">
    <button
      class="is-primary-button button base-button app-body"
      [disabled]="!(inputValue | confirmAction : CONFIRMATION_STRING)"
      (click)="handleConfirm()"
    >
      Delete Folder
    </button>
  </section>
</app-dialog>
