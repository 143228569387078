import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InvitationsService } from '@app/auth/services/invitations.service';
import { map, tap, catchError } from 'rxjs/operators';
import { IInvite } from '@app/auth/types/interfaces/invite.interface';
import { loginPath } from '@app/shared/utils/constants/paths.constants';

@Injectable()
export class InvitationGuard {
  constructor(private invitationsService: InvitationsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const invitationCode: string = route.queryParams.invitation;

    return this.invitationsService.validate(invitationCode).pipe(
      map((invite: IInvite) => invite.valid),
      tap((valid: boolean) => {
        if (!valid) {
          this.router.navigate(loginPath);
        }
      }),
      catchError(() => this.router.navigate(loginPath)),
    );
  }
}
