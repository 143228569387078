import { ROOT_PATH } from '@app/shared/utils/constants/common.constants';
import { head } from 'ramda';
import { Path } from '@app/shared/types/path';

export function getUrlFromPath(path: Path): string {
  const pathWithoutRoot = isPathBeginningWithRootPath(path) ? omitRootPath(path) : path;
  return pathWithoutRoot.join('/');
}

function isPathBeginningWithRootPath(path: Path): boolean {
  return head(path).startsWith(ROOT_PATH);
}

function omitRootPath(path: Path): Path {
  const pathHead = head(path);
  let pathWithoutRoot: Path;

  if (pathHead === ROOT_PATH) {
    pathWithoutRoot = removeHeadFromPath(path);
  } else {
    const pathWithoutHead = removeHeadFromPath(path);
    const pathHeadWithoutRoot = pathHead.slice(1);

    pathWithoutRoot = [pathHeadWithoutRoot, ...pathWithoutHead];
  }

  return pathWithoutRoot;
}

function removeHeadFromPath(path: Path): Path {
  return path.slice(1);
}
