import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { prop } from 'ramda';
import { ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { ApexAxisChartSeries } from 'ng-apexcharts/lib/model/apex-types';

import { IChartValue } from '@app/shared/types/interfaces/chart-value.interface';
import { IAxisChartOptions } from '@app/shared/types/interfaces';
import {
  DATA_LABELS_BARCHART_OPTIONS,
  FILL_BARCHART_OPTIONS,
  FULL_OPACITY,
  GRID_BARCHART_OPTIONS,
  HALF_OPACITY,
  LEGEND_BARCHART_OPTIONS,
  MIXEDCHART_OPTIONS,
  PLOT_BARCHART_OPTIONS,
  STATES_BARCHART_OPTIONS,
  XAXIS_BARCHART_OPTIONS,
  AXIS_ANNOTATIONS,
  YAXIS_BARCHART_OPTIONS,
} from '@app/shared/components/mixed-barchart/mixed-barchart.constants';
import { NumberFormatPipe } from '@app/shared/pipes';
import { ChartSeriesTypes, ChartTypes } from '@app/shared/types/enums/chart-types.enum';

interface ApexChartEventConfig {
  dataPointIndex: number;
  w: { globals: { dom: { baseEl: HTMLElement } } };
}

@Component({
  selector: 'wevestr-mixed-barchart',
  templateUrl: './mixed-barchart.component.html',
  styleUrls: ['./mixed-barchart.component.scss'],
})
export class MixedBarchartComponent implements OnInit, OnChanges {
  @Input() public labels: string[] = [];
  @Input() public values: IChartValue[] = [];
  @Input() public defaultChartOptions: Partial<IAxisChartOptions>;

  public chartOptions: Partial<IAxisChartOptions>;

  constructor(private numberFormatPipe: NumberFormatPipe) {}

  public ngOnInit(): void {
    this.initChartOptions();
  }

  public ngOnChanges(): void {
    if (this.chartOptions) {
      this.updateChartOptions();
    }
  }

  private setElementOpacity =
    (opacity: string) =>
    (element: Element): void => {
      (element as HTMLElement).style.opacity = opacity;
    };

  private getDataPointsCollection<T extends ApexChartEventConfig>(config: T): NodeListOf<Element> {
    return config.w.globals.dom.baseEl.querySelectorAll('.apexcharts-bar-series .apexcharts-series');
  }

  private handleDataPointMouseEnter<T extends ApexChartEventConfig>(config: T): void {
    const dataPointIndex = config.dataPointIndex;
    const dataPointGroups = this.getDataPointsCollection(config);
    dataPointGroups.forEach((dataPointGroup) =>
      Array.from(dataPointGroup.children).forEach((dataPoint, index) => {
        if (index === dataPointIndex) {
          this.setElementOpacity(FULL_OPACITY)(dataPoint);
        } else {
          this.setElementOpacity(HALF_OPACITY)(dataPoint);
        }
      }),
    );
  }

  private handleDataPointMouseLeave<T extends ApexChartEventConfig>(config: T): void {
    const dataPointGroups = this.getDataPointsCollection(config);
    dataPointGroups.forEach((dataPointGroup) =>
      Array.from(dataPointGroup.children).forEach(this.setElementOpacity(FULL_OPACITY)),
    );
  }

  private initChartOptions(): void {
    this.chartOptions = {
      series: this.getSeries(),
      chart: {
        ...MIXEDCHART_OPTIONS,
        events: {
          dataPointMouseEnter: (_1, _2, config) => this.handleDataPointMouseEnter(config),
          dataPointMouseLeave: (_1, _2, config) => this.handleDataPointMouseLeave(config),
        },
      },
      dataLabels: DATA_LABELS_BARCHART_OPTIONS,
      plotOptions: PLOT_BARCHART_OPTIONS,
      xaxis: this.getXaxis(),
      yaxis: this.getYaxis(),
      legend: LEGEND_BARCHART_OPTIONS,
      grid: GRID_BARCHART_OPTIONS,
      colors: this.getColors(),
      states: STATES_BARCHART_OPTIONS,
      fill: FILL_BARCHART_OPTIONS,
      annotations: AXIS_ANNOTATIONS,
      ...this.defaultChartOptions,
    };
  }

  private updateChartOptions(): void {
    const series = this.getSeries();
    this.chartOptions = {
      ...this.chartOptions,
      ...this.defaultChartOptions,
      xaxis: this.getXaxis(),
      colors: this.getColors(),
      series,
    };
    this.updateChartType(series);
  }

  private updateChartType(series: ApexAxisChartSeries): void {
    const isSingleColumnSeries = series.length === 1 && series[0].type === ChartSeriesTypes.COLUMN;
    this.chartOptions.chart.type = isSingleColumnSeries ? ChartTypes.BAR : ChartTypes.LINE;
  }

  private getXaxis(): ApexXAxis {
    return {
      ...XAXIS_BARCHART_OPTIONS,
      categories: this.labels,
    };
  }

  private getYaxis(): ApexYAxis {
    return {
      ...YAXIS_BARCHART_OPTIONS,
      labels: {
        ...YAXIS_BARCHART_OPTIONS.labels,
        formatter: (value: number) => this.defaultYAxisFormatter(value),
      },
    };
  }

  private defaultYAxisFormatter(value: number): string {
    const formatWithoutDecimals = '1.0-0';
    return this.numberFormatPipe.transform(value, formatWithoutDecimals);
  }

  private getColors(): string[] {
    return this.values.map(prop('color'));
  }

  private getSeries(): ApexAxisChartSeries {
    return this.values.map(({ name, data, type }) => ({ name, data, type }));
  }
}
