import { UntypedFormGroup } from '@angular/forms';
import { not } from 'ramda';

import { Currency, Frequency, EditModeTableAction, Size } from '@app/shared/types/enums';
import { TABLE_HEADER_ROW_HEIGHT, TABLE_ROW_HEIGHT } from '@app/shared/utils/constants/stakeholder.contants';
import { ITableOptions } from '@app/types/interfaces/table-options.interface';
import { deepClone, getNarrowCurrencySymbol } from '@app/shared/utils/helpers/common.helpers';
import { FinancialMetricType, CategoryType } from '@app/performance/types/enums';
import { NumberFormatting } from '@app/settings/types/enums';
import { NUMEROUS_COMMAS_REGEX, NUMEROUS_DOTS_REGEX } from '@app/shared/utils/helpers/regex.helpers';
import { SEPARATORS } from '@app/settings/constants/settings.constants';
import { IFinancialMetric } from '@app/performance/types/interfaces/financial-metric.interface';
import { IKpi } from '@app/performance/types/interfaces/kpi.interface';
import { getPercentageFromAmount } from '@app/shared/utils/helpers/stakeholder.helpers';
import { ITooltipDataLabelsPercents, ITooltipValue } from '@app/shared/types/interfaces';
import { IFinancialMetricYear } from '@app/performance/types/interfaces';

export function getViewTypeFormattedHeaderColumnName(label: string, viewType: Frequency, year: number): string {
  if (viewType === Frequency.QUARTERLY) {
    return `${label}/${String(year % 100).padStart(2, '0')}`;
  }

  return label;
}

const performanceBaseTableOptions = {
  headerHeight: TABLE_HEADER_ROW_HEIGHT,
  cellPaddingSize: Size.S,
  editModeActions: [EditModeTableAction.EDIT],
  lastColumnPaddingLeft: '15px',
  tablePaddingLeft: '40px',
  tablePaddingRight: '42px',
  categoryPaddingLeft: '60px',
  categorizedElementPaddingLeft: '65px',
} as Partial<ITableOptions>;

export function getKpisBaseTableOptions(): Partial<ITableOptions> {
  return deepClone({
    ...performanceBaseTableOptions,
    rowHeight: TABLE_ROW_HEIGHT,
    isCategorized: true,
    allowCreateCategories: true,
  });
}

export function sortByPositions<T extends { position?: { value: number } }>(a: T, b: T): number {
  if (!a.position && b.position) {
    return -1;
  } else if (a.position && !b.position) {
    return 1;
  } else if (!a.position && !b.position) {
    return 0;
  }

  return a.position.value - b.position.value;
}

export function getCategoryTypeFromFinancials(financialsType: FinancialMetricType): CategoryType {
  switch (financialsType) {
    case FinancialMetricType.COST_DRIVER:
      return CategoryType.financialsCost;
    case FinancialMetricType.REVENUE_DRIVER:
      return CategoryType.financialsRevenue;
  }
}

export const columnFields = {
  FULL_YEAR: 'fy',
  NAME: 'name',
  TYPE: 'type',
  YEARLY_METRIC: 'yearlyMetricType',
};

export function formatUnchangedValues(
  form: UntypedFormGroup,
  formatting: NumberFormatting,
  excludedKeys: string[],
  currencyCode: string | Currency = Currency.EUR,
): Record<string, string | number> {
  const values: Record<string, number> = Object.assign({}, form.value);
  for (const key in form.controls) {
    if (!excludedKeys.includes(key) && form.controls[key].pristine === true) {
      values[key] = parseCurrencyValue(form.value[key], formatting, currencyCode);
    }
  }

  return values;
}

export function parseCurrencyValue(
  value: string | number,
  formatting: NumberFormatting,
  currencyCode: string | Currency = Currency.EUR,
): number {
  let result: number;
  if (typeof value === 'string') {
    const parsed = value.toString().replace(getNarrowCurrencySymbol(currencyCode), '');
    if (formatting === NumberFormatting.EUROPEAN) {
      result = parseFloat(parsed.toString().replace(NUMEROUS_DOTS_REGEX, '').replace(SEPARATORS.COMMA, SEPARATORS.DOT));
    } else if (formatting === NumberFormatting.AMERICAN) {
      result = parseFloat(parsed.toString().replace(NUMEROUS_COMMAS_REGEX, ''));
    }
  } else {
    result = value;
  }

  return result;
}

export function isMetricEmpty(metric: IFinancialMetric): boolean {
  return !metric.years || metric.years.length === 0;
}

export function isMetricYearHaveValues({ values }: IFinancialMetricYear): boolean {
  return values.some(({ value }) => !!value);
}

export function isMetricValuesNull({ years }: IFinancialMetric): boolean {
  return not(years?.some((year) => isMetricYearHaveValues(year)));
}

export function areAllDummies(values: (IKpi | IFinancialMetric)[]): boolean {
  return values.every((value: IKpi | IFinancialMetric) => value.isDummy);
}

export function areAllMetricsValuesNull(metrics: IFinancialMetric[]): boolean {
  return metrics.every((metric: IFinancialMetric) => isMetricValuesNull(metric));
}

export function calculatePercentage(current: number, previous: number): number {
  return getPercentageFromAmount(current - previous, previous);
}

export function calculateAllPercentages(
  currentDateLabel: ITooltipValue,
  [previousDateLabel, beforePreviousDateLabel, sameDateLabelOfPreviousYear]: ITooltipValue[] = [],
): ITooltipDataLabelsPercents {
  const tooltipPercents = {} as ITooltipDataLabelsPercents;

  tooltipPercents.currentDateLabelPercent = calculatePercentage(currentDateLabel.value, previousDateLabel.value);
  tooltipPercents.previousDateLabelPercent = calculatePercentage(
    previousDateLabel.value,
    beforePreviousDateLabel.value,
  );
  tooltipPercents.yoYPercent = calculatePercentage(currentDateLabel.value, sameDateLabelOfPreviousYear.value);

  return tooltipPercents;
}

export const KPIS_REQUIRED_FIELDS = ['name'];
