import { path, pipe, equals } from 'ramda';

import { ROOT_FOLDER_ID } from '@app/documents/constants/documents.constants';
import { IDocumentBase } from '@app/documents/types/interfaces/document.interface';
import { getExtensionFromFileName } from '@app/shared/utils/helpers/files.helpers';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

export function isRootFolder(folderId: number): boolean {
  return ROOT_FOLDER_ID === folderId;
}

export const getDocumentMimeType = path<string>(['fileObject', 'contentType']);
export const getDocumentFileName = path<string>(['fileObject', 'fileName']);
export const getDocumentExtension = pipe(getDocumentFileName, getExtensionFromFileName);

export function getDocumentNameWithoutExtension(fileName: string): string {
  const nameParts = fileName?.split('.');

  return nameParts?.length > 1 ? nameParts.slice(0, -1).join('.') : fileName;
}

export function isImage(document: IDocumentBase): boolean {
  const mimeType = getDocumentMimeType(document);
  return mimeType && mimeType.startsWith('image');
}

export const isPdf = pipe(getDocumentExtension, equals('pdf'));

export function getBaseDocumentUrl(baseUrl: string): string {
  return `${baseUrl}/companies/${COMPANY_ID_TOKEN}/documents`;
}
