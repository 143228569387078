import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyIdService } from '@app/services/company-id/company-id.service';
import { switchMap, take } from 'rxjs/operators';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable()
export class CompanyIdInterceptor implements HttpInterceptor {
  constructor(private companyId: CompanyIdService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.companyId.get$().pipe(
      take(1),
      switchMap((companyId) => {
        const normalizedRequest = request.clone({
          url: this.urlLookup(request.url, companyId),
          params: this.queryLookup(request.params, companyId),
        });

        return next.handle(normalizedRequest);
      }),
    );
  }

  private urlLookup(url: string, companyId: number): string {
    return url.replace(COMPANY_ID_TOKEN, `${companyId}`);
  }

  private queryLookup(params: HttpParams, companyId: number): HttpParams {
    return params.has('companyId') ? params.set('companyId', companyId) : params;
  }
}
