import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { VERTICAL_MODAL_DIMENSIONS } from '@app/shared/utils/constants/modal.constants';
import { MODAL_PANEL_CLASS } from '@app/shared/utils/constants/common.constants';
import { IShareType } from '@app/shareholder-management/types/interfaces/share-type.interface';
import { ISharesTypeModalOptions } from '@app/shareholder-management/types/interfaces/shares-type-modal.interface copy';
import { SharesTypeModalComponent } from '@app/shareholder-management/containers/shares-type-modal/shares-type-modal.component';
import { IFictiveModalData } from '@app/shared/types/interfaces';
import { FictiveSharesTypeModalComponent } from '@app/shareholder-management/containers/fictive-shares-type-modal/fictive-shares-type-modal.component';

@Injectable({
  providedIn: 'root',
})
export class SharesService {
  public newSharesIssued$ = new Subject<void>();

  constructor(private dialog: MatDialog) {}

  public openSharesTypeModal(data: ISharesTypeModalOptions): Observable<IShareType> {
    return this.dialog
      .open(SharesTypeModalComponent, {
        ...VERTICAL_MODAL_DIMENSIONS,
        data,
        panelClass: [MODAL_PANEL_CLASS, 'dialog-container-short-screens-m'],
        disableClose: true,
      })
      .afterClosed();
  }

  public openFictiveSharesTypeModal(data: IFictiveModalData): Observable<Partial<IShareType>> {
    return this.dialog
      .open(FictiveSharesTypeModalComponent, {
        ...VERTICAL_MODAL_DIMENSIONS,
        data,
        panelClass: [MODAL_PANEL_CLASS],
        disableClose: true,
      })
      .afterClosed();
  }
}
