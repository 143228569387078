import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { WvLetDirective } from '@app/directives/let/let.directive';
import { PopoverTriggerDirective } from '@app/directives/popover-trigger/popover-trigger.directive';
import { FeatureDirective } from '@app/directives/feature/feature.directive';
import { HasEditAccessDirective } from '@app/directives/has-edit-access/has-edit-access.directive';
import { ContentTooltipDirective } from '@app/directives/content-tooltip/content-tooltip.directive';

@NgModule({
  declarations: [
    WvLetDirective,
    PopoverTriggerDirective,
    FeatureDirective,
    HasEditAccessDirective,
    ContentTooltipDirective,
  ],
  imports: [CommonModule, OverlayModule],
  exports: [WvLetDirective, PopoverTriggerDirective, FeatureDirective, HasEditAccessDirective, ContentTooltipDirective],
})
export class WevestrDirectivesModule {}
