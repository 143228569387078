<div class="app-body margin-bottom-m">
  {{ resultsHeadlineForStatus[importDetails.status] }}

  <ng-container *ngIf="importDetails.errors">
    <ul *ngFor="let error of importDetails.errors" class="padding-top-m">
      <li>
        <span *ngIf="error?.filename">{{ error.filename }}: </span>{{ error.message }}
      </li>
    </ul>
  </ng-container>
</div>
<div class="modal-footer">
  <app-button (onClick)="handleBack()">Back</app-button>
  <app-button *ngIf="redirectToSectionName && !isFailed" class="is-primary-button" (onClick)="handleRedirect()">
    Go to {{ redirectToSectionName }}
  </app-button>
</div>
