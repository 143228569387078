import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@app/services/config.service';
import { ICurrency } from '@app/shared/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  protected readonly baseUrl: string;

  constructor(private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getFullBaseUrl();
  }

  /**
   * TODO : move currencies list to static data (StaticDataService)
   */
  public getCurrencies$(): Observable<ICurrency[]> {
    return this.http.get<ICurrency[]>(`${this.baseUrl}/currencies`);
  }
}
