import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { VALIDATION_DELAY } from '@app/forms/constants';
import { FormErrorDirective } from '@app/forms/directives/form-error/form-error.directive';
import { TAllowedInputType, TInputValue } from '@app/forms/types';
import { VALIDITY_STATUS } from '@app/shared/utils/constants/validators.constants';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, filter, map, startWith, take, tap } from 'rxjs/operators';
import { UserSettingsService } from '@app/shared/services/user-settings.service';
import { IFormLabelButton } from '@app/shared/types/interfaces/form-label-button.interface';
import { isNil } from 'ramda';
import { AutofillEvent, AutofillMonitor } from '@angular/cdk/text-field';

export type TDynamicClassList = string[] | string | Set<string> | Record<string, boolean>;

@Component({
  selector: 'wevestr-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent
  implements OnInit, AfterContentInit, ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy
{
  @Input()
  public label: string;
  @Input()
  public additionalLabel: string;
  @Input()
  public placeholder = '';
  @Input()
  public disabled: boolean;
  @Input()
  public type: TAllowedInputType = 'text';
  @Input()
  public inputClassNames: TDynamicClassList = '';
  @Input()
  public formFieldClassNames: TDynamicClassList = '';
  @Input()
  public autocomplete: string;
  @Input()
  public showAllErrors = true;
  @Input()
  public addonInput: AbstractControl;
  @Input()
  public mask: string;
  @Input()
  public isRequired = false;
  @Input()
  public dataId: string;
  @Input()
  public allowNegativeNumbers = false;
  @Input()
  public hasClearButton = false;
  @Input()
  public labelButton: IFormLabelButton;
  @Input()
  public showInvalidFieldStyle = true;
  @Input()
  public showValidFieldStyle = true;
  @Input()
  public showValidityImmediately = false;
  @Input()
  public tooltipText: string;
  @Input()
  public showAutofillEvent = false;

  @ViewChild('inputElement') public inputElement: ElementRef;
  @ViewChild('textAreaElement') public textAreaElement: ElementRef;

  @Output() public pressLabelButton = new EventEmitter<void>();
  @Output() public onAutofill = new EventEmitter<boolean>();

  @ContentChildren(FormErrorDirective)
  public errors: QueryList<FormErrorDirective>;

  // TODO: add number input with a custom display instead of input-with-addon
  // replace all input-with-addon, so that data is in number format but shown in a preferred way (with dots, commas)
  // then also refactor data posting, no need to format input values into number format

  public formControlName: TInputValue;
  public isValid$: Observable<boolean>;
  private disabledControlStatus: boolean;
  public disabledState: boolean;
  public thousandSeparator: string;
  public decimalMarker: string;
  public isNil = isNil;

  private _value: TInputValue;
  private _ngChangeCallback: (value: TInputValue) => void;
  private _ngTouchCallback: () => void;
  private showValidation$ = new ReplaySubject<boolean>();

  constructor(
    @Optional() @Self() private abstractControl: NgControl,
    private userSettingsService: UserSettingsService,
    private autofill: AutofillMonitor,
  ) {
    if (abstractControl) {
      abstractControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    const { decimal, thousands } = this.userSettingsService.separators;
    this.decimalMarker = decimal;
    this.thousandSeparator = thousands;
  }

  public ngOnChanges(): void {
    if (this.showValidityImmediately) {
      this.triggerValidation();
    }
  }

  public ngAfterContentInit(): void {
    if (this.abstractControl) {
      this.formControlName = this.abstractControl.name;
      this.isValid$ = this._getValidity$();
      this.abstractControl.valueChanges.pipe(take(1)).subscribe(() => this.triggerValidation());
    }
    if (this.addonInput) {
      this.validateAddonInputChanges();
    }
  }

  public ngAfterViewInit() {
    if (this.showAutofillEvent) {
      this.autofill
        .monitor(this.inputElement)
        .pipe(map((event: AutofillEvent) => event.isAutofilled))
        .subscribe((isAutofilled) => this.onAutofill.emit(isAutofilled));
    }
  }

  get value(): TInputValue {
    return this._value;
  }

  set value(value: TInputValue) {
    this.writeValue(value);
  }

  private validateAddonInputChanges(): void {
    this.addonInput.valueChanges.pipe(debounceTime(VALIDATION_DELAY)).subscribe(() => this.triggerValidation());
  }

  public writeValue(value: TInputValue): void {
    this._value = value;
    this.onChange(this._value);
  }

  public registerOnTouched(ngOnTouchCallback: () => void): void {
    this._ngTouchCallback = ngOnTouchCallback;
  }

  public registerOnChange(ngOnChangeCallback: (value: TInputValue) => void): void {
    this._ngChangeCallback = ngOnChangeCallback;
  }

  public onTouch(): void {
    if (this._ngChangeCallback instanceof Function) {
      this.triggerValidation();
      this._ngTouchCallback();
    }
  }

  // TODO: after the issue in lib ngx-mask is solved, get rid of this transformation
  // https://github.com/JsDaddy/ngx-mask/issues/1002. Needed because mask inserts 0
  // instead of null for number type and considers null input valid
  public onInput(value: TInputValue): void {
    if (value === '') {
      this.value = null;
    }
  }

  private triggerValidation(): void {
    this.showValidation$.next(true);
  }

  public onChange(change: TInputValue): void {
    if (this._ngChangeCallback instanceof Function) {
      this._ngChangeCallback(change);
    }
  }

  public focus(): void {
    const element = this.type === 'textarea' ? this.textAreaElement : this.inputElement;
    element?.nativeElement?.focus();
  }

  public handleClearValue(): void {
    this.value = null;
    this.focus();
  }

  private _getValidity$(): Observable<boolean> {
    return combineLatest([this._getStatus$(), this.showValidation$]).pipe(
      map(([status]) => status === VALIDITY_STATUS.VALID || status === VALIDITY_STATUS.DISABLED),
      filter(() => (this.showValidityImmediately ? true : !this.abstractControl.pristine)),
      debounceTime(VALIDATION_DELAY),
      tap(() => this._unsetErrors()),
      filter(() => !this.disabled),
      tap(() => {
        let currentControlErrorsKeys = Object.keys(this.abstractControl.errors ?? {});

        if (this.addonInput) {
          currentControlErrorsKeys = [...currentControlErrorsKeys, ...Object.keys(this.addonInput.errors ?? {})];
        }

        currentControlErrorsKeys
          .map((error) => this.errors.find((errorDirective) => errorDirective.errorIds.has(error)))
          .filter((maybeErrorDirective) => maybeErrorDirective) // cleanup undefined's in case if find doesn't return any value
          .sort((errorA, errorB) => errorA.order - errorB.order)
          .slice(0, this.showAllErrors ? Infinity : 1)
          .forEach((error) => error.toggleError(true));
      }),
    );
  }

  private _getStatus$(): Observable<VALIDITY_STATUS> {
    return this.abstractControl.statusChanges.pipe(
      startWith(this.abstractControl.status),
      tap((status: VALIDITY_STATUS) => {
        this.disabledControlStatus = status === VALIDITY_STATUS.DISABLED;
        this.disabledState = this.disabled === void 0 ? this.disabledControlStatus : this.disabled;
      }),
    );
  }

  private _unsetErrors(): void {
    this.errors.forEach((error) => error.toggleError(false));
  }

  public ngOnDestroy(): void {
    if (this.showAutofillEvent) {
      this.autofill.stopMonitoring(this.inputElement);
    }
  }
}
