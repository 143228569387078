import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UserService } from '@app/shared/services/user.service';

@Directive({
  selector: '[appHasRole]',
})
// TODO: remove after finding the way to deal with edit rights for shared table-empty-state component
export class HasRoleDirective implements OnInit {
  @Input('appHasRole') role: number;

  constructor(private userService: UserService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    if (this.role === undefined || this.role === null) {
      this.viewContainerRef.remove();
    }

    const companyRole = this.userService.currentRole;

    if (this.role <= companyRole.role) {
      this.viewContainerRef.remove();
    }
  }
}
