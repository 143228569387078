import { ApexChart, ApexLegend, ApexStates, ApexXAxis } from 'ng-apexcharts';

import { NUMBER_FONT_FAMILY } from '@app/shared/utils/constants/common.constants';
import { ChartTypes } from '@app/shared/types/enums/chart-types.enum';

const AXIS_FONT_COLOR = '#747677';
const AXIS_FONT_SIZE_PX = '10px';
const AXIS_GROUP_FONT_WEIGHT = 700;
const LEGEND_FONT_COLOR = '#031125';
const CHART_FONT_FAMILY = 'Inter';
const LEGEND_FONT_SIZE_PX = '12px';
const LINE_COLOR = '#D8D8D8';
const CHART_HEIGHT = 292;
const XAXIS_LABELS_OFFSET = -4;
const XAXIS_TICKS_HEIGHT = 3;
const XAXIS_CROSSHAIRS_WIDTH = 2;
const XAXIS_CROSSHAIRS_COLOR = '#FF6D22';

export const FULL_OPACITY = '1';
export const HALF_OPACITY = '0.25';
export const COLUMNS_IN_GROUP = 2;

const AXIS_FONT_OPTIONS = {
  colors: AXIS_FONT_COLOR,
  fontSize: AXIS_FONT_SIZE_PX,
  fontWeight: 400,
};

export const PLOT_BARCHART_OPTIONS = {
  bar: {
    horizontal: false,
  },
};

export const XAXIS_BARCHART_OPTIONS: ApexXAxis = {
  type: 'category',
  axisBorder: {
    show: true,
    color: LINE_COLOR,
  },
  labels: {
    show: true,
    style: AXIS_FONT_OPTIONS,
    offsetY: XAXIS_LABELS_OFFSET,
  },
  axisTicks: {
    color: LINE_COLOR,
    height: XAXIS_TICKS_HEIGHT,
  },
  crosshairs: {
    show: true,
    width: XAXIS_CROSSHAIRS_WIDTH,
    position: 'front',
    opacity: 1,
    fill: {
      type: 'solid',
      color: XAXIS_CROSSHAIRS_COLOR,
    },
  },
};

export const YAXIS_BARCHART_OPTIONS = {
  show: true,
  labels: {
    show: true,
    style: {
      ...AXIS_FONT_OPTIONS,
      fontFamily: NUMBER_FONT_FAMILY,
    },
  },
};

export const DATA_LABELS_BARCHART_OPTIONS = {
  enabled: false,
};

export const BARCHART_OPTIONS: ApexChart = {
  type: ChartTypes.BAR,
  height: CHART_HEIGHT,
  stacked: true,
  toolbar: {
    show: false,
  },
  fontFamily: CHART_FONT_FAMILY,
  animations: {
    enabled: true,
    easing: 'easein',
    speed: 400,
  },
};

export const GRID_BARCHART_OPTIONS = {
  show: true,
  borderColor: LINE_COLOR,
  yaxis: {
    lines: {
      show: false,
    },
  },
  xaxis: {
    lines: {
      show: true,
    },
  },
  padding: {
    left: 8,
  },
};

export const LEGEND_BARCHART_OPTIONS: ApexLegend = {
  show: true,
  position: 'bottom',
  fontSize: LEGEND_FONT_SIZE_PX,
  fontWeight: 400,
  onItemClick: {
    toggleDataSeries: false,
  },
  onItemHover: {
    highlightDataSeries: false,
  },
  markers: {
    width: 16,
    height: 8,
    radius: 6,
    offsetX: -1,
  },
  itemMargin: {
    horizontal: 8,
  },
  labels: {
    colors: LEGEND_FONT_COLOR,
  },
  offsetY: -1,
  showForSingleSeries: true,
};

export const STATES_BARCHART_OPTIONS: ApexStates = {
  normal: {
    filter: {
      type: 'none',
    },
  },
  hover: {
    filter: {
      type: 'none',
    },
  },
  active: {
    filter: {
      type: 'none',
    },
  },
};

export const FILL_BARCHART_OPTIONS = {
  opacity: 1,
};

export const AXIS_GROUP_STYLE = {
  fontSize: AXIS_FONT_SIZE_PX,
  fontWeight: AXIS_GROUP_FONT_WEIGHT,
};
