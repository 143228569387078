import { Component, EventEmitter, Input, Output } from '@angular/core';
import { head, last } from 'ramda';
import { IPageParams } from '@app/shared/types/interfaces';
import { compareFnNumbers, displayFnNumber } from '@app/shared/utils/helpers/common.helpers';

@Component({
  selector: 'wevestr-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() public pageSize: number;
  @Input() public selectedPage = 1;

  @Input() public set pagesNumber(pagesNumber: number) {
    this._pagesNumber = pagesNumber;
    this.pages = Array.from({ length: this._pagesNumber }, (_, i: number) => i + 1);
    this.pagesMiddle = Math.floor(this.pages.length / 2);
  }

  @Output() public pageChanges = new EventEmitter<IPageParams>();

  public _pagesNumber: number;

  public pages: number[];
  public pagesMiddle: number;

  public displayFn = displayFnNumber;
  public compareFn = compareFnNumbers;

  public readonly MAX_NUMBER_PAGES_DISPLAYED = 8;
  public readonly pageSizeOptions = [5, 8, 10, 20];

  public handlePageSizeChange(pageSize: number): void {
    this.emitPageChanges(this.selectedPage, pageSize);
  }

  public handleJumpToPage(pageIndex: number): void {
    this.emitPageChanges(pageIndex, this.pageSize);
  }

  public handleSelectPage(pageIndex: number): void {
    this.emitPageChanges(pageIndex, this.pageSize);
  }

  public handleNextPage(): void {
    if (this.selectedPage !== last(this.pages)) {
      this.emitPageChanges(this.selectedPage + 1, this.pageSize);
    }
  }

  public handlePreviousPage(): void {
    if (this.selectedPage !== head(this.pages)) {
      this.emitPageChanges(this.selectedPage - 1, this.pageSize);
    }
  }

  private emitPageChanges(page: number, pageSize: number): void {
    this.selectedPage = page;
    this.pageChanges.emit({ page, pageSize });
  }
}
