import { isSuperAdminRole } from '@app/shared/utils/helpers/role.helpers';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@app/shared/types/classes';
import { ConfigService } from '@app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { tap, switchMap, take } from 'rxjs/operators';
import { IRoleMapping } from '@app/types/interfaces/profile.interface';
import { IProfileUpdate } from '@app/settings/types/interfaces';
import { ICode2fa } from '@app/settings/types/interfaces/code-2fa.interface';
import { IDisable2fa } from '@app/settings/types/interfaces/disable-2fa.interface';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { IEnable2faPayload } from '@app/settings/types/interfaces/enable-2fa.interface';
import { getCompanyRole } from '@app/shared/utils/helpers/user.helpers';
import { IStakeholder } from '@app/types/interfaces/stakeholder';

export const COMPANY_KEY = 'wvs-company';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly baseUrl: string;

  private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);
  private _currentCompanyId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _currentRole$: BehaviorSubject<IRoleMapping> = new BehaviorSubject<IRoleMapping>(null);

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private companySettingsService: CompanySettingsService,
  ) {
    this.baseUrl = this.config.getFullBaseUrl();
  }

  public get(): Observable<User> {
    return this.currentCompanyId$.pipe(
      switchMap((companyId: number) => this.getByCompanyId(companyId)),
      switchMap(() => this.userSubject.asObservable()),
      take(1),
    );
  }

  private getByCompanyId(companyId: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/user`, { params: { companyId } }).pipe(
      tap((response: User) => this.setUser(response)),
      tap(() => this.setRoleAndCompany(companyId)),
    );
  }

  public init(): void {
    this.get().subscribe();
  }

  public editProfile({ profile, updatedField }: IProfileUpdate): Observable<User> {
    const profileChanges = {
      [updatedField]: profile[updatedField],
    };

    return this.currentCompanyId$.pipe(
      switchMap((companyId: number) =>
        this.http.patch<User>(`${this.baseUrl}/user/${companyId}/profile`, profileChanges),
      ),
      tap((response: User) => this.setUser(response)),
      take(1),
    );
  }

  public get2faCode(): Observable<ICode2fa> {
    return this.http.get<ICode2fa>(`${this.baseUrl}/user/two-factor-authentication-code`);
  }

  public enable2fa(enableData: IEnable2faPayload): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/user/enable-2fa`, enableData)
      .pipe(tap(() => this.setUser({ ...this.userSubject.value, enabled2fa: true } as User)));
  }

  public disable2fa(disableData: IDisable2fa): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/user/disable-2fa`, disableData)
      .pipe(tap(() => this.setUser({ ...this.userSubject.value, enabled2fa: false } as User)));
  }

  public get currentCompanyId$(): Observable<number> {
    return this._currentCompanyId$.asObservable();
  }

  public get currentCompanyId(): number {
    return this._currentCompanyId$.value;
  }

  public set currentCompanyId(id: number) {
    localStorage.setItem(COMPANY_KEY, String(id));
    this._currentCompanyId$.next(id);
    this.companySettingsService.companyId = id;
  }

  public get currentRole$(): Observable<IRoleMapping> {
    return this._currentRole$.asObservable();
  }

  public get user$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public get currentRole(): IRoleMapping {
    return this._currentRole$.value ?? <IRoleMapping>{};
  }

  public get user(): User {
    return this.userSubject.value;
  }

  public isSuperAdminOfAnyCompany(): boolean {
    return this.user.profile.roles.some(({ role }) => isSuperAdminRole(role));
  }

  public resetValue(): void {
    this.userSubject.next(null);
    this._currentCompanyId$.next(null);
    this._currentRole$.next(null);
  }

  public removeCurrentCompany(): void {
    localStorage.removeItem(COMPANY_KEY);
  }

  private setUser(userResponse: User): void {
    const user = new User(userResponse);

    this.userSubject.next(user);
  }

  private loadFirstAvailableCompany(): void {
    this.getByCompanyId(null).subscribe();
  }

  private setRoleAndCompany(companyId: number): void {
    let role;
    if (companyId) {
      role = getCompanyRole(this.userSubject.value, companyId);

      if (!role) {
        this.removeCurrentCompany();
        this.loadFirstAvailableCompany();
        return;
      }
    } else {
      role = this.userSubject.value.profile.roles[0];
      this.currentCompanyId = role.companyId;
    }

    this._currentRole$.next(role);
  }

  public setDefaultCompanyId(id?: number): void {
    const defaultCompanyId = id ?? +localStorage.getItem(COMPANY_KEY);
    if (defaultCompanyId) {
      this.currentCompanyId = defaultCompanyId;
      this.get().subscribe();
    }
  }

  public getCurrentStakeholder(user: User, companyId: number): IStakeholder {
    return user?.profile.shareholders.find((s) => s.companyId === companyId);
  }
}
