import { CompanyDashboardType } from '@wevestr/bff-types/enums/companyDashboardType.enum';
import { CompanyRole } from '@wevestr/bff-types/enums/companyRole.enum';
import { TypeToFormControls } from '@app/forms/types';
import { ICountry } from '@app/types/interfaces/profile.interface';
import { ICurrency } from '@app/shared/types/interfaces';
import { OnboardingStep } from '@wevestr/bff-types/models/enums/onboardingStep';

export interface ICompanyInvite {
  token: string;
  onboardingStep?: OnboardingStep;
  companyRole: CompanyRole;
  id: number;
  companyId: number;
  country: number;
  companyName: string;
  companyIndustry: string;
  companyLegalEntity: string;
  companyWebsite?: string;
  companyFoundedYear: number;
  companyDashboardType: typeof CompanyDashboardType[keyof typeof CompanyDashboardType];
  userFirstName: string;
  userLastName?: string;
  userCompanyRole?: string;
  userEmail: string;
  currencyId?: number;
}

type GeneralInfoFormData = {
  userFirstName: string | null;
  userLastName: string | null;
  userEmail: string | null;
  companyName: string | null;
  companyLegalEntity: string | null;
  country: ICountry | null;
  companyFoundedYear: number | null;
  companyWebsite: string | null;
  companyIndustry: { name: string } | null;
  companyRole: { name: CompanyRole } | null;
  currency: ICurrency | null;
};
export type GeneralInfoInfoForm = TypeToFormControls<GeneralInfoFormData>;
