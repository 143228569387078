import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ApexTooltip } from 'ng-apexcharts';

import { getValuesForViewType } from '@app/performance/helpers/dates.helpers';
import { KpisService } from '@app/performance/services';
import { ChartDisplayMode } from '@app/performance/types/enums';
import { IKpi } from '@app/performance/types/interfaces';
import { ChartTooltipComponent } from '@app/shared/components/chart-tooltip/chart-tooltip.component';
import { LineChartComponent } from '@app/shared/components/line-chart/line-chart.component';
import { ColumnChartComponent } from '@app/shared/components/column-chart/column-chart.component';
import { Frequency } from '@app/shared/types/enums';
import { IChart, ITooltipDataLabelsPercents } from '@app/shared/types/interfaces';
import { areOptionsTheSame, getChartColorByIndex, shortifyYear } from '@app/shared/utils/helpers/chart.helpers';
import { areAllDummies, calculateAllPercentages } from '@app/performance/helpers/performance.helpers';

const CHART_HEIGHT = 270;

@Component({
  selector: 'app-kpi-chart',
  templateUrl: './kpi-chart.component.html',
  styleUrls: ['./kpi-chart.component.scss'],
})
export class KpiChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chartMode: ChartDisplayMode;
  @Input() kpis: IKpi[];
  @Input() labels: string[];
  @Input() previousYearKpis: IKpi[];
  @Input() viewType: Frequency;
  @Input() year: number;
  @Input() useLegendWithSelect = false;
  @Input() isEmptyState = false;

  @ViewChild(LineChartComponent) lineChartComponent: LineChartComponent;
  @ViewChild(ColumnChartComponent) columnChartComponent: ColumnChartComponent;

  chartHeight = CHART_HEIGHT;
  chartModes = ChartDisplayMode;
  charts: IChart[];
  previousYearKpiValues: Record<number, number[]>;
  tooltip: ApexTooltip;
  shortifiedYear: string;
  shortifiedPreviousYear: string;

  private tooltipKpis: IKpi[];
  private tooltipComponent: ComponentRef<ChartTooltipComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private kpisService: KpisService,
  ) {}

  public ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ChartTooltipComponent);
    this.tooltipComponent = factory.create(this.injector);
    this.tooltipKpis = this.kpis?.filter((kpi) => !kpi.isCategory);
    this.initTooltip();
  }

  // TODO: refactor this to reduce complexity
  // eslint-disable-next-line complexity
  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.viewType &&
      this.kpis &&
      this.previousYearKpis &&
      (changes.viewType || changes.kpis || changes.previousYearKpis)
    ) {
      this.shortifiedYear = shortifyYear(this.year);
      this.shortifiedPreviousYear = shortifyYear(this.year - 1);
      this.initCharts();
      this.tooltipKpis = this.kpis?.filter((kpi) => !kpi.isCategory);

      this.isEmptyState = areAllDummies(this.kpis);
      this.initPreviousYearKpis();
    }

    if (this.year && changes.year && this.lineChartComponent) {
      this.lineChartComponent.selectedChartIndex = 0;
    }
  }

  private initTooltip(): void {
    this.tooltip = {
      custom: ({ series, seriesIndex, dataPointIndex }) => this.getTooltip(series, seriesIndex, dataPointIndex),
    };
  }

  private getTooltip(series: number[][], seriesIndex: number, dataPointIndex: number): void {
    let kpi: IKpi;
    if (this.chartMode === this.chartModes.LINE) {
      const selectedChartIndex = this.lineChartComponent.selectedChartIndex;
      kpi = this.tooltipKpis[selectedChartIndex];
    } else {
      // TODO: pass and get chart info from native data property of apex charts
      // https://stackoverflow.com/questions/64620934/how-to-add-custom-tooltip-items-to-apexcharts/64621215
      const selectedChart = this.columnChartComponent.chartValues[seriesIndex];
      kpi = this.tooltipKpis.find((kpi) => areOptionsTheSame(kpi, selectedChart));
    }

    const previousYearKpiValue = this.previousYearKpiValues[kpi.id]
      ? this.previousYearKpiValues[kpi.id][dataPointIndex]
      : 0;

    this.tooltipComponent.instance.name = kpi.name;

    const currentDateLabel = {
      label: `${this.labels[dataPointIndex]}/${this.shortifiedYear}`,
      value: series[seriesIndex][dataPointIndex],
      displayedValue: this.kpisService.getFormattedKpiValue(kpi.type, series[seriesIndex][dataPointIndex]),
    };
    this.tooltipComponent.instance.currentDateLabel = currentDateLabel;

    const previousDateLabels = [
      this.getPreviousDateLabelsValue(series, seriesIndex, dataPointIndex),
      this.getPreviousDateLabelsValue(series, seriesIndex, dataPointIndex - 1),
      {
        label: `${this.labels[dataPointIndex]}/${this.shortifiedPreviousYear}`,
        value: previousYearKpiValue,
        displayedValue: this.kpisService.getFormattedKpiValue(kpi.type, previousYearKpiValue),
      },
    ];
    this.tooltipComponent.instance.previousDateLabels = previousDateLabels;

    this.tooltipComponent.instance.tooltipPercents =
      currentDateLabel && previousDateLabels
        ? calculateAllPercentages(currentDateLabel, previousDateLabels)
        : ({} as ITooltipDataLabelsPercents);
    this.tooltipComponent.instance.initPercents();
    this.tooltipComponent.instance.initIcons();

    this.tooltipComponent.changeDetectorRef.detectChanges();

    return this.tooltipComponent.location.nativeElement.innerHTML;
  }

  private initPreviousYearKpis(): void {
    this.previousYearKpiValues = this.previousYearKpis.reduce((result: Record<number, number[]>, kpi: IKpi) => {
      result[kpi.id] = getValuesForViewType(kpi.years[0].values, this.viewType);

      return result;
    }, {});
  }

  private getPreviousDateLabelsValue(
    series: number[][],
    seriesIndex: number,
    dataPointIndex: number,
  ): {
    label: string;
    value: number;
    displayedValue: string;
  } {
    const selectedChartIndex =
      this.chartMode === this.chartModes.LINE ? this.lineChartComponent.selectedChartIndex : seriesIndex;
    const kpi = this.tooltipKpis[selectedChartIndex];
    let previousYearDateLabelIndex = this.labels.length - 1;
    if (dataPointIndex === 0 || dataPointIndex === -1) {
      previousYearDateLabelIndex = dataPointIndex === 0 ? previousYearDateLabelIndex : previousYearDateLabelIndex - 1;
      return this.getPreviousYearDateLabel(previousYearDateLabelIndex, kpi);
    } else {
      return {
        label: `${this.labels[dataPointIndex - 1]}/${this.shortifiedYear}`,
        value: series[seriesIndex][dataPointIndex - 1],
        displayedValue: this.kpisService.getFormattedKpiValue(kpi.type, series[seriesIndex][dataPointIndex - 1]),
      };
    }
  }

  private getPreviousYearDateLabel(
    previousYearDateLabelIndex: number,
    kpi: IKpi,
  ): {
    label: string;
    value: number;
    displayedValue: string;
  } {
    const previousViewTypeKpiValue = this.previousYearKpiValues[kpi.id]
      ? this.previousYearKpiValues[kpi.id][previousYearDateLabelIndex]
      : 0;
    return {
      label: `${this.labels[previousYearDateLabelIndex]}/${this.shortifiedPreviousYear}`,
      value: previousViewTypeKpiValue,
      displayedValue: this.kpisService.getFormattedKpiValue(kpi.type, previousViewTypeKpiValue),
    };
  }

  private initCharts(): void {
    this.charts = this.kpis.map((kpi: IKpi, index: number): IChart => {
      const monthValues = kpi.years && kpi.years.length !== 0 ? kpi.years[0].values : [];
      const viewTypeValues = getValuesForViewType(monthValues, this.viewType);
      const isCategory = Boolean(kpi.isCategory);

      return {
        id: kpi.id,
        name: kpi.name,
        color: getChartColorByIndex(index),
        visible: !isCategory && kpi.isChartVisible !== false,
        data: viewTypeValues,
        isCategory,
        categoryId: kpi.categoryId,
      };
    });
  }

  public ngOnDestroy(): void {
    this.tooltipComponent.destroy();
  }
}
