import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IDocument } from '@app/documents/types/interfaces';
import { DocumentAction } from '@app/documents/types/enums/document-action.enum';
import { getDocumentExtension } from '@app/documents/utils/helpers/documents.helpers';
import { hasDocumentPreview } from '@app/shared/utils/helpers/documents.helpers';

@Component({
  selector: 'wevestr-document-preview-card',
  templateUrl: './document-preview-card.component.html',
  styleUrls: ['./document-preview-card.component.scss'],
})
export class DocumentPreviewCardComponent implements OnInit {
  @Input()
  public document: IDocument;
  @Input()
  public shouldShowFileSize = false;
  @Input()
  public shouldShowFileExtension = false;

  @Output()
  public onPreview = new EventEmitter<IDocument>();

  public documentExtension = '';

  public readonly DocumentAction = DocumentAction;
  public readonly hasDocumentPreview = hasDocumentPreview;

  public ngOnInit(): void {
    this.documentExtension = getDocumentExtension(this.document);
  }

  public handlePreview(): void {
    this.onPreview.emit(this.document);
  }
}
