<ngx-extended-pdf-viewer
  [theme]="'dark'"
  [src]="documentURL"
  [customToolbar]="multiToolbar"
  [filenameForDownload]="title + '.pdf'"
>
</ngx-extended-pdf-viewer>
<ng-template #multiToolbar>
  <div class="flex-row is-justify-content-space-between is-align-items-center toolbar">
    <div class="flex-row is-align-items-center margin-right-m">
      <mat-icon svgIcon="cross" class="margin-right-m cursor-pointer close-icon" (click)="onClose.emit()"></mat-icon>
      <mat-icon svgIcon="file_type_pdf" class="toolbar_icon margin-right-xs"></mat-icon>
      <div class="app-h4-medium-weight toolbar_title">{{ title }}</div>
    </div>
    <div class="flex-row is-align-items-center">
      <mat-icon svgIcon="minus" id="zoomOut" class="toolbar_icon margin-right-m cursor-pointer"></mat-icon>
      <mat-icon svgIcon="add" id="zoomIn" class="toolbar_icon margin-right-m cursor-pointer"></mat-icon>

      <div class="toolbar_separator margin-right-m"></div>

      <mat-icon svgIcon="download_doc" id="download" class="toolbar_icon margin-right-m cursor-pointer"></mat-icon>
      <mat-icon svgIcon="print" id="print" class="toolbar_icon margin-right-m cursor-pointer"></mat-icon>

      <mat-icon svgIcon="more_vert" id="secondaryToolbarToggle" class="toolbar_icon cursor-pointer"></mat-icon>
    </div>
  </div>
</ng-template>
