<div
  #dropdown
  class="dropdown full-width full-height"
  [ngClass]="{'is-active': opened, 'select-height': isUnifiedHeightSet}"
  (keyup.arrowup)="handleGoUp()"
  (keyup.arrowdown)="handleGoDown()"
  (keyup.enter)="handleSelectHighlighted()"
>
  <div class="dropdown-trigger full-width" *ngIf="!multiselectInsideFrame; else multiselectInside">
    <button
      class="button full-width full-height dropdown-trigger__button"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      [disabled]="disabled"
      type="button"
      [ngClass]="{categorized: colorizedValues}"
      [id]="buttonId"
    >
      <ng-container *ngIf="contentHtml; else showValue" [ngTemplateOutlet]="contentHtml"></ng-container>
      <ng-template #showValue>
        <div
          *ngIf="value && !multi; else showPlaceholder"
          class="full-width has-text-left app-body dropdown-selected-item"
          [ngClass]="{'dropdown-selected-item--categorized': colorizedValues, disabled: disabled}"
        >
          <span *ngIf="removeSelection" class="remove-selection-button" (click)="onReset()">
            <mat-icon svgIcon="cross" class="remove-selection-icon"></mat-icon>
          </span>
          <span [class.disabled]="disabled" *ngIf="!colorizedValues; else colorizedAndCategorizedSelected">{{
            value | callback: displayFn
          }}</span>
        </div>
        <ng-template #showPlaceholder>
          <span
            *ngIf="placeholder"
            class="full-width has-text-left placeholder"
            [ngClass]="{
              'placeholder--categorized': colorizedValues
            }"
            >{{ placeholder }}</span
          >
        </ng-template>
      </ng-template>
      <ng-container *ngIf="!disabled">
        <span class="dropdown__arrow-icon">
          <mat-icon
            [svgIcon]="icon"
            [ngClass]="{
              'selectable-icon': !colorizedValues,
              'selectable-icon--categorized': colorizedValues,
              'selectable-icon_small': iconSizeSmall
            }"
          ></mat-icon>
        </span>
      </ng-container>
    </button>
  </div>
  <div [attr.data-id]="menuDataId" class="dropdown-menu full-width" id="dropdown-menu" role="menu">
    <ul
      *ngIf="!colorizedValues; else colorizedAndCategorizedOptions"
      class="dropdown-content app-body"
      [ngClass]="{'no-padding-bottom dropdown-content-border': isCreateOptionShown}"
    >
      <ng-container *ngIf="options?.length !== 0; else emptyOption">
        <li
          class="dropdown-item cursor-pointer app-body dropdown-item_regular-option"
          [ngClass]="{
            'is-disabled': isActiveOptionDisabled && (option | callback: compareFn:value),
            'is-active': (option | callback: compareFn:value),
            'is-highlighted': highlightIndex === index
          }"
          *ngFor="let option of options; index as index"
          (click)="onSelectItem(option, $event)"
          >{{ option | callback: displayFn }}</li
        >
      </ng-container>
    </ul>
    <div class="option-create cursor-pointer app-body" *ngIf="isCreateOptionShown" (click)="create.emit()">
      <mat-icon svgIcon="plus" class="option-create-icon"></mat-icon>
      <span class="option-create-label">{{ createOptionLabel }}</span>
    </div>
  </div>

  <ng-template #emptyOption>
    <li class="app-body empty-option">{{ emptyOptionText }}</li>
  </ng-template>

  <ng-template #multiselectInside>
    <div class="dropdown-trigger multiselect full-width">
      <button
        class="button button-reset-styles multiselect__trigger full-width full-height"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        [ngClass]="{categorized: colorizedValues}"
        [id]="buttonId"
      >
        <ul *ngIf="value && value.length !== 0; else placeholderTemplate" class="multiselect__selected-items-list">
          <li *ngFor="let selectedItem of value; let index = index" class="multiselect__item">
            <ng-container *ngTemplateOutlet="addonImageTemplate; context: {selectedItem: selectedItem}"></ng-container>
            <span class="app-body text-ellipsis">{{ selectedItem | callback: displayFn }}</span>
            <button
              class="button-reset-styles multiselect__delete-button"
              (click)="handleRemoveMultiselectItem(selectedItem, $event)"
            >
              <mat-icon svgIcon="cross" class="multiselect__delete-icon"></mat-icon>
            </button>
          </li>
        </ul>
        <ng-template #placeholderTemplate>
          <span
            *ngIf="placeholder"
            class="full-width has-text-left app-body placeholder"
            [ngClass]="{
              'placeholder--categorized': colorizedValues
            }"
            >{{ placeholder }}</span
          >
        </ng-template>
        <button *ngIf="!disabled" class="button-reset-styles dropdown__arrow-icon" type="button">
          <mat-icon [svgIcon]="icon" class="selectable-icon" [ngClass]="{'arrow-up': opened}"></mat-icon>
        </button>
      </button>
    </div>
  </ng-template>
</div>

<ng-template #colorizedAndCategorizedSelected>
  <div class="option__color" [ngStyle]="{backgroundColor: value.color}"></div>
  <span
    class="option__text"
    [ngClass]="{
      'option__element-text': !value.isCategory,
      'option__category-text': value.isCategory
    }"
    >{{ value | callback: displayFn }}</span
  >
</ng-template>

<ng-template #colorizedAndCategorizedOptions>
  <ul class="dropdown-content app-body">
    <ng-container *ngIf="options?.length !== 0; else emptyOption">
      <li
        *ngFor="let option of options"
        class="dropdown-item option app-body"
        [ngClass]="{
          'option--selected': !multi && (option | callback: compareFn:value),
          'dropdown-item--categorized': colorizedValues
        }"
        (click)="onSelectItem(option, $event)"
      >
        <div
          *ngIf="!multi || !option.isCategory || (option | callback: compareFn:value)"
          class="option__color"
          [ngStyle]="{backgroundColor: !multi || (option | callback: compareFn:value) ? option.color : 'white'}"
        ></div>
        <span
          class="option__text"
          [ngClass]="{
            'option__element-text': !option.isCategory,
            'option__element-text--active': multi && !option.isCategory && (option | callback: compareFn:value),
            'option__category-text': option.isCategory,
            'option__category-text--active': multi && option.isCategory && (option | callback: compareFn:value)
          }"
          >{{ option | callback: displayFn }}</span
        >
      </li>
    </ng-container>
  </ul>
</ng-template>
