import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ILegendOption } from '@app/shared/types/interfaces/legend-option.interface';

@Injectable()
export class ChartLegendService {
  private _legendOptions$: Subject<ILegendOption[]> = new Subject<ILegendOption[]>();
  private _selectedOption$: Subject<ILegendOption> = new Subject<ILegendOption>();

  public get legendOptions$(): Observable<ILegendOption[]> {
    return this._legendOptions$.asObservable();
  }

  public setLegendOptions(options: ILegendOption[]): void {
    this._legendOptions$.next(options);
  }

  public get selectedOption$(): Observable<ILegendOption> {
    return this._selectedOption$.asObservable();
  }

  public setSelectedOption(option: ILegendOption): void {
    this._selectedOption$.next(option);
  }

  public updateOptions(options: ILegendOption[]): void {
    this._legendOptions$.next(options);
  }

  public cleanupLegend(): void {
    this._legendOptions$.next([]);
  }
}
