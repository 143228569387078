import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { ConfigService } from '@app/services/config.service';

/**
 * TODO: get rid of caching/make it pluggable
 */
export abstract class CrudService<T extends { id?: number }> {
  protected values$: BehaviorSubject<T[]> = new BehaviorSubject(null);
  protected refresh = false;
  protected loading = new BehaviorSubject<boolean>(null);
  protected readonly baseUrl: string;

  protected constructor(protected _http: HttpClient, protected _configService: ConfigService, protected cache = false) {
    this.baseUrl = _configService.getFullBaseUrl();
  }

  abstract get url(): string;

  get loading$(): Observable<boolean> {
    return this.loading.asObservable();
  }

  list(params?: Params): Observable<T[]> {
    if (this.returnCacheData()) {
      return this.values$.asObservable();
    }
    this.loading.next(true);
    return this.listRequest(params).pipe(
      switchMap((values: T[]) => {
        this.values$.next(values);
        this.refresh = false;
        return this.values$.asObservable();
      }),
      tap(() => this.afterList()),
      tap(() => this.loading.next(false)),
    );
  }

  get(valueId: number, force?: boolean): Observable<T> {
    if (force) {
      return this.getResource(valueId);
    }
    const value = this.values$.value ? this.values$.value.find((item) => item.id === valueId) : null;
    if (!value) {
      return this.getResource(valueId);
    }
    return of(value);
  }

  create(value: Partial<T>): Observable<T>;
  create(value: T): Observable<T> {
    return this._http
      .post<T>(`${this.baseUrl}/${this.url}`, value)
      .pipe(tap((_value: T) => this.addValue(_value || value)));
  }

  edit(value: Partial<T>): Observable<T>;
  edit(value: T): Observable<T> {
    return this._http
      .patch<T>(`${this.baseUrl}/${this.url}/${value.id}`, value)
      .pipe(tap((_value: T) => this.editValue(value.id, _value)));
  }

  delete(valueId: number | string): Observable<unknown> {
    return this._http.delete(`${this.baseUrl}/${this.url}/${valueId}`).pipe(tap(() => this.deleteValue(valueId)));
  }

  /**@deprecated don't you fluppy dare to use syncrounous value */
  protected returnCacheData(): T | boolean {
    return !this.refresh && this.values$.value && this.cache;
  }

  public listRequest(params?: Params): Observable<T[]> {
    return this._http.get<T[]>(`${this.baseUrl}/${this.url}`, {
      params: params ? params : undefined,
    });
  }

  protected getResource(id: number | string): Observable<T> {
    return this._http.get<T>(`${this.baseUrl}/${this.url}/${id}`);
  }

  protected afterList(): void {
    return;
  }

  protected deleteValue(valueId: number | string): void {
    if (!this.values$.value) {
      return;
    }
    const values: T[] = this.values$.value.filter((value) => value.id !== valueId);
    this.values$.next(values);
  }

  protected addValue(value: T): T {
    const currentValues = this.values$.value ? this.values$.value : [];
    const values: T[] = [...currentValues, value];
    this.values$.next(values);
    return value;
  }

  protected editValue(id: number, value: T): T {
    const values: T[] = this.values$.value ? [...this.values$.value] : [];
    const valueIndex: number = values.findIndex((item: T) => item.id === id);
    values[valueIndex] = value;
    this.values$.next(values);
    return value;
  }

  public onRefresh(): void {
    this.values$ = new BehaviorSubject<T[]>(null);
    this.refresh = true;
  }
}
