import { AbstractControl } from '@angular/forms';

export const passwordMatcherValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const password = control.get('password');
  const confirm = control.get('confirmPassword');

  if (password.value !== confirm.value) {
    confirm.setErrors({ noMatch: true });
  } else if (confirm.hasError('noMatch')) {
    delete confirm.errors.noMatch;
    confirm.updateValueAndValidity();
  }
  return null;
};
