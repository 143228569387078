import { Component } from '@angular/core';

import { ABOUT_WEVESTR, QUOTE } from '@app/auth/constants/auth.constants';

@Component({
  selector: 'wevestr-auth-cover',
  templateUrl: './auth-cover.component.html',
  styleUrls: ['./auth-cover.component.scss'],
})
export class AuthCoverComponent {
  public readonly ABOUT_WEVESTR = ABOUT_WEVESTR;
  public readonly QUOTE = QUOTE;
}
