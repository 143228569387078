export enum AnalyticsJourney {
  Onboarding = 'Onboarding',
  Login = 'Login',
  Stakeholders = 'Stakeholders',
  APIs = 'APIs Connections',
  File = 'File Management',
  CapTable = 'Cap Table',
  ESOP = 'ESOP',
  NotesAndLoans = 'Notes & Loans',
  ScenarioModeling = 'Scenario Modeling',
  Financials = 'Financials',
  KPIs = 'KPIs',
  Documents = 'Documents',
  ManageCompanySettings = 'Manage Company Settings',
  ManageUserSettings = 'Manage User Settings',
  ImportFinancials = 'Import Financials',
  SlicingPie = 'Slicing Pie',
}

export enum AnalyticsSubJourney {
  CompanySignup = 'Company Signup',
  ViewCaptable = 'View Captable',
  ViewMore = 'View More',
  EditTransaction = 'Edit Transaction',
  DeleteTransaction = 'Delete Transaction',
  AddShares = 'Add Shares',
  SignIn = 'Sign In',
  ForgotAndResetPassword = 'Forgot & Reset Password',
  StakeholderSignup = 'Stakeholder Signup',
  ViewCompanySettings = 'View Company Settings',
  ViewUserSettings = 'View User Settings',
  ProductTour = 'Product Tour',
}

export enum AnalyticsSubSubJourney {
  CreatePassword = 'Create Password',
  InviteCoFounders = 'Invite Co-Founders',
  AddNewShareType = 'Add New Share Type',
  AddNewFundingRound = 'Add New Funding Round',
  CaptureContext = 'Capture Context',
  CreateCompanyProfile = 'Create Company Profile',
  CreateUserProfile = 'Create User Profile',
}
