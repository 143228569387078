import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard {
  constructor(private router: Router, private auth: AuthService) {}
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.verify(route.queryParams.token).pipe(
      map(() => {
        this.router.navigate(loginPath);
        return true;
      }),
      catchError(() => {
        this.router.navigate(['/auth/verify-failed']);
        return of(false);
      }),
    );
  }
}
