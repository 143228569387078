import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface IRadioButtonGroupOption {
  id: string;
  name: string;
}

enum RadioButtonGroupSize {
  SMALL = 'small',
}

@Component({
  selector: 'wevestr-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true,
    },
  ],
})
export class RadioButtonGroupComponent implements ControlValueAccessor {
  @Input() public options: IRadioButtonGroupOption[];
  @Input() public size: RadioButtonGroupSize = RadioButtonGroupSize.SMALL;

  public value: string = null;

  public setValue(value: string): void {
    const isOptionAlreadySelected = this.value === value;
    if (!isOptionAlreadySelected) {
      this.value = value;
      if (this.onChange && this.onTouch) {
        this.onChange(value);
        this.onTouch();
      }
    }
  }

  private onChange: (value: string) => void;
  private onTouch: () => void;

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouch: () => string): void {
    this.onTouch = onTouch;
  }
}
