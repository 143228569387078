import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ANY } from '@app/shared/types/any';

@Component({
  selector: 'app-table-column',
  templateUrl: './table-column.component.html',
  styleUrls: ['./table-column.component.scss'],
})
export class TableColumnComponent {
  @Input() classes = {};
  @Input() style = {};
  @Input() dataTooltip: ANY = null;
  @Input() useFullWidth = false;
  @Input() isReorderIconShown = false;
  @Input() isCollapseIconShown = false;
  @Input() isExpanded = false;

  @Input() isEditActionsShown = false;
  @Input() isEditingActionsShown = false;

  @Input() canEdit = false;
  @Input() canDelete = false;
  @Input() isSavingDisabled = false;

  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() toggle: EventEmitter<void> = new EventEmitter();
}
