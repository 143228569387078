import { PersonComponent } from '@app/shared/components/person/person.component';
import { TableComponent } from '@app/shared/components/table/table.component';
import { MenuComponent } from '@app/shared/components/menu/menu.component';
import { BarchartComponent } from '@app/shared/components/barchart/barchart.component';
import { AddAvatarComponent } from '@app/shared/components/add-avatar/add-avatar.component';
import { ColumnChartComponent } from '@app/shared/components/column-chart/column-chart.component';
import { TabsComponent } from '@app/shared/components/tabs/tabs.component';
import { ChartToggleComponent } from '@app/shared/components/chart-toggle/chart-toggle.component';
import { ChartWrapperComponent } from '@app/shared/components/chart-wrapper/chart-wrapper.component';
import { ButtonToggleComponent } from '@app/shared/components/button-toggle/button-toggle.component';
import { LineChartComponent } from '@app/shared/components/line-chart/line-chart.component';
import { AreaChartComponent } from '@app/shared/components/area-chart/area-chart.component';
import { DetailedInfoSideCardComponent } from '@app/shared/components/detailed-info-side-card/detailed-info-side-card.component';
import { IndicatorsComponent } from '@app/shared/components/indicators/indicators.component';
import { ChartTooltipComponent } from '@app/shared/components/chart-tooltip/chart-tooltip.component';
import { ChartLegendComponent } from '@app/shared/components/chart-legend/chart-legend.component';
import { DeleteConfirmationModalComponent } from '@app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { EditModeControlsComponent } from '@app/shared/components/edit-mode-controls/edit-mode-controls.component';
import { ButtonComponent } from '@app/shared/components/button/button.component';
import { TopBarComponent } from '@app/shared/components/top-bar/top-bar.component';
import { KpiChartComponent } from '@app/shared/components/kpi-chart/kpi-chart.component';
import { FinancialMetricChartComponent } from '@app/shared/components/financial-metric-chart/financial-metric-chart.component';
import { TableCategoryRowComponent } from '@app/shared/components/table/table-category-row/table-category-row.component';
import { TableElementRowComponent } from '@app/shared/components/table/table-element-row/table-element-row.component';
import { TableRowComponent } from '@app/shared/components/table/table-row/table-row.component';
import { TableColumnComponent } from '@app/shared/components/table/table-column/table-column.component';
import { CustomConfirmationModalComponent } from '@app/shared/components/custom-confirmation-modal/custom-confirmation-modal.component';
import { DialogComponent } from '@app/shared/components/dialog/dialog.component';
import { EmptyStateChartComponent } from '@app/shared/components/empty-state-chart/empty-state-chart.component';
import { TableEmptyStateComponent } from '@app/shared/components/table-empty-state/table-empty-state.component';
import { ImportAuditFileModalComponent } from '@app/shared/components/import-audit-file-modal/import-audit-file-modal.component';
import { SelectAuditFilesComponent } from '@app/shared/components/select-audit-files/select-audit-files.component';
import { ImportResultsComponent } from '@app/shared/components/import-results/import-results.component';
import { FrameworkSetupComponent } from '@app/shared/components/framework-setup/framework-setup.component';
import { StepperComponent } from '@app/shared/components/stepper/stepper.component';
import { WizardComponent } from '@app/shared/components/wizard/wizard.component';
import { WelcomeCoverComponent } from '@app/shared/components/welcome-cover/welcome-cover.component';
import { PolicyDisclaimerComponent } from '@app/shared/components/policy-disclaimer/policy-disclaimer.component';
import { WizardStepComponent } from '@app/shared/components/wizard-step/wizard-step.component';
import { EnableTfaQrcodeComponent } from '@app/shared/components/enable-tfa-qrcode/enable-tfa-qrcode.component';
import { CredentialsFormComponent } from '@app/shared/components/credentials-form/credentials-form.component';
import { NameCommentFormComponent } from '@app/shared/components/name-comment-form/name-comment-form.component';
import { FilesDndComponent } from '@app/shared/components/files-dnd/files-dnd.component';
import { KeyValuePairComponent } from '@app/shared/components/key-value-pair/key-value-pair.component';
import { VerticalStepperComponent } from '@app/shared/components/vertical-stepper/vertical-stepper.component';
import { ExplainedInputComponent } from '@app/shared/components/explained-input/explained-input.component';
import { TransactionSideCardComponent } from '@app/shared/components/transaction-side-card/transaction-side-card.component';
import { ValuationAttachedToTimingInputComponent } from '@app/shared/components/valuation-attached-to-timing-input/valuation-attached-to-timing-input.component';
import { ExpansionPanelComponent } from '@app/shared/components/expansion-panel/expansion-panel.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { PopoverContentComponent } from '@app/shared/components/popover-content/popover-content.component';
import { CreationStepsWrapperComponent } from '@app/shared/components/creation-steps-wrapper/creation-steps-wrapper.component';
import { SelectedStakeholdersComponent } from '@app/shared/components/selected-stakeholders/selected-stakeholders.component';
import { ExplainedMultiselectComponent } from '@app/shared/components/explained-multiselect/explained-multiselect.component';
import { StakeholdersAvatarsListComponent } from '@app/shared/components/stakeholders-avatars-list/stakeholders-avatars-list.component';
import { DocumentPreviewCardComponent } from '@app/shared/components/document-preview-card/document-preview-card.component';
import { DocumentsPreviewListComponent } from '@app/shared/components/documents-preview-list/documents-preview-list.component';
import { DocumentsLinkComponent } from '@app/shared/components/documents-link/documents-link.component';
import { CurrencyComponent } from '@app/shared/components/currency/currency.component';
import { AddonWithCurrencyComponent } from '@app/shared/components/addon-with-currency/addon-with-currency.component';
import { MaintenanceWarningComponent } from '@app/shared/components/maintenance-warning/maintenance-warning.component';
import { VestingScheduleChartComponent } from '@app/shared/components/vesting-schedule-chart/vesting-schedule-chart.component';
import { DonutChartComponent } from '@app/shared/components/donut-chart/donut-chart.component';
import { StackedBarchartComponent } from '@app/shared/components/stacked-barchart/stacked-barchart.component';
import { IconButtonComponent } from '@app/shared/components/icon-button/icon-button.component';
import { MixedBarchartComponent } from '@app/shared/components/mixed-barchart/mixed-barchart.component';
import { DropdownButtonComponent } from '@app/shared/components/dropdown-button/dropdown-button.component';

export const components = [
  AddAvatarComponent,
  PersonComponent,
  TableComponent,
  MenuComponent,
  BarchartComponent,
  ColumnChartComponent,
  TabsComponent,
  DetailedInfoSideCardComponent,
  LineChartComponent,
  ChartToggleComponent,
  ChartWrapperComponent,
  ButtonToggleComponent,
  IndicatorsComponent,
  AreaChartComponent,
  ChartTooltipComponent,
  ChartLegendComponent,
  DeleteConfirmationModalComponent,
  EditModeControlsComponent,
  ButtonComponent,
  TopBarComponent,
  KpiChartComponent,
  FinancialMetricChartComponent,
  TableCategoryRowComponent,
  TableElementRowComponent,
  TableRowComponent,
  TableColumnComponent,
  CustomConfirmationModalComponent,
  DialogComponent,
  EmptyStateChartComponent,
  TableEmptyStateComponent,
  ImportAuditFileModalComponent,
  SelectAuditFilesComponent,
  FrameworkSetupComponent,
  ImportResultsComponent,
  StepperComponent,
  WizardComponent,
  WelcomeCoverComponent,
  PolicyDisclaimerComponent,
  WizardStepComponent,
  EnableTfaQrcodeComponent,
  CredentialsFormComponent,
  NameCommentFormComponent,
  FilesDndComponent,
  KeyValuePairComponent,
  VerticalStepperComponent,
  ExplainedInputComponent,
  TransactionSideCardComponent,
  ValuationAttachedToTimingInputComponent,
  ExpansionPanelComponent,
  PaginatorComponent,
  PopoverContentComponent,
  CreationStepsWrapperComponent,
  SelectedStakeholdersComponent,
  ExplainedMultiselectComponent,
  StakeholdersAvatarsListComponent,
  DocumentPreviewCardComponent,
  DocumentsPreviewListComponent,
  DocumentsLinkComponent,
  CurrencyComponent,
  AddonWithCurrencyComponent,
  MaintenanceWarningComponent,
  VestingScheduleChartComponent,
  DonutChartComponent,
  StackedBarchartComponent,
  IconButtonComponent,
  MixedBarchartComponent,
  DropdownButtonComponent,
];

export * from '@app/shared/components/person/person.component';
export * from '@app/shared/components/table/table.component';
export * from '@app/shared/components/menu/menu.component';
export * from '@app/shared/components/add-avatar/add-avatar.component';
export * from '@app/shared/components/barchart/barchart.component';
export * from '@app/shared/components/column-chart/column-chart.component';
export * from '@app/shared/components/tabs/tabs.component';
export * from '@app/shared/components/chart-toggle/chart-toggle.component';
export * from '@app/shared/components/chart-wrapper/chart-wrapper.component';
export * from '@app/shared/components/button-toggle/button-toggle.component';
export * from '@app/shared/components/detailed-info-side-card/detailed-info-side-card.component';
export * from '@app/shared/components/indicators/indicators.component';
export * from '@app/shared/components/line-chart/line-chart.component';
export * from '@app/shared/components/area-chart/area-chart.component';
export * from '@app/shared/components/chart-tooltip/chart-tooltip.component';
export * from '@app/shared/components/chart-legend/chart-legend.component';
export * from '@app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
export * from '@app/shared/components/edit-mode-controls/edit-mode-controls.component';
export * from '@app/shared/components/button/button.component';
export * from '@app/shared/components/top-bar/top-bar.component';
export * from '@app/shared/components/kpi-chart/kpi-chart.component';
export * from '@app/shared/components/financial-metric-chart/financial-metric-chart.component';
export * from '@app/shared/components/table/table-category-row/table-category-row.component';
export * from '@app/shared/components/table/table-element-row/table-element-row.component';
export * from '@app/shared/components/table/table-row/table-row.component';
export * from '@app/shared/components/table/table-column/table-column.component';
export * from '@app/shared/components/dialog/dialog.component';
export * from '@app/shared/components/empty-state-chart/empty-state-chart.component';
export * from '@app/shared/components/table-empty-state/table-empty-state.component';
export * from '@app/shared/components/import-audit-file-modal/import-audit-file-modal.component';
export * from '@app/shared/components/select-audit-files/select-audit-files.component';
export * from '@app/shared/components/framework-setup/framework-setup.component';
export * from '@app/shared/components/import-results/import-results.component';
export * from '@app/shared/components/wizard/wizard.component';
export * from '@app/shared/components/stepper/stepper.component';
export * from '@app/shared/components/welcome-cover/welcome-cover.component';
export * from '@app/shared/components/policy-disclaimer/policy-disclaimer.component';
export * from '@app/shared/components/wizard-step/wizard-step.component';
export * from '@app/shared/components/enable-tfa-qrcode/enable-tfa-qrcode.component';
export * from '@app/shared/components/credentials-form/credentials-form.component';
export * from '@app/shared/components/name-comment-form/name-comment-form.component';
export * from '@app/shared/components/files-dnd/files-dnd.component';
export * from '@app/shared/components/key-value-pair/key-value-pair.component';
export * from '@app/shared/components/vertical-stepper/vertical-stepper.component';
export * from '@app/shared/components/explained-input/explained-input.component';
export * from '@app/shared/components/transaction-side-card/transaction-side-card.component';
export * from '@app/shared/components/valuation-attached-to-timing-input/valuation-attached-to-timing-input.component';
export * from '@app/shared/components/expansion-panel/expansion-panel.component';
export * from '@app/shared/components/paginator/paginator.component';
export * from '@app/shared/components/popover-content/popover-content.component';
export * from '@app/shared/components/creation-steps-wrapper/creation-steps-wrapper.component';
export * from '@app/shared/components/selected-stakeholders/selected-stakeholders.component';
export * from '@app/shared/components/explained-multiselect/explained-multiselect.component';
export * from '@app/shared/components/stakeholders-avatars-list/stakeholders-avatars-list.component';
export * from '@app/shared/components/document-preview-card/document-preview-card.component';
export * from '@app/shared/components/documents-preview-list/documents-preview-list.component';
export * from '@app/shared/components/documents-link/documents-link.component';
export * from '@app/shared/components/currency/currency.component';
export * from '@app/shared/components/addon-with-currency/addon-with-currency.component';
export * from '@app/shared/components/maintenance-warning/maintenance-warning.component';
export * from '@app/shared/components/vesting-schedule-chart/vesting-schedule-chart.component';
export * from '@app/shared/components/donut-chart/donut-chart.component';
export * from '@app/shared/components/stacked-barchart/stacked-barchart.component';
export * from '@app/shared/components/icon-button/icon-button.component';
export * from '@app/shared/components/mixed-barchart/mixed-barchart.component';
export * from '@app/shared/components/dropdown-button/dropdown-button.component';
