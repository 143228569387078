import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';

import { AuthService } from '@app/auth/services/auth.service';
import { ERROR_MESSAGES } from '@app/shared/utils/constants/error.constants';
import { PASSWORD_ICONS, REQUIRED_2FA_CODE } from '@app/shared/utils/constants/auth.constants';
import { ConfigService } from '@app/services/config.service';
import { ToastService } from '@app/shared/services/toast.service';
import { IAuthCopyright } from '@app/auth/types/interfaces/auth-copyright.interface';
import { togglePasswordType } from '@app/shared/utils/helpers/common.helpers';
import { DEFAULT_LOGIN_COPY, PASSWORD_INPUT_TYPE, ONBOARIND_LOGIN_TEXT } from '@app/auth/constants/auth.constants';
import { InvitationsService } from '@app/auth/services/invitations.service';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';
import { IUserTokens } from '@app/types/interfaces';
import { UserService } from '@app/shared/services';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { AnalyticsJourney, AnalyticsSubJourney } from '@app/shared/types/enums/analytics-journey.enum';
import { PageName } from '@app/shared/types/enums/page-name.enum';
import { ACCESS_REVOKED_PATH } from '@app/shared/utils/constants/paths.constants';
import { OnboardingRouteNames } from '@app/shared/types/enums/onboarding-route-names.enum';
import { TypeToFormControls } from '@app/forms/types';
import { ButtonService } from '@app/forms/components/button/button.service';

const INCORRECT_CREDENTIALS_ERROR = 'Email and/or password is incorrect.';

type LoginFormData = {
  email: string | null;
  password: string | null;
  keepSignedIn: boolean | null;
};
type LoginForm = TypeToFormControls<LoginFormData>;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup<LoginForm>;
  public errorMessage = '';
  public passwordType = PASSWORD_INPUT_TYPE;
  public inputType = PASSWORD_INPUT_TYPE;
  readonly ERROR_MESSAGE_TEXT = 'This credentials are not valid';
  public passwordIcons = PASSWORD_ICONS;
  public readonly defaultLoginCopy = DEFAULT_LOGIN_COPY;
  public loginCopyright: IAuthCopyright = this.defaultLoginCopy;
  public onboardingLoginText: string;

  public isEmailAutofilled: boolean;
  public isPasswordAutofilled: boolean;

  public readonly ERRORS = {
    REQUIRED: 'This field is required',
    EMAIL: 'Not a valid email address',
  };

  public readonly onboardingRoute = [`/${OnboardingRouteNames.ONBOADING}`];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private config: ConfigService,
    private invitationService: InvitationsService,
    private toastService: ToastService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private buttonService: ButtonService,
  ) {}

  @Input()
  set loginCopy(copy: IAuthCopyright) {
    this.loginCopyright = {
      ...this.defaultLoginCopy,
      ...copy,
    };
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      keepSignedIn: [false],
    });

    if (this.invitationService.isOnboardingLinkTokenPresent()) {
      this.onboardingLoginText = ONBOARIND_LOGIN_TEXT;
    }

    this.buttonService.setLoading(false);
  }

  public togglePasswordVisibility(): void {
    this.inputType = togglePasswordType(this.inputType);
  }

  public loginUser(): void {
    this.errorMessage = '';

    if (this.form.valid) {
      const { email, password, keepSignedIn } = this.form.getRawValue();
      this.analyticsService.track(AnalyticsEvent.SignIn, {
        journey: AnalyticsJourney.Login,
        subJourney: AnalyticsSubJourney.SignIn,
        pageName: PageName.Login,
      });

      this.buttonService.setLoading(true);
      this.authService
        .login({ email, password }, keepSignedIn)
        .pipe(
          tap((userTokens: IUserTokens) => {
            this.analyticsService.track(AnalyticsEvent.SignInSuccess, {
              journey: AnalyticsJourney.Login,
              subJourney: AnalyticsSubJourney.SignIn,
              pageName: PageName.Login,
            });
            this.checkInvitation(userTokens);
          }),
          switchMap(() => this.analyticsService.setProfiles$()),
        )
        .subscribe(
          () => {
            this.setUserbackToken();
            this.navigateOnSuccess();
          },
          (error) => {
            this.buttonService.setLoading(false);

            this.showErrorToast(error);
            this.navigateOnError(error);
          },
        );
    }
  }

  private showErrorToast(error: HttpErrorResponse): void {
    const isAccessRevoked = this.isAccessRevoked(error);
    const isUnauthorized = error.status === StatusCodes.UNAUTHORIZED;
    const errorMessage = isUnauthorized ? INCORRECT_CREDENTIALS_ERROR : error.message;

    if (error.error.message?.code === REQUIRED_2FA_CODE) {
      return;
    }

    this.analyticsService.track(AnalyticsEvent.SignInFailure, {
      journey: AnalyticsJourney.Login,
      subJourney: AnalyticsSubJourney.SignIn,
      pageName: PageName.Login,
      failureReason: errorMessage,
    });

    if (!isAccessRevoked) {
      this.toastService.error(errorMessage);
    }
  }

  private isAccessRevoked(error: HttpErrorResponse): boolean {
    return error.error.message?.isRevoked;
  }

  public handleSignupClick(): void {
    this.analyticsService.track(AnalyticsEvent.SignUpClick, {
      journey: AnalyticsJourney.Login,
      subJourney: AnalyticsSubJourney.CompanySignup,
      pageName: PageName.Login,
    });
    this.router.navigate(this.onboardingRoute);
  }

  private checkInvitation(userTokens: IUserTokens): void {
    const invitation = this.invitationService.invitation;
    const isSameUser = invitation?.email === userTokens.user.email;
    if (isSameUser) {
      this.presetCompany(invitation.companyId);
    }
    this.clearInvitation();
  }

  private presetCompany(companyId: number): void {
    this.userService.setDefaultCompanyId(companyId);
  }

  private clearInvitation(): void {
    this.invitationService.invitation = null;
  }

  private navigateOnSuccess(): void {
    this.router.navigate([`/${CompanyRouteNames.COMPANY}`]);
  }

  private navigateOnError(error: HttpErrorResponse): void {
    const isAccessRevoked = this.isAccessRevoked(error);
    if (isAccessRevoked) {
      this.router.navigate(ACCESS_REVOKED_PATH);
    } else if (error.message === ERROR_MESSAGES.EMAIL_NOT_VERIFIED) {
      this.router.navigate(['/auth/check-mail']);
    } else if (error.error.message?.code === REQUIRED_2FA_CODE) {
      this.authService.token2fa = error.error.message.token;
      this.router.navigate(['/auth/verify-code']);
    } else {
      this.errorMessage = this.ERROR_MESSAGE_TEXT;
      this.form.valueChanges.pipe(take(1)).subscribe(() => (this.errorMessage = ''));
    }
  }

  private setUserbackToken(): void {
    const token = this.config.getUserbackToken();
    if (token) {
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
      Userback = window.Userback || {};
    Userback.access_token = '${token}';
    (function(id) {
        var s = document.createElement('script');
        s.async = 1;s.src = 'https://static.userback.io/widget/v1.js';
        var parent_node = document.head || document.body;parent_node.appendChild(s);
    })('userback-sdk');`;
      const body = this.document.getElementsByTagName('body')[0];
      body.appendChild(script);
    }
  }
}
