import { Injectable } from '@angular/core';
import { AnalyticsJourney, AnalyticsSubJourney } from '@app/shared/types/enums/analytics-journey.enum';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { AnalyticsService } from '@app/shared/services/analytics.service';

type StateI = 'started' | 'success' | 'failed';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsStakeholderService {
  constructor(private analyticsService: AnalyticsService) {}

  public stakeholderAnalyticsAdd(state: StateI) {
    if (state === 'success') {
      this.analyticsService.track(AnalyticsEvent.AddStakeholderSuccess, {
        journey: AnalyticsJourney.Stakeholders,
        subJourney: AnalyticsSubJourney.StakeholderSignup,
      });
    } else if (state === 'failed') {
      this.analyticsService.track(AnalyticsEvent.AddStakeholderFailure, {
        journey: AnalyticsJourney.Stakeholders,
        subJourney: AnalyticsSubJourney.StakeholderSignup,
      });
    } else if (state === 'started') {
      this.analyticsService.track(AnalyticsEvent.AddStakeholderStarted, {
        journey: AnalyticsJourney.Stakeholders,
        subJourney: AnalyticsSubJourney.StakeholderSignup,
      });
    }
  }

  public stakeholderAnalyticsEdit(state: StateI) {
    if (state === 'success') {
      this.analyticsService.track(AnalyticsEvent.EditStakeholderSuccess, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (state === 'failed') {
      this.analyticsService.track(AnalyticsEvent.EditStakeholderFailure, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (state === 'started') {
      this.analyticsService.track(AnalyticsEvent.EditStakeholderStarted, {
        journey: AnalyticsJourney.Stakeholders,
      });
    }
  }

  public stakeholderAnalyticsRevokeAccess(state: StateI) {
    if (state === 'success') {
      this.analyticsService.track(AnalyticsEvent.RevokeAccessSuccess, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (state === 'failed') {
      this.analyticsService.track(AnalyticsEvent.RevokeAccessFailure, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (state === 'started') {
      this.analyticsService.track(AnalyticsEvent.RevokeAccessStarted, {
        journey: AnalyticsJourney.Stakeholders,
      });
    }
  }

  public stakeholderAnalyticsDelete(state: StateI) {
    if (state === 'success') {
      this.analyticsService.track(AnalyticsEvent.DeleteStakeholderSuccess, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (state === 'failed') {
      this.analyticsService.track(AnalyticsEvent.DeleteStakeholderFailure, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (state === 'started') {
      this.analyticsService.track(AnalyticsEvent.DeleteStakeholderStarted, {
        journey: AnalyticsJourney.Stakeholders,
      });
    }
  }

  public stakeholderAnalyticsEmailInvite(status: StateI, isInvited: boolean) {
    if (status === 'success' && isInvited) {
      this.analyticsService.track(AnalyticsEvent.InviteStakeholderSuccess, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (status === 'failed' && isInvited) {
      this.analyticsService.track(AnalyticsEvent.InviteStakeholderFailure, {
        journey: AnalyticsJourney.Stakeholders,
      });
    } else if (status === 'started' && isInvited) {
      this.analyticsService.track(AnalyticsEvent.InviteStakeholderStarted, {
        journey: AnalyticsJourney.Stakeholders,
      });
    }
  }
}
