import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DemoService } from '@app/shared/services/demo.service';
import { AuthService } from '@app/auth/services/auth.service';
import { UserService } from '@app/shared/services/user.service';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';

@Component({
  selector: 'wevestr-welcome-demo',
  templateUrl: './welcome-demo.component.html',
  styleUrls: ['./welcome-demo.component.scss'],
})
export class WelcomeDemoComponent {
  public isStartButtonDisabled = false;

  constructor(
    private demoService: DemoService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public startDemo(): void {
    if (!this.isStartButtonDisabled) {
      const code: string = this.route.snapshot.queryParams.code;
      this.isStartButtonDisabled = true;

      this.demoService.start(code).subscribe((user) => {
        const keepSignedIn = true;
        this.userService.removeCurrentCompany();
        this.authService.login({ email: user.email, password: user.password }, keepSignedIn).subscribe(
          () => {
            this.router.navigate([`/${CompanyRouteNames.COMPANY}/equity/dashboard`]);
            this.isStartButtonDisabled = false;
          },
          () => {
            this.isStartButtonDisabled = false;
          },
        );
      });
    }
  }
}
