import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TEMPLATE_GUARD_ON_RUN } from '@app/directives/contants/directives.constants';

export class WvLetContext<T> {
  public $implicit: T;
  public wvLet: T;
}

@Directive({
  selector: '[wvLet]',
})
export class WvLetDirective<T> implements OnInit {
  private _context = new WvLetContext<unknown>();

  @Input('wvLet')
  public set wvLet(value: T) {
    this._context.$implicit = this._context.wvLet = value;
  }

  constructor(private _vcr: ViewContainerRef, private _templateRef: TemplateRef<WvLetContext<T>>) {}

  public static wvLetUseIfTypeGuard: void;

  static ngTemplateGuard_wvLet = TEMPLATE_GUARD_ON_RUN;

  static ngTemplateContextGuard<D>(_dir: WvLetDirective<D>, _ctx: unknown): _ctx is WvLetContext<D> {
    return true;
  }

  public ngOnInit(): void {
    this._vcr.createEmbeddedView(this._templateRef, this._context);
  }
}
