import { filter, propEq, pipe, length } from 'ramda';

export const getMetricsVisibleInChart = <T extends { isVisibleInChart: boolean }>(metrics: T[]): T[] =>
  filter(propEq<string>('isVisibleInChart', true), metrics);

export const getVisibleMetricsCount = <T extends { isVisibleInChart: boolean }>(metrics: T[]): number =>
  pipe(getMetricsVisibleInChart, length)(metrics);

export const isAnyMetricVisibleInChart = <T extends { isVisibleInChart: boolean }>(metrics: T[]): boolean =>
  pipe<T[], number, boolean>(getVisibleMetricsCount, Boolean)(metrics);

export const setMetricVisibilityInChart =
  (isVisibleInChart: boolean) =>
  <T>(metric: T): T & { isVisibleInChart: boolean } => ({
    ...metric,
    isVisibleInChart,
  });

export const toggleMetricVisibilityInChart = <T extends { isVisibleInChart: boolean }>(metric: T): T =>
  setMetricVisibilityInChart(!metric.isVisibleInChart)(metric);
