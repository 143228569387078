import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IParticipant, ITab } from '@app/shared/types/interfaces';
import { IPhase } from '@app/types/interfaces/profile.interface';
import { PhasesService } from '@app/shared/services/phases.service';
import { TransactionService } from '@app/shared/services';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';

enum Views {
  PARTICIPANTS = 'shareholders',
  ROUNDS = 'rounds',
}

export enum CapTableWidgetTabs {
  Shareholders = 'Top Shareholders',
  Rounds = 'Rounds',
}

@Component({
  selector: 'wevestr-cap-table-widget',
  templateUrl: './cap-table-widget.component.html',
  styleUrls: ['./cap-table-widget.component.scss'],
})
export class CapTableWidgetComponent implements OnInit {
  @Input()
  public isSmallWidget = false;
  public readonly companyRouteName = CompanyRouteNames.COMPANY;

  public capTableView: string = Views.PARTICIPANTS;
  public phases$: Observable<IPhase[]>;
  public queryParameter: string;
  public participants$: Observable<IParticipant[]>;
  public tabs: ITab[];
  public Views = Views;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private phasesService: PhasesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.queryParameter = this.isSmallWidget ? 'cap-table-small-view' : 'cap-table-view';
    this.subscribeToRouterEvents();
    this.initTabs();
    this.participants$ = this.getParticipants$();
    this.getPhases();
  }

  private getParticipants$(): Observable<IParticipant[]> {
    return this.transactionService
      .getCapTableParticipations()
      .pipe(
        map((participants) =>
          participants.sort(({ sharesAmount }, { sharesAmount: sharesAmount2 }) => sharesAmount2 - sharesAmount),
        ),
      );
  }

  private getPhases(): void {
    this.phases$ = this.phasesService.list({ isCaptableView: true });
  }

  private initTabs(): void {
    this.tabs = [
      {
        name: CapTableWidgetTabs.Shareholders,
        route: '.',
        queryParams: { [this.queryParameter]: Views.PARTICIPANTS },
      },
      {
        name: CapTableWidgetTabs.Rounds,
        route: '.',
        queryParams: { [this.queryParameter]: Views.ROUNDS },
      },
    ];
  }

  private subscribeToRouterEvents(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      const capTableView = params[this.queryParameter];

      if (!capTableView || !Object.values(Views).some((view) => capTableView === view)) {
        this.router.navigate([], {
          queryParams: { [this.queryParameter]: Views.PARTICIPANTS },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      } else if (this.capTableView !== capTableView) {
        this.capTableView = params[this.queryParameter];
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
