import { Component, Input, OnInit } from '@angular/core';
import { ITooltipDataLabelsPercents, ITooltipValue } from '@app/shared/types/interfaces';

@Component({
  selector: 'app-chart-tooltip',
  templateUrl: './chart-tooltip.component.html',
  styleUrls: ['./chart-tooltip.component.scss'],
})
export class ChartTooltipComponent implements OnInit {
  @Input() name: string;
  @Input() currentDateLabel: ITooltipValue;
  @Input() previousDateLabels: ITooltipValue[];
  @Input() tooltipPercents: ITooltipDataLabelsPercents;

  public currentPeriodPercent: number;
  public previousPeriodPercent: number;
  public yoYPercent: number;

  public currentPeriodIcon: string;
  public previousPeriodIcon: string;
  public yoYPercentIcon: string;

  constructor() {}

  ngOnInit(): void {
    this.initPercents();
    this.initIcons();
  }

  public initPercents(): void {
    this.currentPeriodPercent = this.tooltipPercents.currentDateLabelPercent;
    this.previousPeriodPercent = this.tooltipPercents.previousDateLabelPercent;
    this.yoYPercent = this.tooltipPercents.yoYPercent;
  }

  public initIcons(): void {
    this.currentPeriodIcon = this.defineIcon(this.currentPeriodPercent);
    this.previousPeriodIcon = this.defineIcon(this.previousPeriodPercent);
    this.yoYPercentIcon = this.defineIcon(this.yoYPercent);
  }

  private defineIcon(percent: number): string {
    return percent >= 0 ? (percent > 0 ? 'arrow_circle_up' : 'percent_zero') : 'arrow_circle_down';
  }
}
