<app-table-row
  [options]="options"
  [row]="rowValue"
  [index]="index"
  [isReorderEnabled]="isReorderEnabled"
  [roundedCorners]="roundedCorners"
  [roundedTopCorners]="roundedTopCorners"
  [roundedBottomCorners]="roundedBottomCorners"
  (hover)="onHover($event)"
  class="form"
>
  <ng-container *ngFor="let column of columns; index as columnIndex; last as last">
    <app-table-column
      *ngIf="!column.singleDisplay || !childIndex; else noValueDisplay"
      [style]="column | callback : getTdStyle : columnIndex : last && !editMode : rowValue"
      [classes]="{
        'cell-padding-l': options.cellPaddingSize === 'l',
        'cell-padding-m': options.cellPaddingSize === 'm',
        'cell-padding-s': options.cellPaddingSize === 's'
      }"
      [isReorderIconShown]="isReorderEnabled && columnIndex === 0 && isHovered"
    >
      <ng-container *ngTemplateOutlet="leftColoredBorder; context: {columnIndex: columnIndex}"></ng-container>
      <ng-container *ngIf="column.customCell && cellValueTemplate?.elementRef?.nativeElement; else normalCell">
        <ng-template
          [ngTemplateOutlet]="cellValueTemplate"
          [ngTemplateOutletContext]="{
            $implicit: rowValue,
            childValue: childValue,
            column: column,
            editable: editRowIndex === index,
            form: form
          }"
        >
        </ng-template>
      </ng-container>
      <ng-template #normalCell>
        <ng-container *ngIf="column | callback : isValueEditable : editMode : editRowIndex; else nonEditableValue">
          <ng-container *ngIf="column.dataType === 'number' || column.dataType === 'financials'; else textInput">
            <wevestr-input
              [formControl]="form.get(column.field)"
              [allowNegativeNumbers]="column?.inputOptions?.allowNegativeNumbers"
              [mask]="'separator' + (column?.inputOptions?.allowDecimals ? '.4' : '')"
              class="element-input wevestr-input app-numbers-font"
              [ngStyle]="{
                'text-align': column.textAlign ? column.textAlign : null
              }"
              ngDefaultControl
            >
            </wevestr-input>
            <div class="error-message app-body-small" *ngIf="form.get(column.field).invalid; else placeholderError">
              {{ form.get(column.field).errors | callback : getErrorMessage}}
            </div>
            <ng-template #placeholderError>
              <div class="error-placeholder" *ngIf="form.invalid"></div>
            </ng-template>
          </ng-container>

          <ng-template #textInput>
            <wevestr-input
              [formControl]="form.get(column.field)"
              type="text"
              class="element-input wevestr-input"
              [ngStyle]="{
                'text-align': column.textAlign ? column.textAlign : null
              }"
              ngDefaultControl
            >
            </wevestr-input>
          </ng-template>
        </ng-container>
        <ng-template #nonEditableValue>
          <ng-container *ngIf="column.renderer; else fieldValue">
            <ng-container *ngIf="editMode && editRowIndex === index && form; else nonEditingRow">
              <ng-container *ngIf="(form.value | callback : column.renderer : childValue) | isDefined; else simpleRender">
                <span [innerHTML]="(form.value | callback : column.renderer : childValue) | safeHtml"></span>
              </ng-container>
            </ng-container>
            <ng-template #nonEditingRow>
              <ng-container *ngIf="(rowValue | callback : column.renderer : childValue) | isDefined; else simpleRender">
                <ng-container *ngIf="column.hasRightAlignment; else noRightAlignment">
                  <span #cellWithRightAlignment [innerHTML]="(rowValue | callback : column.renderer : childValue) | safeHtml"></span>
                </ng-container>
                <ng-template #noRightAlignment>
                  <span [innerHTML]="(rowValue | callback : column.renderer : childValue) | safeHtml"></span>
                </ng-template>
              </ng-container>
            </ng-template>
            <ng-template #simpleRender>
              <ng-container *ngIf="column.hasRightAlignment; else noRightAlignment">
                <span #cellWithRightAlignment *ngTemplateOutlet="renderSimpleValue; context: {column: column}"></span>
              </ng-container>
              <ng-template #noRightAlignment>
                <span *ngTemplateOutlet="renderSimpleValue; context: {column: column}"></span>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-template #fieldValue>
            <span *ngIf="column.fromChildValue; else fromRowValue">{{
              (childValue[column.field] | isDefined) ? childValue[column.field] : '-'
            }}</span>
            <ng-template #fromRowValue>
              <div>{{ (rowValue[column.field] | isDefined) ? rowValue[column.field] : '-' }}</div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
    </app-table-column>
    <ng-template #noValueDisplay>
      <app-table-column>
        <ng-container *ngTemplateOutlet="leftColoredBorder; context: {columnIndex: columnIndex}"></ng-container>
      </app-table-column>
    </ng-template>
  </ng-container>

  <app-table-column
    *ngIf="editMode"
    [classes]="{
      'cell-padding-l': options.cellPaddingSize === 'l',
      'cell-padding-m': options.cellPaddingSize === 'm',
      'cell-padding-s': options.cellPaddingSize === 's'
    }"
    [style]="null | callback : getTdStyle :null : true"
    [isEditActionsShown]="editMode && isHovered && !isSomeElementEditing"
    [isEditingActionsShown]="isRowEditing"
    [canEdit]="canEdit && isRowEditable"
    [canDelete]="canDelete && isRowDeletable"
    (edit)="handleEnterRowEditMode()"
    (delete)="deleteRow.emit()"
    (save)="handleSaveRowData()"
    (cancel)="cancelSaveData.emit()"
    [isSavingDisabled]="!(form.valid | callback: isRowValid : requiredFields)"
  ></app-table-column>

  <app-table-column *ngIf="options.actions && options.actions.length !== 0" [classes]="{'has-text-centered': true}">
    <app-menu *ngIf="!options.hideActionsForRow || !(rowValue | callback : options.hideActionsForRow : childValue)">
      <mat-icon appMenuTrigger class="cursor-pointer view-more-dots" svgIcon="more_vert"></mat-icon>
      <div appMenuContent>
        <div class="flex-column">
          <ng-container *ngFor="let action of options.actions">
            <div
              *ngIf="action && (!action.predicate || (rowValue | callback : action.predicate : childValue))"
              class="app-menu__item app-body-small"
              (click)="handleAction({row: rowValue, rowIndex: index, action: action, childIndex: childIndex})"
            >
              {{ action.name }}
            </div>
          </ng-container>
        </div>
      </div>
    </app-menu>
  </app-table-column>
</app-table-row>

<ng-template #leftColoredBorder let-columnIndex="columnIndex">
  <div
    *ngIf="options.hasColorBorder && columnIndex === 0"
    class="left-colored-border full-height"
    [ngStyle]="{
      'background-color': options.getRowColorBorder ? (index | callback : options.getRowColorBorder : rowValue) : '#a0a0a0'
    }"
  ></div>
</ng-template>

<ng-template #renderSimpleValue let-column="column">
  <ng-container *ngIf="(rowValue | callback : column.renderer) | isDefined; else withoutRenderer">
    <span [innerHTML]="(rowValue | callback : column.renderer) | safeHtml"></span>
  </ng-container>
  <ng-template #withoutRenderer>
    {{ (rowValue[column.field] | isDefined) ? rowValue[column.field] : '-' }}
  </ng-template>
</ng-template>
