<ng-container *ngIf="controlsEnabled$ | async as controls">
  <div
    class="calendar__header flex-row is-justify-content-space-between is-align-items-center"
    [ngClass]="{ 'remove-bottom-border': type === pickerType.month }"
  >
    <div
      class="flex is-justify-content-space-between is-align-items-center arrows-container"
      *ngIf="type === pickerType.date; else previousYearControl"
    >
      <button
        (click)="handlePreviousMonthChange()"
        class="arrow-group button-reset-styles flex"
        [disabled]="controls.previousMonth"
      >
        <mat-icon svgIcon="arrow" class="arrow left"></mat-icon>
      </button>
      <button
        class="double-arrow arrow-group button-reset-styles flex"
        (click)="handlePreviousYearChange()"
        [disabled]="controls.previousYear"
      >
        <mat-icon svgIcon="arrow" class="arrow left"></mat-icon>
        <mat-icon svgIcon="arrow" class="arrow left"></mat-icon>
      </button>
    </div>

    <span class="font-weight-medium header__label">{{ periodLabel }}</span>

    <div
      class="flex is-justify-content-space-between is-align-items-center arrows-container"
      *ngIf="type === pickerType.date; else nextYearControl"
    >
      <button
        class="double-arrow arrow-group button-reset-styles flex"
        (click)="handleNextYearChange()"
        [disabled]="controls.nextYear"
      >
        <mat-icon svgIcon="arrow" class="arrow right font-weight-medium"></mat-icon>
        <mat-icon svgIcon="arrow" class="arrow right font-weight-medium"></mat-icon>
      </button>
      <button
        (click)="handleNextMonthChange()"
        class="arrow-group button-reset-styles flex"
        [disabled]="controls.nextMonth"
      >
        <mat-icon svgIcon="arrow" class="arrow right font-weight-medium"></mat-icon>
      </button>
    </div>
  </div>

  <ng-template #previousYearControl>
    <button
      (click)="handlePreviousYearChange()"
      class="arrow-group button-reset-styles flex"
      [disabled]="controls.previousYear"
    >
      <mat-icon svgIcon="arrow" class="arrow left"></mat-icon>
    </button>
  </ng-template>

  <ng-template #nextYearControl>
    <button
      (click)="handleNextYearChange()"
      class="arrow-group button-reset-styles flex"
      [disabled]="controls.nextYear"
    >
      <mat-icon svgIcon="arrow" class="arrow right"></mat-icon>
    </button>
  </ng-template>

  <div class="calendar__footer flex is-justify-content-flex-end">
    <button
      class="button tertiary-button button__smallest margin-right-m"
      *ngIf="type === pickerType.month"
      (click)="resetDate()"
    >
      Reset
    </button>
    <button class="button primary-button button__smallest" (click)="setCurrent()" [disabled]="controls.currentDate">
      <span [matTooltip]="controls.currentDate ? 'Current date is unavailable' : ''">{{ buttonLabel }}</span>
    </button>
  </div>
</ng-container>
