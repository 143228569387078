import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'ramda';

@Pipe({
  name: 'isNotEmptyArray',
})
export class IsNotEmptyArrayPipe implements PipeTransform {
  public transform<T>(array: T[] = []): boolean {
    return array && !isEmpty(array);
  }
}
