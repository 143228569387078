import { IMonthValue } from '@app/performance/types/interfaces/month-value.interface';
import { Frequency } from '@app/shared/types/enums';
import { ViewTypeConversion } from '@app/performance/types/enums';

const QUARTER_UPDATED_NTH_MONTH = 1;
const MONTHS_PER_QUARTER = 3;
export const MONTHS_PER_YEAR = 12;
export const QUARTERS_PER_YEAR = 4;

export const getMonthNames = (): string[] => [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const getShortMonthNames = (): string[] => getMonthNames().map((name) => getMonthShortName(name));

export const getMonthShortName = (month: string): string => month[0].toUpperCase() + month.slice(1, 3);

export const getQuarters = (): string[] => ['Q1', 'Q2', 'Q3', 'Q4'];

export const getQuarterValues = (values: IMonthValue[], viewTypeConversion?: ViewTypeConversion): number[] => {
  if (values.length === 0) {
    return Array(QUARTERS_PER_YEAR).fill(0);
  } else {
    switch (viewTypeConversion) {
      case ViewTypeConversion.FIRST_VALUE:
        return getQuarterMonthsFirstValue(values);
      case ViewTypeConversion.LAST_VALUE:
        return getQuarterMonthsLastValue(values);
      case ViewTypeConversion.SUM:
        return getQuarterMonthsSum(values);
      default:
        return getQuarterMonthsSum(values);
    }
  }
};

export const getQuarterMonthsFirstValue = (values: IMonthValue[]): number[] => {
  const _values = [];

  for (let i = 0; i < values.length; i = i + MONTHS_PER_QUARTER) {
    _values.push(values[i].value);
  }

  return _values;
};

export const getQuarterMonthsLastValue = (values: IMonthValue[]): number[] => {
  const _values = [];

  for (let i = MONTHS_PER_QUARTER - 1; i < values.length; i = i + MONTHS_PER_QUARTER) {
    _values.push(values[i].value);
  }

  return _values;
};

export const getQuarterMonthsSum = (values: IMonthValue[]) => {
  return values.reduce((result, month: IMonthValue, index: number) => {
    if (index % MONTHS_PER_QUARTER === 0) {
      result.push(month.value);
    } else {
      result[result.length - 1] += month.value;
    }

    return result;
  }, []);
};

export const getEmptyMonthValues = (): IMonthValue[] => getMonthNames().map((month) => ({ month, value: 0 }));

export const getMonthValues = (values: IMonthValue[]): number[] => {
  let monthValues;

  if (values.length === 0) {
    monthValues = Array(MONTHS_PER_YEAR).fill(0);
  } else {
    monthValues = values.map((month: IMonthValue) => month.value);
  }

  return monthValues;
};

export const getValuesForViewType = (
  values: IMonthValue[],
  viewType: Frequency,
  viewTypeConversion?: ViewTypeConversion,
): number[] => {
  switch (viewType) {
    case Frequency.QUARTERLY:
      return getQuarterValues(values, viewTypeConversion);
    case Frequency.MONTHLY:
      return getMonthValues(values);
    default:
      return getQuarterValues(values, viewTypeConversion);
  }
};

export const getLabelsForViewType = (viewType: Frequency): string[] => {
  switch (viewType) {
    case Frequency.QUARTERLY:
      return getQuarters();
    case Frequency.MONTHLY:
      return getShortMonthNames();
    default:
      return getQuarters();
  }
};

export const getMonthValueEmptyArray = () => getMonthNames().map((month: string) => ({ month, value: 0 }));

export const getMonthValueArray = (
  viewType: Frequency,
  labels: string[],
  values: Record<string, string | number>,
): IMonthValue[] => {
  if (viewType === Frequency.MONTHLY) {
    const months = getMonthNames();
    return labels.map((label: string, index: number) => ({
      month: months[index],
      value: values[label] ? +values[label] : 0,
    }));
  } else if (viewType === Frequency.QUARTERLY) {
    const months = getMonthValueEmptyArray();
    labels.forEach((label: string, index: number) => {
      const monthIndex = index * MONTHS_PER_QUARTER + (QUARTER_UPDATED_NTH_MONTH - 1);
      months[monthIndex].value = +values[label] || 0;
    });

    return months;
  }
};

export const getUpdatedMonthValueArray = (
  viewType: Frequency,
  previousValues: IMonthValue[],
  labels: string[],
  values: Record<string, string | number>,
): IMonthValue[] => {
  if (viewType === Frequency.MONTHLY) {
    const months = getMonthNames();
    return labels.map((label: string, index: number) => ({
      month: months[index],
      value: values[label] ? +values[label] : 0,
    }));
  } else if (viewType === Frequency.QUARTERLY) {
    return getUpdatedQuarterMonths(previousValues, labels, values);
  }
};

const getUpdatedQuarterMonths = (
  previousValues: IMonthValue[],
  labels: string[],
  values: Record<string, string | number>,
) => {
  const previousQuarterValues = getQuarterValues(previousValues);
  const months =
    previousValues.length !== 0
      ? previousValues.map((monthValue) => ({ ...monthValue, value: monthValue.value ?? 0 }))
      : getEmptyMonthValues();

  labels.forEach((label: string, index: number) => {
    const difference = (+values[label] || 0) - previousQuarterValues[index];
    const monthIndex = index * MONTHS_PER_QUARTER + (QUARTER_UPDATED_NTH_MONTH - 1);
    months[monthIndex].value = months[monthIndex].value + difference;
  });

  return months;
};
