import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';
import { ParticipantRouteNames } from '@app/participant/types/enums/participant-route-names.enum';
import { IModeOption } from '@app/types/interfaces/mode.interface';

export const MODE_KEY = {
  COMPANY: 'company',
  PARTICIPANT: 'participant',
};

export const MODE_OPTIONS: { [key: string]: IModeOption } = {
  [MODE_KEY.COMPANY]: {
    name: 'Administrator',
    uri: CompanyRouteNames.COMPANY,
    id: MODE_KEY.COMPANY,
  },
  [MODE_KEY.PARTICIPANT]: {
    name: 'Participant',
    uri: ParticipantRouteNames.PARTICIPANT,
    id: MODE_KEY.PARTICIPANT,
  },
};
