import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { MODE_KEY, MODE_OPTIONS } from '@app/types/enums/mode-options.enum';
import { IModeOption } from '@app/types/interfaces/mode.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-mode-selector',
  templateUrl: './mode-selector.component.html',
  styleUrls: ['./mode-selector.component.scss'],
})
export class ModeSelectorComponent implements OnInit {
  public readonly options = Object.values(MODE_OPTIONS);
  public selectedOption$ = new BehaviorSubject<IModeOption>(MODE_OPTIONS[MODE_KEY.COMPANY]);
  constructor(private router: Router, private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    const isParticipant = this.router.url.startsWith('/participant');
    this.selectedOption$.next(isParticipant ? MODE_OPTIONS[MODE_KEY.PARTICIPANT] : MODE_OPTIONS[MODE_KEY.COMPANY]);
  }

  public handleModeChange(mode: IModeOption): void {
    switch (mode.name) {
      case MODE_OPTIONS[MODE_KEY.COMPANY].name:
        this.analyticsService.track(AnalyticsEvent.ViewModeAdminView);
        break;
      case MODE_OPTIONS[MODE_KEY.PARTICIPANT].name:
        this.analyticsService.track(AnalyticsEvent.ViewModeParticipantView);
        break;
    }
    this.selectedOption$.next(mode);
    this.router.navigate([mode.uri]);
  }
}
