import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDocument } from '@app/documents/types/interfaces';
import { getNumberBasedOnUnit } from '@app/shared/utils/helpers/files.helpers';
import { BytesUnit } from '@app/shared/types/enums';

@Component({
  selector: 'wevestr-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() public label: string;
  @Input() public disabled = false;
  @Input()
  public allowedFileExtensions: string[];
  @Input()
  public canDelete = false;

  @Output() public upload = new EventEmitter<File>();
  @Output() public remove = new EventEmitter<void>();

  @ViewChild('file', { static: true })
  private fileInput: ElementRef;

  public value: Partial<IDocument>;
  public onTouched: () => void;

  constructor() {}

  public writeValue(obj: IDocument): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  public handleDelete(): void {
    this.value = null;
    this.remove.emit();
  }

  public handleFileUpload(files: File[]): void {
    const file = files[0];
    this.upload.emit(file);
    this.value = {
      fileObject: {
        size: +getNumberBasedOnUnit(file.size, BytesUnit.mb).toFixed(2),
        fileName: file.name,
        contentType: file.type,
      },
      createdAt: new Date(),
    };
    this.allowLoadingTheSameFileAgain();
  }

  private allowLoadingTheSameFileAgain(): void {
    this.fileInput.nativeElement.value = '';
  }

  public registerOnChange(): void {}

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled?: boolean): void {
    this.disabled = isDisabled;
  }
}
