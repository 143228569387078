import { Component, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ITableColumn } from '@app/shared/types/interfaces/table-columns.interface';
import { TableBaseRow } from '@app/shared/types/classes/table-row-base.component.class';

@Component({
  selector: 'app-table-category-row',
  templateUrl: './table-category-row.component.html',
  styleUrls: ['./table-category-row.component.scss'],
})
export class TableCategoryRowComponent extends TableBaseRow implements OnInit {
  @Output() toggle: EventEmitter<number> = new EventEmitter();
  name: UntypedFormControl;

  constructor() {
    super();
  }

  @HostListener('keyup.enter', ['$event'])
  submitForm(): void {
    if (this.name && this.name.valid) {
      this.handleSaveRowData();
    }
  }

  ngOnInit(): void {
    this.initForm();
    if (this.editMode && this.editRowIndex === this.index) {
      this.handleEnterRowEditMode();
    }
  }

  private initForm(): void {
    this.name = new UntypedFormControl();
  }

  handleEnterRowEditMode(): void {
    this.enterEditMode.emit();

    if (this.rowValue && this.rowValue.name) {
      this.name.setValue(this.rowValue.name);
    }
  }

  isRowEmpty(): boolean {
    return !this.name.value?.trim();
  }

  getColumnsToRender(): ITableColumn[] {
    return this.columns.slice(1);
  }

  getCategoryNameColumnStyle(): Record<string, string | number> {
    const paddingRight = this.options.tablePaddingRight ? this.options.tablePaddingRight : null;
    const rowStyle = this.options.getRowStyle ? this.options.getRowStyle({ index: this.index }) : {};
    let paddingLeft = null;

    if (this.options.categoryPaddingLeft) {
      paddingLeft = this.options.categoryPaddingLeft;
    } else if (this.options.tablePaddingLeft) {
      paddingLeft = this.options.tablePaddingLeft;
    }

    return {
      'padding-left': paddingLeft,
      'padding-right': paddingRight,
      'border-bottom': rowStyle['border-bottom'],
    };
  }

  getTdStyle(column?: ITableColumn, last?: boolean): Record<string, string | number> {
    const paddingRight = last && this.options.tablePaddingRight ? this.options.tablePaddingRight : null;
    const rowStyle = this.options.getRowStyle ? this.options.getRowStyle({ index: this.index }) : {};

    let styles = {
      'padding-right': paddingRight,
      'border-bottom': rowStyle['border-bottom'],
    } as Record<string, string | number>;

    if (column) {
      const valueStyle = column ? column.valuesStyle : {};
      styles = {
        ...styles,
        ['text-align']: column.textAlign || null,
        ...valueStyle,
      };
    }

    return styles;
  }

  handleSaveRowData(): void {
    !this.isRowEmpty() && this.saveRowData.emit({ name: this.name.value, isCategory: true });
  }
}
