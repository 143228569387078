import { DATE_INVALID_SHORT_ERROR, REQUIRED_ERROR } from '@app/forms/constants';
import { HUNDRED_PERCENT } from '@app/shared/utils/constants/common.constants';
import { SHARES_TOOLTIPS } from '@app/shared/utils/constants/stakeholder.contants';
import { POSITIVE_NUMBER } from '@app/forms/utils/helpers/generic-form-errors.helper';

export const MULTIPLE_TRIGGERS_LABEL = 'Multiple';

export const TRIGGER_DATA_TYPES = {
  NUMBER: 'number',
  DATE: 'date',
};

export const MAX_SAFE_INT = 2147483647;

export const CONVERTIBLE_MODAL_ERRORS = {
  POSITIVE_NUMBER: POSITIVE_NUMBER,
  PERCENT: (field: string): string => `${field} should be a positive number less than ${HUNDRED_PERCENT}`,
  START_DATE: 'Start Date should be between Date of Issue and End Date',
  END_DATE: 'End Date should be after Start Date and Date of Issue',
  DISCOUNT_START_DATE_CONVERTIBLE: `Discount start date should be after the start date of convertible`,
  DISCOUNT_START_DATE_CONVERTIBLE_END: `Discount start date should be before the end date of convertible`,
  DISCOUNT_START_DATE_SELF: `Discount start date should be before the end date of it`,
  DISCOUNT_END_DATE: `Discount end date should be before the end date of convertible`,
  DISCOUNT_END_DATE_START_CONVERTIBLE: `Discount end date should be after the start date of convertible`,
  DATE_OF_ISSUE: 'Date of issue should be equal or less than today',
  TRIGGER_REQUIRED_VALUE: 'This type of trigger requires a value',
  TRIGGER_DATE_NOT_IN_RANGE: 'Date trigger should be within start and end dates of convertible',
  REQUIRED: REQUIRED_ERROR,
  INTERVALS_OVERLAP: 'The discounts intervals should not overlap',
  DATE_INVALID: DATE_INVALID_SHORT_ERROR,
  INVESTMENT: 'Valuation should be greater than investment',
};

export const CONVERTIBLE_MODAL_TOOLTIP = {
  maxDiscount: 'It is possible to set the maximum of 3 discounts.',
  valuationCap: 'At this valuation, this convertible note becomes equity in the company.',
  interest:
    'The interest rate of a convertible note indicates how much interest accrues to the investor prior to the note’s conversion.',
  discount:
    'A discount is often offered to the lender to reward them for their investment risk, whereby granting them the right to convert the amount of the loan, plus interest, at a reduced price to the purchase price paid by other investors.',
  tagAlongRight: SHARES_TOOLTIPS.tagAlongRight,
  dragAlongRight: SHARES_TOOLTIPS.dragAlongRight,
  trigger:
    'The trigger event occurs when the company raises equity investment at or above a certain amount. Raising that amount will generally trigger the conversion of the convertible notes (both principal and possibly accrued interest) to equity automatically.',
  startDate: 'This is the start date of this specific discount period.',
  endDate: 'This is the end date of this specific discount period.',
};

export const CONVERTIBLE_MODAL_AVATAR_SIZE = 96;
export const MAXIMUM_DISCOUNTS_NUMBER = 3;
export const MAXIMUM_TRIGGERS_NUMBER = 2;

export const CONVERT_CONVERTIBLE_TOOLTIP = {
  round:
    'You can select any round within the duration of convertible. If there are no suitable ones – you can create a new round.',
};
