import { RouterModule } from '@angular/router';
import { GuestGuard } from '@app/shared/guards/guest.guard';
import { NgModule } from '@angular/core';

import * as fromContainers from '@app/auth/containers';
import * as fromComponents from '@app/auth/components';
import {
  DemoInvitationGuard,
  RedirectToNewInvitationGuard,
  ResetPasswordGuard,
  TokenGuard,
  VerifyCodeGuard,
} from '@app/auth/guards';
import { UserGuard } from '@app/company/guards';
import { AuthRoutes } from '@app/shared/types/enums/auth-routes.enum';
import { IWVRoutes } from '@app/shared/types/interfaces/wevestr-route.interface';
import { EMAIL_CHANGE_ROUTES } from '@app/auth/types/enums/email-change-routes.enum';
import { InterimScreenComponent } from '@app/components/components/interim-screen/interim-screen.component';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { PageName } from '@app/shared/types/enums/page-name.enum';

export const AUTH_ROUTES: IWVRoutes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'payment-needed',
    canActivate: [UserGuard],
    component: fromComponents.PaymentNeededComponent,
    data: { pageName: PageName.PaymentNeeded },
  },
  {
    path: 'login',
    canActivate: [GuestGuard],
    component: fromContainers.LoginComponent,
    data: { pageName: PageName.Login },
  },
  {
    path: 'verify-code',
    canActivate: [VerifyCodeGuard],
    component: fromContainers.VerifyCodeComponent,
    data: { pageName: PageName.VerifyCode },
  },
  {
    path: 'check-mail',
    canActivate: [GuestGuard],
    component: fromComponents.VerifyEmailComponent,
    data: { pageName: PageName.VerifyEmail },
  },
  {
    path: 'verify-failed',
    canActivate: [GuestGuard],
    component: fromComponents.VerifyFailedComponent,
    data: { pageName: PageName.VerifyFailed },
  },
  {
    path: 'verify',
    canActivate: [TokenGuard],
    component: fromComponents.VerifyFailedComponent,
    data: { pageName: PageName.VerifyFailed },
  },
  {
    path: 'forgot-credentials',
    canActivate: [GuestGuard],
    component: fromContainers.ForgotCredentialsComponent,
    data: { pageName: PageName.ForgotPassword },
  },
  {
    path: AuthRoutes.ACCEPTED_INVITE,
    component: fromComponents.AcceptedInviteComponent,
    data: { pageName: PageName.AcceptInvitation },
  },
  {
    path: AuthRoutes.EXPIRED_INVITE,
    // component: fromComponents.ExpiredInviteComponent,
    component: fromContainers.ExpiredInviteLinkComponent,
    data: { pageName: PageName.InvitationExpired },
  },
  {
    path: AuthRoutes.ACCESS_REVOKED,
    canActivate: [GuestGuard],
    component: fromContainers.RevokeAccessComponent,
  },
  {
    path: 'reset-password',
    canActivate: [GuestGuard, ResetPasswordGuard],
    component: fromContainers.PasswordResetComponent,
    data: { pageName: PageName.ResetPassword },
  },
  {
    path: 'reset-password-success',
    component: InterimScreenComponent,
    data: {
      title: 'Are you ready to start?',
      description: 'You’ve successfully reset your password.',
      redirectUrl: loginPath,
    },
  },
  {
    path: 'invitation',
    canActivate: [RedirectToNewInvitationGuard],
    component: fromContainers.LoginComponent,
    data: { pageName: PageName.Login },
  },
  {
    path: 'approvals',
    children: [
      {
        path: 'email-change',
        children: [
          {
            path: EMAIL_CHANGE_ROUTES.old,
            component: fromContainers.EmailChangeApprovalComponent,
            data: { pageName: PageName.EmailChangeApproval },
          },
          {
            path: EMAIL_CHANGE_ROUTES.new,
            component: fromContainers.EmailChangeApprovalComponent,
            data: { pageName: PageName.EmailChangeApproval },
          },
        ],
      },
    ],
  },
  {
    path: 'demo/welcome',
    canActivate: [GuestGuard, DemoInvitationGuard],
    component: fromContainers.WelcomeDemoComponent,
    data: { pageName: PageName.WelcomeDemo },
  },
];

@NgModule({
  imports: [RouterModule.forChild(AUTH_ROUTES)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
