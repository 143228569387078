import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Frequency } from '@app/shared/types/enums';
import { IBasicItem } from '@app/shared/types/interfaces';
import { ChartDisplayMode } from '@app/performance/types/enums/chart-display-mode.enum';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { map } from 'rxjs/operators';
import { ICompanySettings } from '@app/shared/types/interfaces/company-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  private _viewType$: BehaviorSubject<Frequency> = new BehaviorSubject<Frequency>(Frequency.QUARTERLY);
  private _year$: BehaviorSubject<number> = new BehaviorSubject(new Date().getFullYear());

  private static NUMBER_OF_DISPLAYED_PAST_YEARS = 5;

  constructor(private companySettingsService: CompanySettingsService) {}

  get viewType$(): Observable<Frequency> {
    return this._viewType$.asObservable();
  }

  get year$(): Observable<number> {
    return this._year$.asObservable();
  }

  /**@deprecated don't you fluppy dare to use syncrounous value */
  get viewType(): Frequency {
    return this._viewType$.value;
  }

  /**@deprecated don't you fluppy dare to use syncrounous value */
  get year(): number {
    return this._year$.value;
  }

  setViewType(value: Frequency): void {
    this._viewType$.next(value);
  }

  setYear(value: number): void {
    this._year$.next(value);
  }

  getUntilYear(): number {
    return Math.max(new Date().getFullYear(), this.year);
  }

  get viewTypes$(): Observable<{ id: Frequency; name: string }[]> {
    return this.companySettingsService.get().pipe(
      map((settings: ICompanySettings) => {
        const viewTypes = [{ id: Frequency.QUARTERLY, name: 'Quarterly' }];

        if (!settings.dataEditMode || settings.dataEditMode === Frequency.MONTHLY) {
          viewTypes.push({ id: Frequency.MONTHLY, name: 'Monthly' });
        }

        if (settings.dataEditMode === Frequency.MONTHLY) {
          this._viewType$.next(Frequency.MONTHLY);
        }

        return viewTypes;
      }),
    );
  }

  get years(): IBasicItem[] {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: PerformanceService.NUMBER_OF_DISPLAYED_PAST_YEARS + 1 }, (_, index): IBasicItem => {
      return {
        id: index,
        name: String(currentYear - index),
      };
    });
  }

  get chartModes(): { id: ChartDisplayMode; icon: string }[] {
    return [
      { id: ChartDisplayMode.LINE, icon: 'single_chart' },
      { id: ChartDisplayMode.BAR, icon: 'bar_chart' },
    ];
  }
}
