import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '@app/services/config.service';
import { IInvite } from '@app/auth/types/interfaces';
import { User } from '@app/shared/types/classes';

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  private readonly baseUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.baseUrl = this.config.getFullBaseUrl();
  }

  public get url(): string {
    return `${this.baseUrl}/demo`;
  }

  public start(invitationCode: string): Observable<User & { password: string }> {
    return this.http.post<User & { password: string }>(`${this.url}/auth`, { code: invitationCode });
  }

  public validate(code: string): Observable<IInvite> {
    return this.http.get<IInvite>(`${this.url}/invitation/${code}/valid`);
  }
}
