import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SettingsRouteNames } from '@app/settings/types/enums';
import { COMPANY_SETTINGS_TABS_PATH } from '@app/settings/constants/paths.constants';

// TODO: inject service in needed modules only(settings, home)
@Injectable({
  providedIn: 'root',
})
export class SettingsNavigationService {
  constructor(private router: Router) {}

  public navigateToSlicingPieCompanySettingsPage(): Promise<boolean> {
    return this.navigateCompanySettingsTab(SettingsRouteNames.COMPANY_STARTUP_COOP);
  }

  public navigateToExternalSourcesCompanySettingsPage(): Promise<boolean> {
    return this.navigateCompanySettingsTab(SettingsRouteNames.COMPANY_EXTERNAL_SOURCES);
  }

  public navigateCompanySettingsTab(tabName: SettingsRouteNames): Promise<boolean> {
    return this.router.navigate(COMPANY_SETTINGS_TABS_PATH[tabName]);
  }
}
