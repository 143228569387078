import { CapTableParticipantType } from '@app/shareholder-management/types/enums/cap-table-participant-type.enum';
import { TransactionType } from '@app/shareholder-management/types/enums/transaction-type.enum';

interface ISharesTransaction {
  sharesAmount: number;
  totalSharesPercentage: number;
}

export interface IParticipant extends ISharesTransaction {
  id: number;
  logoUrl?: string;
  name: string;
  phases: string[];
  transactions?: IParticipantTransaction[];
  participants?: IParticipant[];
  type: CapTableParticipantType;
  folderId?: number;
}

export interface IParticipantTransaction extends ISharesTransaction {
  id: number;
  phase: {
    id: number;
    name: string;
  };
  dateIssued: string | Date;
  isDeletable: boolean;
  type?: TransactionType;
  investmentAmount?: number;
  valuationAmount?: number;
  valuationTiming?: string;
  folderId?: number;
}
