import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { map } from 'rxjs/operators';
import { IFrameworkPhase } from '@app/framework/types/interfaces';
import { Observable } from 'rxjs';
import { convertStringToLocalDate } from '@app/shared/utils/helpers/dates.helpers';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable()
export class FrameworkPhasesService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getFullBaseUrl();
  }

  private get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/framework/framework-phases`;
  }

  public setPhasesDates(phases: IFrameworkPhase[]): Observable<IFrameworkPhase> {
    return this.http.post<IFrameworkPhase>(`${this.baseUrl}/${this.url}/timeline`, phases);
  }

  public getActive(): Observable<IFrameworkPhase> {
    return this.http.get<IFrameworkPhase>(`${this.baseUrl}/${this.url}/active`);
  }

  public list(): Observable<IFrameworkPhase[]> {
    return this.http.get<IFrameworkPhase[]>(`${this.baseUrl}/${this.url}`).pipe(
      map((phases) => {
        return phases.map((phase) => ({
          ...phase,
          startDate: convertStringToLocalDate(phase.startDate),
          endDate: convertStringToLocalDate(phase.endDate),
        }));
      }),
    );
  }
}
