export enum DatePickerType {
  date = 'month',
  month = 'year',
}

export type TDateString = string | Date;

export type TDateLike = TDateString | number;

export type TDateInterval<T = Date> = {
  id: number;
  startDate: T;
  endDate: T;
};
