import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { IAuditImportParameters } from '@app/shared/types/interfaces/import-parameters.interface';
import {
  ALLOWED_AUDIT_IMPORT_TYPES,
  IMPORT_AUDIT_FILES_DND_FILE_LABEL,
  IMPORT_AUDIT_FILES_TABLE_COLUMNS,
  MAX_FILES_COUNT,
  MAX_FILES_COUNT_ERROR,
  MAX_TOTAL_FILES_SIZE_MB,
  IMPORT_TABLE_HEADER_ROW_HEIGHT,
  IMPORT_TABLE_ROW_HEIGHT,
} from '@app/shared/utils/constants/import.constants';
import { OverrideMode, IImportAuditFile } from '@app/shared/types/interfaces';
import { getFilesArrayFromFileList } from '@app/shared/utils/helpers/files.helpers';
import {
  getDuplicateFileError,
  getInvalidFileTypeError,
  isImportFileTypeValid,
  isMoreFilesThanAllowed,
} from '@app/shared/utils/helpers/import.helpers';
import { removeElementByIndex } from '@app/shared/utils/helpers/arrays.helpers';
import { isEmpty } from 'ramda';
import { ITableColumn } from '@app/shared/types/interfaces/table-columns.interface';
import { ITableOptions } from '@app/types/interfaces/table-options.interface';
import { Size } from '@app/shared/types/enums';
import { ToastService } from '@app/shared/services/toast.service';
import { RadioButtonThemes } from '@app/forms/types';

@Component({
  selector: 'wevestr-select-audit-files',
  templateUrl: './select-audit-files.component.html',
  styleUrls: ['./select-audit-files.component.scss'],
})
export class SelectAuditFilesComponent implements OnInit {
  @Input() public isImportInProgress: boolean;

  @Output() public select = new EventEmitter<IAuditImportParameters>();

  public readonly maxFilesCount = MAX_FILES_COUNT;
  public readonly maxTotalFilesSizeMb = MAX_TOTAL_FILES_SIZE_MB;
  public readonly allowedAuditTypes = ALLOWED_AUDIT_IMPORT_TYPES;
  public readonly filesLabel = IMPORT_AUDIT_FILES_DND_FILE_LABEL;

  public readonly filesTableConstants = IMPORT_AUDIT_FILES_TABLE_COLUMNS;
  public readonly OverrideModes = OverrideMode;

  public auditFiles: IImportAuditFile[] = [];
  public tableOptions: ITableOptions;
  public isDirty = false;

  public readonly columns: ITableColumn[] = [
    {
      headerName: this.filesTableConstants.NAME.headerName,
      field: this.filesTableConstants.NAME.field,
      customCell: true,
    },
    {
      headerName: this.filesTableConstants.REPLACE.headerName,
      field: this.filesTableConstants.REPLACE.field,
      textAlign: 'center',
      customCell: true,
    },
    {
      headerName: this.filesTableConstants.UPDATE.headerName,
      field: this.filesTableConstants.UPDATE.field,
      textAlign: 'center',
      customCell: true,
    },
  ];

  public readonly RADIO_BUTTON_THEMES = RadioButtonThemes;

  constructor(private toastSerivce: ToastService) {}

  ngOnInit(): void {
    this.tableOptions = this.initTableOptions();
  }

  private initTableOptions(): ITableOptions {
    return {
      rowHeight: IMPORT_TABLE_ROW_HEIGHT,
      headerHeight: IMPORT_TABLE_HEADER_ROW_HEIGHT,
      cellPaddingSize: Size.S,
      pagination: false,
      hasFooter: false,
      hasBodyBorder: true,
      showBottomBorders: true,
    };
  }

  // TODO: think of introducing rowIndex property in appCellValue obj
  private findFileIndex(file: File): number {
    return this.auditFiles.findIndex((selectedFile) => selectedFile.file.name === file.name);
  }

  public handleRemoveFile(file: File): void {
    if (this.isImportInProgress) {
      return;
    }

    this.auditFiles = removeElementByIndex(this.auditFiles, this.findFileIndex(file));
  }

  public handleImportData(): void {
    this.select.emit({
      files: this.auditFiles,
    });
  }

  public handleFilesUpload(fileList: FileList): void {
    this.isDirty = true;
    if (this.isImportInProgress) {
      return;
    }

    const files = getFilesArrayFromFileList(fileList);
    const { errors, validFiles } = this.validateFiles(files);

    if (!isEmpty(errors)) {
      this.printErrors(errors);
    }

    if (!isEmpty(validFiles)) {
      this.selectFiles(validFiles);
    }
  }

  private validateFiles(files: File[]): { validFiles: File[]; errors: string[] } {
    const totalFilesCount = this.auditFiles.length + files.length;
    if (isMoreFilesThanAllowed(totalFilesCount)) {
      return { errors: [MAX_FILES_COUNT_ERROR], validFiles: [] };
    }

    const invalidFileTypeErrors = this.getInvalidFileTypeErrors(files);
    const duplicateFileErrors = this.getDuplicateFileErrors(files);

    return {
      errors: [...invalidFileTypeErrors, ...duplicateFileErrors],
      validFiles: this.filterOutInvalidFiles(files),
    };
  }

  private getInvalidFileTypeErrors(files: File[]): string[] {
    return files.filter((file) => !isImportFileTypeValid(file)).map((file) => getInvalidFileTypeError(file.name));
  }

  private getDuplicateFileErrors(files: File[]): string[] {
    return files.filter((file) => this.isFileAlreadySelected(file)).map((file) => getDuplicateFileError(file.name));
  }

  private filterOutInvalidFiles(files: File[]): File[] {
    return files.filter((file) => isImportFileTypeValid(file) && !this.isFileAlreadySelected(file));
  }

  private isFileAlreadySelected(file: File): boolean {
    return this.findFileIndex(file) !== -1;
  }

  private selectFiles(files: File[]): void {
    const newAuditFiles = files.map((file) => ({ file, overrideMode: OverrideMode.REPLACE }));
    this.auditFiles = [...this.auditFiles, ...newAuditFiles];
  }

  private printErrors(errors: string[]): void {
    const errorDurationMsec = 3000;
    errors.forEach((error) => this.toastSerivce.error(error, errorDurationMsec));
  }

  public handleSelectOverrideMode(file: File, overrideMode: OverrideMode): void {
    this.auditFiles[this.findFileIndex(file)].overrideMode = overrideMode;
  }
}
