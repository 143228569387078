<app-top-bar class="top-bar" [user]="user$ | async" [logoUrl]="logoUrl$ | async" (logout)="onLogout()"></app-top-bar>

<div class="flex-column align-items-center position-relative">
  <h1 class="font-weight-medium margin-bottom-l">Oops.. Error</h1>
  <div class="error-message margin-bottom-xl" *ngIf="message">
    <h4 class="margin-bottom-m">{{message.primaryMessage}}</h4>
    <h4>{{message.secondaryMessage}}</h4>
  </div>
  <button class="button base-button app-body" (click)="goBack()">Go back</button>
  <img src="assets/images/astronaut.svg" class="astronaut-image" />
</div>
