import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { positiveNumberValidator } from '@app/shared/utils/helpers/validation.helpers';
import { IAdditionalTermControl } from '@app/manage-shares/types/interfaces/additional-term-control.interface';
import { RIGHTS_MAX_LENGTH } from '@app/shared/utils/constants/stakeholder.contants';
import { REQUIRED_ERROR, WHITESPACES_ERROR } from '@app/forms/constants';
import { HUNDRED_PERCENT } from '@app/shared/utils/constants/common.constants';
import { DigitsConfigService } from '@app/shared/services/digits-config.service';
import { MAX_AMOUNT, POSITIVE_NUMBER } from '@app/forms/utils/helpers/generic-form-errors.helper';

export interface IAdditionalTermsState {
  tagAlong?: ISingleAdditionalTermState;
  dragAlong?: ISingleAdditionalTermState;
  preemptiveRight?: ISingleAdditionalTermState;
  votingRight?: ISingleAdditionalTermState;
  liquidationPreference?: ISingleAdditionalTermState;
}

export interface ISingleAdditionalTermState {
  checkbox: boolean;
  note: string;
  percent?: number;
}

@Component({
  selector: 'wevestr-additional-term-card',
  templateUrl: './additional-term-card.component.html',
  styleUrls: ['./additional-term-card.component.scss'],
})
export class AdditionalTermCardComponent implements OnInit {
  @Input() public parentForm: UntypedFormGroup;
  @Input() public controlName: string;
  @Input() public includePercentNumber: boolean;
  @Input() public title: string;
  @Input() public checkboxDataId: string;
  @Input() public showValidityImmediately = true;
  @Input() public notePlaceholder: string;
  @Input() public noteValidators: ValidatorFn[];
  @Input() public infoTooltip: string;
  @Input() public isEditNoteVisible = false;

  @Input() public set isNoteVisible(isVisible: boolean) {
    this.toggleNoteVisibility(isVisible, false);
  }

  @Output() public noteVisibilityChanged = new EventEmitter<boolean>();

  public rightsMaxLength = RIGHTS_MAX_LENGTH;
  public form: UntypedFormGroup;
  public termControl: IAdditionalTermControl;
  public percentageMask: string;
  public isNoteOpened: boolean;

  public ERRORS: {
    POSITIVE: string;
    GREATER_THAN_LIMIT: string;
    REQUIRED: string;
    WHITESPACES: string;
  };

  constructor(private digitsConfigService: DigitsConfigService) {}

  public ngOnInit(): void {
    this.initForm();
    this.initTerm();
    this.percentageMask = this.digitsConfigService.percentageMask;
    this.ERRORS = {
      POSITIVE: POSITIVE_NUMBER(`${this.title} percent`),
      GREATER_THAN_LIMIT: MAX_AMOUNT(`${this.title} percent`, 100),
      REQUIRED: REQUIRED_ERROR,
      WHITESPACES: WHITESPACES_ERROR,
    };
  }

  private initForm(): void {
    this.form = this.parentForm.get(this.controlName) as FormGroup;
  }

  private initTerm(): void {
    const checkbox = this.form.get('checkbox');
    const note = this.form.get('note');
    const percent = this.form.get('percent');
    const percentValidators = [Validators.required, positiveNumberValidator, Validators.max(HUNDRED_PERCENT)];

    this.termControl = {
      checkbox,
      note: note,
      percent: percent || null,
      isChecked: checkbox.value,
      noteValidators: this.noteValidators || [],
      percentValidators,
    };

    if (checkbox.value && this.includePercentNumber) {
      this.toggleValidators(checkbox.value, this.termControl.percent, this.termControl.percentValidators);
      this.termControl.percent.updateValueAndValidity();
    }

    if (checkbox.value) {
      this.toggleNoteVisibility(true, false);
    }
  }

  private toggleValidators(isTermChecked: boolean, control: AbstractControl, validators: ValidatorFn[]): void {
    if (control) {
      isTermChecked ? control.setValidators(validators) : control.clearValidators();
    }
  }

  public toggleChecked(): void {
    const newCheckboxState = !this.termControl.isChecked;

    this.toggleValidators(newCheckboxState, this.termControl.note, this.termControl.noteValidators);
    this.termControl.note.updateValueAndValidity();

    if (this.includePercentNumber) {
      this.toggleValidators(newCheckboxState, this.termControl.percent, this.termControl.percentValidators);
      this.termControl.percent.updateValueAndValidity();
    }

    this.toggleNoteVisibility(newCheckboxState);

    this.termControl.isChecked = newCheckboxState;
  }

  public toggleNoteVisibility(isOpened: boolean, shouldEmitUpdate = true): void {
    this.isNoteOpened = isOpened;
    if (shouldEmitUpdate) {
      this.noteVisibilityChanged.emit(isOpened);
    }
  }
}
