import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { OnboardingRouteNames } from '@app/shared/types/enums/onboarding-route-names.enum';

@Injectable()
export class RedirectToNewInvitationGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.router.navigate([OnboardingRouteNames.ACCEPT_STAKEHOLDER_INVITE], {
      queryParamsHandling: 'merge',
      queryParams: route.queryParams,
    });
  }
}
