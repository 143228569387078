<div>
  <div class="app-body-small-bold has-text-grey-light padding-bottom-xs">{{ key }}</div>

  <ng-container [ngSwitch]="type">
    <div *ngSwitchCase="InputType.TEXT" class="app-h5 text">
      {{ value }}
    </div>
    <div *ngSwitchCase="InputType.DATE" class="app-h5">
      {{value | dateFormat: DATE_FORMAT}}
    </div>
    <div *ngSwitchCase="InputType.NUMBER" class="app-numbers">
      {{value}}
    </div>
  </ng-container>
</div>
