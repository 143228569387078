import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LENGTH_2FA_CODE } from '@app/shared/utils/constants/auth.constants';
import {
  BACKSPACE_CODE,
  LEFT_ARROW_CODE,
  RIGHT_ARROW_CODE,
  SPACE_CODE,
} from '@app/shared/utils/constants/key-codes.constants';
import { isNumericValue } from '@app/shared/utils/helpers/common.helpers';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeInputComponent),
      multi: true,
    },
  ],
})
export class CodeInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() length = LENGTH_2FA_CODE;
  @Input() hasMiddleVisualSeparator = false;

  public middleVisualSeparatorIndex: number = null;
  public digitsArray: UntypedFormArray = new UntypedFormArray([]);
  public focusedDigitIndex = 0;
  public propagateChange: (value: string) => void;
  public readonly DIGIT_ID_PREFIX = 'digit_';

  private _keyUp$ = new BehaviorSubject<KeyboardEvent>(null);
  private codeValue: string;
  private unsubscribe$ = new Subject();

  public ngOnInit(): void {
    this.initArray();
    this.subscribeToKeyChanges();
    if (this.hasMiddleVisualSeparator) {
      this.middleVisualSeparatorIndex = this.digitsArray.controls.length / 2 - 1;
    }
  }

  public handleKeyDown(event: KeyboardEvent): boolean {
    if (event.code === SPACE_CODE) {
      return false;
    }
  }

  public handleKeyUp(event: KeyboardEvent): void {
    this._keyUp$.next(event);
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(obj: string): void {
    if (obj !== void 0) {
      this.codeValue = obj;
    }
  }

  private subscribeToKeyChanges(): void {
    this._keyUp$
      .pipe(debounceTime(10), takeUntil(this.unsubscribe$))
      .subscribe((event: KeyboardEvent) => this.handleEvent(event));
  }

  private handleEvent(event: KeyboardEvent): void {
    if (!event) {
      return;
    }

    if (isNumericValue(event.key)) {
      this.handleNumericValue(event.key);
    } else {
      this.handleNonNumericKey(event.code);
    }

    this.codeValue = this.digitsArray.value.filter((digit: string) => digit !== void 0 && digit !== null).join('');

    if (this.propagateChange) {
      this.propagateChange(this.codeValue);
    }
  }

  private handleNumericValue(key: string): void {
    if (
      this.digitsArray.at(this.focusedDigitIndex) !== null &&
      this.digitsArray.at(this.focusedDigitIndex) !== void 0
    ) {
      this.digitsArray.at(this.focusedDigitIndex).setValue(key);
    }
    this.setNextFocusedDigit();
  }

  private handleNonNumericKey(code: string): void {
    switch (code) {
      case LEFT_ARROW_CODE:
        this.setPreviousFocusedDigit();
        break;
      case RIGHT_ARROW_CODE:
        this.setNextFocusedDigit();
        break;
      case BACKSPACE_CODE:
        this.digitsArray.at(this.focusedDigitIndex).setValue(null);
        this.setPreviousFocusedDigit();
        break;
      default:
        return;
    }
  }

  private setNextFocusedDigit(): void {
    if (
      this.digitsArray.at(this.focusedDigitIndex).value === void 0 ||
      this.digitsArray.at(this.focusedDigitIndex).value === null ||
      this.focusedDigitIndex === this.length - 1
    ) {
      return;
    }

    this.focusedDigitIndex += 1;
    this.focusElement();
  }

  private setPreviousFocusedDigit(): void {
    if (this.focusedDigitIndex - 1 < 0) {
      return;
    }
    this.focusedDigitIndex -= 1;
    this.focusElement();
  }

  private initArray(): void {
    for (let i = 0; i < this.length; i++) {
      this.digitsArray.push(new UntypedFormControl(null));
    }
  }

  private focusElement(): void {
    const element = document.getElementById(`${this.DIGIT_ID_PREFIX}${this.focusedDigitIndex}`);
    element.focus();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
