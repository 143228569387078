import { Injectable } from '@angular/core';
import { DateFormatPipe } from '@app/shared/pipes';
import { YEAR_MONTH_DAY_FORMAT } from '@app/shared/utils/constants/date.constants';
import {
  isValid,
  isEqual,
  isDateFitDayRange,
  isBeforeOrEqual,
  isAfterOrEqual,
  isBefore,
  isAfter,
  now,
} from '@app/shared/utils/helpers/dates.helpers';
import { TDateInterval, TDateLike } from '@app/forms/types';
import { isNil, propOr } from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class WevestrDateIntervalValidationService {
  constructor(private dateFormatPipe: DateFormatPipe) {}

  public validateDateAccordingSelectedInterval(
    date: Date,
    selectedIntervalId: number,
    dateIntervals: TDateInterval<TDateLike>[],
  ): { dateBeforePreviousInterval?: Date; dateAfterNextInterval?: Date; dateFromAnotherInterval?: number } {
    const normalizedDate = new Date(this.dateFormatPipe.transform(date, YEAR_MONTH_DAY_FORMAT));

    const intervalsWithDates = this.getIntervalsWithDates(dateIntervals);
    const intervalContainingDate = this.findIntervalContainingDate(intervalsWithDates, normalizedDate);

    if (intervalContainingDate && !this.isIntervalIdEqual(selectedIntervalId)(intervalContainingDate)) {
      return { dateFromAnotherInterval: intervalContainingDate.id };
    }

    const isIntervalWithDates = this.isIntervalExists(intervalsWithDates, selectedIntervalId);
    if (isIntervalWithDates) {
      return this.validateIntervalWithDates(normalizedDate, selectedIntervalId, intervalsWithDates);
    }
  }

  public getLastAvailableDateOfInterval(selectedIntervalId: number, dateIntervals: TDateInterval<TDateLike>[]): Date {
    const intervalsWithDates = this.getIntervalsWithDates(dateIntervals);
    const isIntervalWithDates = this.isIntervalExists(intervalsWithDates, selectedIntervalId);
    if (!isIntervalWithDates) {
      return now();
    }
    const [_, nextInterval] = this.getPreviousAndNextInterval(intervalsWithDates, selectedIntervalId);
    return nextInterval?.startDate ? new Date(nextInterval.startDate) : now();
  }

  public validateIntervalWithDates(
    date: Date,
    selectedIntervalId: number,
    intervalsWithDates: TDateInterval[],
  ): { dateBeforePreviousInterval?: Date; dateAfterNextInterval?: Date } {
    const [previousInterval, nextInterval] = this.getPreviousAndNextInterval(intervalsWithDates, selectedIntervalId);

    if (previousInterval && this.isDateBeforePreviousInterval(date, previousInterval)) {
      return { dateBeforePreviousInterval: previousInterval.endDate };
    }

    if (nextInterval && this.isDateAfterNextInterval(date, nextInterval)) {
      return { dateAfterNextInterval: nextInterval.startDate };
    }
  }

  private fillIntervalsWithDates(intervals: TDateInterval<TDateLike>[]): TDateInterval[] {
    return intervals.map(this.fillIntervalDates);
  }

  public extendIntervals<T extends TDateInterval<TDateLike>>(intervals: T[]): T[] {
    const intervalsWithDates = this.fillIntervalsWithDates(intervals);

    return intervalsWithDates.map((interval) => this.extendInterval(interval, intervalsWithDates) as T);
  }

  private extendInterval<T extends TDateInterval>(interval: T, intervals: T[]): T {
    return this.isEmpty(interval) ? interval : this.extendNonEmptyInterval<T>(interval, intervals);
  }

  private isEmpty(interval: TDateInterval): boolean {
    return isNil(interval.startDate) && isNil(interval.endDate);
  }

  private extendNonEmptyInterval<T extends TDateInterval>(interval: T, intervals: T[]): T {
    const [previousInterval, nextInterval] = this.getPreviousAndNextInterval(intervals, interval.id);

    return {
      ...interval,
      startDate: propOr(null, 'endDate')(previousInterval),
      endDate: propOr(null, 'startDate')(nextInterval),
    };
  }

  public getPreviousAndNextInterval(intervals: TDateInterval[], intervalId: number): [TDateInterval, TDateInterval] {
    const intervalslWithoutDuplicates = this.removeSelectedIntervalDuplicates(intervalId, intervals);

    const indexOfInterval = intervalslWithoutDuplicates.findIndex(this.isIntervalIdEqual(intervalId));

    const previousInterval = intervalslWithoutDuplicates[indexOfInterval - 1];
    const nextInterval = intervalslWithoutDuplicates[indexOfInterval + 1];

    return [previousInterval, nextInterval];
  }

  public isIntervalExists(intervals: TDateInterval[], intervalId: number): boolean {
    return !!this.findIntervalById(intervals, intervalId);
  }

  public findIntervalById(intervals: TDateInterval[], intervalId: number): TDateInterval {
    return intervals.find(this.isIntervalIdEqual(intervalId));
  }

  public findIntervalContainingDate(intervals: TDateInterval[], date: Date): TDateInterval {
    return intervals.find((interval) => isDateFitDayRange(date, interval));
  }

  public isIntervalIdEqual(intervalId: number): (interval: TDateInterval) => boolean {
    return (interval: TDateInterval) => interval.id === intervalId;
  }

  public isDateBeforePreviousInterval(date: Date, previousInterval: TDateInterval): boolean {
    return isEqual(previousInterval.startDate, previousInterval.endDate)
      ? isBefore(date, previousInterval.startDate)
      : isBeforeOrEqual(date, previousInterval.startDate);
  }

  public isDateAfterNextInterval(date: Date, nextInterval: TDateInterval): boolean {
    return isEqual(nextInterval.startDate, nextInterval.endDate)
      ? isAfter(date, nextInterval.endDate)
      : isAfterOrEqual(date, nextInterval.endDate);
  }

  public getIntervalsWithDates(dateIntervals: TDateInterval<TDateLike>[]): TDateInterval[] {
    return dateIntervals.map(this.fillIntervalDates).filter(this.isIntervalDatesValid);
  }

  public fillIntervalDates(interval: TDateInterval<TDateLike>): TDateInterval {
    return {
      ...interval,
      startDate: interval.startDate && new Date(interval.startDate),
      endDate: interval.endDate && new Date(interval.endDate),
    };
  }

  public isIntervalDatesValid(interval: TDateInterval): boolean {
    return isValid(interval.startDate) && isValid(interval.endDate);
  }

  public removeSelectedIntervalDuplicates(selectedIntervalId: number, dateIntervals: TDateInterval[]): TDateInterval[] {
    const selectedInterval = this.findIntervalById(dateIntervals, selectedIntervalId);

    return dateIntervals.filter(
      (interval) => this.isIntervalIdEqual(selectedIntervalId)(interval) || !this.isEqual(interval, selectedInterval),
    );
  }

  public isEqual(interval: TDateInterval, intervalToCompare: TDateInterval): boolean {
    return (
      isEqual(interval.startDate, intervalToCompare.startDate) && isEqual(interval.endDate, intervalToCompare.endDate)
    );
  }
}
