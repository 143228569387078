export const IS_DEMO_MODE_ENABLED_FEATURE = 'is_demo_mode_enabled';
export const IS_MAINTENANCE_ENABLED_FEATURE = 'is_maintenance_enabled';

export const APP_FEATURE_TO_FLAGSMITH_FEATURE_MAP: {
  [key: string]: string;
} = {
  isMaintenanceEnabled: IS_MAINTENANCE_ENABLED_FEATURE,
};

export const APP_FEATURES = ['isMaintenanceEnabled'];
