import { TPermissionAccessTypeMap } from '@app/stakeholders/types/interfaces/permissions.interface';
import { ICompany } from '@app/shareholder-management/types/interfaces';
import { CompanyDashboardType } from '@wevestr/bff-types/enums/companyDashboardType.enum';
import { COMPANY_NO_ACCESS_PERMISSIONS } from '@wevestr/bff-types/constants/permissionMap.constants';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { UserService } from '@app/shared/services/user.service';
import { Permission } from '@app/shared/types/enums/permission.enum';
import { IPermissionsMap, IPermissionsAccessConfiguration } from '@app/shared/types/interfaces';
import { IRoleMapping } from '@app/types/interfaces/profile.interface';
import { AccessType } from '@app/shared/types/enums/access-type.enum';
import { Section } from '@app/shared/types/enums/section.enum';
import { PERMISSION_CONFIGURATIONS_FOR_SECTION } from '@app/shared/utils/constants/permission.constants';
import { PERMISSIONS_WITH_ORDER, EDITABLE_PERMISSIONS } from '@app/stakeholders/constants/permission.constants';
import { CompanyService } from '@app/shared/services/company.service';
import { PermissionsTypes } from '@wevestr/bff-types/enums/permissionTypes.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private userService: UserService, private companyService: CompanyService) {}

  public hasEditAccess$(permission: Permission): Observable<boolean> {
    return this.getPermissionMap$().pipe(map((permissionMap) => this.hasEditAccess(permission, permissionMap)));
  }

  public getCompanyRole$(): Observable<IRoleMapping> {
    return this.userService.currentRole$;
  }

  public getPermissionMap$(): Observable<IPermissionsMap> {
    return this.getCompanyRole$().pipe(
      take(1),
      map(({ permissionMap }) => permissionMap),
    );
  }

  private filterPermissions = (permission: Permission, companyDashboardType: CompanyDashboardType) => {
    const PERMISSION_PREDICATES: Partial<Record<Permission, () => boolean>> = {
      [Permission.capTableAccess]: () => this.isCapTableEnabled(companyDashboardType),
      [Permission.frameworkAccess]: () => this.isFrameworkEnabled(companyDashboardType),
      [Permission.scenarioModelingAccess]: () => this.isCapTableEnabled(companyDashboardType),
    };
    const predicate = PERMISSION_PREDICATES[permission];
    return predicate ? predicate() : true;
  };

  public getFilteredPermissionMap$ = (
    permissionMap: TPermissionAccessTypeMap,
  ): Observable<Partial<TPermissionAccessTypeMap>> => {
    return this.companyService.getCurrentCompany$().pipe(
      map((company: ICompany) => {
        const filteredKeys = Object.keys(permissionMap).filter((permission) => {
          switch (permission) {
            case Permission.homeAccess:
              return false;
            case Permission.capTableAccess:
              return this.isCapTableEnabled(company.dashboardType);
            case Permission.frameworkAccess:
              return this.isFrameworkEnabled(company.dashboardType);
            case Permission.scenarioModelingAccess:
              return this.isCapTableEnabled(company.dashboardType);
          }
          return true;
        });
        const filteredPermissionMap = {} as Partial<TPermissionAccessTypeMap>;
        filteredKeys.forEach((permission) => {
          filteredPermissionMap[permission as Permission] = permissionMap[permission as Permission] as AccessType;
        });
        return filteredPermissionMap;
      }),
    );
  };

  public isCapTableEnabled(companyDashboardType: CompanyDashboardType): boolean {
    return COMPANY_NO_ACCESS_PERMISSIONS[companyDashboardType]?.capTableAccess !== PermissionsTypes.NONE;
  }

  public isFrameworkEnabled(companyDashboardType: CompanyDashboardType): boolean {
    return COMPANY_NO_ACCESS_PERMISSIONS[companyDashboardType]?.frameworkAccess !== PermissionsTypes.NONE;
  }

  public getPermissions$(): Observable<Permission[]> {
    return combineLatest([of(EDITABLE_PERMISSIONS), this.companyService.getCurrentCompany$()]).pipe(
      map(([permissions, company]: [Permission[], ICompany]) =>
        permissions.filter((permission) => {
          return this.filterPermissions(permission, company.dashboardType);
        }),
      ),
    );
  }

  public getOrderedPermissions$(): Observable<Permission[]> {
    return combineLatest([of(PERMISSIONS_WITH_ORDER), this.companyService.getCurrentCompany$()]).pipe(
      map(([permissions, company]: [Permission[], ICompany]) =>
        permissions.filter((permission) => {
          return this.filterPermissions(permission, company.dashboardType);
        }),
      ),
    );
  }

  public hasEditAccess(permission: Permission, permissionMap: IPermissionsMap): boolean {
    return this.isUserPermissionsFitAnyAccessConfiguration(permissionMap, [
      this.generateEditAccessConfigurationForPermission(permission),
    ]);
  }

  public generateEditAccessConfigurationForPermission(permission: Permission): IPermissionsAccessConfiguration {
    return { [permission]: [AccessType.fullAccess] };
  }

  public hasViewAccess$(permission: Permission): Observable<boolean> {
    return this.getPermissionMap$().pipe(map((permissionMap) => this.hasViewAccess(permission, permissionMap)));
  }

  public hasViewAccess(permission: Permission, permissionMap: IPermissionsMap): boolean {
    return this.isUserPermissionsFitAnyAccessConfiguration(permissionMap, [
      this.generateViewAccessConfigurationForPermission(permission),
    ]);
  }

  public generateViewAccessConfigurationForPermission(permission: Permission): IPermissionsAccessConfiguration {
    return { [permission]: [AccessType.fullAccess, AccessType.viewOnly] };
  }

  public isUserFitAnyAccessConfiguration$(
    accessConfigurations: IPermissionsAccessConfiguration[],
  ): Observable<boolean> {
    return this.getPermissionMap$().pipe(
      map((permissionMap) => this.isUserPermissionsFitAnyAccessConfiguration(permissionMap, accessConfigurations)),
    );
  }

  public isUserPermissionsFitAnyAccessConfiguration(
    permissionMap: IPermissionsMap,
    accessConfigurations: IPermissionsAccessConfiguration[],
  ): boolean {
    return accessConfigurations.some((accessConfiguration) =>
      this.isUserPermissionsFitAccessConfiguration(permissionMap, accessConfiguration),
    );
  }

  public isUserPermissionsFitAccessConfiguration(
    permissionMap: IPermissionsMap,
    accessConfiguration: IPermissionsAccessConfiguration,
  ): boolean {
    return Object.entries(accessConfiguration).every(([permission, accessTypes]) =>
      accessTypes.some((accessType) =>
        this.hasPermissionWithAccessType(permissionMap, permission as Permission, accessType),
      ),
    );
  }

  public hasPermissionWithAccessType(
    permissionMap: IPermissionsMap,
    permission: Permission,
    accessType: AccessType,
  ): boolean {
    return permissionMap[permission] === accessType;
  }

  public hasAccessToSection$(section: Section): Observable<boolean> {
    return this.getPermissionMap$().pipe(map((permissionMap) => this.hasAccessToSection(section, permissionMap)));
  }

  public hasAccessToSection(section: Section, permissionMap: IPermissionsMap): boolean {
    const permisssionConfigurationsForSection = PERMISSION_CONFIGURATIONS_FOR_SECTION[section];

    return (
      permisssionConfigurationsForSection &&
      this.isUserPermissionsFitAnyAccessConfiguration(permissionMap, permisssionConfigurationsForSection)
    );
  }
}
