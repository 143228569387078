import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IGroupOption } from '@app/shared/types/interfaces/group-option.interface';

interface IIndexedValue {
  groupIndex: number;
  value: unknown;
}
@Component({
  selector: 'app-grouped-select',
  templateUrl: './grouped-select.component.html',
  styleUrls: ['./grouped-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupedSelectComponent),
      multi: true,
    },
  ],
})
export class GroupedSelectComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() options: IGroupOption[];
  @Input() displayFn = (item: { name: string }): string => item.name;
  @Input() compareFn = (o1: { name: string }, o2: { name: string }): boolean => o1 && o2 && o1.name === o2.name;
  @Input() icon = 'dropdown';

  @Output() select = new EventEmitter<{ groupIndex: number; value: unknown }>();
  @Output() close = new EventEmitter();
  @Output() reset = new EventEmitter();

  @ViewChild('dropdown', { static: true, read: ElementRef }) dropdown: ElementRef;

  disabled = false;
  value: IIndexedValue;
  propagateChange: (change: IIndexedValue) => void;
  opened = false;

  @HostListener('document:click', ['$event.target'])
  hide(eventTarget: HTMLElement): void {
    const wasOpened = this.opened;
    this.opened = !this.disabled && !this.clickedOutsideMenu(eventTarget) && !this.opened;

    if (wasOpened && !this.opened) {
      this.close.emit();
    }
  }

  onSelectItem(groupIndex: number, option: unknown): void {
    this.value = { groupIndex, value: option };

    if (this.propagateChange) {
      this.propagateChange({ groupIndex, value: option });
    }

    this.select.emit({ groupIndex, value: option });
  }

  onReset(): void {
    this.value = null;
    this.reset.emit();
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(isDisabled?: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: IIndexedValue): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  private clickedOutsideMenu(eventTarget: HTMLElement): boolean {
    return !this.dropdown.nativeElement.contains(eventTarget);
  }
}
