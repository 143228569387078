import { IDocument, IDocumentBase } from '@app/documents/types/interfaces';
import { IUploadDocument } from '@app/types/interfaces';
import { getDocumentExtension } from '@app/documents/utils/helpers/documents.helpers';
import { isAcceptedMimeType } from '@app/shared/utils/helpers/common.helpers';
import { ALLOWED_PREVIEW_TYPES } from '@app/shared/utils/constants/documents.constants';
import { UploadStatus } from '@app/types/enums/upload-status.enum';

export const getUpdatedDocumentsAfterRename = (
  existingDocuments: IUploadDocument[],
  documentIndex: number,
  renamedDocument: IDocument,
): IUploadDocument[] => {
  const documents = [...existingDocuments];
  const modifiedDocument = documents[documentIndex];
  const documentExtension = getDocumentExtension(modifiedDocument.documentCopy);
  if (renamedDocument?.title) {
    modifiedDocument.name = `${renamedDocument.title}.${documentExtension}`;
    if (modifiedDocument.documentCopy) {
      modifiedDocument.documentCopy.title = renamedDocument.title;
    }
  }
  return documents;
};

export const hasDocumentPreview = (document: IDocumentBase): boolean => {
  const documentContentType = document?.fileObject?.contentType;
  return documentContentType && isAcceptedMimeType(documentContentType, ALLOWED_PREVIEW_TYPES);
};

export function mapToUploadDocuments(existingDocuments: IDocument[], folderId: number): Partial<IUploadDocument>[] {
  const documents = [...existingDocuments];

  return documents
    .filter((document: IDocument) => !document.isFolder)
    .map((document: IDocument) => ({
      status: UploadStatus.SUCCESS,
      size: document.fileObject.size,
      name: document.fileObject.fileName,
      folderId: folderId,
      documentCopyId: document.id,
      documentCopy: document,
    }));
}

export function getUploadSubscriptionIndex(
  uploadsAmount: number,
  documentsAmount: number,
  documentIndex: number,
): number {
  return uploadsAmount - (documentsAmount - documentIndex);
}
