import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDocumentBase } from '@app/documents/types/interfaces';
import { ProductTourService } from '@app/services/product-tour.service';

@Component({
  selector: 'wevestr-start-product-tour-modal',
  templateUrl: './start-product-tour-modal.component.html',
  styleUrls: ['./start-product-tour-modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class StartProductTourModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public document: IDocumentBase,
    private dialogRef: MatDialogRef<StartProductTourModalComponent>,
    private productTourService: ProductTourService,
  ) {}

  public close(): void {
    this.dialogRef.close();
    this.productTourService.declineTour();
  }

  public startTour(): void {
    this.dialogRef.close();
    this.productTourService.startTour();
  }
}
