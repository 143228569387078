import { Directive } from '@angular/core';

@Directive({
  selector: '[appMenuTrigger]',
})
export class MenuTriggerDirective {
  constructor() {}
}

@Directive({
  selector: '[appMenuContent]',
})
export class MenuContentDirective {
  constructor() {}
}
