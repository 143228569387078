import { Injectable } from '@angular/core';
import * as bulmaToast from 'bulma-toast';
import { ToastType } from 'bulma-toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  success(message: string, duration = 3000): void {
    this.openToast(message, 'is-success', duration);
  }

  error(message: string, duration = 3000): void {
    this.openToast(message, 'is-danger', duration);
  }

  private openToast(message: string, type: ToastType, duration: number): void {
    bulmaToast.toast({
      message,
      type,
      duration,
      animate: { in: 'fadeIn', out: 'fadeOut' },
      position: 'bottom-right',
      dismissible: true,
    });
  }
}
