import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocument } from '@app/documents/types/interfaces';
const DEFAULT_LABEL = 'douments';

@Component({
  selector: 'wevestr-documents-preview-list',
  templateUrl: './documents-preview-list.component.html',
  styleUrls: ['./documents-preview-list.component.scss'],
})
export class DocumentsPreviewListComponent {
  @Input() public documents: IDocument[];
  @Input() public label: string = DEFAULT_LABEL;
  @Input() public shouldShowEmptyState = true;
  @Input() public shouldShowFileSize: boolean;
  @Input() public shouldShowFileExtension = false;

  @Output() public previewDocument = new EventEmitter<IDocument>();

  public handleDocumentPreview(document: IDocument): void {
    this.previewDocument.emit(document);
  }
}
