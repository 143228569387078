import { Injectable } from '@angular/core';

import { AuthService } from '@app/auth/services/auth.service';
import { SectionsNavigationService } from '@app/shared/services/section-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(private auth: AuthService, private sectionNavigationService: SectionsNavigationService) {}
  public canActivate(): Promise<boolean> {
    const isTokensSet = !!this.auth.accessToken;

    if (isTokensSet) {
      return Promise.resolve(true);
    }

    return this.sectionNavigationService.navigateToLogin().then(() => false);
  }

  public canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
