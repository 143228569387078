import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { Injectable } from '@angular/core';
import { CrudService } from '@app/shared/services/crud.service';
import { IPhase } from '@app/types/interfaces/profile.interface';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class PhasesService extends CrudService<IPhase> {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService, false);
  }

  get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/cap-table/phases`;
  }
}
