import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastService } from '@app/shared/services/toast.service';
import { phaseMultipliersUpdateMessageFormatter } from '@app/shared/utils/constants/toast-messages.constants';

@Injectable()
export class PhaseMultipliersService {
  protected readonly baseUrl: string;

  constructor(private http: HttpClient, configService: ConfigService, private toastService: ToastService) {
    this.baseUrl = configService.getFullBaseUrl();
  }

  getUrl(companyId: number): string {
    return `companies/${companyId}/framework/phase-multipliers`;
  }

  editMultiple<T>(multipliers: T, companyId: number, contributionTypeName: string): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/${this.getUrl(companyId)}`, multipliers).pipe(
      tap(() => {
        this.toastService.success(phaseMultipliersUpdateMessageFormatter(contributionTypeName));
      }),
    );
  }
}
