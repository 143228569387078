import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';

import * as fromGuards from '@app/auth/guards';
import * as fromContainers from '@app/auth/containers';
import * as fromComponents from '@app/auth/components';
import { SharedModule } from '@app/shared/shared.module';
import { VerifyEmailComponent } from '@app/auth/components';
import { LoginComponent } from '@app/auth/containers';
import { FlagsmithService } from '@app/services/flagsmith.service';
import { TermsPolicyDisclaimerComponent } from '@app/components/components/terms-policy-disclaimer/terms-policy-disclaimer.component';
import { SecurityFormComponent } from '@app/components/components/security-form/security-form.component';
import { InterimScreenComponent } from '@app/components/components/interim-screen/interim-screen.component';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    TermsPolicyDisclaimerComponent,
    SecurityFormComponent,
    InterimScreenComponent,
  ],
  exports: [VerifyEmailComponent, LoginComponent],
  providers: [
    ...fromGuards.guards,
    {
      provide: APP_INITIALIZER,
      useFactory: setupRoutesFactory,
      multi: true,
      deps: [FlagsmithService],
    },
  ],
})
export class AuthModule {}

export function setupRoutesFactory(flagsmithService: FlagsmithService): () => Promise<void> {
  return async () => {
    await flagsmithService.init();
  };
}
