import { Component, EventEmitter, Input, Output } from '@angular/core';

const DEFAULT_BACK_BUTTON_TEXT = 'Back to Overview';

@Component({
  selector: 'wevestr-creation-steps-wrapper',
  templateUrl: './creation-steps-wrapper.component.html',
  styleUrls: ['./creation-steps-wrapper.component.scss'],
})
export class CreationStepsWrapperComponent {
  @Input()
  public backButtonText = DEFAULT_BACK_BUTTON_TEXT;
  @Output()
  public back = new EventEmitter<void>();

  public handleBack(): void {
    this.back.emit();
  }
}
