<div class="chart-tooltip">
  <div class="app-body-small-bold chart-tooltip__name padding-bottom-m">{{ name }}</div>
  <table>
    <tbody>
      <tr>
        <td class="app-body-small">{{ currentDateLabel.label }}</td>
        <td class="app-numbers-small-bold padding-left-m text-align-right">{{ currentDateLabel.displayedValue }}</td>
        <td class="padding-left-s chart-tooltip__percents-box">
          <div
            class="flex-row is-align-content-center justify-content-space-between"
            *ngIf="currentPeriodPercent || currentPeriodPercent === 0; else notAvailable"
            [ngClass]="{
              'light-green-color': currentPeriodPercent > 0,
              'light-red-color': currentPeriodPercent < 0,
              'orange-color': currentPeriodPercent === 0
            }"
          >
            <mat-icon [svgIcon]="currentPeriodIcon"></mat-icon>
            <span class="app-numbers-small padding-left-xs">{{ currentPeriodPercent | numberFormat }}%</span>
          </div>
        </td>
      </tr>
      <tr class="padding-top-s">
        <td class="app-body-small">{{ previousDateLabels[0].label }}</td>
        <td class="app-numbers-small-bold padding-left-m text-align-right">
          {{ previousDateLabels[0].displayedValue }}
        </td>
        <td class="padding-left-s chart-tooltip__percents-box">
          <div
            class="flex-row is-align-content-center justify-content-space-between"
            *ngIf="previousPeriodPercent || previousPeriodPercent === 0; else notAvailable"
            [ngClass]="{
              'light-green-color': previousPeriodPercent > 0,
              'light-red-color': previousPeriodPercent < 0,
              'orange-color': previousPeriodPercent === 0
            }"
          >
            <mat-icon [svgIcon]="previousPeriodIcon"></mat-icon>
            <span class="app-numbers-small padding-left-xs">{{ previousPeriodPercent | numberFormat }}%</span>
          </div>
        </td>
      </tr>
      <tr class="padding-top-s">
        <td class="app-body-small">YoY</td>
        <td></td>
        <td class="padding-left-s chart-tooltip__percents-box">
          <div
            *ngIf="yoYPercent || yoYPercent === 0; else notAvailable"
            class="flex-row is-align-content-center justify-content-space-between"
            [ngClass]="{
              'light-green-color': yoYPercent > 0,
              'light-red-color': yoYPercent < 0,
              'orange-color': yoYPercent === 0
            }"
          >
            <mat-icon [svgIcon]="yoYPercentIcon"></mat-icon>
            <span class="app-numbers-small padding-left-xs">{{ yoYPercent | numberFormat }}%</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #notAvailable>
  <div class="text-align-right">
    <span class="app-numbers-small">N/A</span>
  </div>
</ng-template>
