<div
  class="flex-row toggle is-align-items-center"
  [ngClass]="{'toggle--disabled': disabled}"
  [matTooltipDisabled]="!disabled || !tooltipText"
  [matTooltip]="tooltipText"
  matTooltipPosition="below"
  matTooltipClass="tooltip"
>
  <div
    *ngFor="let option of values"
    class="app-body-medium-weight toggle__value"
    [ngClass]="{'toggle__value--active': option | callback: compareFn : selectedValue}"
    (click)="handleSelectOption(option)"
  >
    <mat-icon *ngIf="option.icon; else displayName" [svgIcon]="option.icon"></mat-icon>
    <ng-template #displayName>
      {{ option.name }}
    </ng-template>
  </div>
</div>
