<div class="disclaimer app-body-small margin-top-l">
  <p>At We.Vestr we care about security and your personal information.</p>
  <div
    >Read our
    <a
      class="anchor anchor--with-decoration"
      [href]="privacyPolicyUrl"
      target="_blank"
      (click)="handlePrivacyPolicyClick($event)"
      >Privacy Policy</a
    >
    and <a class="anchor anchor--with-decoration" [href]="cookiePolicy" target="_blank">Cookie Policy</a>.
  </div>
</div>
