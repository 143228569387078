import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EMPTY_OPTION_TEXT } from '@app/shared/utils/constants/common.constants';

@Component({
  selector: 'wevestr-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent<T> implements ControlValueAccessor {
  @Input() public label: string;
  @Input() public disabled = false;
  @Input() public placeholder: string;
  @Input() public options: T[];
  @Input() public displayFn = (item: T): string => <string>(<Record<string, unknown>>item).name;
  @Input() public compareFn = (o1: T, o2: T): boolean =>
    o1 && o2 && (<Record<string, unknown>>o1).name === (<Record<string, unknown>>o2).name;
  @Input() public emptyOptionText = EMPTY_OPTION_TEXT.default;

  @Output() private close = new EventEmitter();

  public opened = false;
  public value: T[] = [];

  public onTouched: () => void;
  private propagateChange: (value: T[]) => void;

  @HostListener('document:click', ['$event'])
  public hide(event: MouseEvent): void {
    const wasOpened = this.opened;
    this.opened = !this.disabled && !this.clickedOutsideMenu(<HTMLElement>event.target);

    if (wasOpened && !this.opened) {
      this.close.emit();
    }
  }

  @ViewChild('dropdown', { static: true, read: ElementRef })
  public dropdown: ElementRef;

  public onSelectItem(option: T): void {
    const selectedOptionIndex = this.value && this.value.findIndex((item) => this.compareFn(option, item));

    if (selectedOptionIndex !== null && selectedOptionIndex > -1) {
      this.removeOption(selectedOptionIndex);
    } else {
      this.addOption(option);
    }

    if (this.propagateChange) {
      this.propagateChange(this.value);
    }
  }

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled?: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: T[]): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  public isHighlighted = (option: T): boolean => {
    return this.value && !!this.value.find((item: T) => this.compareFn(option, item));
  };

  private removeOption(index: number): void {
    this.value.splice(index, 1);
  }

  private addOption(option: T): void {
    if (this.value) {
      this.value.push(option);
    } else {
      this.value = [option];
    }
  }

  private clickedOutsideMenu(eventTarget: HTMLElement): boolean {
    return !this.dropdown.nativeElement.contains(eventTarget);
  }
}
