<div class="card padding-l full-height">
  <div class="flex-row is-justify-content-space-between">
    <h5 class="app-h5-medium-weight">Cap Table</h5>
    <mat-icon
      class="cursor-pointer view-more-link"
      svgIcon="external_link"
      [routerLink]="'/' + companyRouteName + '/equity/cap-table/' + capTableView"
    ></mat-icon>
  </div>
  <app-tabs
    [tabs]="tabs"
    [activeRoute]="activatedRoute"
    [queryParameter]="queryParameter"
    [queryParameterValue]="capTableView"
    isSmall="true"
  ></app-tabs>
  <div class="margin-top-l">
    <wevestr-shareholders-view-content
      *ngIf="capTableView === Views.PARTICIPANTS && participants$ | async as participants"
      [participants]="participants"
      isWidget="true"
      [isSmallWidget]="isSmallWidget"
      [isDashboardPage]="true"
    >
    </wevestr-shareholders-view-content>
    <app-rounds-view-content
      *ngIf="capTableView === Views.ROUNDS && phases$ | async as phases"
      [phases]="phases"
      isWidget="true"
      [isSmallWidget]="isSmallWidget"
    >
    </app-rounds-view-content>
  </div>
</div>
