import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap, take, concatMap, first } from 'rxjs/operators';

import { User } from '@app/shared/types/classes';
import { UserService } from '@app/shared/services/user.service';
import { IBreadcrumb, ICompanySettings } from '@app/shared/types/interfaces';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { ICompany, ISidenavOption, ISidenavService } from '@app/types/interfaces';
import { IAuthService } from '@app/types/interfaces/auth-service.interface';
import { CompanyService } from '@app/shared/services/company.service';
import { loginPath, creatingCompanyOnboardingPath } from '@app/shared/utils/constants/paths.constants';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { DESKTOP_WIDTH } from '@app/shared/utils/constants/common.constants';
import { SectionsNavigationService } from '@app/shared/services/section-navigation.service';
import { ITopControlOption } from '@app/types/interfaces/top-control-option.interface';
import { SettingsAccessService } from '@app/settings/services/settings-access.service';
import { COMPANY_TOP_OPTION_KEYS } from '@app/company/types/enums/company-top-control-options';
import { InvitationsService } from '@app/auth/services/invitations.service';
import { ICompanyInvitationResponse } from '@app/shared/types/interfaces/company-invitation-response.inteface';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { ICompany as ICompanyBFF } from '@wevestr/bff-types/models/interfaces/company.interface';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';

const LOADING_DELAY_MS = 1500;

@Component({
  selector: 'app-layout',
  template: '<div>LayoutComponentPlaceholder</div>',
})
export class LayoutComponent implements OnInit, OnDestroy {
  public sidenavOptions: ISidenavOption[];
  public activeOption: string;
  public topControlOptions: ITopControlOption[];
  public currentCompanyId$: Observable<number>;

  public sidenavCollapsed$: Observable<boolean>;
  public user$: Observable<User>;
  public breadcrumbs$: Observable<IBreadcrumb[]>;
  public companies$: Observable<ICompany[]>;
  public logoUrl$: Observable<string>;
  public loading = false;

  protected companyUpdate$ = new Subject<ICompany>();
  protected ngUnsubscribe = new Subject();
  protected COMING_SOON_ROUTE = 'coming-soon';
  public isAnimationVisible = false;

  constructor(
    @Inject('authService') protected authService: IAuthService,
    @Inject('sidenavService') protected sidenavService: ISidenavService,
    protected userService: UserService,
    protected companyService: CompanyService,
    protected router: Router,
    protected breadcrumbsService: BreadcrumbsService,
    protected companySettingsService: CompanySettingsService,
    protected sectionNavigationService: SectionsNavigationService,
    protected settingsAccessService: SettingsAccessService,
    protected invitationsService: InvitationsService,
    @Inject(DOCUMENT) private document: Document,
    protected analyticsService: AnalyticsService,
  ) {}

  public ngOnInit(): void {
    this.subscribeSidenavOptions();
    this.sidenavCollapsed$ = this.sidenavService.collapsed$;
    this.user$ = this.userService.user$;
    this.breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;
    this.companies$ = this.companyService.list();
    this.currentCompanyId$ = this.userService.currentCompanyId$;
    this.logoUrl$ = this.userService.currentCompanyId$.pipe(
      take(1),
      switchMap((companyId) => this.companyService.get(companyId)),
      map((company: ICompany) => company.logoUrl),
    );
    this.isAnimationVisible = this.getAnimationVisibility();
    if (this.isAnimationVisible) {
      this.showLoadingScreen();
    }

    this.updateRoutesData();
    this.subscribeRouterEvents();
    this.onCompanyUpdate$().subscribe();
    this.filterTopControlsOptions();
  }

  private getAnimationVisibility(): boolean {
    return !!window.history.state?.showAnimation;
  }

  private showLoadingScreen(): void {
    this.loading = true;
    this.companies$.pipe(first(), delay(LOADING_DELAY_MS)).subscribe(() => (this.loading = false));
  }

  protected isTopControlOptionEnabled(item: ITopControlOption): Observable<boolean> {
    switch (item.key) {
      case COMPANY_TOP_OPTION_KEYS.COMPANY_SETTINGS:
        return this.settingsAccessService.hasAccessToCompanySettings$();
    }
    return new Observable((observer) => observer.next(true));
  }

  private filterTopControlsOptions(): void {
    this.topControlOptions = this.topControlOptions.map((item) => {
      return {
        ...item,
        enabled$: this.isTopControlOptionEnabled(item),
      };
    });
  }

  private onCompanyUpdate$(): Observable<unknown> {
    return this.companyUpdate$.pipe(
      switchMap((company) => {
        return this.analyticsService.group$(company as unknown as ICompanyBFF).pipe(
          switchMap(() => {
            const isCompanyOnboardingNotFinished = company.onboarding && !company.onboarding.isOnboardingFinished;

            return isCompanyOnboardingNotFinished
              ? this.goToCompanyOnboarding$(company)
              : this.loadSelectedCompany$(company);
          }),
        );
      }),
      takeUntil(this.ngUnsubscribe),
    );
  }

  protected loadSelectedCompany$(company: ICompany): Observable<ICompanySettings> {
    return of(company).pipe(
      tap((company) => {
        this.loading = true;
        this.selectCompany(company);
      }),
      switchMap((company) => this.companyService.get(company.id, true)),
      switchMap(() => this.userService.get()),
      switchMap(() => this.sidenavService.sidenavOptions$.pipe(take(1))),
      tap((sidenavOptions) => (this.sidenavOptions = sidenavOptions)),
      switchMap(() => this.sectionNavigationService.navigateToFirstAvailableSection$()),
      concatMap(() => this.companySettingsService.get()),
      delay(LOADING_DELAY_MS),
      tap(() => (this.loading = false)),
    );
  }

  private goToCompanyOnboarding$(company: ICompany): Promise<boolean> {
    this.selectCompany(company);
    return this.sectionNavigationService.navigateToCompanyOnboarding(company.onboarding.onboardingToken);
  }

  protected selectCompany(company: ICompany): void {
    // TODO: use more clear way how to set that if possible
    this.userService.currentCompanyId = company.id; // eslint-disable-line deprecation/deprecation
    this.companySettingsService.companyId = company.id;
  }

  public handleToggleSidenav(): void {
    this.sidenavService.toggle();
  }

  public handleChangeCompany(company: ICompany): void {
    this.companyUpdate$.next(company);
  }

  public handleCreateCompany(): void {
    this.invitationsService
      .createNewCompany(this.companies$)
      .subscribe(({ companyInvitationToken }: ICompanyInvitationResponse) => {
        this.router.navigate(creatingCompanyOnboardingPath, {
          queryParams: {
            token: companyInvitationToken,
          },
        });
      });
  }

  public onLogout(): void {
    this.analyticsService.track(AnalyticsEvent.LogOut);
    this.authService
      .logOut()
      .pipe(
        tap(() => {
          this.companySettingsService.settings = null;
          this.userService.removeCurrentCompany();
          this.removeUserbackElement();
        }),
      )
      .subscribe(() => this.router.navigate(loginPath));
  }

  public removeUserbackElement(): void {
    const body = this.document.getElementsByTagName('body')[0];
    const userbackElement = body.getElementsByTagName('ubdiv')[0];
    if (userbackElement) {
      body.removeChild(userbackElement);
    }
  }

  public isDesktopWidth$ = this.sidenavService.deviceWidth$.pipe(map((width) => width > DESKTOP_WIDTH));

  private subscribeRouterEvents(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateRoutesData();
      }
    });
  }

  private updateRoutesData(): void {
    this.setActiveRoute();

    if (!this.activeOption.startsWith(this.COMING_SOON_ROUTE)) {
      this.resetBreadcrumb();
    }
  }

  private setActiveRoute(): void {
    const url = this.router.url;
    this.activeOption = url.substring(1).split('?')[0];
  }

  private resetBreadcrumb(): void {
    const navOptions = [...BreadcrumbsService.navOptions, ...(this.sidenavOptions ?? [])];

    const sidenavOption = navOptions.find((option) => this.activeOption.startsWith(option.routerLink));
    if (sidenavOption) {
      this.breadcrumbsService.init({ name: sidenavOption.name, routerLink: `/${sidenavOption.routerLink}` });
      if (sidenavOption.children) {
        const child = sidenavOption.children.find((child) => this.activeOption.startsWith(child.routerLink));
        if (child && !child.isBreadcrumbHidden) {
          this.breadcrumbsService.push({ name: child.name, routerLink: `/${child.routerLink}` });
        }
      }
    } else {
      this.breadcrumbsService.erase();
    }
  }

  private subscribeSidenavOptions(): void {
    this.sidenavService.sidenavOptions$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((sidenavOptions: ISidenavOption[]) => (this.sidenavOptions = sidenavOptions));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
