import { Component, Input } from '@angular/core';
import { IIndicator } from '@app/company/types/interfaces/indicator';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
})
export class IndicatorsComponent {
  @Input() indicators: IIndicator[] = [];
}
