import { IProfile } from '@app/types/interfaces/profile.interface';

export enum UserRole {
  SHAREHOLDER = 0,
  ADMIN = 1,
  SUPERADMIN = 2,
}

export class User {
  id: number;
  email: string;
  profile: IProfile;
  enabled2fa: boolean;

  constructor(user?: User) {
    this.profile = user.profile;
    this.email = user.email;
    this.id = user.id;
    this.enabled2fa = user.enabled2fa;
  }

  get name(): string {
    return this.profile.shareholders[0].firstName ? this.profile.shareholders[0].firstName : '';
  }
}
