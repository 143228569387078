import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICompanySettings, IPermissionsMap, ITab } from '@app/shared/types/interfaces';
import { COMPANY_TABS, PERSONAL_TABS } from '@app/settings/constants/settings.constants';
import { PermissionsService } from '@app/shared/services/permissions.service';
import { SettingsAccessService } from '@app/settings/services/settings-access.service';
import { isPersonalSettingsRoute } from '@app/shared/utils/helpers/settings.helpers';
import { SettingsRouteNames } from '@app/settings/types/enums';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';

const ESOP_TAB = COMPANY_TABS.find((tab) => tab.route === SettingsRouteNames.ESOP);
const COMPANY_STARTUP_COOP_TAB = COMPANY_TABS.find((tab) => tab.route === SettingsRouteNames.COMPANY_STARTUP_COOP);

@Injectable()
export class SettingsTabResolver {
  constructor(
    private settingsAccessService: SettingsAccessService,
    private permissionsService: PermissionsService,
    private companySettingsService: CompanySettingsService,
  ) {}

  resolve(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITab[]> {
    const isPersonalSettings = isPersonalSettingsRoute(state.url);

    return isPersonalSettings ? this.getPersonalSettingsTabs$() : this.getCompanySettingsTabs$();
  }

  private getPersonalSettingsTabs$(): Observable<ITab[]> {
    return of(PERSONAL_TABS);
  }

  private getCompanySettingsTabs$(): Observable<ITab[]> {
    return combineLatest([this.permissionsService.getPermissionMap$(), this.companySettingsService.get()]).pipe(
      map(([permissionMap, settings]) => this.getAllowedCompanySettingsTabs(permissionMap, settings)),
    );
  }

  private getAllowedCompanySettingsTabs(permissionMap: IPermissionsMap, settings: ICompanySettings): ITab[] {
    return COMPANY_TABS.filter((companySettingsTab) =>
      this.settingsAccessService.canViewCompanySettingsTab(companySettingsTab.route, permissionMap),
    )
      .filter((companySettingsTab) => companySettingsTab.name !== ESOP_TAB.name)
      .map((companySettingsTab) => {
        return {
          ...companySettingsTab,
          editDisabled:
            companySettingsTab.name === COMPANY_STARTUP_COOP_TAB.name
              ? !settings.isFrameworkSetup
              : companySettingsTab.editDisabled,
        };
      });
  }
}
