import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@app/auth/services/auth.service';
import { UserService } from '@app/shared/services';
import { User } from '@app/shared/types/classes';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { CompanyService } from '@app/shared/services/company.service';
import { ICompany } from '@app/shareholder-management/types/interfaces';

interface IErrorMessage {
  primaryMessage: string;
  secondaryMessage: string;
}

const DEFAULT_ERROR_ROUTE = '**';

const messagesByRoutes: [string, IErrorMessage][] = [
  [
    DEFAULT_ERROR_ROUTE,
    {
      primaryMessage: `Something went wrong. This page doesn't exist or is deleted.`,
      secondaryMessage: `If this page should work, please go back and try again.`,
    },
  ],
  [
    '/access-denied',
    {
      primaryMessage: `Something went wrong. Probably you don't have access to this page.`,
      secondaryMessage: `Please contact your admin to get permission.`,
    },
  ],
];

@Component({
  selector: 'app-page-not-found',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  public user$: Observable<User>;
  public logoUrl$: Observable<string>;
  public message: IErrorMessage;
  private readonly routeToMessageMap = new Map<string, IErrorMessage>(messagesByRoutes);

  constructor(
    private authService: AuthService,
    private location: Location,
    private router: Router,
    private companyService: CompanyService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.message = this.getErrorMessage(this.router.url);
    if (this.authService.accessToken) {
      this.user$ = this.userService.get();

      this.logoUrl$ = this.companyService
        .get(this.userService.currentCompanyId)
        .pipe(map((company: ICompany) => company.logoUrl));
    }
  }

  public goBack(): void {
    history.length > 1 ? this.location.back() : this.router.navigateByUrl('');
  }

  public onLogout(): void {
    this.authService.logOut().subscribe(() => this.router.navigate(loginPath));
  }

  private getErrorMessage(url: string): IErrorMessage {
    return this.routeToMessageMap.get(url) ?? this.routeToMessageMap.get(DEFAULT_ERROR_ROUTE);
  }
}
