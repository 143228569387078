import { BLUE_COLOR } from '@app/shared/utils/constants/colors.constants';

export const FULL_WIDTH = '100%';

export const NUMBER_FONT_FAMILY = 'SF Mono';
export const NUMBER_FONT_SIZE = '14px';
export const DEVICE_MIN_WIDTH = 1024;
export const DESKTOP_WIDTH = 1200;

export const MODAL_PANEL_CLASS = 'wevestr-dialog-container';
export const MODAL_MAX_WIDTH = '500px';
export const HUNDRED_PERCENT = 100;

export const DEFAULT_DIGITS_FORMAT = '1.0-0';

export const CURRENCY_MAX_SYMBOLS_WITHOUT_WHITESPACE = 1;

export const SUPPORT_EMAIL_ADDRESS = 'support@wevestr.com';

export const EMPTY_STATE = {
  CONTRIBUTIONS: {
    title: 'No contributions yet!',
    description: 'When you have your first contribution, you can add it to the Slicing Pie.',
  },
  LOANS: {
    title: 'There are no loans found!',
    description: 'If your company has any loans you can start registering it.',
  },
  CONVERTIBLES: {
    title: 'There are no convertibles found!',
    description: 'If your company has any convertibles you can start registering it.',
  },
  SAFE_NOTES: {
    title: 'There are no safe notes found!',
    description: 'If your company has any safe notes you can start registering it.',
  },
  KPI: {
    title: 'Use this to track your Company KPIs.',
    description: 'Start tracking important KPIs for you and your company stakeholders.',
  },
  REVENUE: {
    description: 'Here you can register your Revenue drivers by importing them or adding them manually.',
  },
  COSTS: {
    description: 'Here you can register your Cost drivers by importing them or adding them manually.',
  },
  PL_CASH_FLOW: {
    description: 'Please start with registering Revenues and Costs so we can count your P&L.',
  },
  SHARE_TYPES: {
    title: 'There are no Share Types found',
    description: 'If your company has any Share Types you can start registering them in Cap Table.',
  },
  ROUNDS: {
    title: 'There are no Funding Rounds found',
    description: 'If your company has any Funding Rounds you can start registering them in Cap Table.',
  },
  GRANT_TYPES: {
    title: 'There are no Grant Types found',
    description: 'If your company has any Grant Types you can start registering them in ESOP.',
  },
};
export const NO_AVAILABLE_DATA = 'No data to be shown';
export const INDICATOR_EMPTY_STATE_DISPLAY_VALUE = 'N/A';
export const NOT_AVAILABLE_OR_APPLICABLE_SIGN = 'N/A';
export const NO_DATA_AVAILABLE = 'No data available yet.';
export const NO_SHARES_RECORDED = 'Oops.. No shares recorded yet!';
export const RECORD_SHARES = 'Start recording shares and be ready to invite your stakeholders.';

export const TOTAL_LABEL = 'Total';
export const LAST_NAME = 'Last Name';
export const FIRST_NAME = 'First Name';
export const ORGANIZATION_NAME = 'Organization Name';

export const YOU = 'You';

export const CREATE_PATH = 'create';
export const IMPORT_PATH = 'import';
export const EDIT_PATH = 'edit';
export const VIEW_PATH = 'view';
export const ROOT_PATH = '/';

type Size = 'default' | 'small' | 'medium' | 'large';
export const DEFAULT_SPINNER = {
  type: 'ball-spin-clockwise',
  size: 'large' as Size,
  bdColor: 'rgba(199,203,207,0.55)',
  color: BLUE_COLOR,
  fullScreen: false,
};

export const DASH_SYMBOL = '-';

export const EMPTY_OPTION_TEXT = {
  round: 'No Funding Rounds available',
  shareTypes: 'No Share Types available',
  group: 'No Groups available',
  default: 'No Options available',
  file: 'No Files available',
  grantType: 'No Grant Types available',
  stakeholder: 'No Stakeholders available',
  participant: 'No available Participants.',
};

export const IGNORE_TAB_INDEX = -1;
