<div class="vertical-stepper">
  <ng-container *ngFor="let step of steps; let i = index">
    <button
      class="flex-row is-align-items-center button-reset-styles stepper-link"
      (click)="isStepperClickable && handleOpenStep(i)"
      [disabled]="!isStepperClickable || !step.complete"
    >
      <span
        class="step-icon"
        [class.complete-step]="step.complete && currentStepIndex !== i"
        [class.selected-step]="currentStepIndex === i"
      >
        {{ i + 1 }}
      </span>
      <span class="margin-left-m app-body-small-medium-weight-const">
        {{ step.label }}
      </span>
    </button>
    <div class="divider" [class.complete-divider]="step.complete"></div>
  </ng-container>
</div>
