import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/services/config.service';
import { Observable } from 'rxjs';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';

@Injectable({ providedIn: 'root' })
export class FrameworkService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getFullBaseUrl();
  }

  private getUrl(): string {
    return `companies/${COMPANY_ID_TOKEN}/framework`;
  }

  public setup(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${this.getUrl()}/`, {});
  }
}
