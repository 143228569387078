import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wevestr-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() public set panelExpanded(state: boolean) {
    this.isExpanded = state;
  }
  @Input() public isChevronShown = true;

  @Output() public onPanelToggle = new EventEmitter<boolean>();

  public isExpanded = false;

  public toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.onPanelToggle.emit(this.isExpanded);
  }
}
