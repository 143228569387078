<div class="full-width flex-row is-align-items-center">
  <div>
    <mat-icon class="file-type-icon" [svgIcon]="documentExtension | documentExtensionIcon"></mat-icon>
  </div>
  <div class="full-width flex-column padding-left-m">
    <div class="flex-column">
      <div class="app-body-small-bold document__name">{{ document.title }}<span *ngIf="shouldShowFileExtension">.{{documentExtension}}</span></div>
      <div class="app-body-small margin-top-xs document__size" *ngIf="shouldShowFileSize && document?.fileObject?.size"
        >{{ document.fileObject.size }}MB in total</div
      >
    </div>
  </div>

  <mat-icon
    *ngIf="document | callback: hasDocumentPreview"
    class="cursor-pointer"
    svgIcon="visibility"
    [matTooltip]="DocumentAction.PREVIEW | titlecase"
    matTooltipPosition="above"
    (click)="handlePreview()"
  ></mat-icon>
</div>
