import jwtDecode from 'jwt-decode';

interface IDecodedJWT {
  exp: number;
}

export class JwtService {
  private readonly SECONDS_DELTA = 10;

  public isTokenExpired(token: string): boolean {
    const decoded = jwtDecode<IDecodedJWT>(token);
    return Date.now() / 1000 + this.SECONDS_DELTA - decoded.exp > 0;
  }
}
