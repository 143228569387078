<div class="expired-invite-container">
  <header class="expired-invite-header">
    <div>
      <img src="assets/images/logo.svg" alt="WE.VESTR Logo" class="logo" />
      <a href="/auth/login" class="login-register-link">Login / Register</a>
    </div>
  </header>

  <main class="expired-invite-content">
    <div class="illustration">
      <img src="assets/images/expired-link.png" alt="Expired Link Image" />
    </div>
    <h1 class="title">Link has been expired</h1>
    <p class="message">
      The link you followed is no longer active.
      <br />
      Please contact the admin for a new invitation.
    </p>
    <a [href]="websiteLink" class="back-to-website-link">Back to website</a>
    <p class="help-message">Need help? We're here for you!</p>
  </main>
</div>
