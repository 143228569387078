<div class="stepper flex">
  <div
    *ngFor="let step of steps; let index = index"
    class="stepper__item-container flex is-justify-content-flex-end"
    [ngClass]="step.status"
  >
    <button
      class="circle flex flex-center"
      [ngClass]="[step.status, step.disabled ? 'disabled' : '']"
      (click)="onStepperItemClick(step, index)"
    >
      <span *ngIf="!iconsMap[step.status]; else iconStatus" class="app-body-bold">
        {{ index + 1 }}
      </span>
      <span class="app-body-small circle__label">
        {{ step.display }}
      </span>
      <ng-template #iconStatus>
        <mat-icon [svgIcon]="iconsMap[step.status]" class="circle__icon"></mat-icon>
      </ng-template>
    </button>
  </div>
</div>
