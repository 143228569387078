import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wevestr-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @Input() public icon: string;
  @Input() public iconDataId: string = null;
  @Input() public title = '';
  @Input() public buttonClassNames = '';

  @Output() public onClick = new EventEmitter<void>();

  public handleClick(): void {
    this.onClick.emit();
  }
}
