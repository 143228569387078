import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySettingsService } from '@app/shared/services/currency-settings.service';

const DEFAULT_EMPTY_VALUE = '-;';

// TODO: pipe is needed because valuationAmount comes to cap-table/participants
// as 0 instead of null, so this pipe sets 0 valuation to N/A. BE should be updated to send null.
@Pipe({
  name: 'renderNonZeroCurrency',
})
export class RenderNonZeroCurrencyPipe implements PipeTransform {
  constructor(private currencySettingsService: CurrencySettingsService) {}

  public transform(value: number, defaultValue = DEFAULT_EMPTY_VALUE): string {
    if (!value) {
      return defaultValue;
    }

    return this.currencySettingsService.format(value);
  }
}
