import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IStakeholder } from '@app/types/interfaces/stakeholder';
import { DAY_MONTH_YEAR_DATE_FORMAT } from '@app/shared/utils/constants/date.constants';
import { ITransaction } from '@app/shared/types/interfaces/transaction.interface';
import { DigitsConfigService } from '@app/shared/services/digits-config.service';

@Component({
  selector: 'wevestr-transaction-side-card',
  templateUrl: './transaction-side-card.component.html',
  styleUrls: ['./transaction-side-card.component.scss'],
})
export class TransactionSideCardComponent implements OnInit {
  public stakeholder: IStakeholder;
  public transaction: ITransaction;
  public readonly DAY_MONTH_YEAR_DATE_FORMAT = DAY_MONTH_YEAR_DATE_FORMAT;
  public readonly noDecimalFormat: string;
  public readonly tooltipClassName = 'info-tooltip';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { transaction: ITransaction; stakeholder: IStakeholder },
    digitsConfigService: DigitsConfigService,
  ) {
    this.noDecimalFormat = digitsConfigService.ngWholeNumberFormat;
  }

  public ngOnInit(): void {
    this.stakeholder = this.data.stakeholder;
    this.transaction = this.data.transaction;
  }
}
