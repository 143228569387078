import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getSelectButtonIdByControlName } from '@app/shared/utils/helpers/common.helpers';
import { EMPTY_OPTION_TEXT } from '@app/shared/utils/constants/common.constants';
import { compareByName } from '@app/shared/utils/helpers/compare.helpers';
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<T extends { name: string }> implements OnChanges, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() options: T[];
  @Input() displayFn = (item: T): string => item.name;
  @Input() compareFn = compareByName;
  @Input() icon = 'dropdown';
  @Input() iconSizeSmall = false;
  @Input() isUnifiedHeightSet = true;
  @Input() public menuDataId: string;

  @Input() public set formControlName(name: string) {
    this.buttonId = getSelectButtonIdByControlName(name);
  }
  @Input() isCreateOptionShown = false;
  @Input() isActiveOptionDisabled = false;
  @Input() removeSelection = false;
  @Input() createOptionLabel: string;

  @Input() public emptyOptionText = EMPTY_OPTION_TEXT.default;
  @Output() create = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() pairwiseSelect = new EventEmitter<{ previous: T | T[]; current: T }>();
  @Output() removeMultiselectItem = new EventEmitter<{ currentValues: T | T[]; itemToDelete: T }>();
  @ViewChild('dropdown', { static: true, read: ElementRef }) dropdown: ElementRef;
  @ContentChild(TemplateRef) public contentHtml: TemplateRef<string>;

  // TODO unify multi-selects
  @Input() multi = false;
  @Input() multiselectInsideFrame = false;
  @Input() set selectedValue(value: T) {
    this._initialValue = value;
    this.value = value;
  }
  @Input() colorizedValues = false;
  @Input() className: string;
  @Input() addonImageTemplate: TemplateRef<unknown>;
  @Input() public setNullValueIfOptionsEmpty = true;

  opened = false;
  @Input() disabled = false;
  value: T | T[];
  propagateChange: (value: T | T[]) => void;
  onTouch: () => void;
  highlightIndex = -1;
  private _initialValue: T;
  public buttonId: string;

  @HostListener('document:click', ['$event'])
  public hide(event: MouseEvent): void {
    const wasOpened = this.opened;
    this.opened = !this.disabled && !this.clickedOutsideMenu(<HTMLElement>event.target) && !this.opened;

    if (wasOpened && !this.opened) {
      this.onTouch?.();
      this.close.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.multi) {
      if (changes.options && changes.initialValue && this._initialValue) {
        this.value = this._initialValue;
      }

      if ((!this.options || this.options.length === 0) && this.setNullValueIfOptionsEmpty) {
        this.value = null;
      }
    }
  }

  public onSelectItem(option: T, $event: MouseEvent | KeyboardEvent): void {
    const isDisabledOptionSelected = this.isDisabledOption(option);

    if (isDisabledOptionSelected) {
      $event.stopPropagation();
      return;
    }
    if ((this.multi || this.multiselectInsideFrame) && $event) {
      $event.stopPropagation();
    }

    const previousValue = this.value;

    this.multiselectInsideFrame
      ? this.addSelectedValueToMultiselect(option, previousValue)
      : this.setAndEmitSelectedValue(option, previousValue);
  }

  private isDisabledOption(option: T): boolean {
    const isActiveOption = this.compareFn(option, this.value as T);
    return this.isActiveOptionDisabled && isActiveOption;
  }

  private setAndEmitSelectedValue(option: T, previousValue: T | T[]): void {
    this.value = option;

    if (this.propagateChange) {
      this.propagateChange(option);
    }

    this.pairwiseSelect.emit({ previous: previousValue, current: this.value });
    this.select.emit(option);
  }

  public handleRemoveMultiselectItem(itemToDelete: T, $event: MouseEvent): void {
    $event.stopPropagation();
    this.removeMultiselectItem.emit({ currentValues: this.value, itemToDelete: itemToDelete });
  }

  private addSelectedValueToMultiselect(option: T, previousValue: T | T[]): void {
    this.value = this.value ? [...(this.value as Array<T>), option] : [option];

    if (this.propagateChange) {
      this.propagateChange(this.value);
    }

    this.pairwiseSelect.emit({ previous: previousValue, current: option });
  }

  handleGoUp(): void {
    if (this.highlightIndex - 1 < 0) {
      this.highlightIndex = this.options.length - 1;
    } else {
      this.highlightIndex -= 1;
    }
  }

  onReset(): void {
    if (this.propagateChange) {
      this.propagateChange(null);
    }
    this.value = null;
    this.reset.emit();
  }

  handleGoDown(): void {
    if (this.highlightIndex + 1 === this.options.length) {
      this.highlightIndex = 0;
    } else {
      this.highlightIndex += 1;
    }
  }

  public handleSelectHighlighted(): void {
    const hasHighlightedOption = this.hasHighlightedOption();
    if (hasHighlightedOption) {
      const option = this.getHighlightedOption();
      this.onSelectItem(option, null);
      this.removeHighlightFromSelectedOption();
    }
  }

  private hasHighlightedOption(): boolean {
    return this.highlightIndex !== -1;
  }

  private getHighlightedOption(): T {
    return this.options[this.highlightIndex];
  }

  private removeHighlightFromSelectedOption(): void {
    this.highlightIndex = -1;
  }

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled?: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: T): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  private clickedOutsideMenu(eventTarget: HTMLElement): boolean {
    return !this.dropdown.nativeElement.contains(eventTarget);
  }
}
