<span *ngIf="stakeholders | isEmptyArray">-</span>
<div *ngIf="stakeholders | isNotEmptyArray" class="participants-avatars-container flex-row">
  <div
    *ngFor="let stakeholder of stakeholders | slice: 0:displayCount"
    [matTooltip]="stakeholder?.displayName"
    [matTooltipDisabled]="!stakeholder?.displayName"
    [matTooltipClass]="['info-tooltip', 'info-tooltip_center-alignment-no-gap']"
    matTooltipPosition="below"
    class="participant-avatar"
  >
    <app-avatar
      [name]="stakeholder?.displayName ? stakeholder.displayName : stakeholder.firstName + ' ' + stakeholder.lastName"
      [image]="stakeholder?.avatarUrl ? stakeholder.avatarUrl : null"
    >
    </app-avatar>
  </div>

  <div
    *ngIf="moreThanDisplayCountLabel"
    [matTooltip]="hiddenNamesTooltip"
    [matTooltipDisabled]="!hiddenNamesTooltip"
    [matTooltipClass]="['info-tooltip', 'info-tooltip_center-alignment-no-gap']"
    matTooltipPosition="below"
    class="participant-avatar"
  >
    <app-avatar [userElipsisPlaceholder]="moreThanDisplayCountLabel"></app-avatar>
  </div>
</div>
