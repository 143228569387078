import { IPermissionsAccessConfiguration } from '@app/shared/types/interfaces';
import { Permission } from '@app/shared/types/enums';
import { VIEW_ACCESS_TYPES, EDIT_ACCESS_TYPES } from '@app/shared/utils/constants/permission.constants';
import { SettingsRouteNames } from '@app/settings/types/enums';

export type TabAccessConfiguration = {
  canRead: IPermissionsAccessConfiguration[];
  canEdit: IPermissionsAccessConfiguration[];
};

type CompanySettingsTabsAccessConfigurations = Partial<Record<SettingsRouteNames, TabAccessConfiguration>>;

export const COMPANY_SETTINGS_TABS_ACCESS_CONFIGURATIONS: CompanySettingsTabsAccessConfigurations = {
  [SettingsRouteNames.COMPANY_GENERAL]: {
    canRead: [{ [Permission.companySettingsAccess]: VIEW_ACCESS_TYPES }],
    canEdit: [{ [Permission.companySettingsAccess]: EDIT_ACCESS_TYPES }],
  },
  [SettingsRouteNames.COMPANY_CAP_TABLE]: {
    canRead: [{ [Permission.capTableAccess]: EDIT_ACCESS_TYPES }],
    canEdit: [{ [Permission.capTableAccess]: EDIT_ACCESS_TYPES }],
  },
  [SettingsRouteNames.COMPANY_STARTUP_COOP]: {
    canRead: [{ [Permission.frameworkAccess]: EDIT_ACCESS_TYPES }],
    canEdit: [{ [Permission.frameworkAccess]: EDIT_ACCESS_TYPES }],
  },
  [SettingsRouteNames.ESOP]: {
    canRead: [{ [Permission.esopAdminAccess]: EDIT_ACCESS_TYPES }],
    canEdit: [{ [Permission.esopAdminAccess]: EDIT_ACCESS_TYPES }],
  },
  /*[SettingsRouteNames.COMPANY_IMPORT]: {
    canRead: [{ [Permission.financialsAccess]: EDIT_ACCESS_TYPES }],
    canEdit: [{ [Permission.financialsAccess]: EDIT_ACCESS_TYPES }],
  },*/
  [SettingsRouteNames.COMPANY_EXTERNAL_SOURCES]: {
    canRead: [{ [Permission.financialsAccess]: EDIT_ACCESS_TYPES }],
    canEdit: [{ [Permission.financialsAccess]: EDIT_ACCESS_TYPES }],
  },
  [SettingsRouteNames.COMPANY_EXTERNAL_SOURCES_EXACT]: {
    canRead: [{ [Permission.financialsAccess]: EDIT_ACCESS_TYPES }],
    canEdit: [{ [Permission.financialsAccess]: EDIT_ACCESS_TYPES }],
  },
};
