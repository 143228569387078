export interface ILegendOption {
  id: number;
  color: string;
  name: string;
  isSelected: boolean;
  isVisible?: boolean;
  categoryId?: number;
  isCategory: boolean;
  isExpanded?: boolean;
  isPartiallySelected?: boolean;
  childrenNumber?: number;
}
