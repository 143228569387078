<div
  #dropdown
  class="dropdown full-width full-height"
  [ngClass]="{ 'is-active': opened && filteredOptions?.length }"
  (keyup.arrowup)="handleGoUp()"
  (keyup.arrowdown)="handleGoDown()"
  (keydown.enter)="$event.preventDefault(); handleSelectHighlighted()"
>
  <input
    #input
    type="text"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="userInput"
    (keyup)="handleKeyUp($event, $event.currentTarget.value)"
    class="full-width autocomplete-input"
    [class.extra-padding]="isSearchIconVisible"
    (blur)="handleBlur()"
    (focus)="handleFocus()"
    [autocomplete]="autocomplete"
  />

  <mat-icon *ngIf="isDropdownIconVisible" svgIcon="dropdown" class="dropdown-icon"></mat-icon>
  <mat-icon *ngIf="isSearchIconVisible" svgIcon="search_loop" class="search-icon" [class.arrow-up]="opened"></mat-icon>

  <div class="dropdown-menu full-width">
    <div class="dropdown-content">
      <div
        *ngFor="let option of filteredOptions; index as index"
        [ngClass]="{ 'is-highlighted': highlightIndex === index }"
        class="dropdown-item cursor-pointer app-body"
        (mousedown)="handleSelectItem(option)"
      >
        {{ option | callback : displayFn}}
      </div>
    </div>
  </div>
</div>
