<div class="full-height flex-column is-justify-content-space-between">
  <div class="verify-code flex-column is-align-items-center">
    <h1 class="app-h3-medium-weight">Two Factor Authentication</h1>
    <h4 class="app-body margin-top-l margin-bottom-l">Two-factor authentication is enabled for your account. Please enter the code from your authenticator app.</h4>
    <form class="code-input">
      <app-code-input  [formControl]="codeControl" [hasMiddleVisualSeparator]="true"></app-code-input>
      <div class="app-body-small-const warning margin-top-m" *ngIf="hasError">Invalid code!</div>
    </form>
  </div>

  <div class="full-width flex-row is-justify-content-space-between">
    <button
      type="submit"
      class="button back-button button__small app-body margin-top-l"
      (click)="handleBack()"
    >
      Back
    </button>
    <button
      type="submit"
      class="button base-button button__small app-body margin-top-l"
      (click)="handleVerifyCode()"
    >
      Verify
    </button>
  </div>
</div>



