import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetter',
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  public transform(value: string): string {
    if (typeof value !== 'string') {
      return 'Not assigned';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
