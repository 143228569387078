<div class="disclaimer app-body-small-const margin-top-l">
  <p>At WE.VESTR we care about security and your personal information.</p>
  <div
    >Read our
    <a
      class="anchor anchor--with-decoration"
      [href]="privacyPolicyUrl"
      target="_blank"
      (click)="handlePrivacyPolicyClick($event)"
      >Privacy Policy</a
    >
    and
    <a
      class="anchor anchor--with-decoration"
      [href]="termsAndConditionsUrl"
      target="_blank"
      (click)="handleTermsAndConditionsClick($event)"
      >Terms & Conditions</a
    >.
  </div>
</div>
