import { Params } from '@angular/router';

export interface ITab<T = string> {
  name: string;
  route: T;
  disabled?: boolean;
  queryParams?: Params;
  description?: string;
  isBeta?: boolean;
}
