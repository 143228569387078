import { ConnectedOverlayPositionChange, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef,
  EventEmitter,
} from '@angular/core';
import { SizeWidth } from '@app/shared/types/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[wvPopoverTriggerFor]',
})
export class PopoverTriggerDirective {
  @Input()
  public wvPopoverTriggerFor!: TemplateRef<unknown>;

  @Input()
  public closeOnClickOutside = true;

  @Input()
  public closeOnClickInside = false;

  @Input()
  public contentOffsetY = SizeWidth.L;

  @Output()
  public isActiveTemplate = new EventEmitter<boolean>();

  @Output()
  public positionChanged = new EventEmitter<ConnectedOverlayPositionChange>();

  @HostListener('click')
  public openPopover(): void {
    this.attachOverlay();
  }

  private unsubscribe$ = new Subject<void>();
  private overlayRef: OverlayRef;

  constructor(private elementRef: ElementRef, private overlay: Overlay, private vcr: ViewContainerRef) {}

  public ngOnInit(): void {
    this.createOverlay();
  }

  public ngOnDestroy(): void {
    this.detachOverlay();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
        { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
      ])
      .withDefaultOffsetY(this.contentOffsetY);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'popover-backdrop',
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.isActiveTemplate.emit(false);
          this.detachOverlay();
        }
      });

    this.overlayRef.overlayElement.onclick = () => {
      if (this.closeOnClickInside) {
        this.isActiveTemplate.emit(false);
        this.detachOverlay();
      }
    };

    positionStrategy.positionChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => this.positionChanged.next(event));
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(this.wvPopoverTriggerFor, this.vcr);

      this.isActiveTemplate.emit(true);
      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
