import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() public title: string;
  @Input() public closeOnEsc: boolean;
  @Input() public canClose = true;

  @Output() public close = new EventEmitter<void>();
  constructor(private dialogRef: MatDialogRef<unknown>) {}

  @HostListener('document:keyup.esc', [])
  public handleEsc(): void {
    if (this.closeOnEsc) {
      this.handleClose();
    }
  }

  public handleClose(): void {
    if (this.canClose) {
      this.dialogRef.close();
      this.close.emit();
    }
  }
}
