<div class="padding-l padding-top-m">
  <div class="create-container card padding-l full-width">
    <div class="back-button app-body flex-row" (click)="handleBack()">
      <mat-icon svgIcon="chevron_left"></mat-icon>
      <span class="padding-left-m">{{ backButtonText }}</span>
    </div>

    <ng-content #steps></ng-content>
  </div>
</div>
