import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IDocument } from '@app/documents/types/interfaces';
import { isImage, isPdf } from '@app/documents/utils/helpers/documents.helpers';
import { PreviewDocumentComponent } from '@app/components/containers/preview-document/preview-document.component';

enum DocumentType {
  PDF = 'pdf',
  IMAGE = 'image',
}

interface IDocumentTypeDetail {
  type: DocumentType;
  predicate: (document: IDocument) => boolean;
  generateSrc: () => string | SafeResourceUrl;
}

@Component({
  selector: 'wevestr-side-preview-document',
  templateUrl: './side-preview-document.component.html',
  styleUrls: ['./side-preview-document.component.scss'],
})
export class SidePreviewDocumentComponent implements OnInit {
  public documentSrc: string | SafeResourceUrl;
  public documentType: DocumentType;
  public readonly DocumentType = DocumentType;

  private documentTypeDetails = [
    {
      type: DocumentType.PDF,
      predicate: (document: IDocument) => isPdf(document),
      generateSrc: () => this.generateDocumentUrl(),
    },
    {
      type: DocumentType.IMAGE,
      predicate: (document: IDocument) => isImage(document),
      generateSrc: () => this.generateSafeDocumentUrl(),
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public document: { content: Blob; source: IDocument },
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<PreviewDocumentComponent>,
  ) {}

  public ngOnInit(): void {
    this.initDocumentParams();
  }

  private initDocumentParams(): void {
    const documentTypeDetails = this.getDocumentTypeDetails(this.document.source);
    this.documentType = documentTypeDetails?.type;
    this.documentSrc = documentTypeDetails?.generateSrc();
  }

  private getDocumentTypeDetails(documentSource: IDocument): IDocumentTypeDetail {
    return this.documentTypeDetails.find(({ predicate }) => predicate(documentSource));
  }

  private generateDocumentUrl(): string {
    return window.URL.createObjectURL(this.document.content);
  }

  private generateSafeDocumentUrl(): SafeResourceUrl {
    const objectUrl = this.generateDocumentUrl();
    return this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
  }

  public handleClose(): void {
    this.dialogRef.close();
  }

  @HostListener('document:keyup.esc', [])
  public handleEsc(): void {
    this.dialogRef.close();
  }
}
