import { Component, Input } from '@angular/core';
import { ICode2fa } from '@app/settings/types/interfaces/code-2fa.interface';

@Component({
  selector: 'app-enable-tfa-qrcode',
  templateUrl: './enable-tfa-qrcode.component.html',
  styleUrls: ['./enable-tfa-qrcode.component.scss'],
})
export class EnableTfaQrcodeComponent {
  @Input() public code: ICode2fa;
  @Input() public hint: string;
  @Input() public qrCodeImageSize: number;
}
