import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

enum CheckboxTypes {
  blue = 'blue',
  base = 'base',
  colored = 'colored',
}

@Component({
  selector: 'wevestr-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  public additionalClass: string;
  public readonly CheckboxTypes = CheckboxTypes;

  @Input('checked')
  private _checked = false;
  @Input()
  public disabled = false;
  @Input()
  public id: string;
  @Input()
  public type = CheckboxTypes.base;
  @Input()
  public color: string;
  @Input()
  public isRounded = false;

  @Output() public onChecked = new EventEmitter<boolean>();

  public get checked(): boolean {
    return this._checked;
  }

  public set checked(value: boolean) {
    this._checked = value;
    this.onChecked.emit(value);

    if (this.handleValueChange && this.handleControlTouch) {
      this.handleValueChange(this._checked);
      this.handleControlTouch();
    }
  }

  private handleValueChange: (value: boolean) => void;
  private handleControlTouch: () => void;

  public ngOnInit(): void {
    this.setClasses();
  }

  public writeValue(toggled: boolean): void {
    this.checked = toggled;
  }

  public registerOnChange(onChange: () => void): void {
    this.handleValueChange = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.handleControlTouch = onTouch;
  }

  public setClasses(): void {
    this.additionalClass = `${CheckboxTypes[this.type]}-checkbox`;
  }

  public onBlur(): void {
    this.handleControlTouch();
  }
}
