<button
  (click)="handleClick($event)"
  [disabled]="disabled || isLoading || isHTTPLoading"
  [ngClass]="getButtonClass()"
  [type]="type"
  [attr.data-id]="buttonDataId"
>
  <!-- If variant is not 'cancel', show the loading template; otherwise, show only buttonText -->
  <ng-container *ngIf="variant !== 'cancel'; else cancelButtonTemplate">
    <ng-container *ngIf="!isLoading && !isHTTPLoading; else loadingTemplate">
      {{ buttonText }}
    </ng-container>

    <ng-template #loadingTemplate>
      <mat-spinner diameter="14" strokeWidth="2"></mat-spinner>
      {{ loadingText }}
    </ng-template>
  </ng-container>

  <!-- Template for the cancel button -->
  <ng-template #cancelButtonTemplate>
    {{ buttonText }}
  </ng-template>
</button>
