import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatPipe } from '@app/shared/pipes/number-format.pipe';
import { isNil } from 'ramda';

import { DASH_SYMBOL } from '@app/shared/utils/constants/common.constants';

@Pipe({
  name: 'formatPercentage',
})
export class FormatPercentagePipe implements PipeTransform {
  constructor(private numberFormatPipe: NumberFormatPipe) {}

  public transform(percentage: number, emptyValue = DASH_SYMBOL): string {
    return isNil(percentage)
      ? emptyValue
      : `${percentage < 0.01 && percentage > 0 ? '<0.01' : this.numberFormatPipe.transform(percentage)}%`;
  }
}
