import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { VERTICAL_MODAL_DIMENSIONS } from '@app/shared/utils/constants/modal.constants';
import { Observable } from 'rxjs';
import { IConfirmationAction } from '@app/shared/types/interfaces/confirmation-action.interface';
import { CustomConfirmationModalComponent } from '@app/shared/components/custom-confirmation-modal/custom-confirmation-modal.component';
import { DeleteConfirmationModalComponent } from '@app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ComponentProps } from '@app/shared/types/interfaces/component-props.type';
import { MODAL_PANEL_CLASS } from '@app/shared/utils/constants/common.constants';
import { DeleteFolderConfirmationModalComponent } from '@app/documents/containers/delete-folder-confirmation-modal/delete-folder-confirmation-modal.component';
import { IDeleteConfirmation, IDocumentBase } from '@app/documents/types/interfaces';
import { DELETE_FOLDERS_MODAL_MAX_WIDTH } from '@app/documents/constants/documents.constants';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  openDeleteConfirmationModal<T extends Type<unknown>>(
    entityName: string,
    messageComponent?: T,
    messageProps?: ComponentProps<InstanceType<T>>,
  ): Observable<boolean> {
    return this.dialog
      .open(DeleteConfirmationModalComponent, {
        width: VERTICAL_MODAL_DIMENSIONS.width,
        data: {
          entityName,
          messageComponent,
          messageProps,
        },
        panelClass: MODAL_PANEL_CLASS,
        disableClose: true,
      })
      .afterClosed()
      .pipe(filter((result) => !!result));
  }

  openCustomConfirmationModal(action: IConfirmationAction): Observable<boolean> {
    return this.dialog
      .open(CustomConfirmationModalComponent, {
        width: VERTICAL_MODAL_DIMENSIONS.width,
        data: action,
        panelClass: MODAL_PANEL_CLASS,
        disableClose: true,
      })
      .afterClosed()
      .pipe(filter((result) => !!result));
  }

  openDeleteFolderConfirmationModal(document: IDocumentBase): Observable<IDeleteConfirmation> {
    return this.dialog
      .open(DeleteFolderConfirmationModalComponent, {
        width: DELETE_FOLDERS_MODAL_MAX_WIDTH,
        data: document,
        panelClass: MODAL_PANEL_CLASS,
        disableClose: true,
      })
      .afterClosed()
      .pipe(filter((result) => !!result));
  }
}
