import { pickAll } from 'ramda';

import { ICompanyInvite } from '@app/shared/types/interfaces/general-info.interface';
import { ICompanyInvitationGeneralInfoResponse } from '@wevestr/bff-types/dtos/inviteCompanyDTO';
import { CompanyRole } from '@wevestr/bff-types/enums/companyRole.enum';

export const formatGeneralInfoResponse = (info: ICompanyInvitationGeneralInfoResponse): ICompanyInvite => {
  const { companyRole, companyFoundedYear, email } = info;
  const keys = [
    'id',
    'country',
    'onboardingStep',
    'companyId',
    'companyName',
    'companyIndustry',
    'companyLegalEntity',
    'companyWebsite',
    'companyDashboardType',
    'userFirstName',
    'userLastName',
    'userCompanyRole',
  ];
  const values = pickAll<ICompanyInvitationGeneralInfoResponse, ICompanyInvite>(keys, info);
  return {
    ...values,
    companyRole: <CompanyRole>companyRole,
    companyFoundedYear: companyFoundedYear ? parseInt(companyFoundedYear.toString(), 10) : void 0,
    userEmail: email,
    currencyId: null,
    token: null,
  };
};
