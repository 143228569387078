import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IChart } from '@app/shared/types/interfaces';

@Component({
  selector: 'app-chart-toggle',
  templateUrl: './chart-toggle.component.html',
  styleUrls: ['./chart-toggle.component.scss'],
})
export class ChartToggleComponent implements OnInit {
  @Input() charts: IChart[];
  @Input() activeChartIndex: number;

  @Output() selectChart: EventEmitter<{ chart: IChart; index: number }> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleSelectChart(chart: IChart, index: number) {
    this.selectChart.emit({ chart, index });
    this.activeChartIndex = index;
  }
}
