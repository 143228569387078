import { IUserSettings } from '@app/settings/types/interfaces';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { UserSettingsService } from '@app/shared/services/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsResolver {
  constructor(private userSettingsService: UserSettingsService) {}

  resolve(): Observable<IUserSettings> {
    return this.userSettingsService.getSettings(true);
  }
}
