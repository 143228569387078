import * as fromComponents from '@app/shared/components';
import * as fromDirectives from '@app/shared/directives';
import * as fromPipes from '@app/shared/pipes';

import { AngularResizeEventModule } from 'angular-resize-event';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { WevestrComponentsModule } from '@app/components/wevestr-components.module';
import { WevestrDirectivesModule } from '@app/directives/wevestr-directives.module';
import { WevestrFormsModule } from '@app/forms/wevestr-forms.module';
import { WevestrPipesModule } from '@app/pipes/wevestr-pipes.module';

const materialModules = [
  MatIconModule,
  MatTooltipModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatStepperModule,
];

@NgModule({
  declarations: [...fromComponents.components, ...fromPipes.pipes, ...fromDirectives.directives],
  imports: [
    CommonModule,
    RouterModule,
    NgApexchartsModule,
    AngularResizeEventModule,
    DragDropModule,
    WevestrFormsModule,
    WevestrDirectivesModule,
    WevestrComponentsModule,
    NgxSpinnerModule,
    WevestrPipesModule,
    ...materialModules,
  ],
  exports: [
    WevestrFormsModule,
    WevestrDirectivesModule,
    WevestrComponentsModule,
    WevestrPipesModule,
    RouterModule,
    NgApexchartsModule,
    AngularResizeEventModule,
    NgxSpinnerModule,
    ...materialModules,
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...fromDirectives.directives,
  ],
  providers: [...fromPipes.pipes],
})
export class SharedModule {}
