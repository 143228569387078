import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentProps } from '@app/shared/types/interfaces/component-props.type';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface IDeleteConfirmationModalProps<Component extends Type<unknown> = Type<unknown>> {
  entityName: string;
  messageComponent?: Component;
  messageProps?: ComponentProps<InstanceType<Component>>;
}

@Component({
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteConfirmationModalComponent {
  public confirmDeleteControl = new FormControl<string | null>('');
  public entityName: string;
  public messageComponent?: Type<unknown>;
  public isButtonDisabled$: Observable<boolean>;

  private readonly CONFIRMATION_STRING = 'delete';
  private readonly messageComponentProps: IDeleteConfirmationModalProps['messageProps'];
  @ViewChild('messageContainer', { read: ViewContainerRef, static: true })
  private readonly messageContainer: ViewContainerRef;
  @ViewChild('defaultMessage', { read: TemplateRef, static: true })
  private readonly defaultMessageTemplate: TemplateRef<unknown>;

  constructor(
    @Inject(MAT_DIALOG_DATA) config: IDeleteConfirmationModalProps,
    private dialogRef: MatDialogRef<DeleteConfirmationModalComponent>,
    private cdr: ChangeDetectorRef,
  ) {
    this.entityName = config.entityName;
    if (config.messageComponent) {
      this.messageComponent = config.messageComponent;
      this.messageComponentProps = config.messageProps ?? {};
    }
  }

  public ngOnInit(): void {
    this.isButtonDisabled$ = this.confirmDeleteControl.valueChanges.pipe(
      map((value) => value.toLowerCase() !== this.CONFIRMATION_STRING),
      startWith(true),
    );
  }

  public ngAfterViewInit(): void {
    this.messageComponent ? this.setMessageComponent() : this.setDefaultMessage();
  }

  public handleConfirm(): void {
    const isDeleteActionConfirmed = true;
    this.dialogRef.close(isDeleteActionConfirmed);
  }

  private setMessageComponent(): void {
    const componentRef = this.messageContainer.createComponent(this.messageComponent);
    Object.assign(componentRef.instance, this.messageComponentProps);
    componentRef.changeDetectorRef.detectChanges();
  }

  private setDefaultMessage(): void {
    this.messageContainer.createEmbeddedView(this.defaultMessageTemplate);
    this.cdr.detectChanges();
  }
}
