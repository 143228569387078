<div
  *ngIf="count; else noDocuments"
  [ngClass]="{
    'has-text-grey-light': count === 0,
    'cursor-pointer documents-link has-text-link': count > 0
  }"
  (click)="handleClick()"
>
  {{ count }} {{ label || (count > 1 ? 'Documents' : 'Document') }}
</div>

<ng-template #noDocuments>
  <div class="has-text-grey-light">No uploads</div>
</ng-template>
