import { Component } from '@angular/core';

@Component({
  selector: 'wevestr-expired-invite-link',
  templateUrl: './expired-invite-link.component.html',
  styleUrls: ['./expired-invite-link.component.scss'],
})
export class ExpiredInviteLinkComponent {
  public websiteLink = 'https://wevestr.com';
}
