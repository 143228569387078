import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/auth/services/auth.service';
import { TypeToFormControls } from '@app/forms/types';
import { loginPath } from '@app/shared/utils/constants/paths.constants';

type ForgotCredentialsFormData = {
  email: string | null;
};
type ForgotCredentialsForm = TypeToFormControls<ForgotCredentialsFormData>;

@Component({
  selector: 'app-forgot-credentials',
  templateUrl: './forgot-credentials.component.html',
  styleUrls: ['./forgot-credentials.component.scss'],
})
export class ForgotCredentialsComponent implements OnInit {
  public form: FormGroup<ForgotCredentialsForm>;

  public readonly MESSAGES = {
    EMAIL: 'Not a valid email address',
    REQUIRED: 'This field is required',
  };

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  public handleSendResetLink(): void {
    if (this.form.valid) {
      const email = this.form.controls.email.value;
      this.authService.resetPassword(email).subscribe(() => {
        this.router.navigate(['/auth/check-mail']);
      });
    }
  }

  public navigateToLogin(): void {
    this.router.navigate(loginPath);
  }
}
