import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';

import { IInactiveInvitationResponse, IInvitation, IInvitationResponse, IInvite } from '@app/auth/types/interfaces';
import { ConfigService } from '@app/services/config.service';
import { ICompanyInvite } from '@app/shared/types/interfaces/general-info.interface';
import { IAccountInfo } from '@app/auth/types/interfaces/account-info.interface';
import { formatGeneralInfoResponse } from '@app/auth/utils/helpers/invitation.helpers';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { IAcceptInvitationDTO } from '@wevestr/bff-types/dtos/acceptInvitationDTO';
import { ICompanyInvitationResponse } from '@app/shared/types/interfaces/company-invitation-response.inteface';
import { UserService } from '@app/shared/services';
import { getProfileUserName } from '@app/shared/utils/helpers/user.helpers';
import { ICompany } from '@app/types/interfaces';
import { getNextCompanyName } from '@app/auth/helpers/invitation.helpers';
import { IOnboardCompanyDTO } from '@wevestr/bff-types/dtos/onboardCompanyDTO';
import { toFormData } from '@app/shared/utils/helpers/common.helpers';
import { ICompanyInvitationGeneralInfoResponse } from '@wevestr/bff-types/dtos/inviteCompanyDTO';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  private readonly baseUrl: string;
  private onboardingLinkToken: string = null;

  public invitation: IInvitationResponse | IInactiveInvitationResponse;

  constructor(config: ConfigService, private http: HttpClient, private userService: UserService) {
    this.baseUrl = config.getFullBaseUrl();
  }

  public get url(): string {
    return `${this.baseUrl}/invitations`;
  }

  public list(): Observable<IInvitation[]> {
    const params = { companyId: COMPANY_ID_TOKEN } as Params;
    return this.http.get<IInvitation[]>(this.url, { params });
  }

  public create(email: string, role: number): Observable<IInvitation> {
    const params = { companyId: COMPANY_ID_TOKEN } as Params;
    return this.http.post<IInvitation>(`${this.url}`, { email, role }, { params });
  }

  public createNewCompany(companies$: Observable<ICompany[]>): Observable<ICompanyInvitationResponse> {
    return forkJoin([
      this.userService.get(),
      this.userService.currentCompanyId$.pipe(first()),
      companies$.pipe(first()),
    ]).pipe(
      exhaustMap(([user, companyId, companies]) => {
        const nextCompanyName = getNextCompanyName(companies);
        const userName = getProfileUserName(user.profile, companyId);
        const params = {
          userEmail: user.email,
          userFirstName: userName.firstName || null,
          userLastName: userName.lastName || null,
          companyName: nextCompanyName,
        };
        return this.http.post<ICompanyInvitationResponse>(`${this.url}/company`, params);
      }),
    );
  }

  public edit(code: string, editPayload: IAccountInfo): Observable<string> {
    const formData = toFormData(editPayload);
    return this.http.patch<string>(`${this.url}/${code}`, formData);
  }

  public validate(code: string): Observable<IInvite> {
    return this.http.get<IInvite>(`${this.url}/${code}/valid`);
  }

  public get(code: string): Observable<IInvitationResponse | IInactiveInvitationResponse> {
    return this.http.get<IInvitation>(`${this.url}/${code}`);
  }

  public accept(code: string, acceptInvitationPayload: IAcceptInvitationDTO): Observable<string> {
    return this.http.post<string>(`${this.url}/${code}/accept`, acceptInvitationPayload);
  }

  public resend(id: number): Observable<string> {
    const params = { companyId: COMPANY_ID_TOKEN } as Params;
    return this.http.put<string>(`${this.url}/${id}/resend`, {}, { params });
  }

  public getGeneralInfoByToken(token: string): Observable<ICompanyInvite> {
    return this.http
      .get<ICompanyInvitationGeneralInfoResponse>(`${this.url}/company`, { params: { token } })
      .pipe(map((info: ICompanyInvitationGeneralInfoResponse) => formatGeneralInfoResponse(info)));
  }

  public sendOnboardingRequest(): Observable<void> {
    return this.http.post<void>(`${this.url}/company/${COMPANY_ID_TOKEN}/start-onboarding`, {});
  }

  public saveOnboardingLinkToken(token: string): void {
    this.onboardingLinkToken = token;
  }

  public isOnboardingLinkTokenPresent(): boolean {
    return !!this.getOnboardingLinkToken();
  }

  public getOnboardingLinkToken(): string {
    return this.onboardingLinkToken;
  }

  public removeOnboardingLinkToken(): void {
    this.onboardingLinkToken = null;
  }

  public onboardCompany(company: IOnboardCompanyDTO, params: HttpParams): Observable<ICompany> {
    const formData = toFormData(company);
    return this.http.post<ICompany>(`${this.url}/company/onboard`, formData, { params });
  }
}
