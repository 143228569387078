import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { IStepClickEvent, IStepperItem } from '@app/shared/types/interfaces';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WizardStepComponent } from '@app/shared/components/wizard-step/wizard-step.component';

export type IWizardStepState<TDisplay = string | number> = IStepperItem<TDisplay>;

@Component({
  selector: 'wevestr-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements AfterContentInit {
  @Output()
  public onHeaderItemClick = new EventEmitter<IStepClickEvent>();

  public headersStatus$: Observable<IWizardStepState[]>;

  @ContentChildren(WizardStepComponent, { descendants: true })
  private steps: QueryList<WizardStepComponent>;

  public ngAfterContentInit(): void {
    this.headersStatus$ = this.steps.changes.pipe(
      startWith(this.steps),
      map((steps) => this._getHeaderStatus(steps)),
    );
  }

  public onHeaderClick(event: IStepClickEvent): void {
    this.onHeaderItemClick.emit(event);
  }

  private _getHeaderStatus(steps: WizardStepComponent[]): IWizardStepState[] {
    return steps.map(({ stepStatus: status, stepName: display, disabled }) => ({
      disabled,
      display,
      status,
    }));
  }
}
