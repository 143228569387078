import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConfigService, IConfig } from '@app/services/config.service';

export class WvFeatureContext {
  public $implicit: keyof IConfig = null;
  public wvFeature: keyof IConfig = null;
}

/** Feature directive that allows template to display only if provided feature flag is enabled
 * @example
 * <div *wvFeature="'featureNameEnabled'"></div>
 * // if featureNameEnabled is true in envirnoment.ts then template will be displayed
 */
@Directive({
  selector: '[wvFeature]',
})
export class FeatureDirective {
  private context: WvFeatureContext = new WvFeatureContext();

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<WvFeatureContext>,
    private configService: ConfigService,
  ) {}

  @Input()
  set wvFeature(featureName: keyof IConfig) {
    this.context.$implicit = this.context.wvFeature = featureName;
    this.updateView();
  }

  private updateView(): void {
    if (this.configService.isFeatureEnabled(this.context.$implicit)) {
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    } else {
      this.viewContainer.clear();
    }
  }

  public static ngTemplateContextGuard(_dir: WvFeatureContext, _ctx: unknown): _ctx is keyof IConfig {
    return true;
  }
}
