import { Pipe, PipeTransform } from '@angular/core';
import { getNextMonthDate } from '@app/shared/utils/helpers/dates.helpers';

@Pipe({
  name: 'nextMonthDate',
})
export class NextMonthDatePipe implements PipeTransform {
  public transform(value: Date): Date {
    return value ? getNextMonthDate(value) : void 0;
  }
}
