import { NumberFormatting, SettingsRouteNames, TSettingsTab } from '@app/settings/types/enums';
import { IProfile } from '@app/types/interfaces/profile.interface';
// TODO: move to shared folder
import { ICompany } from '@app/shareholder-management/types/interfaces/company.interface';
import { IUserSettings } from '@app/settings/types/interfaces';
import { ITableColumn } from '@app/shared/types/interfaces/table-columns.interface';

export const PERSONAL_TABS: TSettingsTab[] = [
  {
    name: 'General',
    route: SettingsRouteNames.PERSONAL_GENERAL,
    description: '',
  },
  {
    name: 'Personal',
    route: SettingsRouteNames.PERSONAL_USER,
    description: '',
  },
  {
    name: 'Security',
    route: SettingsRouteNames.PERSONAL_SECURITY,
    description: '',
  },
];

export const COMPANY_TABS: TSettingsTab[] = [
  {
    name: 'Company',
    route: SettingsRouteNames.COMPANY_GENERAL,
    description: '',
  },
  {
    name: 'Cap Table',
    route: SettingsRouteNames.COMPANY_CAP_TABLE,
    description:
      'Here is an overview of Funding Rounds and Share Types for your company. We have provided some as default but you can add custom ones as well. You can add an Internal Note, this is only viewable by admins.',
  },
  {
    name: 'Slicing Pie',
    route: SettingsRouteNames.COMPANY_STARTUP_COOP,
    description: '',
  },
  {
    name: 'ESOP',
    route: SettingsRouteNames.ESOP,
    description:
      'Here is an overview of Grant Types for your company. We have provided some as default but you can add custom ones as well. You can add an Internal Note, this is only viewable by admins.',
  },
  /*{
    name: 'Import',
    route: SettingsRouteNames.COMPANY_IMPORT,
    description: '',
    editDisabled: true,
  },*/
  {
    name: 'Connections',
    route: SettingsRouteNames.COMPANY_EXTERNAL_SOURCES,
    description: 'This is your connection overview. Connect your tools to speed up your workflow.',
    editDisabled: true,
  },
];

export const SETTINGS_ENABLE_TFA_CODE_HINT = 'Add key phrase into you authenticator app and remember the key phrase';
export const SETTINGS_ENABLE_TFA_CODE_TEXT = 'Enter the confirmation code shown in your verification app';
export const SETTINGS_QR_CODE_SIZE = 185;

export const PROFILE_FIELDS: Record<string, keyof IProfile> = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DATE_OF_BIRTH: 'birthDate',
  LOCATION: 'countryId',
  NATIONALITY: 'citizenshipId',
};

export const GENERAL_SETTINGS_FIELDS: Record<string, keyof IUserSettings> = {
  FORMATTING: 'formatting',
};

export const USER_SETTINGS_FIELDS_LABELS: Record<string, string> = {
  [PROFILE_FIELDS.FIRST_NAME]: 'First Name',
  [PROFILE_FIELDS.LAST_NAME]: 'Last Name',
  [PROFILE_FIELDS.DATE_OF_BIRTH]: 'Date of Birth',
  [PROFILE_FIELDS.LOCATION]: 'Location',
  [PROFILE_FIELDS.NATIONALITY]: 'Nationality',
  [GENERAL_SETTINGS_FIELDS.DECIMALS]: 'Decimals',
  [GENERAL_SETTINGS_FIELDS.FORMATTING]: 'Formatting',
};

export const COMPANY_SETTINGS_FIELDS: Record<string, string> = {
  CURRENCY: 'currency',
};

export const COMPANY_SETTINGS_FIELDS_VALUES = [COMPANY_SETTINGS_FIELDS.CURRENCY];

export const COMPANY_FIELDS: Record<string, keyof ICompany> = {
  LOGO: 'logo',
  NAME: 'name',
  LEGAL_ENTITY: 'legalEntity',
  DESCRIPTION: 'description',
  INDUSTRY: 'industry',
  LOCATION: 'location',
  FOUNDED_YEAR: 'foundedYear',
  WEBSITE: 'website',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
};

export const COMPANY_FIELDS_LABELS: Record<string, string> = {
  [COMPANY_SETTINGS_FIELDS.CURRENCY]: 'Currency',
  [COMPANY_FIELDS.LOGO]: 'Logo',
  [COMPANY_FIELDS.NAME]: 'Name',
  [COMPANY_FIELDS.LEGAL_ENTITY]: 'Legal Entity',
  [COMPANY_FIELDS.DESCRIPTION]: 'Description',
  [COMPANY_FIELDS.INDUSTRY]: 'Industry',
  [COMPANY_FIELDS.LOCATION]: 'Location',
  [COMPANY_FIELDS.FOUNDED_YEAR]: 'Founded Year',
  [COMPANY_FIELDS.WEBSITE]: 'Website',
  [COMPANY_FIELDS.LINKEDIN]: 'LinkedIn',
  [COMPANY_FIELDS.TWITTER]: 'Twitter',
  [COMPANY_FIELDS.FACEBOOK]: 'Facebook',
  [COMPANY_FIELDS.INSTAGRAM]: 'Instagram',
};

export const SETTINGS_2FA_MODAL_MAX_WIDTH = '500px';

export const FORMATTING_OPTIONS = [
  {
    id: NumberFormatting.EUROPEAN,
    name: '123.456,78',
  },
  {
    id: NumberFormatting.AMERICAN,
    name: '123,456.78',
  },
];

export enum SEPARATORS {
  COMMA = ',',
  DOT = '.',
}

export const NUMBERS_STYLE = 'app-numbers';
export const MILLION_ABBREV = 'M';
export const MILLION_NUMBER = 1000000;
export const DEFAULT_DECIMALS_NUMBER = 2;

export const BASE_SETTINGS_COLUMN = {
  footerValue: () => '',
  isFullWidthEditInput: true,
  valuesStyle: {
    'font-size': '14px',
    'line-height': '1.25',
    'padding-top': '16px',
    'padding-bottom': '16px',
  },
} as Partial<ITableColumn>;
