import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  public transform(fullName: string): string | null {
    let initials = null;
    if (fullName) {
      const [firstName, lastName] = fullName.split(' ');

      initials = `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ''}`.toUpperCase();
    }

    return initials;
  }
}
