<div class="card-with-borders expansion-panel padding-m">
  <div class="flex-row is-justify-content-space-between align-items-center">
    <ng-content select="[panelHeader]"></ng-content>
    <div (click)="toggleExpand()" class="flex-row is-align-items-center is-justify-content-flex-end margin-right-s">
      <mat-icon *ngIf="isChevronShown" svgIcon="chevron" class="collapse-icon" [class.expanded]="isExpanded"></mat-icon>
    </div>
  </div>
  <div *ngIf="isExpanded" class="expansion-panel__content">
    <ng-content select="[panelContent]"></ng-content>
  </div>
</div>
