<div #dropdown class="dropdown full-width full-height" [ngClass]="{'is-active': isOpened}">
  <div class="dropdown-trigger full-width" (click)="toggleDropdown($event)">
    <button
      class="dropdown-trigger-button button full-width full-height"
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      type="button"
    >
      <span *ngIf="placeholder" class="dropdown-placeholder full-width has-text-left">
        {{ placeholder }}
      </span>
      <mat-icon svgIcon="dropdown" class="dropdown-trigger-button-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-menu dropdown-menu-position-{{ menuPosition }} full-width" role="menu">
    <div class="dropdown-content app-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
