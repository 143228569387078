import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RadioButtonThemes } from '@app/forms/types';

@Component({
  selector: 'wevestr-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent<T> implements ControlValueAccessor, AfterViewInit {
  @Input() public disabled = false;
  @Input() public theme = RadioButtonThemes.black;
  @Input() public groupName: string;
  @Input() public valueName: T;

  @ViewChild('radioInput', { static: true }) public radioInput: ElementRef;

  public value: T;

  @Input() public set checked(value: boolean) {
    if (value) {
      this.value = this.valueName;
      this.updateChecked();
      if (this.onChange && this.onTouch) {
        this.onChange(this.valueName);
        this.onTouch();
      }
    }
  }

  public onInputChange(): void {
    if (this.onChange) {
      this.onChange(this.valueName);
    }
  }

  public writeValue(value: T): void {
    this.value = value;
    this.updateChecked();
  }

  public onChange: (value: T) => void;
  public onTouch: () => void;

  public registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  public ngAfterViewInit(): void {
    this.updateChecked();
  }

  public updateChecked(): void {
    const checked = this.value === this.valueName;
    if (this.radioInput) {
      this.radioInput.nativeElement.checked = checked;
    }
  }
}
