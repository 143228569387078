import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '@app/shared/services/user.service';
import { ImportDataService } from '@app/settings/services';
import { financialsProfitAndLossPath } from '@app/shared/utils/constants/paths.constants';
import { RunImportService } from '@app/shared/services/run-import.service';
import { IImportDetails } from '@app/shared/types/interfaces';
import { IAuditImportParameters } from '@app/shared/types/interfaces/import-parameters.interface';
import { SelectAuditFilesComponent } from '@app/shared/components/select-audit-files/select-audit-files.component';

@Component({
  selector: 'app-import-audit-file-modal',
  templateUrl: './import-audit-file-modal.component.html',
  styleUrls: ['./import-audit-file-modal.component.scss'],
})
export class ImportAuditFileModalComponent implements OnInit, OnDestroy {
  public isImportInProgress: boolean;
  public readonly IMPORT_STEPS = {
    ATTACH_FILES: 1,
    IMPORT_RESULTS: 2,
  };
  public currentImportStep: number;
  public importDetails: IImportDetails;
  @ViewChild(SelectAuditFilesComponent)
  public auditFilesSelect: SelectAuditFilesComponent;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<ImportAuditFileModalComponent>,
    private userService: UserService,
    private runImportService: RunImportService,
    private importDataService: ImportDataService,
  ) {}

  public ngOnInit(): void {
    this.isImportInProgress = false;
    this.currentImportStep = this.IMPORT_STEPS.ATTACH_FILES;
  }

  public handleRedirect(): void {
    this.closeModal();
    this.goToProfitAndLossPage();
  }

  private closeModal(): void {
    this.dialogRef.close();
  }

  private goToProfitAndLossPage(): void {
    this.router.navigate(financialsProfitAndLossPath);
  }

  public handleClose(): void {
    this.closeModal();
  }

  public runImport(importParams: IAuditImportParameters): void {
    this.startImport();
    this.runImportService
      .runImport(this.userService.currentCompanyId, importParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (importDetails: IImportDetails) => {
          this.importDetails = importDetails;
          this.goToNextImportStep();
          this.finishImport();
        },
        () => this.finishImport(),
      );
  }

  public goToPreviousImportStep(): void {
    this.currentImportStep = this.IMPORT_STEPS.ATTACH_FILES;
  }

  private goToNextImportStep(): void {
    this.currentImportStep = this.IMPORT_STEPS.IMPORT_RESULTS;
  }

  private startImport(): void {
    this.isImportInProgress = true;
    this.importDataService.setImportingInProgress(true);
  }

  private finishImport(): void {
    this.isImportInProgress = false;
    this.importDataService.setImportingInProgress(false);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
