import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// TODO: move interfaces and helpers in shared folder
import { IDocument } from '@app/documents/types/interfaces';
import { isImage, isPdf } from '@app/documents/utils/helpers/documents.helpers';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent implements OnInit {
  public documentURL: SafeResourceUrl;
  public isImage = false;
  public isPdf = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public document: { content: Blob; source: IDocument },
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<PreviewDocumentComponent>,
  ) {}

  ngOnInit(): void {
    this.getDocumentURL();
    this.isPdf = isPdf(this.document.source);
    this.isImage = isImage(this.document.source);
  }

  handleClose(): void {
    this.dialogRef.close();
  }

  //TODO: use app-dialog component
  @HostListener('document:keyup.esc', [])
  handleEsc(): void {
    this.dialogRef.close();
  }

  private getDocumentURL(): void {
    this.documentURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.document.content));
  }
}
