import { Injectable } from '@angular/core';
import { ConfigService } from '@app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { LocalStorageService, UserService } from '@app/shared/services';
import { ITokens } from '@app/auth/types/interfaces/tokens.interface';
import { JwtService } from '@app/auth/services/jwt.service';
import { IAccessToken, IRefreshToken } from '@app/auth/types/interfaces';
import { ICreatePasswordPayload } from '@app/shared/types/interfaces';
import { IAuthService } from '@app/types/interfaces';
import { IVerify2faCode } from '@app/auth/types/interfaces/verify-2fa-code.interface';
import { IUserTokens } from '@app/auth/types/interfaces/user-tokens.interface';
import { EventType } from '@app/shared/types/enums/event-type.enum';
import { InvitationsService } from '@app/auth/services/invitations.service';

export const ACCESSS_TOKEN = 'wvs-access-token';
export const REFRESH_TOKEN = 'wvs-refresh-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends JwtService implements IAuthService {
  readonly baseUrl: string;
  private _token2fa: string;
  private keepSignedIn: boolean;

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private userService: UserService,
    private localStorage: LocalStorageService,
    private invitationService: InvitationsService,
  ) {
    super();
    this.baseUrl = this.config.getFullBaseUrl();
  }

  get token2fa(): string {
    return this._token2fa;
  }

  set token2fa(token: string) {
    this._token2fa = token;
  }

  public login(credentials: Record<string, string>, keepSignedIn = false): Observable<IUserTokens> {
    this.keepSignedIn = keepSignedIn;
    return this.http.post<IUserTokens>(`${this.baseUrl}/auth/signin`, credentials).pipe(
      tap(({ tokens }: IUserTokens) => {
        this.setTokens(keepSignedIn, tokens);
      }),
      take(1),
    );
  }

  public refreshAccessToken(refreshToken: string): Observable<IAccessToken> {
    const data: IRefreshToken = { refreshToken };
    return this.http
      .post<IAccessToken>(`${this.baseUrl}/auth/refresh`, data)
      .pipe(tap((data: IAccessToken) => localStorage.setItem(ACCESSS_TOKEN, data.accessToken)));
  }

  public verify2fa(data: IVerify2faCode): Observable<IUserTokens> {
    return this.http
      .post<IUserTokens>(`${this.baseUrl}/auth/verify-2fa`, data)
      .pipe(tap(({ tokens }: IUserTokens) => this.setTokens(this.keepSignedIn, tokens)));
  }

  public get accessToken(): string {
    return localStorage.getItem(ACCESSS_TOKEN) || sessionStorage.getItem(ACCESSS_TOKEN);
  }

  public get refreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN) || sessionStorage.getItem(REFRESH_TOKEN);
  }

  public createCredentials(createPasswordPayload: ICreatePasswordPayload): Observable<IUserTokens> {
    return this.http.post<IUserTokens>(`${this.baseUrl}/auth/credentials`, createPasswordPayload);
  }

  public verify(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/auth/verify?token=${token}`);
  }

  public resetPassword(email: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/auth/reset-password`, { email });
  }

  public validateResetPassword(resetToken: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/auth/verify-reset-password?token=${resetToken}`);
  }

  public savePassword(createPasswordPayload: ICreatePasswordPayload): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/auth/change-password`, createPasswordPayload);
  }

  public logOut(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/auth/logout`, { refreshToken: this.refreshToken }).pipe(
      tap(() => {
        this.logOutAllSessions();
        this.removeTokens();
        this.userService.resetValue();
        this.invitationService.removeOnboardingLinkToken();
      }),
    );
  }

  public removeTokens(): void {
    localStorage.removeItem(ACCESSS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem(ACCESSS_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
  }

  public setTokens(keepSignedIn: boolean, tokens: ITokens): void {
    this.getStorage(keepSignedIn).setItem(ACCESSS_TOKEN, tokens.access);
    this.getStorage(keepSignedIn).setItem(REFRESH_TOKEN, tokens.refresh);
  }

  public areSessionStorageTokensSet(): string {
    return sessionStorage.getItem(ACCESSS_TOKEN) && sessionStorage.getItem(REFRESH_TOKEN);
  }

  private getStorage(keepSignedIn: boolean): Storage {
    return keepSignedIn ? localStorage : sessionStorage;
  }

  private logOutAllSessions(): void {
    this.localStorage.broadcast(EventType.LOG_OUT, EventType.LOG_OUT);
  }

  public isLoggedIn(): boolean {
    return !!this.accessToken;
  }
}
