import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { TRIGGER_DATA_TYPES } from '@app/shareholder-management/constants/convertibles.constants';
import { NOTIFICATION_TYPES } from '@app/documents/constants/notification.constants';
import { checkDiscountIntervalsOverlap } from '@app/shared/utils/helpers/common.helpers';
import { getNextMonthDate, isSameDay, isBefore, isAfter } from '@app/shared/utils/helpers/dates.helpers';
import { DateValidator } from '@app/forms/validators/date.validator';
import { isNil } from 'ramda';

const beginningOfTimes = new Date('0000-01-01');

export const positiveNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value && control.value !== 0) {
    return null;
  }

  const isValid = Number(control.value) > 0;

  return isValid ? null : { nonPositive: true };
};

export const notNegativeNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value && control.value !== 0) {
    return null;
  }

  const isValid = Number(control.value) >= 0;

  return isValid ? null : { nonPositive: true };
};

export function lessThanNumberValidator(limit: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const isValid = Number(control.value) < limit;
    return isValid ? null : { greaterThanLimit: limit };
  };
}

export const dateOfIssueValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const dateOfIssue = new Date(control.value);
  const today = new Date();

  const isValid = dateOfIssue.getTime() <= today.getTime() || isNaN(dateOfIssue.getTime());
  return isValid ? null : { greaterThanToday: true };
};

type TChildValidationParams = {
  control: AbstractControl | UntypedFormControl;
  predicate: () => boolean;
  error: string;
  parentErrors: Record<string, boolean>;
};

const validateChildControl = ({ control, predicate, error, parentErrors }: TChildValidationParams): void => {
  if (predicate()) {
    if (parentErrors) {
      parentErrors[error] = true;
    }
    control.markAsDirty();
    control.setErrors({ ...control.errors, [error]: true });
  } else if (control.hasError(error)) {
    delete control.errors[error];
    control.updateValueAndValidity();
  }
};

export const loanDatesValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const controls = {
      start: control.get('dateStart'),
      firstRepayment: control.get('firstRepaymentDate'),
      end: control.get('dateEnd'),
    },
    timestamps = {
      start: new Date(controls.start.value).getTime(),
      firstRepayment: new Date(controls.firstRepayment.value).getTime(),
      end: new Date(controls.end.value).getTime(),
    },
    errors: ValidationErrors = {};

  const childValidations: TChildValidationParams[] = [
    {
      control: controls.firstRepayment,
      predicate: () => timestamps.start >= timestamps.firstRepayment,
      error: 'repaymentDateInvalid',
      parentErrors: errors,
    },
    {
      control: controls.end,
      predicate: () => timestamps.firstRepayment > timestamps.end || timestamps.start >= timestamps.end,
      error: 'dateEndInvalid',
      parentErrors: errors,
    },
  ];

  childValidations.forEach((validators) => validateChildControl(validators));

  return Object.keys(errors).length ? errors : null;
};

export const convertibleDatesValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const controls = {
      start: control.get('dateStart'),
      end: control.get('dateEnd'),
      dateOfIssue: control.get('dateOfIssue'),
    },
    timestamps = {
      start: new Date(controls.start.value).getTime(),
      end: new Date(controls.end.value).getTime(),
      dateOfIssue: new Date(controls.dateOfIssue.value).getTime(),
      today: new Date().getTime(),
    };
  const errors: ValidationErrors = {};
  const childValidations: TChildValidationParams[] = [
    {
      control: controls.dateOfIssue,
      predicate: () => timestamps.dateOfIssue > timestamps.today,
      error: 'dateOfIssueInvalid',
      parentErrors: errors,
    },
    {
      control: controls.start,
      predicate: () => timestamps.dateOfIssue > timestamps.start,
      error: 'dateStartInvalid',
      parentErrors: errors,
    },
    {
      control: controls.end,
      predicate: () => timestamps.start > timestamps.end || timestamps.dateOfIssue > timestamps.end,
      error: 'dateEndInvalid',
      parentErrors: errors,
    },
  ];

  childValidations.forEach((validators) => validateChildControl(validators));

  return Object.keys(errors).length ? errors : null;
};

export const convertibleDiscountDatesValidator = (control: AbstractControl): ValidationErrors | null => {
  const errors: ValidationErrors = {};
  const controls = {
      start: control.get('discountStartDate'),
      end: control.get('discountEndDate'),
    },
    timestamps = {
      start: new Date(controls.start.value).getTime(),
      end: new Date(controls.end.value).getTime(),
    };

  validateChildControl({
    control: controls.start,
    predicate: () => timestamps.start > timestamps.end,
    error: 'discountStartViolatesEnd',
    parentErrors: errors,
  });

  return Object.keys(errors).length ? errors : null;
};

export const discountDatesNotOverlapValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const errors: ValidationErrors = {};
  const formArray = control.get('discounts') as UntypedFormArray;
  for (let index = 0; index < formArray.controls.length - 1; index++) {
    if (checkDiscountIntervalsOverlap(formArray.controls[index], formArray.controls[index + 1])) {
      errors.datesOverlap = true;
    }
    if (index === 1) {
      if (checkDiscountIntervalsOverlap(formArray.controls[index - 1], formArray.controls[index + 1])) {
        errors.datesOverlap = true;
      }
    }
  }

  return Object.keys(errors).length ? errors : null;
};

export const phaseDatesTimelineValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const errors: ValidationErrors = {};
  const phasesFormArray = control.get('phases') as UntypedFormArray;

  phasesFormArray.controls.forEach((phaseDatesControls, index, phasesFormArrayControls) => {
    if (index > 0) {
      const controls = {
          phaseStart: phaseDatesControls.get('startDate'),
          previousPhaseEnd: phasesFormArrayControls[index - 1].get('endDate'),
        },
        timestamps = {
          start: new Date(controls.phaseStart.value).getTime(),
          validStart: getNextMonthDate(controls.previousPhaseEnd.value).getTime(),
        };

      validateChildControl({
        control: controls.phaseStart,
        predicate: () => timestamps.start !== timestamps.validStart,
        error: 'wrongPhaseStartDate',
        parentErrors: errors,
      });
    }
  });

  return Object.keys(errors).length ? errors : null;
};

// TODO: refactor this to reduce complexity
// eslint-disable-next-line complexity
export const triggerValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const typeControl = control.get('type'),
    criteriaControl = control.get('criteria');

  const error: ValidationErrors = {};

  if (typeControl.value?.shouldHaveValue) {
    if (criteriaControl.value === null) {
      error.requiredValue = true;
      criteriaControl.setErrors({ required: true });
    } else if (typeControl.value.valueType === TRIGGER_DATA_TYPES.NUMBER && Number(criteriaControl.value) <= 0) {
      criteriaControl.setErrors({ numericValueInvalid: true });
    } else if (typeControl.value.valueType === TRIGGER_DATA_TYPES.DATE) {
      if (DateValidator(criteriaControl)) {
        criteriaControl.setErrors({ dateInvalid: true });
      } else if (criteriaControl.errors) {
        delete criteriaControl.errors.dateInvalid;
      }
    }
  }

  return Object.keys(error).length ? error : null;
};

export const dateNotInPastValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let validator = null;
  if (control.value && !isNaN(control.value.getTime())) {
    const date = new Date(control.value),
      today = new Date(),
      yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);
    const isValid = date.getTime() >= yesterday.getTime();

    validator = isValid ? null : { dateNotInPast: true };
  }
  return validator;
};

export const notificationTypeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const type = control.get('notificationType').value,
    date = control.get('notification').get('date').value,
    title = control.get('notification').get('title').value,
    errors: ValidationErrors = {};

  if (type === NOTIFICATION_TYPES.SELECTED_DATE) {
    if (!date) {
      errors.date = true;
    }
    if (!title) {
      errors.title = true;
    }
  }

  return Object.keys(errors).length ? errors : null;
};

export const noWhitespaceValidator = (control: AbstractControl): { [key: string]: boolean } => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};

export const IsEmptyWithoutOnlyWhitespacesValidator = ({ value }: AbstractControl): { [key: string]: boolean } => {
  if (value !== '' && value?.trim() === '') {
    return { whitespace: true };
  }
  return null;
};

export const isNotNegativeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value && control.value !== 0) {
    return null;
  }

  const isValid = Number(control.value) >= 0;

  return isValid ? null : { nonPositive: true };
};

export const greaterThanDateValidator =
  (minDate = beginningOfTimes): ValidatorFn =>
  (control: AbstractControl) => {
    const date = new Date(control.value);
    const isValid = date > minDate;

    return isValid ? null : { lowerThanOrSameMinDate: true };
  };

export const greaterOrTheSameDayThanDateValidator =
  (minDate: Date): ValidatorFn =>
  (control: AbstractControl) => {
    if (!minDate) {
      return null;
    }
    const date = new Date(control.value);

    const isValid = isSameDay(date, minDate) || isAfter(date, minDate);

    return isValid ? null : { lowerThanMinDate: true };
  };

export const lowerOrTheSameDayThenDateValidator =
  (maxDate: Date): ValidatorFn =>
  (control: AbstractControl) => {
    if (!maxDate) {
      return null;
    }
    const date = new Date(control.value);

    const isValid = isSameDay(date, maxDate) || isBefore(date, maxDate);

    return isValid ? null : { greaterThanMaxDate: true };
  };

export const lowerThenDateValidator =
  (maxDate: Date): ValidatorFn =>
  (control: AbstractControl) => {
    const date = new Date(control.value);
    const isValid = date < maxDate || !maxDate;

    return isValid ? null : { greaterThanMaxDate: true };
  };

export const removeFormControlError = (control: AbstractControl, errorName: string): void => {
  if (control?.errors && control?.errors[errorName]) {
    delete control.errors[errorName];
    if (Object.keys(control.errors).length === 0) {
      control.setErrors(null);
    }
  }
};

export const nonInfinityValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value && control.value !== 0) {
    return null;
  }

  const isValid = Number(control.value) !== Infinity;
  return isValid ? null : { infinity: true };
};

export const nonNaNValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (isNil(control.value)) {
    return null;
  }
  const isValid = !isNaN(Number(control.value));
  return isValid ? null : { NaN: true };
};

export const conditionalValidator =
  (
    predicate: (control?: AbstractControl) => boolean,
    validator: (control: AbstractControl) => ValidationErrors | null,
  ): ValidatorFn =>
  (control: AbstractControl) => {
    return predicate(control) ? validator(control) : null;
  };

export const requiredIfValidator =
  (predicate: (control?: AbstractControl) => boolean): ValidatorFn =>
  (control: AbstractControl) => {
    if (!control.parent) {
      return null;
    }
    return conditionalValidator(predicate, Validators.required)(control);
  };

export const isNotMoreThanMaxValue =
  (getMaxValue: () => number): ValidatorFn =>
  (control: AbstractControl) => {
    const value = +control.value;

    if (!value) {
      return null;
    }

    return value > getMaxValue() ? { max: true } : null;
  };
