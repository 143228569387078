import { Injectable } from '@angular/core';
import flagsmith from 'flagsmith';
import { IFlagsmithTrait, IFlags } from 'flagsmith/types';

import {
  IS_DEMO_MODE_ENABLED_FEATURE,
  IS_MAINTENANCE_ENABLED_FEATURE,
} from '@app/services/flagsmith.service.constants';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FlagsmithService {
  private initialized = false;
  private initPromise: Promise<void>;
  constructor() {}

  public async init(): Promise<void> {
    if (this.initialized) {
      return Promise.resolve();
    }
    if (this.initPromise) {
      return this.initPromise;
    }
    this.initPromise = flagsmith.init({
      environmentID: environment.flagsmithEnvID,
      api: environment.flagsmithBaseUrl,
      cacheFlags: true,
      enableAnalytics: false,
    });

    await this.initPromise;
    this.initialized = true;
    delete this.initPromise;
  }

  public isFeatureEnabled(featureName: string): boolean {
    return flagsmith.hasFeature(featureName);
  }

  public getFeatureValue(featureName: string): IFlagsmithTrait {
    return flagsmith.getValue(featureName);
  }

  public isMaintenanceModeEnabled(): boolean {
    return this.isFeatureEnabled(IS_MAINTENANCE_ENABLED_FEATURE);
  }

  public isDemoModeEnabled(): boolean {
    return this.isFeatureEnabled(IS_DEMO_MODE_ENABLED_FEATURE);
  }

  public getAllValues(): IFlags<string> {
    return flagsmith.getAllFlags();
  }
}
