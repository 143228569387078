import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { AnalyticsJourney, AnalyticsSubJourney } from '@app/shared/types/enums/analytics-journey.enum';
import { Section } from '@app/shared/types/enums/section.enum';
import { SIDEBAR_SECTIONS } from '@app/shared/utils/constants/sections.constants';

export const ANALYTICS_LOOKUP: Record<
  string,
  { event: AnalyticsEvent; journey?: AnalyticsJourney; subJourney?: AnalyticsSubJourney }
> = {
  [SIDEBAR_SECTIONS[Section.capTable]?.name]: {
    event: AnalyticsEvent.ViewCapTable,
    journey: AnalyticsJourney.CapTable,
  },
  [SIDEBAR_SECTIONS[Section.home]?.name]: {
    event: AnalyticsEvent.ViewHome,
  },
  [SIDEBAR_SECTIONS[Section.dashboard]?.name]: {
    event: AnalyticsEvent.ViewDashboard,
  },
  [SIDEBAR_SECTIONS[Section.loansAndConvertibles]?.name]: {
    event: AnalyticsEvent.ViewNotesAndLoans,
    journey: AnalyticsJourney.NotesAndLoans,
  },
  [SIDEBAR_SECTIONS[Section.esop]?.name]: {
    event: AnalyticsEvent.ViewESOP,
    journey: AnalyticsJourney.ESOP,
  },
  [SIDEBAR_SECTIONS[Section.scenarioModeling]?.name]: {
    event: AnalyticsEvent.ViewScenarioModeling,
    journey: AnalyticsJourney.ScenarioModeling,
  },
  [SIDEBAR_SECTIONS[Section.financials]?.name]: {
    event: AnalyticsEvent.ViewFinancials,
    journey: AnalyticsJourney.Financials,
  },
  [SIDEBAR_SECTIONS[Section.kpis]?.name]: {
    event: AnalyticsEvent.ViewKPIs,
    journey: AnalyticsJourney.KPIs,
  },
  [SIDEBAR_SECTIONS[Section.documents]?.name]: {
    event: AnalyticsEvent.ViewDocuments,
    journey: AnalyticsJourney.Documents,
  },
  [SIDEBAR_SECTIONS[Section.slicingPie]?.name]: {
    event: AnalyticsEvent.ViewSlicingPie,
    journey: AnalyticsJourney.SlicingPie,
  },
  [SIDEBAR_SECTIONS[Section.stakeholders]?.name]: {
    event: AnalyticsEvent.ViewStakeholders,
    journey: AnalyticsJourney.Stakeholders,
  },
};
