import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { ICompanySettings } from '@app/shared/types/interfaces/company-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsResolver {
  constructor(private companySettingsService: CompanySettingsService) {}

  public resolve(): Observable<ICompanySettings> {
    return this.companySettingsService.get();
  }
}
