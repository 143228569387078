import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICreateSafeNoteDTO } from '@wevestr/bff-types/dtos/createSafeNoteDTO';

import { CrudService } from '@app/shared/services/crud.service';
import { convertStringToLocalDate } from '@app/shared/utils/helpers/dates.helpers';
import { ISafeNote } from '@app/shareholder-management/types/interfaces/safe-note.interface';
import { ConfigService } from '@app/services/config.service';
import { ITriggerType } from '@app/shareholder-management/types/interfaces';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { isValueDefined } from '@app/shared/utils/helpers/common.helpers';
import { IConvertSafeNotesToEquityDTO } from '@app/notes-loans/types/interfaces/convert-multi-safe-notes-shareholder.interface';
import { IGetSafeNoteFiltersDTO } from '@wevestr/bff-types/dtos/getSafeNotesDTO';
import { ISafeNotesConversionResponseDTO } from '@wevestr/bff-types/dtos/convertSafeNotesToEquityDTO';

type TriggerTypesResponse = {
  triggers: ITriggerType[];
};

export type SafeNoteListFilter = {
  isConverted?: boolean;
  activeAt?: Date | string;
};

@Injectable({ providedIn: 'root' })
export class SafeNotesService extends CrudService<ISafeNote> {
  public safeNotesUpdated$ = new Subject<boolean>();

  constructor(configService: ConfigService, http: HttpClient) {
    super(http, configService);
  }

  public get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/convertibles/safe-notes`;
  }

  public getSafeNotesUpdate$(): Observable<boolean> {
    return this.safeNotesUpdated$.asObservable();
  }

  public getTriggerTypes(): Observable<TriggerTypesResponse> {
    return this._http.get<TriggerTypesResponse>(`${this.baseUrl}/${this.url}/trigger-types`);
  }

  public notifyAboutUpdates(isRefresh: boolean): void {
    this.safeNotesUpdated$.next(isRefresh);
  }

  public createSafeNote(dto: Partial<ICreateSafeNoteDTO>): Observable<void> {
    return this._http.post<void>(`${this.baseUrl}/${this.url}`, dto);
  }

  public editSafeNote(dto: Partial<ICreateSafeNoteDTO>): Observable<void> {
    return this._http.patch<void>(`${this.baseUrl}/${this.url}/${dto.id}`, dto);
  }

  public get(safeNoteId: number): Observable<ISafeNote> {
    return super.get(safeNoteId).pipe(map(SafeNotesService.formatSafeNoteDates));
  }

  public list({ activeAt, isConverted }: SafeNoteListFilter = {}): Observable<ISafeNote[]> {
    let filter: IGetSafeNoteFiltersDTO;
    if (activeAt || isValueDefined(isConverted)) {
      filter = {
        activeAt: new Date(activeAt).toISOString(),
        isConverted,
      };
    }

    return super.list(filter).pipe(map((safeNotes) => safeNotes.map(SafeNotesService.formatSafeNoteDates)));
  }

  public getEquityConversionPreview(
    safeNoteToConvert: IConvertSafeNotesToEquityDTO,
  ): Observable<ISafeNotesConversionResponseDTO[]> {
    return this._http.post<ISafeNotesConversionResponseDTO[]>(
      `${this.baseUrl}/${this.url}/convert-to-equity?preview=true`,
      safeNoteToConvert,
    );
  }

  public convertSafeNotesToShares(safeNoteToConvert: IConvertSafeNotesToEquityDTO): Observable<void> {
    return this._http.post<void>(`${this.baseUrl}/${this.url}/convert-to-equity`, safeNoteToConvert);
  }

  private static formatSafeNoteDates = (safeNote: ISafeNote): ISafeNote => ({
    ...safeNote,
    dateStart: convertStringToLocalDate(safeNote.dateStart),
    dateOfIssue: convertStringToLocalDate(safeNote.dateOfIssue),
    discounts: safeNote.discounts.map((discount) => ({
      ...discount,
      dateStart: convertStringToLocalDate(discount.dateStart),
    })),
  });
}
