import { Component, inject, Input } from '@angular/core';
import { AnalyticsService } from '@app/shared/services/analytics.service';

import { LegalDocumentsService } from '@app/shared/services/legal-documents.service';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { AnalyticsJourney, AnalyticsSubJourney } from '@app/shared/types/enums/analytics-journey.enum';
import { PageName } from '@app/shared/types/enums/page-name.enum';
import { openInNewTab } from '@app/shared/utils/helpers/common.helpers';

@Component({
  selector: 'wevestr-terms-policy-disclaimer',
  templateUrl: './terms-policy-disclaimer.component.html',
  styleUrls: ['./terms-policy-disclaimer.component.scss'],
  standalone: true,
  providers: [LegalDocumentsService],
})
export class TermsPolicyDisclaimerComponent {
  private docsService = inject(LegalDocumentsService);

  public readonly privacyPolicyUrl = this.docsService.privacyPolicy;
  public readonly termsAndConditionsUrl = this.docsService.termsAndConditions;
  @Input() public pageName: PageName;

  constructor(private analyticsService: AnalyticsService) {}

  public handlePrivacyPolicyClick($event: Event): void {
    $event.preventDefault();
    this.analyticsService.track(AnalyticsEvent.ViewPrivacyPolicy, {
      journey: AnalyticsJourney.Onboarding,
      subJourney: AnalyticsSubJourney.CompanySignup,
      pageName: this.pageName,
    });
    openInNewTab(this.privacyPolicyUrl);
  }

  public handleTermsAndConditionsClick($event: Event): void {
    $event.preventDefault();
    this.analyticsService.track(AnalyticsEvent.ViewTermsAndConditions, {
      journey: AnalyticsJourney.Onboarding,
      subJourney: AnalyticsSubJourney.CompanySignup,
      pageName: this.pageName,
    });
    openInNewTab(this.termsAndConditionsUrl);
  }
}
