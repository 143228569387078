import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyRouteNames } from '@app/company/types/enums/company-route-names.enum';
import { DocumentsRouteNames } from '@app/documents/types/enums/documents-route-names.enum';

@Component({
  selector: 'wevestr-documents-link',
  templateUrl: './documents-link.component.html',
  styleUrls: ['./documents-link.component.scss'],
})
export class DocumentsLinkComponent {
  @Input() public folderId: number;
  @Input() public count: number;
  @Input() public label: string;
  @Input() public routingLink = [CompanyRouteNames.COMPANY, DocumentsRouteNames.documents];

  constructor(private router: Router) {}

  public handleClick(): void {
    if (this.count <= 0) {
      return;
    }
    this.router.navigate(this.routingLink, { queryParams: { folderId: this.folderId } });
  }
}
