import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { UserService } from '@app/shared/services';
import { AuthService } from '@app/auth/services/auth.service';
import { loginPath } from '@app/shared/utils/constants/paths.constants';
import { AnalyticsService } from '@app/shared/services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard {
  constructor(
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private auth: AuthService,
    private router: Router,
  ) {}

  public canLoad(): Observable<boolean> {
    return this.userService.currentCompanyId$.pipe(
      switchMap((companyId) => {
        if (!companyId) {
          this.userService.setDefaultCompanyId();
        }
        return this.canActivate();
      }),
      take(1),
    );
  }

  public canActivate(): Observable<boolean> {
    if (this.userService.user?.profile) {
      return of(true);
    }
    return this.analyticsService.setProfiles$().pipe(
      map(() => true),
      catchError(() => {
        this.auth.removeTokens();
        this.router.navigate(loginPath);
        return of(false);
      }),
    );
  }
}
