<app-table-row
  [options]="options"
  [row]="rowValue"
  [index]="index"
  [isReorderEnabled]="isReorderEnabled && (!rowValue.isExpanded || rowValue.isEmpty)"
  [roundedCorners]="roundedCorners"
  (hover)="handleHover($event)"
>
  <app-table-column
    *ngIf="!(rowValue && rowValue.areSumUpValuesShown && !isRowEditing)"
    [style]="getCategoryNameColumnStyle()"
    [useFullWidth]="true"
    [isReorderIconShown]="isReorderEnabled && isHovered && (!rowValue.isExpanded || rowValue.isEmpty)"
    [isEditActionsShown]="editMode && isHovered && !isSomeElementEditing"
    [isEditingActionsShown]="isRowEditing"
    [isCollapseIconShown]="!isRowEditing && !rowValue.isEmpty"
    [isExpanded]="rowValue.isExpanded"
    [canEdit]="canEdit && isRowEditable"
    [canDelete]="canDelete && isRowDeletable && rowValue.isEmpty"
    (edit)="handleEnterRowEditMode()"
    (delete)="deleteRow.emit()"
    (save)="handleSaveRowData()"
    (cancel)="cancelSaveData.emit()"
    (toggle)="toggle.emit()"
    [isSavingDisabled]="!this.name.value?.trim()"
  >
    <div class="category-name">
      <span class="category-name__title">Category:</span>
      <ng-container *ngIf="!isRowEditing">{{ rowValue.name }}</ng-container>
      <div class="category-name__input" *ngIf="isRowEditing">
        <wevestr-input [formControl]="name"></wevestr-input>
      </div>
    </div>
  </app-table-column>

  <ng-container *ngIf="(rowValue && rowValue.areSumUpValuesShown && !isRowEditing)">
    <app-table-column
      [style]="getCategoryNameColumnStyle()"
      [isReorderIconShown]="isReorderEnabled && isHovered && (!rowValue.isExpanded || rowValue.isEmpty)"
      [isCollapseIconShown]="!rowValue.isEmpty"
      [isExpanded]="rowValue.isExpanded"
      (toggle)="toggle.emit()"
    >
      <div class="category-name"><span class="category-name__title">Category:</span>{{ rowValue.name }}</div>
    </app-table-column>

    <ng-container *ngFor="let column of getColumnsToRender(); index as columnIndex; last as last">
      <app-table-column [style]="getTdStyle(column, !editMode && last)">
        <span [innerHTML]="(rowValue | callback : column.renderer) | safeHtml"></span>
      </app-table-column>
    </ng-container>

    <app-table-column
      *ngIf="editMode"
      [style]="getTdStyle(null, true)"
      [isEditActionsShown]="editMode && isHovered && !isSomeElementEditing"
      [isEditingActionsShown]="isRowEditing"
      [canEdit]="canEdit && isRowEditable"
      [canDelete]="canDelete && isRowDeletable && rowValue.isEmpty"
      (edit)="handleEnterRowEditMode()"
      (delete)="deleteRow.emit()"
      (save)="handleSaveRowData()"
      (cancel)="cancelSaveData.emit()"
      [isSavingDisabled]="!this.name.value?.trim()"
    >
    </app-table-column>
  </ng-container>
</app-table-row>
