<div>
  <div class="app-body-bold margin-bottom-s">{{label}}</div>
</div>
<div class="file-container flex-column is-justify-content-center padding-left-m" [ngClass]="{'file-container--filled': !!value}">
  <div *ngIf="value; else noValue" class="flex-row is-justify-content-space-between is-align-items-center">
    <div class="flex-row is-align-items-center">
      <mat-icon
        class="file-icon" [svgIcon]="value.fileObject.contentType | documentExtensionIcon"
      ></mat-icon>
      <div class="flex-column full-width">
        <div class="app-h5 break-word">{{value.fileObject.fileName}}</div>
        <div class="app-body-small file-statistics flex-row is-align-items-center">{{value.fileObject.size}}MB<div class="dot"></div>Uploaded: {{value.createdAt | dateFormat: 'dd-MM-yyyy'}}</div>
      </div>
    </div>
    <app-menu *ngIf="canDelete">
      <mat-icon appMenuTrigger class="cursor-pointer menu-icon" svgIcon="more_vert"></mat-icon>
      <div appMenuContent>
        <div
          class="app-menu__item app-body-small"
          (click)="handleDelete()">
          Delete
        </div>
      </div>
    </app-menu>
  </div>
  <ng-template #noValue>
    <div class="flex-row is-align-items-center">
      <mat-icon class="file-icon" [svgIcon]="'doc_upload'"></mat-icon>
      <span class="app-body-small-bold"><a class="cursor-pointer" (click)="file.click()">Click here</a> to select the individual document you want to upload. </span>
    </div>
  </ng-template>
</div>

<input
  id="file_upload"
  type="file"
  #file
  [accept]="allowedFileExtensions"
  (change)="handleFileUpload($event.target.files)"
/>
