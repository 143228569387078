import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IAxisChartOptions, IChart } from '@app/shared/types/interfaces';
import { GREY_COLOR, LIGHT_GREY_COLOR } from '@app/shared/utils/constants/colors.constants';
import {
  AXIS_FONT_SIZE,
  CHART_TEXT_FONT_COLOR,
  CHART_FONT_FAMILY,
  LABEL_TEXT_FONT_SIZE,
  LEGEND_MARKER_SIZE,
  XAXIS_TEXT_FONT_SIZE,
} from '@app/shared/utils/constants/chart.constants';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent implements OnInit, OnChanges {
  @Input() public charts: IChart[];
  @Input() public labels: string[];
  @Input() public height: number;

  public chartOptions: Partial<IAxisChartOptions>;

  // TODO: figure out type of context, none is provided by library
  public chartContext: unknown;

  public ngOnInit(): void {
    this.initChart();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.charts && this.labels && (changes.labels || changes.values)) {
      this.initChart();
    }
  }

  //eslint-disable-next-line max-lines-per-function
  private initChart(): void {
    const yAxis = this.charts.map((chart) => ({
      name: chart.name,
      show: false,
      labels: {
        style: {
          fontSize: AXIS_FONT_SIZE,
          colors: CHART_TEXT_FONT_COLOR,
        },
      },
    }));
    this.chartOptions = {
      series: this.charts,
      chart: {
        height: this.height,
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        fontFamily: CHART_FONT_FAMILY,
        foreColor: GREY_COLOR,
        events: {
          mounted: (chartContext) => {
            this.chartContext = chartContext;
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 0,
        },
        borderColor: LIGHT_GREY_COLOR,
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        fontWeight: 'bold',
        fontSize: LABEL_TEXT_FONT_SIZE,
        markers: {
          height: LEGEND_MARKER_SIZE,
          width: LEGEND_MARKER_SIZE,
          radius: 0,
        },
      },
      yaxis: yAxis,
      xaxis: {
        type: 'category',
        categories: this.labels,
        axisBorder: {
          color: LIGHT_GREY_COLOR,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            fontSize: XAXIS_TEXT_FONT_SIZE,
            colors: CHART_TEXT_FONT_COLOR,
          },
        },
      },
    };
  }
}
