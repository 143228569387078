<section class="password-reset flex-column align-items-center">
  <h2 class="app-h4-bold text-align-center margin-bottom-s password-reset__title">Reset Your Password</h2>
  <h5 class="app-body text-align-center password-reset__description">
    Your new password must be different from previously used password
  </h5>
  <wevestr-security-form
    class="security-form"
    [privacyPolicyUrl]="privacyPolicyUrl"
    [cookieUrl]="cookieUrl"
    [termsAndConditionsUrl]="termsAndConditionsUrl"
    [isBackButtonVisible]="false"
    continueButtonTitle="Save"
    (saveInfo)="handleResetPassword($event)"
  >
  </wevestr-security-form>
</section>
