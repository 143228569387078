import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { DAY_MONTH_YEAR_DATE_FORMAT } from '@app/shared/utils/constants/date.constants';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public transform(value: string | number | Date, format: string = DAY_MONTH_YEAR_DATE_FORMAT): string {
    const isValueNotNullish = value ?? false,
      isInvalidDate = value instanceof Date && isNaN(value.getTime());
    if (!isValueNotNullish || isInvalidDate) {
      return '';
    }

    return formatDate(value, format, this.locale);
  }
}
