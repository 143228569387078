import { filter, propEq } from 'ramda';

const CATEGORY_ID = 'category';

export const findMetricsByCategoryId = <T extends { categoryId?: string | number }>(
  categoryId: string | number,
  metrics: T[],
): T[] => filter(propEq<string>('categoryId', transformToCategoryRealId(categoryId)), metrics);

export const transformToCategoryRowId = (id: string | number): string => {
  return id ? `${CATEGORY_ID}_${id}` : null;
};

export const transformToCategoryRealId = (id: number | string): number => +String(id).replace(`${CATEGORY_ID}_`, '');
