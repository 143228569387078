// TODO: use bff typed permissions
export enum Permission {
  dashboardAccess = 'dashboardAccess',
  capTableAccess = 'capTableAccess',
  debtsAccess = 'debtsAccess',
  financialsAccess = 'financialsAccess',
  kpisAccess = 'kpisAccess',
  // TODO: rename to slicing pie access accross the app
  frameworkAccess = 'frameworkAccess',
  documentsAccess = 'documentsAccess',
  esopAdminAccess = 'esopAdminAccess',
  scenarioModelingAccess = 'scenarioModelingAccess',
  companySettingsAccess = 'companySettingsAccess',
  stakeholdersAccess = 'stakeholdersAccess',
  esopParticipantAccess = 'esopParticipantAccess',
  homeAccess = 'homeAccess',
}

export type EditablePermission = Exclude<Permission, Permission.homeAccess>;

export enum BulkPermission {
  bulkPermission = 'bulkPermission',
}
