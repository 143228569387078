<div class="container">
  <div *ngIf="imageSrc; else defaultImage" class="custom-image">
    <img [src]="imageSrc" />
  </div>
  <ng-template #defaultImage>
    <div *ngIf="isImageVisible">
      <img src="assets/images/empty_astronaut.svg" />
    </div>
  </ng-template>

  <div *ngIf="title" class="empty-title app-body-bold">
    {{ title }}
  </div>
  <div class="empty-description app-body-small-const">
    {{ description }}
  </div>
  <!-- TODO: replace with wvHasEditAccess after finding the way to deal with shared component -->
  <div *appHasRole="userRoles.ADMIN">
    <ng-container *ngTemplateOutlet="buttonTemplate"> </ng-container>
  </div>
</div>
