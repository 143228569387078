import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { iif, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { FULL_WIDTH, MODAL_PANEL_CLASS } from '@app/shared/utils/constants/common.constants';
import { SetPhasesTimelineComponent } from '@app/settings/containers/set-phases-timeline/set-phases-timeline.component';
import { FrameworkService } from '@app/shared/services/framework.service';
import { CompanySettingsService } from '@app/shared/services/company-settings.service';
import { ToastService } from '@app/shared/services/toast.service';
import { ICompanySettings } from '@app/shared/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PhasesTimelineService {
  constructor(
    private dialog: MatDialog,
    private frameworkService: FrameworkService,
    private toastService: ToastService,
    private companySettingsService: CompanySettingsService,
  ) {}

  public openSetPhasesTimelineModal(): Observable<boolean> {
    const MAX_WIDTH = '500px';

    return this.dialog
      .open(SetPhasesTimelineComponent, {
        autoFocus: false,
        panelClass: MODAL_PANEL_CLASS,
        maxWidth: MAX_WIDTH,
        maxHeight: '90vh',
        width: FULL_WIDTH,
        disableClose: true,
      })
      .afterClosed();
  }

  public startSetup(): Observable<ICompanySettings> {
    const isSinglePhasedSlicingPie = this.companySettingsService.isSinglePhasedSlicingPie;
    return this.frameworkService.setup().pipe(
      switchMap(() => (isSinglePhasedSlicingPie ? of(true) : this.openSetPhasesTimelineModal())),
      switchMap((isSuccess: boolean) =>
        iif(() => isSuccess, this.companySettingsService.edit({ isFrameworkSetup: true })),
      ),
      tap(() => this.handleSuccessfulSetup()),
    );
  }

  private handleSuccessfulSetup(): void {
    this.toastService.success('Framework setup successfully!');
  }
}
