import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isNewValue } from '@app/shared/utils/helpers/ng.helpers';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {
  @Input()
  public set size(size: number) {
    this.sizeInPx = `${size}px`;
  }
  @Input()
  public name: string;
  @Input()
  public userElipsisPlaceholder: number;

  @Input('image')
  public imageUrl: string;

  public backgroundImageUrl: string;
  public sizeInPx = '32px';
  public readonly DEFAULT_FONT_SIZE = '14px';

  public ngOnChanges(changes: SimpleChanges): void {
    if (isNewValue(changes, 'imageUrl')) {
      this.backgroundImageUrl = this.imageUrl && `url("${this.imageUrl}")`;
    }
  }
}
