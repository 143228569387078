import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '@app/services/config.service';
import { COMPANY_ID_TOKEN } from '@app/services/company-id/company-id.constants';
import { ICategory } from '@app/performance/types/interfaces';
import { CategoryService } from '@app/performance/services/category.service';

@Injectable({
  providedIn: 'root',
})
export class KpisCategoryService extends CategoryService<ICategory> {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService);
  }

  get url(): string {
    return `companies/${COMPANY_ID_TOKEN}/kpis/categories`;
  }
}
