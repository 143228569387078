/**
 * @internal Following date format should not be used for display of values
 */
export const YEAR_MONTH_DAY_FORMAT = 'yyyy-MM-dd';

export const DAY_MONTH_YEAR_DATE_FORMAT = 'dd-MM-yyyy';
export const DAY_FULL_MONTH_YEAR_DATE_FORMAT = 'dd MMM yyyy';
export const FULL_MONTH_DAY_YEAR_DATE_FORMAT = 'MMMM dd, yyyy';
export const MONTH_YEAR_DATE_FORMAT = 'MM-yyyy';
export const FULL_MONTH_YEAR_DATE_FORMAT = 'MMMM yyyy';
export const FULL_MONTH_DAY_TIME_FORMAT = 'MMMM d, h:mm a';
export const FULL_MONTH_YEAR_TIME_FORMAT = 'd MMMM, yyyy, HH:mm';

export const INVALID_DATE = new Date(NaN);
export const DAY_MONTH_YEAR_LENGTH = DAY_MONTH_YEAR_DATE_FORMAT.length;
export const NUMBER_OF_MONTH = 12;
export const MAX_NUMBER_OF_DAYS = 31;
export const FIRST_DAY_IN_MONTH_NUMBER = 1;

export const UTC_TIMEZONE = 'UTC';
