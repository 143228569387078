import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
  CONTAINS_CAPITAL_LETTER,
  CONTAINS_DIGIT,
  CONTAINS_LOWERCASE_LETTER,
} from '@app/shared/utils/helpers/regex.helpers';

export const PASSWORD_VALIDATION_RULES = [CONTAINS_DIGIT, CONTAINS_LOWERCASE_LETTER, CONTAINS_CAPITAL_LETTER];

export const getStringValidatorByRegExp = (rules: RegExp[], errorFieldName: string): ValidatorFn => {
  return ({ value }: AbstractControl) => {
    const inputString: string = value ?? '',
      isStringValid = rules.every((rule) => inputString.match(rule));
    return isStringValid ? null : { [errorFieldName]: true };
  };
};
