import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { ESOPVestingDistribution } from '@app/esop/plan-management/types/distribution-type.enum';
import { IVestingEvent } from '@app/esop/plan-management/types/vesting-event.interface';
import { VestingDirectionsType } from '@app/esop/plan-management/types/vesting-direction.interface';
import { distributionOptions } from '@app/esop/plan-management/constants';

export interface ICustomVestingFormValues {
  vestingDirection?: VestingDirectionsType;
  vestingSchedules: IVestingEvent[];
  distribution: ESOPVestingDistribution;
}

export interface CustomVestingAllocationFormItem {
  allocation: FormControl<number | null>;
  vestingDate: FormControl<Date | null>;
}

export interface CustomVestingForm {
  allocations: FormArray<FormGroup<CustomVestingAllocationFormItem>>;
  distribution: FormControl<typeof distributionOptions[number] | null>;
}
