import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format2faSecretCode',
})
export class Format2faSecretCodePipe implements PipeTransform {
  transform(value: string): unknown {
    return value.replace(/(.{4})/g, '$1 ');
  }
}
