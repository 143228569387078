<section class="forgot-credentials flex-column">
  <h2 class="app-h4-bold text-align-center margin-bottom-s forgot-credentials__title">Forgot Password?</h2>
  <h5 class="app-body text-align-center margin-bottom-m forgot-credentials__description">
    No worries, we send you instructions for resetting your password
  </h5>
  <form class="margin-top-l-const flex-column align-items-center form" [formGroup]="form">
    <wevestr-input
      dataId="Input-EmailAddress"
      label="Email"
      formControlName="email"
      placeholder="Enter your email address"
      autocomplete="email"
      type="email"
      class="full-width"
    >
      <ng-container errors>
        <div wvFormError="required" class="error-message app-body-small">
          {{ MESSAGES.REQUIRED }}
        </div>
        <div wvFormError="email" class="error-message app-body-small">
          {{ MESSAGES.EMAIL }}
        </div>
      </ng-container>
    </wevestr-input>
    <button
      data-id="Button-Continue"
      [disabled]="form.invalid"
      class="button primary-button app-body full-width margin-bottom-l"
      (click)="handleSendResetLink()"
      type="submit"
    >
      Reset Password
    </button>
    <div
      (click)="navigateToLogin()"
      class="back-button"
    >
      Back
    </div>
  </form>
</section>
