import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(private readonly router: Router, private auth: AuthService) {}
  public canActivate(): boolean {
    if (!this.auth.accessToken) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
