import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

import { IConfirmationAction } from '@app/shared/types/interfaces/confirmation-action.interface';

@Component({
  templateUrl: './custom-confirmation-modal.component.html',
  styleUrls: ['./custom-confirmation-modal.component.scss'],
})
export class CustomConfirmationModalComponent {
  public inputControl = new FormControl('');
  public confirmation = this.action.confirmationString;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public action: IConfirmationAction,
    private dialogRef: MatDialogRef<CustomConfirmationModalComponent>,
  ) {}

  public handleClose(): void {
    this.dialogRef.close();
  }

  public handleConfirm(): void {
    const isActionConfirmed = true;
    this.dialogRef.close(isActionConfirmed);
  }
}
