import { Component, Input } from '@angular/core';
import { IBreadcrumb } from '@app/shared/types/interfaces';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: IBreadcrumb[];
  @Input() disabled: boolean;
}
