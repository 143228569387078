import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { ConfigService } from '@app/services/config.service';
import { ICountry } from '@app/types/interfaces/profile.interface';
import { ICompanyRole } from '@app/shared/types/interfaces/company-role.interface';
import { IIndustry } from '@app/shared/types/interfaces/industry.interface';

@Injectable({
  providedIn: 'root',
})
export class StaticDataService {
  private readonly baseUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.baseUrl = this.config.getFullBaseUrl();
  }

  public getCountries(): Observable<ICountry[]> {
    return this.http.get<ICountry[]>(`${this.baseUrl}/${this.url}/countries`).pipe(pluck('countries'));
  }

  public getCompanyRoles(): Observable<ICompanyRole[]> {
    return this.http.get(`${this.baseUrl}/${this.url}/company-roles`).pipe(pluck('roles'));
  }

  public getIndustries(): Observable<IIndustry[]> {
    return this.http.get(`${this.baseUrl}/${this.url}/industries`).pipe(pluck('industries'));
  }

  private get url(): string {
    return 'data';
  }
}
