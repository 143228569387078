import { pipe, last, path } from 'ramda';

import { BytesUnit } from '@app/shared/types/enums/bytes-unit.enum';
import {
  BYTES_IN_KILOBYTE,
  UNITS_SORTED_IN_ASCENDING_ORDER,
  DECIMALS_NUMBER_FOR_UNITS,
  UNRECOGNIZED_EXTENSION,
} from '@app/shared/utils/constants/files.constants';
import { getLogByBase } from '@app/shared/utils/helpers/math.helpers';
import { isString } from '@app/shared/utils/helpers/common.helpers';

export function formatFileSizeToReadableFormat(fileSizeInBytes: number): string {
  if (fileSizeInBytes === 0) {
    return 'Empty file';
  }

  const maxUnit = determineMaxUnit(fileSizeInBytes);
  const numberBasedOnUnit = getNumberBasedOnUnit(fileSizeInBytes, maxUnit);

  return `${numberBasedOnUnit.toFixed(DECIMALS_NUMBER_FOR_UNITS)} ${maxUnit}`;
}

function determineMaxUnit(bytesNumber: number): BytesUnit {
  return pipe(getLogByBase(BYTES_IN_KILOBYTE), Math.floor, getMaxUnitForKilobytesPower)(bytesNumber);
}

function getMaxUnitForKilobytesPower(kilobytesPower: number): BytesUnit {
  return UNITS_SORTED_IN_ASCENDING_ORDER[kilobytesPower];
}

function getKilobytesPowerForUnit(bytesUnit: BytesUnit): number {
  const kilobytesPower = UNITS_SORTED_IN_ASCENDING_ORDER.findIndex((unit) => bytesUnit === unit);

  return kilobytesPower;
}

export function getNumberBasedOnUnit(bytesNumber: number, bytesUnit: BytesUnit): number {
  const unitKilobytesPower = getKilobytesPowerForUnit(bytesUnit);

  return bytesNumber / Math.pow(BYTES_IN_KILOBYTE, unitKilobytesPower);
}

export function isAllowedFileExtension(allowedExtensions: string[], extension: string): boolean {
  return allowedExtensions.includes(`.${extension}`);
}

export function getFilesArrayFromFileList(fileList: FileList): File[] {
  return Array.from(fileList);
}

export const getFileExtension = pipe(path(['name']), getExtensionFromFileName);

export function getExtensionFromFileName(fileName: string): string {
  let extension: string = UNRECOGNIZED_EXTENSION;

  if (!fileName || !isString(fileName)) {
    return extension;
  }

  const nameParts = fileName.split('.');
  const isExtensionPresent = nameParts.length > 1;

  if (isExtensionPresent) {
    extension = last(nameParts) || UNRECOGNIZED_EXTENSION;
  }

  return extension;
}
